import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './UpgradeModal.module.less'
import { Button, Modal } from 'antd'
import { useTranslationWithType } from '@/i18n/i18n'
import { commonUtils } from '@/helpers/commonUtils'
import PlanItem from '../PlanItem/PlanItem'
import { pricingModal } from '@/helpers/api/pricing'
import { userService } from '@/services/userService'
import AmeIcon from '../AmeIcon/AmeIcon'
import { vipService } from '@/services/vipService'
import { routerService } from '@/services/routerService'
import ContactText from '../ContactText/ContactText'
import { PdfUpgradeModalType } from '../PdfUpgradeModal'

/** UpgradeModal 组件的 Props */
export type UpgradeModalProps = {
  open: boolean
  triggerType: PdfUpgradeModalType
  onCancel: () => void
  onPaid: () => void
  limitAlertStr?: string
}

const UpgradeModal: FC<UpgradeModalProps> = observer(
  ({ open, onCancel, triggerType, limitAlertStr, onPaid }: UpgradeModalProps) => {
    const { t } = useTranslationWithType()
    const [plan, setPlan] = useState<pricingModal.VipPlan | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
      if (!open) {
        return
      }
      const version = userService.vipVersion
      if (version === 'pro+') {
        return
      }
      userService.trackUserRecord('show_upgrade_modal')
      vipService.updatePlanList().then((planList) => {
        setPlan(
          planList.find((p) => {
            if (p.timeType !== 'year_subscription') {
              return false
            }
            if (version === 'free' && p.version === 'pro') {
              return true
            }
            if (version === 'pro' && p.version === 'pro+') {
              return true
            }
            return false
          }) || null
        )
      })
    }, [open])
    return (
      <Modal
        open={open}
        centered
        wrapClassName={classNames(style.upgradeModal, {
          [style.free]: userService.vipVersion === 'free',
        })}
        footer={false}
        width={'716px'}
        onCancel={onCancel}
      >
        <div
          className={classNames(style.upgradeBoxContent, 'flex-col', {
            [style.proV]: userService.vipVersion === 'pro+',
          })}
        >
          <p className={classNames(style.title)}>{t('upgradeModal.title')}</p>
          {userService.vipVersion === 'pro+' && (
            <>
              <img
                className={classNames(style.bg)}
                src={commonUtils.getStaticFile('pricing/bg__pdf-limited.png')}
              />
              <p className={classNames(style.text)}>
                {limitAlertStr || t('upgradeModal.limitAlertStr')}
              </p>
              <p className={classNames(style.text)}>{t('upgradeModal.contactStr')}</p>
            </>
          )}
          {userService.vipVersion !== 'pro+' && (
            <>
              <div className={classNames(style.desc)}>
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('pricing/icon__fireworks.png')}
                />
                <span>{limitAlertStr}</span>
              </div>

              {/*  套餐 */}
              {!!plan && <PlanItem plan={plan} active></PlanItem>}

              <Button
                type='primary'
                loading={loading}
                className={style.buyBtn}
                onClick={() => {
                  if (!plan) {
                    return
                  }
                  if (loading) {
                    return
                  }
                  userService.trackUserRecord('click_upgrade_modal_btn')
                  setLoading(true)
                  vipService.checkout(
                    { planId: plan!.id, source: `upgradeModal_${triggerType}` },
                    {
                      dataErrorCallback: () => {
                        setLoading(false)
                      },
                      dataPopupClosed: () => {
                        setLoading(false)
                      },
                      dataPopupWebhookReceived: () => {
                        setLoading(false)
                        onPaid()
                      },
                    }
                  )
                }}
              >
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('pricing/icon__rockets.png')}
                />
                <span>{t('upgradeModal.btn')}</span>
              </Button>

              <p
                className={classNames(style.more)}
                onClick={() => {
                  userService.trackUserRecord('click_upgrade_modal_more_btn')
                  routerService.navigateTo('pricing')
                }}
              >
                <span>{t('upgradeModal.more')}</span>
                <AmeIcon
                  type='icon-line-global_arrow'
                  className={classNames(style.ameIcon)}
                ></AmeIcon>
              </p>

              {userService.vipVersion !== 'free' && (
                <ContactText
                  style={{ fontSize: '14px', position: 'absolute', left: '20px', bottom: '20px' }}
                ></ContactText>
              )}
            </>
          )}
        </div>
      </Modal>
    )
  }
)

export default UpgradeModal
