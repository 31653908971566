import EN_TRANSLATION from './locales/en/translation'

// type FlattenKeys<T, Prefix extends string = ''> = {
//   [K in keyof T]: T[K] extends object ? FlattenKeys<T[K], `${Prefix}${K}.`> : `${Prefix}${K}`
// }[keyof T]

// type TranslationKey = FlattenKeys<TranslationData>

export type FlattenKeys<T extends Record<string, any>, P extends string = ''> = {
  [K in keyof T]: T[K] extends Record<string, any>
    ?
        | `${Extract<K, string> extends never ? '' : `${P}${Extract<K, string>}.`}`
        | FlattenKeys<T[K], `${P}${Extract<K, string>}.`>
    : `${P}${Extract<K, string>}`
}[keyof T]
export type TranslationKey = Exclude<FlattenKeys<typeof EN_TRANSLATION>, `${string}.`>

export const SUPPORT_LANG = ['zh', 'en']

class I18nService {
  public t: (key: TranslationKey) => string
  public uiLang: string = 'en'
  public i18n: any
  constructor() {
    // 在 ViewModel 的构造函数中不使用 useTranslation 钩子
    this.t = (key: TranslationKey) => key // 默认返回 key，可以根据需要自定义翻译逻辑
  }

  public setTranslationFunction(t: (key: TranslationKey) => string) {
    // 在组件中将 useTranslation 的 t 函数传递给 ViewModel
    this.t = t
  }
}

export const _i18n = new I18nService()
