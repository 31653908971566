const TRANSLATION = {
  slogen: 'Nejjednodušší asistent AI',
  pageHeader: {
    help: 'Centrum nápovědy',
    contact: 'Kontaktujte nás',
    pricing: 'Ceny',
    signUp: 'Přihlásit se',
    settings: 'Nastavení',
    logout: 'Odhlásit se',
    logoutSucc: 'Odhlášení proběhlo úspěšně',
  },
  pageFooter: {
    product: {
      title: 'produkty',
      web: 'Webový překlad a shrnutí',
      pdf: 'Překlad PDF',
      video: 'Překlad a shrnutí videa',
      image: 'Překlad obrázků',
    },
    resources: { title: 'Zdroje' },
    follow: { title: 'Následovat', twitter: 'Cvrlikání', facebook: 'Facebook' },
    legal: { title: 'Právní', policy: 'Zásady ochrany osobních údajů', terms: 'Podmínky služby' },
  },
  home: {
    getStart: 'Začít',
    getStartedForFree: 'Začněte zdarma',
    top: {
      desc: 'Noam je bezplatné rozšíření prohlížeče AI, které vám pomůže přeložit souhrnné webové stránky, videa a obsah PDF.\n      Připojte se k Noamovi a užijte si kouzlo!',
      title2: 'Jedno kouzlo',
      title1: 'Jeden klik',
    },
    howItWork: {
      title: 'Tak jak to funguje',
      desc: 'Noam integruje modely umělé inteligence, aby uživatelům poskytl inteligentní prostředí pro překlady a shrnutí webových stránek mezi jazyky, překlady PDF a obrázků, překlady videa a shrnutí',
    },
    PDFTranslate: {
      title: 'Překlad PDF',
      desc: 'Dvojjazyčný překlad + chat PDF',
      sub1: {
        title: 'Dvojjazyčné čtení',
        desc: 'Dvojjazyčné čtení, takže můžete při čtení porovnávat původní text a překlad a zlepšit tak efektivitu čtení',
      },
      sub2: {
        title: 'Chat PDF',
        desc: 'Umělá inteligence analyzuje a shrnuje a může odpovídat na vaše otázky na základě obsahu PDF',
      },
      sub3: {
        title: 'Přesnost a účinnost',
        desc: 'PDF s podporou umělé inteligence pro přesnější a efektivnější překlady',
      },
    },
    aiAssistant: {
      title: 'Asistent AI',
      desc: 'Webový překlad bude chytřejší a pohodlnější',
      panel1: {
        title: 'Dvojjazyčný překlad',
        desc: 'Dvojjazyčné překlady s křížovými odkazy vám pomohou porovnat původní text s překladem, abyste lépe porozuměli a zlepšili efektivitu čtení.',
      },
      panel2: {
        left: {
          title: 'Překlad po najetí myší',
          desc: 'Při procházení webové stránky umístěte ukazatel myši přímo na obsah, který je třeba přeložit, abyste získali výsledek překladu.',
        },
        right: {
          title: 'Překlad výběru textu',
          desc: 'Při procházení webové stránky přímo vyberte text, který má být podtržen, abyste získali výsledek překladu.',
        },
      },
      panel3: {
        one: {
          title: 'Ukaž a klikni',
          desc: 'Překládejte a sumarizujte webové stránky a video obsah za vás kdykoli, kdekoli a v jakémkoli scénáři; přináší hladší zážitek!',
        },
        two: {
          title: 'Komplexnější a pohodlnější',
          desc: 'Vícejazyčné plné pokrytí, dvojjazyčné bezbariérové ​​čtení, překlad jedním tlačítkem; přinese vám jedinečný zážitek!',
        },
        three: {
          title: 'řízený AI',
          desc: 'Velký model řízený umělou inteligencí, který zajišťuje přesnost a konzistenci překladu a zlepšuje zážitek ze čtení',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Překlad a shrnutí videa',
          desc: 'Váš odborník na efektivitu v kombinaci s chatgpt překládá a shrnuje videoobsah YouTube, aby vám ušetřil čas při čtení!',
        },
        tab2: {
          key: 'obraz',
          title: 'Překladač obrázků',
          desc: 'Kliknutím přeložíte obrázky při procházení webu',
        },
        tab3: {
          key: 'Web',
          title: 'Webový překlad a shrnutí',
          desc: 'Rychlé extrahování klíčových informací z webových stránek pro shrnutí a zvýšení efektivity čtení',
        },
        tag1: 'Jednoduchý',
        tag2: 'Účinnost',
        tag3: 'Snadno srozumitelné',
      },
    },
    userReviews: {
      title: 'uživatelské recenze',
      desc: 'Pětihvězdičková recenze na chromewebstore',
    },
    lastBlock: { desc: 'Lepší překlad AI a hladší zážitek ze čtení' },
  },
  login: {
    title: 'Vítejte v destinaci Noam',
    btn: 'Přihlaste se pomocí Google',
    tips: 'Přihlášením souhlasím',
    terms: 'Podmínky použití',
    and: 'a',
    policy: 'Zásady ochrany osobních údajů',
    btn1: 'Přihlas se nyní',
    desc: 'Chcete-li odemknout další zážitky, přihlaste se',
  },
  pricing: {
    pageTitle: 'Ceny',
    desc: 'Porovnejte a vyberte si to nejlepší pro vás',
    yearly: 'Každoročně',
    monthly: 'Měsíční',
    discount: '20% sleva',
    preMonth: 'Za měsíc',
    billedYearly: 'Účtováno ročně',
    billedMonthly: 'Účtováno měsíčně',
    detailedCompare: 'Podrobné srovnání',
    FAQ: 'FAQ',
    day: 'den',
    month: 'Měsíc',
    around: 'Kolem',
    free: {
      benefit1: 'Okamžitý překlad 100x denně',
      benefit2: 'Webový překlad a shrnutí',
      benefit3: '30 listů Překlad obrázku',
      benefit4: 'Překlad a shrnutí videa',
      benefit5: '10 Překlad PDF',
      benefit6: '5 000 žetonů',
      recommendText: 'Zkušební verze zdarma',
      btn: 'Bavte se',
    },
    pro: {
      benefit1: 'Zákaznický servis Rychlá podpora',
      benefit2: '1 000 000 tokenů/měsíc',
      benefit3: 'Webový překlad a shrnutí',
      benefit4: '200 listů Překlad obrázků',
      benefit5: 'Překlad a shrnutí videa',
      benefit6: '200 PDF Překlad',
      btn: 'Začít',
      recommendText: 'Většina lidí si vybírá',
    },
    proPlus: {
      benefit1: 'Zákaznický servis Rychlá podpora',
      benefit2: '3 000 000 tokenů/měsíc',
      benefit3: 'Webový překlad a shrnutí',
      benefit4: '500 listů Překlad obrázků',
      benefit5: 'Překlad a shrnutí videa',
      benefit6: '500 PDF Překlad',
      recommendText: 'Nejvhodnější volba',
    },
    enterprise: {
      benefit1: 'Zákaznický servis Rychlá podpora',
      benefit2: 'Multi uživatel',
      benefit3: 'Řešení na míru',
      benefit5: 'Exkluzivní Account Manager',
      recommendText: 'Přizpůsobené plányＥ≥3人）',
      talkToUs: 'Mluvte s námi',
      btn: 'Kontakt',
    },
    web: {
      contrastiveTranslation: 'Dvojjazyčný překlad',
      instantTranslation: 'Okamžitý překlad',
      hoverTranslation: 'Překlad po najetí myší',
      dictionaryTranslation: 'Překlad výběru textu',
      summary: 'Shrnutí webové stránky',
    },
    imageTranslation: 'Překlad obrázků',
    video: { translation: 'Překlad videa', summary: 'Shrnutí videa' },
    pdf: {
      translation: 'PDF překlad',
      maxPage: 'Maximální počet stránek v PDF',
      chat: 'Počet dotazů na PDF',
    },
    FQAObj: {
      q1: 'Mohu jej používat zdarma?',
      a1: 'K dispozici je bezplatná verze Noam, kterou lze použít přímo.',
      q2: 'Existují různé členské plány a možnosti poplatků?',
      a2: 'Kromě bezplatné verze existují tři členské plány, Pro, Pro+ a Enterprise; můžete si vybrat mezi dvěma způsoby platby, průběžnou měsíční a průběžnou roční, přičemž komplexní náklady průběžné roční jsou výhodnější.',
      q3: 'Jaký je rozdíl mezi nepřetržitým měsíčním předplatným a nepřetržitým ročním předplatným?',
      a3: 'Ve funkčnosti není žádný rozdíl. Celkové náklady na nepřetržité roční předplatné jsou nižší než na nepřetržité měsíční předplatné; měsíční předplatné se účtuje jednou měsíčně, zatímco roční předplatné se účtuje jednou ročně.',
      q4: 'Jak zruším svůj plán automatického obnovení?',
      a4: 'Chcete-li plán obnovení zrušit, můžete přejít na stránku nastavení backendu a zvolit si předplatné.',
    },
  },
  upgradeModal: {
    title: 'Připomenutí',
    limitAlertStr:
      'Kvóta vašeho bezplatného plánu byla vyčerpána, doporučujeme vám upgradovat plán, abyste mohli pokračovat v používání~!',
    upgradeAlertStr:
      'Kvóta vašeho bezplatného plánu byla vyčerpána, doporučujeme vám upgradovat plán, abyste mohli pokračovat v používání~!',
    btn: 'Vylepšit nyní',
    more: 'Další plány',
    contactStr: 'Počkejte prosím na další reset nebo nás kontaktujte na contact@noam.tools.',
    upload: {
      free: 'Dosáhli jste limitu překladu pdf',
      pro: 'V tomto měsíci jste dosáhli limitu počtu překladů ve formátu pdf',
    },
    chat: {
      free: 'Dosáhli jste svého limitu překročení limitu Chatpdf',
      pro: 'V tomto měsíci jste dosáhli svého limitu Chatpdf',
    },
    pageLimit: { free: 'Dosáhli jste limitu překročení limitu stránek PDF' },
  },
  buyModal: {
    title: 'Plán předplatného',
    freeTrial: '3denní bezplatná zkušební verze',
    selectLabel: 'Vyberte plán',
    btn: 'Vyzkoušejte zdarma',
    yearLabel: 'rok',
    monthLabel: 'Měsíc',
    afterFree: 'Po soudu',
  },
  privacy: {
    title: 'Zásady ochrany osobních údajů',
    updateTime: 'Aktualizováno: 15. března 2024',
    block1:
      'My, Noam (dále jen „Noam“, „my“, „naše“ nebo „nás“), respektujeme vaše soukromí a zavazujeme se chránit veškeré informace, které od vás nebo o vás získáme. Tyto zásady ochrany osobních údajů popisují naše postupy týkající se shromažďování nebo používání osobních údajů z vašeho používání našich webových stránek, aplikací a služeb (souhrnně označovaných jako „služby“). Tyto zásady ochrany osobních údajů se nevztahují na obsah, který zpracováváme jménem zákazníků našich obchodních produktů, jako je naše API. Použití těchto údajů podléhá naší zákaznické smlouvě, která se vztahuje na přístup k těmto produktům a jejich používání.',
    subTitle1: 'Osobní údaje, které shromažďujeme',
    block2: 'Shromažďujeme následující osobní údaje („Osobní údaje“), které se vás týkají:',
    block3:
      'Informace, které nám poskytnete: Pokud si vytvoříte účet, abyste mohli používat naše služby nebo s námi komunikovat, budeme shromažďovat osobní údaje následovně:',
    subBlock1:
      'Informace o účtu: Když si na naší platformě vytvoříte účet, shromažďujeme informace související s vaším účtem, včetně vašeho jména, kontaktních údajů, přihlašovacích údajů k účtu, informací o platební kartě a historie transakcí (dále jen „informace o účtu“).',
    subBlock2:
      'Uživatelský obsah: Když používáte naše služby, shromažďujeme osobní údaje z vašich vstupů, nahraných souborů nebo zpětné vazby, kterou nám poskytnete (dále jen „obsah“).',
    subBlock3:
      'Komunikační informace: Pokud nám posíláte zprávy, budeme shromažďovat vaše jméno, kontaktní údaje a obsah zpráv, které odesíláte (dále jen „informace o komunikaci“).',
    subBlock4:
      'Informace o sociálních médiích: Máme stránky na webech sociálních médií, jako je Instagram, Facebook, Medium, Twitter, YouTube a LinkedIn. Když komunikujete s našimi stránkami sociálních médií, shromažďujeme osobní údaje, které se nám rozhodnete poskytnout, jako jsou vaše kontaktní údaje (dále jen „sociální informace“). Společnosti hostující naše stránky na sociálních sítích nám navíc mohou poskytovat souhrnné informace a analýzy o našich aktivitách na sociálních sítích.',
    block4:
      'Osobní údaje automaticky získané z vašeho používání služeb: Když přistupujete ke službám, používáte je nebo s nimi komunikujete, dostáváme následující informace o vašem přístupu, používání nebo interakci („Technické informace“):',
    subBlock5:
      'Log Data: Informace automaticky zasílané vaším prohlížečem při používání našich služeb. Data protokolu zahrnují vaši adresu internetového protokolu (IP), typ a nastavení prohlížeče, datum a čas vašich požadavků a způsob, jakým komunikujete s našimi webovými stránkami.',
    subBlock6:
      'Údaje o používání: Můžeme automaticky shromažďovat informace o vašem používání služby, jako jsou typy obsahu, který si prohlížíte nebo se kterým pracujete, funkce, které používáte, a vaše podniknuté akce, stejně jako vaše časové pásmo, zemi, datum a čas přístup, uživatelský agent a verze, typ počítače nebo mobilního zařízení a připojení k počítači.',
    subBlock7:
      'Informace o zařízení: Obsahuje název zařízení, operační systém, identifikátor zařízení a prohlížeč, který používáte. Shromážděné informace mohou záviset na typu zařízení, které používáte, a jeho nastavení.',
    subBlock8:
      'Cookies: Cookies používáme k provozování a správě našich služeb a zlepšování vašeho zážitku. „Cookie“ je informace odeslaná webovou stránkou, kterou navštívíte, do vašeho prohlížeče. Svůj prohlížeč můžete nastavit tak, aby přijímal všechny soubory cookie, odmítal všechny soubory cookie nebo vás upozornil, když je soubor cookie poskytnut, abyste se mohli rozhodnout, zda jej pokaždé přijmete. Odmítnutí souborů cookie vám však může zabránit v používání nebo negativně ovlivnit zobrazení nebo funkčnost určitých oblastí nebo funkcí webových stránek. Další informace o souborech cookie naleznete na stránce Vše o souborech cookie.',
    subBlock9:
      'Analytika: Můžeme používat různé online analytické produkty, které používají soubory cookie, aby nám pomohly analyzovat, jak uživatelé používají naše služby, a zlepšit váš zážitek při používání služeb.',
    subTitle2: 'Jak používáme osobní údaje',
    block5: 'Osobní údaje můžeme použít pro následující účely:',
    subBlock10: 'Poskytování, správa, údržba a/nebo analyzování služeb;',
    subBlock26: 'zlepšování našich služeb a provádění výzkumu;',
    subBlock11: 'Komunikace s vámi;',
    subBlock12: 'Vývoj nových projektů a služeb;',
    subBlock13:
      'Prevence podvodů, trestné činnosti nebo zneužití našich služeb a ochrana bezpečnosti našich IT systémů, architektury a sítí;',
    subBlock14: 'Provádění převodů podniků;',
    subBlock15:
      'Dodržování zákonných povinností a soudních řízení, ochrana našich práv, soukromí, bezpečnosti nebo majetku, jakož i práv našich přidružených společností, vás nebo jiných třetích stran.',
    block6:
      'Souhrnné informace nebo informace o zrušení identifikace: Můžeme agregovat nebo zrušit identifikaci osobních údajů, abychom zabránili jejich opětovnému použití pro účely identifikace, a použít tyto informace k analýze účinnosti našich služeb, vylepšování a přidávání funkcí do našich služeb, provádění výzkumu a pro podobné účely. . Kromě toho můžeme analyzovat obecné chování a charakteristiky uživatelů našich služeb a sdílet souhrnné informace s třetími stranami, zveřejňovat takové souhrnné informace nebo je obecně zpřístupňovat. Můžeme shromažďovat souhrnné informace prostřednictvím služeb, souborů cookie a jinými prostředky popsanými v těchto Zásadách ochrany osobních údajů. Budeme udržovat a používat deidentifikované informace v anonymní nebo deidentifikované formě a nebudeme se pokoušet takové informace znovu identifikovat, pokud to nevyžaduje zákon.',
    subTitle3: 'Zveřejnění osobních údajů',
    block7:
      'V určitých případech můžeme vaše osobní údaje poskytnout třetím stranám bez dalšího upozornění, pokud to nevyžaduje zákon:',
    subBlock16:
      'Dodavatelé a poskytovatelé služeb: Abychom nám pomohli při plnění obchodních provozních potřeb a provádění určitých služeb a funkcí, můžeme poskytovat osobní údaje dodavatelům a poskytovatelům služeb, včetně poskytovatelů hostingových služeb, poskytovatelů cloudových služeb a dalších poskytovatelů služeb informačních technologií, softwaru pro e-mailovou komunikaci a poskytovatelé služeb webové analýzy atd. Tito partneři budou při plnění svých povinností pro nás přistupovat, zpracovávat nebo ukládat osobní údaje pouze na náš pokyn.',
    subBlock17:
      'Převody podniků: Pokud se zapojíme do strategických transakcí, reorganizací, bankrotů, převzetí bankrotu nebo převodu služeb k jinému poskytovateli (společně označované jako „Transakce“), vaše osobní údaje a další informace mohou být sdíleny s protějšky transakce a dalšími stranami, které pomáhají při Transakce v rámci due diligence a převedeny v rámci Transakcí spolu s dalším majetkem na nástupnickou nebo přidruženou společnost.',
    subBlock18:
      'Právní požadavky: Můžeme sdílet vaše osobní údaje s vládními úřady, partnery v oboru nebo jinými třetími stranami, včetně vašich informací o interakci s našimi službami, (1) pokud to vyžaduje zákon nebo pokud se domníváme, že taková akce je nezbytná pro splnění zákonných povinností; (2) k ochraně a obraně našich práv nebo majetku; (3) pokud jednostranně zjistíme, že došlo k porušení našich podmínek, zásad nebo zákonů; (4) odhalovat podvody nebo jiné nezákonné činnosti nebo jim předcházet; (5) chránit naše produkty, zaměstnance nebo uživatele nebo veřejnou bezpečnost, zabezpečení, integritu; nebo (vi) bránit se právní odpovědnosti.',
    subTitle4: 'Vaše práva',
    block8:
      'V závislosti na vaší geografické poloze mohou mít jednotlivci v Evropském hospodářském prostoru, Spojeném království a na celém světě určitá zákonná práva týkající se jejich osobních údajů. Můžete mít například právo:',
    subBlock19:
      'Získejte přístup k vašim osobním údajům a informacím o tom, jak jsou zpracovávány.',
    subBlock20: 'Vymažte své osobní údaje z našich záznamů.',
    subBlock21: 'Opravte nebo aktualizujte své osobní údaje.',
    subBlock22: 'Předání vašich osobních údajů třetí straně (přenositelnost údajů).',
    subBlock23: 'Omezte způsob, jakým zpracováváme vaše osobní údaje.',
    subBlock24:
      'Odvolání souhlasu – tam, kde se spoléháme na souhlas jako na právní základ pro zpracování, můžete svůj souhlas kdykoli odvolat.',
    subBlock25: 'Vznést námitku proti tomu, jak zpracováváme vaše osobní údaje.',
    subTitle5: 'Odkazy na jiné webové stránky',
    block9:
      'Služba může obsahovat odkazy na jiné webové stránky, které společnost Noam neprovozuje ani neřídí, včetně služeb sociálních médií ("Webové stránky třetích stran"). Informace, které sdílíte s webovými stránkami třetích stran, se budou řídit konkrétními zásadami ochrany osobních údajů a podmínkami služeb webových stránek třetích stran, nikoli těmito zásadami ochrany osobních údajů. Tyto odkazy poskytujeme pro pohodlí a neimplikují naši podporu nebo kontrolu těchto webových stránek. Kontaktujte prosím webové stránky třetích stran přímo pro informace o jejich zásadách ochrany osobních údajů a podmínkách.',
    subTitle6: 'Bezpečnost a uchovávání informací',
    block10:
      'Zavádíme obchodně přiměřená technická, administrativní a organizační opatření na ochranu online i offline osobních údajů před ztrátou, zneužitím a neoprávněným přístupem, zveřejněním, pozměněním nebo nepřátelskými činy. Žádný internetový nebo e-mailový přenos však nemůže zaručit úplnou bezpečnost nebo přesnost. Zejména e-maily zaslané nám nebo od nás nemusí být bezpečné. Měli byste být tedy obzvláště opatrní při rozhodování, jaké informace nám prostřednictvím služby nebo e-mailu zasílat. Kromě toho neneseme odpovědnost za jakékoli narušení nastavení ochrany osobních údajů nebo bezpečnostních opatření ve službě nebo nastavení zabezpečení nebo bezpečnostních opatření webových stránek třetích stran. Vaše osobní údaje budeme uchovávat po dobu nezbytně nutnou k tomu, abychom vám mohli poskytovat služby nebo pro jiné legitimní obchodní účely, jako je řešení sporů, z důvodů bezpečnosti a ochrany nebo plnění našich zákonných povinností. Doba, po kterou osobní údaje uchováváme, bude záviset na mnoha faktorech, jako je množství, povaha, citlivost informací, potenciální rizika neoprávněného použití nebo zveřejnění, účely, pro které údaje zpracováváme, a další právně závazné úvahy.',
    subTitle7: 'Úprava Zásad ochrany osobních údajů',
    block11:
      'Tyto Zásady ochrany osobních údajů můžeme čas od času aktualizovat. Když tak učiníme, zveřejníme aktualizovanou verzi na této stránce, pokud to nebude vyžadovat jinak platnými zákony.',
    subTitle8: 'Jak nás kontaktovat',
    block12:
      'Pokud máte nějaké nezodpovězené otázky nebo obavy týkající se těchto Zásad ochrany osobních údajů, kontaktujte prosím náš zákaznický servis. E-mailem:',
  },
  terms: {
    title: 'Podmínky služby',
    updateTime: 'Aktualizováno: 15. března 2024',
    block1: 'Děkujeme, že používáte Noam!',
    block2:
      'Tyto podmínky použití se vztahují na vaše používání služeb společnosti Noam, včetně našich aplikačních programovacích rozhraní, softwaru, nástrojů, vývojářských služeb, dat, dokumentace a webových stránek (souhrnně označovaných jako „služby“). Používáním našich Služeb souhlasíte s dodržováním těchto podmínek. Naše zásady ochrany osobních údajů vysvětlují, jak shromažďujeme a používáme osobní údaje.',
    subTitle1: 'Registrace a přístup',
    block3:
      'Pokud je vám méně než 18 let, musíte k používání služby získat povolení od svých rodičů nebo zákonných zástupců. Pokud Službu používáte jménem jiné osoby nebo subjektu, musíte být oprávněni přijmout tyto podmínky jejich jménem. Pro registraci účtu musíte poskytnout přesné a úplné informace. Nesmíte poskytnout své přístupové údaje ani účet nikomu mimo vaši organizaci a nesete odpovědnost za všechny aktivity prováděné pomocí vašich přihlašovacích údajů.',
    subTitle2: 'Požadavky na použití',
    block4:
      '(a) Použití služby. Ke Službě můžete přistupovat za těchto podmínek a my vám poskytujeme nevýhradní právo Službu používat. Při používání Služby budete dodržovat tyto podmínky a všechny příslušné zákony. My a naše přidružené společnosti vlastníme všechna práva, tituly a zájmy ve Službě.',
    block5:
      '(b) Zpětná vazba. Uvítáme zpětnou vazbu, připomínky, nápady, návrhy a vylepšení. Pokud takový obsah poskytnete, můžeme jej používat bez jakýchkoli omezení a bez kompenzace vůči vám.',
    block6:
      '(c) Omezení. Nesmíte: (1) používat Službu způsobem, který porušuje, zpronevěřuje nebo porušuje jakákoli práva ostatních; (2) zpětně analyzovat, dekompilovat, rozebírat, překládat nebo se jinak pokoušet odhalit zdrojový kód nebo základní součásti modelů, algoritmů a systémů služeb (pokud taková omezení nezakazují platné zákony); (3) využívat výstup ze Služby k vývoji modelů konkurujících Noam; (4) extrahovat data nebo výstup ze Služby pomocí jakýchkoli automatizovaných nebo programovacích metod, pokud to není povoleno prostřednictvím API, včetně scrapingu, web harvestingu nebo extrakce webových dat; (5) představovat výstup služby jako uměle vytvořený, když tomu tak není, nebo jinak porušovat naše zásady používání; (6) nakupovat, prodávat nebo převádět API klíče bez našeho předchozího souhlasu; nebo (7) poskytnout nám jakékoli osobní údaje dětí mladších 13 let nebo příslušného věku digitálního souhlasu. Budete dodržovat všechny limity sazeb a další požadavky v naší dokumentaci.',
    block7:
      '(d) Služby třetích stran. Jakýkoli software, služby nebo jiné produkty třetích stran spojené se Službou se řídí jejich vlastními podmínkami a nejsme odpovědní za produkty třetích stran.',
    subTitle3: 'Obsah',
    block8:
      '(a) Váš obsah. Službě můžete poskytnout vstup („Vstup“) a přijímat výstup („Výstup“) generovaný a vracený na základě Vstupu (souhrnně označovaný jako „Obsah“). Mezi stranami a v rozsahu povoleném příslušnými zákony vlastníte veškerý Vstup. Společnost Noam může používat obsah k poskytování a udržování služby, dodržování platných zákonů a prosazování našich zásad. Jste odpovědní za obsah, včetně zajištění toho, že neporušuje žádné platné zákony nebo tyto podmínky.',
    block9:
      '(b) Přesnost. Umělá inteligence a strojové učení jsou rychle se rozvíjející oblasti výzkumu. Neustále se snažíme zlepšovat naše služby, aby byly přesnější, spolehlivější, bezpečnější a přínosnější. Vzhledem k pravděpodobnostní povaze strojového učení může používání naší služby v určitých případech vést k nesprávným výstupům, které nemusí přesně odrážet skutečné osoby, místa nebo fakta. Měli byste náležitě posoudit přesnost jakéhokoli výstupu na základě vašeho případu použití, včetně ruční kontroly výstupů.',
    subTitle4: 'Poplatky a platby',
    block10:
      '(a) Poplatky a fakturace. Všechny poplatky ("Poplatky") zaplatíte podle cen a podmínek na příslušné cenové stránce nebo podle písemné dohody mezi námi. Vyhrazujeme si právo opravit chyby nebo chyby v cenách, i když jsme vystavili faktury nebo přijali platbu. Poskytnete úplné a přesné fakturační údaje, včetně platné a autorizované platební metody. Budeme účtovat vaši platební metodu pravidelně, jak bylo dohodnuto, ale můžeme přiměřeně změnit datum fakturace. Udělujete oprávnění společnosti Noam, jejím přidruženým společnostem a externím zpracovatelům plateb účtovat vaši platební metodu. Pokud se vaše platba nezdaří, zašleme vám písemné oznámení a můžeme pozastavit přístup ke Službě, dokud platbu neobdržíme. Pokud není v této smlouvě uvedeno jinak, platby jsou nevratné.',
    block11:
      '(b) Daně. Pokud není uvedeno jinak, poplatky nezahrnují žádné federální, státní, místní a zahraniční daně, cla a podobné výměry („daně“). Jste odpovědní za všechny daně spojené s vašimi nákupy, s výjimkou daní založených na našem čistém příjmu, který vám budeme fakturovat. Souhlasíte s tím, že neprodleně zaplatíte takové daně a poskytnete nám právně platná prohlášení o ochraně osobních údajů a získáte potřebné souhlasy pro nakládání s takovými údaji, a zaručujete nám, že s takovými údaji nakládáte v souladu s platnými zákony.',
    block12:
      '(c) Změny cen. Můžeme změnit naše ceny tím, že vás o tom budeme informovat prostřednictvím vašeho účtu a/nebo našich webových stránek. Zvýšení cen bude účinné ihned po zveřejnění. Jakékoli změny cen se budou vztahovat na poplatky za váš účet po datu účinnosti změny.',
    block13:
      '(d) Spory a platby po splatnosti. Pokud chcete zpochybnit jakékoli poplatky nebo daně, kontaktujte prosím contact@noam.tools do třiceti (30) dnů od data faktury.',
    block14:
      '(e) Bezplatné použití. Pro získání bezplatných kreditů nemůžete vytvořit více účtů. Pokud zjistíme, že nevyužíváte bezplatné kredity v dobré víře, můžeme vám účtovat standardní poplatky nebo přestat poskytovat přístup ke Službě.',
    subTitle5: 'Důvěrnost, bezpečnost a ochrana dat',
    block15:
      '(a) Důvěrnost. Můžete mít přístup k důvěrným informacím společnosti Noam. Důvěrné informace můžete používat pouze v souladu s těmito podmínkami pro účely používání Služby. Důvěrné informace nesmíte sdělit žádné třetí straně a důvěrné informace budete chránit způsobem, který není méně ochranný, než chráníte své vlastní podobné důvěrné informace, alespoň s přiměřenou péčí. Důvěrné informace jsou neveřejné informace, které společnost Noam označila za důvěrné nebo s nimiž by se za daných okolností mělo zacházet jako s důvěrnými, včetně softwaru, specifikací a dalších neveřejných obchodních informací. Důvěrné informace nezahrnují informace, které: (1) se stanou obecně dostupnými veřejnosti bez vašeho zavinění; (2) jste měli v držení bez jakékoli povinnosti zachovávat důvěrnost, než jste jej obdrželi podle těchto podmínek; (3) je vám zákonně sdělena třetí stranou bez jakékoli povinnosti důvěrnosti; nebo (4) jste nezávisle vyvinuli bez použití důvěrných informací. Důvěrné informace můžete prozradit, pokud to vyžaduje zákon, soud nebo jiný vládní příkaz, ale musíte Noam předem písemně upozornit přiměřeným způsobem a v rámci možností vyvinout přiměřené úsilí k omezení rozsahu zveřejnění, včetně pomoci nám. v oponování žádostí o zveřejnění.',
    block16:
      '(b) Zabezpečení. Musíte zavést přiměřená a vhodná opatření, která pomohou zabezpečit váš přístup ke Službě a její používání. Pokud zjistíte jakékoli zranitelnosti nebo porušení související s vaším používáním služby, musíte neprodleně kontaktovat společnost Noam a poskytnout podrobnosti o zranitelnosti nebo porušení.',
    block17:
      '((c) Zpracování osobních údajů. Pokud ke zpracování osobních údajů používáte Službu, musíte poskytnout ze zákona dostatečná prohlášení o ochraně osobních údajů a získat nezbytné souhlasy se zpracováním těchto údajů a zaručujete nám, že s takovými údaji nakládáte v souladu s platnými právními předpisy .',
    subTitle6: 'Termín a ukončení',
    block18:
      'a) ukončení; Suspenze. Tyto podmínky vstoupí v platnost od vašeho prvního použití služby a zůstanou v platnosti, dokud nebudou ukončeny. Tyto podmínky můžete kdykoli z jakéhokoli důvodu ukončit tím, že přestanete používat službu a obsah. Tyto podmínky můžeme z jakéhokoli důvodu ukončit s předchozím upozorněním. Tyto podmínky můžeme okamžitě ukončit tím, že vás upozorníme, pokud závažným způsobem porušíte oddíl 2 (Požadavky na používání), oddíl 5 (Důvěrnost, bezpečnost a ochrana dat), oddíl 8 (Řešení sporů) nebo oddíl 9 (Všeobecné podmínky), nebo pokud naše vztah s jakýmkoli poskytovatelem technologií třetí strany mimo naši kontrolu, změny nebo pro dodržování zákonů nebo vládních požadavků. Můžeme pozastavit váš přístup ke Službě, pokud nedodržíte tyto podmínky nebo pokud vaše používání představuje bezpečnostní riziko pro nás nebo jakoukoli třetí stranu, nebo pokud máme podezření, že vaše používání je podvodné nebo může vystavit nás nebo jakoukoli třetí stranu odpovědnosti. .',
    block19:
      '(b) Účinky ukončení. Po ukončení přestanete používat Službu a neprodleně vrátíte nebo zničíte jakékoli důvěrné informace podle našich pokynů. Ustanovení těchto podmínek, která by svou povahou měla přetrvat ukončení nebo vypršení platnosti, včetně, ale bez omezení na oddíly 3 a 5-9, zůstanou v platnosti.',
    subTitle7: 'Odškodnění; Vyloučení odpovědnosti; Omezení odpovědnosti',
    block20:
      '(a) Odškodnění. Budete nás, naše přidružené společnosti a naše zaměstnance bránit, odškodníte a ochráníte před a proti jakýmkoli nárokům, ztrátám a výdajům (včetně poplatků za právní zastoupení) vyplývajících z vašeho používání služby nebo souvisejících s vaším používáním služby, včetně vašeho obsahu, vašeho používání produktů nebo služeb souvisejících se službou a vaše porušení těchto podmínek nebo platných zákonů.',
    block21:
      '(b) Vyloučení odpovědnosti. Služba je poskytována „tak jak je“. V rozsahu povoleném zákonem my a naše přidružené společnosti a poskytovatelé licencí neposkytujeme žádná prohlášení ani záruky jakéhokoli druhu týkající se Služby a neposkytujeme žádné záruky, včetně, ale nikoli výhradně, záruk prodejnosti, vhodnosti pro konkrétní účel, uspokojivé kvality, neporušení a tiché užívání a jakékoli záruky vyplývající z obchodu nebo používání obchodu. Nezaručujeme, že Služba bude nepřerušovaná, přesná nebo bezchybná, nebo že jakýkoli Obsah bude zabezpečený, neztratí se nebo nezmění.',
    block22:
      '(c) Omezení odpovědnosti. Ani my, naše přidružené společnosti ani naši poskytovatelé licencí neneseme odpovědnost za žádné nepřímé, náhodné, zvláštní, následné nebo represivní škody, včetně ušlého zisku, ztráty pověsti, ztráty použití, ztráty dat nebo jiných nehmotných ztrát, a to ani v případě, že byli jsme upozorněni na možnost takových škod. Podle těchto podmínek naše celková odpovědnost nepřesáhne poplatky, které jste zaplatili za službu během dvanácti (12) měsíců předcházejících nároku, nebo sto amerických dolarů (100 USD), podle toho, která hodnota je vyšší. Omezení v této části platí v maximálním rozsahu povoleném příslušnými zákony.',
    subTitle8: 'Rozhodné právo a jurisdikce',
    block23:
      'Podmínky a vaše používání Služeb se řídí zákony Spojených států amerických, s výjimkou kolizních norem USA. Vaše používání Aplikace může také podléhat dalším místním, státním, národním nebo mezinárodním zákonům. Jakékoli právní kroky nebo řízení týkající se těchto Podmínek budou vedeny výhradně u soudů Spojených států amerických a souhlasíte s tím, že se podřídíte osobní jurisdikci těchto soudů.',
    subTitle9: 'Obecné podmínky',
    block24:
      'a) Vztah smluvních stran. Noam a vy jste nezávislí dodavatelé a žádná ze stran nemá pravomoc zastupovat nebo zavazovat druhou stranu nebo přebírat závazky za druhou stranu bez předchozího písemného souhlasu druhé strany.',
    block25:
      '(b) Používání značky. Bez našeho předchozího písemného souhlasu nesmíte používat jméno, loga nebo ochranné známky společnosti Noam ani žádné z jejích přidružených společností.',
    subTitle10: 'Oddělitelnost',
    block26:
      'Pokud bude jakékoli ustanovení těchto podmínek shledáno jako neplatné, nezákonné nebo nevymahatelné, zbývající ustanovení zůstanou v plné platnosti a účinnosti.',
  },
  contact: {
    title: 'Kontaktujte nás',
    block1:
      'Pro přizpůsobení nebo pokud máte nějaké dotazy nebo návrhy, kontaktujte nás na contact@noam.tools a my vám okamžitě odpovíme.',
    block2: 'Podpořte nás hodnocením v obchodě s rozšířeními Google Apps na ★★★★★.',
    block3: 'Pomozte ostatním sdílením tohoto rozšíření:',
  },
  pdf: {
    toast1: 'PDF se načítá, zkuste to znovu později!',
    toast2: 'Obsah PDF je příliš mnoho, dialogové okno prozatím nepodporujte',
    toast3: 'Stahování, zkuste to prosím znovu po dokončení stahování.',
    shareMsg1: 'Sdílejte generování odkazu úspěšné',
    shareMsg2: 'Sdílejte aktualizace obsahu úspěšně',
    shareMsg3:
      'Sdílení odkazu se nezdařilo, zkuste to prosím znovu později nebo kontaktujte naši zákaznickou službu.',
    shareMsg4:
      'Aktualizace obsahu sdílení se nezdařila, zkuste to znovu později nebo se obraťte na zákaznický servis',
    shareBtnText1: 'Generovat odkazy pro sdílení',
    shareBtnText2: 'Aktualizovaný obsah sdílení',
    uploading: 'Nahrávání',
    uploadingMsg: 'Buďte prosím trpěliví, protože nahrání nějakou dobu trvá.',
    cancelUpload: 'zrušení',
    uploadErr:
      'Nahrání souboru se nezdařilo. Zkuste to znovu později nebo kontaktujte zákaznický servis',
    uploadMsg1: 'Přetáhněte soubor sem',
    uploadMsg2: 'Podporované typy souborů: PDF |  Maximální velikost souboru: 100 MB',
    uploadMsg3: 'Kliknutím vyberte nebo přetáhněte soubory, které chcete nahrát sem',
    searchPlaceholder: 'Vyhledávání',
    collectionEmpty: 'Žádný obsah ve sbírce~',
    kw: 'sešívačka',
    video: 'video',
    link: 'odkaz',
    dataLoading: 'Načítání dat...',
    freeBannerTips: 'Konec bezplatného překladu PDF',
    vipBannerTips: 'V tomto měsíci jste dosáhli limitu počtu překladů ve formátu pdf.',
    freeBannerBtn: 'Upgradujte, abyste si užili více',
    vipBannerBtn: 'Upgradujte, abyste si užili více',
    dialogTitle: 'Pozornost',
    dialogContent: 'Opravdu jej chcete smazat?',
    okBtn: 'Vymazat',
    cancelBtn: 'zrušení',
    unLoginToast: 'Pro použití této funkce se prosím přihlaste',
    parseErrTips: 'Soubor nelze analyzovat',
    new: 'nový soubor',
    parseErrDialogContent:
      'Aktuální odkaz na PDF nepodporuje přímý překlad, stáhněte si prosím původní odkaz PDF po překladu',
    okBtn2: 'uznat',
    notPdf: 'Pouze soubory PDF',
    uploadingMsg1: 'Probíhá nahrávání souboru. Prosím zkuste to znovu později.',
    uploadingMsg2: 'Aktuálně máte převáděný soubor, zkuste to prosím znovu později.',
    uploadingMsg3:
      'Dotaz na průběh převodu se nezdařil, zkuste to znovu později nebo kontaktujte zákaznický servis',
    uploadingMsg4:
      'Noam nemá přímý přístup k místním souborům, nahrajte prosím ručně PDF k překladu.',
    uploadingMsg5:
      'Nepodařilo se získat adresu URL souboru, zkuste to znovu později nebo kontaktujte zákaznickou službu.',
    fileMsg: 'Aktuální objem souboru:',
    fileMsg2: 'Nahrajte soubor menší než 100 milionů',
    fileErrMsg:
      'Čtení souboru se nezdařilo, zkuste to znovu později nebo kontaktujte zákaznický servis.',
    parseFileToast:
      'Analýza souboru se nezdařila, zkuste to znovu později nebo kontaktujte naši zákaznickou službu.',
    noId: 'ID souboru neexistuje',
    autoDetect: 'Auto detekce',
    hasTransTask: 'Překládá se úkol. Prosím, čekejte.',
    transSucc: 'Překlad byl úspěšný!',
    entry: 'Seznam PDF',
    listError:
      'Seznam PDF se nepodařilo načíst, zkuste to znovu později nebo kontaktujte zákaznický servis.',
    collectionTab: 'Memo',
    pdfLoading: 'Analýza...',
    installTips:
      'Nainstalujte si malý zásuvný modul, můžete překládat více souborů PDF, můžete také zažít hladký překlad webových stránek:',
    webTrans: 'úplný křížový odkaz',
    hoverTrans: 'Překlad odstavce po najetí myší',
    selectTrans: 'podtrhni slova a přelož',
    installText: 'Jednotné kontaktní místo pro všechny vaše každodenní překladatelské potřeby',
    start: 'Začněte svůj bezplatný zážitek',
    shareTips: 'Překlad PDF je dokončen, pokračujte a sdílejte jej se svými přáteli!',
    download: {
      loading: 'Uložené...',
      btn: 'Uložit hned',
      trans: 'Pouze překlad',
      both: 'dvojjazyčnost',
      download: 'Stažení',
      msg1: 'Některé překlady aktuálního dokumentu jsou delší než původní text a text se může po stažení překrývat, proto se doporučuje přečíst si jej na webové stránce.',
      msg2: '1、Obsah nebyl úplně přeložen, stáhněte si ho po úplném překladu.',
      msg3: '2、Dokumentová část překladu je příliš dlouhá, můžete si ji přečíst na webové stránce, abyste se vyhnuli překrývání textu.',
      msg4: 'V současné době se překládají úkoly, zkuste to prosím znovu po dokončení překladu.',
      fileName: 'Název souboru:',
      fileType: 'Typ souboru ke stažení:',
      transContinue: 'Pokračujte v překládání',
      save: 'Uložit do místního',
      downloadMsg1: 'PDF Pokrok v překladu:',
      downloadMsg2: 'Předpokládané požadavky',
      minute: 'Minut...',
      downloadSucc: 'Stahování PDF je dokončeno, klikněte na Uložit!',
    },
    guide: {
      title1: 'Dvojjazyčný překlad',
      title2: 'Chat PDF',
      text1: 'Dvojjazyčné čtení',
      text2:
        'Dvojjazyčné čtení, takže můžete při čtení porovnávat původní text a překlad a zlepšit tak efektivitu čtení',
      text3: 'Chat PDF',
      text4:
        'Umělá inteligence analyzuje a shrnuje a může odpovídat na vaše otázky na základě obsahu PDF',
      text5: 'Přesnost a účinnost',
      text6: 'PDF s podporou umělé inteligence pro přesnější a efektivnější překlady',
      placeholder: 'Vyplňte prosím odkaz PDF',
      start: 'Spusťte překlad',
      toast: 'Zadejte prosím platný online odkaz na pdf končící na .pdf',
    },
    original: 'Originál',
    parallel: 'Paralelní',
    trans: 'Překlad',
    initial: 'Přeložit PDF',
    thumbnail: 'katalogy',
    downloadBtn: 'Stažení',
    shareBtn: 'Podíl',
    uploadBtn: 'nahrát',
    installText2: 'Nejprve prosím nainstalujte',
    chatPdf: {
      clearSucc: 'Úspěšně vymazáno',
      clear: 'Průhledná',
      clearContent:
        'Po vymazání bude vytvořena nová konverzace a obsah této konverzace nebude uložen. Opravdu ji chcete vymazat?',
      clearDialogTitle: 'Pozornost',
      cancel: 'zrušení',
      chatTitle: 'Vítejte v Noam, váš souhrn PDF je připraven:',
      summaryTitle: 'Otázky, které by vás mohly zajímat:',
      placeholder: 'Zadejte jakýkoli dotaz ohledně PDF',
      copy: 'kopírovat',
      copySucc: 'Úspěch kopírování',
      historyTitle: 'Dějiny',
      expand: 'Kolaps',
      noDataDesc: 'Žádná historie~',
      noMore: 'Už ne.',
      Search: 'Vyhledávání',
      delete: 'Vymazat',
      loading: 'načítání...',
      deleteTitle: 'Spropitné',
      deleteContent: 'Po smazání toto PDF nelze obnovit, chcete jej smazat?',
      deleteOk: 'Vymazat',
      deleteCancel: 'zrušení',
      deleteSuccess: 'Úspěšně smazat',
      listError:
        'Seznam četby se nepodařilo načíst. Zkuste to znovu později nebo kontaktujte zákaznickou podporu',
    },
    shareModal: {
      shareSetting: 'Nastavení sdílení obsahu',
      shareText: 'Podíl',
      text1: 'Nastavení sdílení',
      text2: 'Generovat odkazy pro sdílení',
      range: 'Rozsah:',
      custom: 'Přizpůsobení:',
      page: 'stránky',
      sourceLang: 'Zdroj:',
      target: 'Cíl:',
      errMsg:
        'Obsah aktuálního rozsahu sdílení nebyl plně přeložen, proto jej před sdílením přeložte.',
      pageNuErr: 'Zadejte prosím správnou hodnotu čísla stránky',
      trans: 'přeložit',
      cancel: 'zrušení',
      update: 'Aktualizované odkazy pro sdílení',
      gen: 'Podíl',
      shareTips: 'Sdílejte přeložený odkaz PDF se svými přáteli, abyste si mohli přečíst:',
      copySucc: 'Úspěch kopírování odkazu',
      copy: 'kopírovat',
      settings: 'Nastavení sdílení:',
      modify: 'modifikovat',
      translateTips:
        'Tip: Pokud překlad trvá dlouho, můžete vyskakovací okno zavřít a po dokončení překladu jej sdílet.',
      translating: 'Překlad PDF...',
      remain: 'zbývající',
      remain1: 'Celkové projektované požadavky',
      totalPage: 'celkem 1 stránka',
      search: 'Vyhledávání',
      notSupport: 'Tento jazyk není aktuálně podporován',
    },
    toolbar: { Adjust: 'Upravit', autoFit: 'Automatické přizpůsobení' },
    memoTotalCount: 'Celkem 1 obsah',
    dragTipModal: {
      press: 'lis',
      black: 'prostor',
      leftKey: 'levé tlačítko',
      tips: 'přetáhnout a zobrazit.',
      ok: 'mám to',
    },
  },
  locale: {
    zh: 'čínština',
    en: 'Angličtina',
    es: 'španělština',
    ru: 'ruština',
    pt: 'portugalština (Brazílie)',
    id: 'indonéština',
    cs: 'čeština',
    de: 'Němec',
    fr: 'francouzština',
    it: 'italština',
    nl: 'holandský',
    pl: 'polština',
    vi: 'vietnamština',
    tr: 'turečtina',
    uk: 'ukrajinština',
    zhHant: 'Tradiční čínská',
    ja: 'japonský',
    ko: 'korejština',
    ar: 'arabština',
    fa: 'Peršan',
    th: 'thajština',
    hi: 'hindština',
    noSearchLang: 'Tento jazyk není podporován',
    placeholder: 'Vyhledávání',
    af: 'afrikánština',
    am: 'amharština',
    as: 'ásámština',
    az: 'ázerbájdžánský',
    ba: 'Baškir',
    bg: 'bulharský',
    bn: 'Bangla',
    bo: 'tibetský',
    bs: 'bosenský',
    ca: 'katalánština',
    cy: 'velština',
    da: 'dánština',
    dsb: 'Dolní lužická srbština',
    dv: 'Divehi',
    el: 'řecký',
    et: 'estonština',
    eu: 'baskický',
    fi: 'finština',
    fil: 'filipínský',
    fj: 'fidžijský',
    frCA: 'francouzština (Kanada)',
    ga: 'irština',
    gl: 'galicijský',
    gom: 'Konkani',
    gu: 'Gudžarátština',
    ha: 'Hausa',
    he: 'hebrejština',
    hr: 'chorvatský',
    hsb: 'hornolužická srbština',
    ht: 'haitská kreolština',
    hu: 'maďarský',
    hy: 'arménský',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandský',
    iu: 'Inuktitut',
    iuLatn: 'inuktitut (latinka)',
    ka: 'gruzínský',
    kk: 'kazašský',
    km: 'khmerské',
    kmr: 'kurdština (severní)',
    kn: 'Kannada',
    ku: 'kurdština (střední)',
    ky: 'kyrgyzština',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'litevský',
    lug: 'Ganda',
    lv: 'lotyšský',
    lzh: 'čínština (literární)',
    mai: 'Maithili',
    mg: 'malgašský',
    mi: 'maorští',
    mk: 'makedonský',
    ml: 'malajálamština',
    mnCyrl: 'mongolština (cyrilice)',
    mnMong: 'mongolština (tradiční)',
    mr: 'maráthština',
    ms: 'malajština',
    mt: 'maltština',
    mww: 'Hmong Daw',
    my: 'Myanmar (barmština)',
    nb: 'norský',
    ne: 'nepálský',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'pandžábština',
    prs: 'Dari',
    ps: 'paštština',
    ptPT: 'portugalština (Portugalsko)',
    ro: 'rumunština',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'sinhálština',
    sk: 'Slovák',
    sl: 'slovinský',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'somálský',
    sq: 'albánský',
    srCyrl: 'srbština (cyrilice)',
    srLatn: 'srbština (latinka)',
    st: 'sesotho',
    sv: 'švédský',
    sw: 'svahilština',
    ta: 'tamilština',
    te: 'telugština',
    ti: 'Tigrinya',
    tk: 'turkmenský',
    tlhLatn: 'klingonština (latinka)',
    tlhPiqd: 'klingonština (pIqaD)',
    tn: 'Setswana',
    to: 'Tongan',
    tt: 'Tatar',
    ty: 'tahitský',
    ug: 'ujgurské',
    ur: 'Urdu',
    uz: 'uzbečtina (latinka)',
    xh: 'Xhosa',
    yo: 'jorubština',
    yua: 'Yucatec Maya',
    yue: 'kantonština (tradiční)',
    zhHans: 'Zjednodušená čínština',
    zu: 'Zulu',
    auto: 'Auto detekce',
  },
  stripe: {
    contact: 'V případě jakýchkoliv dotazů nás kontaktujte na contact@noam.tools!',
    fail: {
      title: 'Platba selhala',
      text1: 'Váš požadavek na platbu bohužel nemůžeme zpracovat.',
      text2: 'Zkontrolujte prosím připojení k internetu a zkuste to znovu.',
      btn: 'Zkuste to znovu',
    },
    succ: {
      title: 'Gratulujeme',
      text1: 'vítejte v Noame,',
      text2: 'začněme spolu!',
      btn: 'Pojďme udělat nějaké kouzlo',
    },
  },
  welfare: {
    newUser: {
      label: 'Výhody nového uživatele:',
      joinBtn: 'Přidej se teď',
      text: 'Pouze 100 volných míst! Popadněte své hned teď!',
      remain: 'Zbývá pouze 30',
    },
  },
  help: {
    Fixedplugin: 'Opravený plugin',
    Howtouse: 'Jak používat',
    try: 'Pokusit se',
    FreeTrialBenefits: 'Výhody bezplatné zkušební verze',
    pin: 'Pin Noam pro snadnější přístup～～',
    Video: 'Video',
    ParallelTranslation: 'Paralelní překlad',
    WebSummary: 'Souhrn webu',
    ChatPDF: 'Chat PDF',
    AquickOverviewOfNoam: 'Rychlý přehled Noama',
    WordSelectionTranslation: 'Překlad výběru slov',
    HoverTranslation: 'Překlad po najetí myší',
    ImageTranslation: 'Překlad obrázků',
    InstantTranslation: 'Okamžitý překlad',
    VideoTranslation: 'Překlad videa',
    benefits: 'Připravili jsme pro vás benefity!',
    Free: 'Volný, uvolnit',
    Membership: 'Členství',
    Translation: 'Překlad',
    PDFparallelTranslation: 'Paralelní překlad PDF',
    ChatPDF2: 'ChatPDF',
    Summary: 'souhrn',
    VideoSummary: 'Shrnutí videa',
    FavoritesManagement: 'Správa oblíbených',
    Focusing: 'Se zaměřením',
    dayFree: '3denní bezplatná zkušební verze',
    planIsNotExist: 'plán neexistuje',
    TryItForFree: 'Vyzkoušejte zdarma',
    startYoutube: 'Začněme s YouTube~',
    startWiki: 'Začněme s Wikimedia~',
    tryNow: 'Zkuste to teď~',
    GoWithYouTube: 'Přejděte na YouTube',
    GoWithWikipedia: 'Přejděte na Wikipedii',
    NoamHelp: 'Noam vám může pomoci přeložit a shrnout',
    SkipAllSteps: 'Přeskočte všechny kroky',
    next: 'další',
    done: 'Hotovo',
  },
  trialModal: {
    title: 'Dárek na uvítanou',
    trialText: '7denní zkušební verze',
    desc1: 'Po skončení zkušebního období vám bude účtován roční poplatek 180 USD',
    desc2: 'Zrušit kdykoli',
    btn: 'Začněte hned (pouze 0,99 $)',
    installBtn: 'Nainstalujte nyní a získejte dárek',
    web: 'Paralelní překlad',
    select: 'Překlad výběru slov',
    hover: 'Překlad po najetí myší',
    image: 'Překlad obrázků',
    realtime: 'Okamžitý překlad',
    video: 'Překlad videa',
    pdf: 'Paralelní překlad PDF',
    chat: 'ChatPDF',
    webSummary: 'Webové shrnutí',
    videoSummary: 'Shrnutí videa',
    memo: 'Správa oblíbených',
    focus: 'Se zaměřením',
  },
  privacyCollectionPanel: {
    title: 'Tento web používá soubory cookie',
    text: 'Pro zlepšení vašeho zážitku bychom rádi používali soubory cookie k personalizaci obsahu a služeb.',
    acceptBtn: 'Přijmout vše',
  },
  installTips: {
    text: 'Nainstalujte si „Noam Browser Plugin“, abyste odemkli další funkce! křížové odkazy na webu,\n    shrnutí webových videí a další!',
    btn: 'Vyzkoušej to teď',
  },
}

export default TRANSLATION
