import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { ee } from '../eventEmitter/eventEmitter'
import { TranslateServiceType } from '../translateService'
import { TranslateServiceConsts } from '../translateService/consts'
import { FREE_BENEFITS, PRO_BENEFITS, PRO_V_BENEFITS } from './const'
import { EDGE_INSTALL_URL, FIREFOX_INSTALL_URL, GOOGLE_INSTALL_URL } from '@/helpers/commonConsts'
import { DEFAULT_LOGIN_URL, getLoginParams } from '@/pages/LoginPage'
import { uiController } from '@/controllers/uiController'
import { vipService } from '../vipService'
const PRO_V_AGREE_TYPES = ['pro+_quarter_subscription', 'pro+_month_subscription']
const PRO_AGREE_TYPES = ['quarter_subscription', 'month_subscription']
type Locale = TranslateServiceType.Locale
type LocaleWithAutoDetect = TranslateServiceType.LocaleWithAutoDetect

const NEW_USER_DISCOUNT_START_TIME = new Date('2024-06-7 00:00').getTime()
export const NEW_USER_DISCOUNT_DAY = 7
class UserService {
  @observable userData: ServerDataTypes.UserInfo | null = null

  // pdf 相关配置
  @observable pdfSourceLang: LocaleWithAutoDetect = TranslateServiceConsts.AUTO_DETECT_LANG
  // eslint-disable-next-line max-len
  @observable pdfTargetLang: Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  @observable canScroll = false
  @observable dragPdfModal = false
  @observable initialed = false
  @observable enableNewUserDiscount = false
  private checkLoginTimer: number | undefined
  private loginPopup: any

  constructor() {
    makeObservable(this)
    this.init()
  }

  @computed get dragTipVisibility() {
    return this.canScroll && this.dragPdfModal
  }

  @computed get isLogin() {
    return !!this.userData
  }

  @computed get vipVersion() {
    if (!this.userData) {
      return 'free'
    }
    return this.userData.versionData.version
  }

  @computed get maxPDFPage() {
    if (!this.isLogin) {
      return parseInt(PRO_BENEFITS.pdfMaxPage)
    }
    const {
      versionData: { version },
    } = this.userData!
    if (version === 'free') {
      return parseInt(FREE_BENEFITS.pdfMaxPage)
    }
    if (version === 'pro+') {
      return parseInt(PRO_V_BENEFITS.pdfMaxPage)
    }
    return parseInt(PRO_BENEFITS.pdfMaxPage)
  }

  @computed get remainPdfUploadCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfUploadRemainCount },
    } = this.userData!
    return chatPdfUploadRemainCount
  }

  @computed get remainPdfChatCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfChatRemainCount },
    } = this.userData!
    return chatPdfChatRemainCount
  }
  @computed get alipayAgree() {
    if (!this.isLogin) {
      return null
    }
    const {
      versionData: { version, alipayAgree, agreeType },
    } = this.userData!
    if (version === 'free' || !alipayAgree) {
      return false
    }

    if (
      (version === 'pro+' && PRO_V_AGREE_TYPES.includes(agreeType)) ||
      (version === 'pro' && PRO_AGREE_TYPES.includes(agreeType))
    ) {
      return true
    }
    return false
  }

  @computed get newUserDiscountTime() {
    if (!userService.userData) {
      return 0
    }
    const { createdAt } = userService.userData!
    let startTime =
      createdAt * 1000 <= NEW_USER_DISCOUNT_START_TIME
        ? NEW_USER_DISCOUNT_START_TIME
        : createdAt * 1000

    const endTime = startTime + NEW_USER_DISCOUNT_DAY * 24 * 60 * 60 * 1000
    if (endTime > Date.now()) {
      return endTime
    } else {
      return 0
    }
  }

  public async init() {
    this.updateSEMKey()
    await this.updateUserInfo()
    const { pdfSourceLang, pdfTargetLang, dragPdfModal } = storageHelper.get([
      'pdfSourceLang',
      'pdfTargetLang',
      'dragPdfModal',
    ])
    if (pdfSourceLang) {
      this.pdfSourceLang = pdfSourceLang
      if (pdfSourceLang === 'auto-detect') {
        this.pdfSourceLang = TranslateServiceConsts.AUTO_DETECT_LANG
      }
    }
    if (pdfTargetLang) {
      this.pdfTargetLang = pdfTargetLang
    } else {
      let lng = Object.keys(TranslateServiceConsts.ALL_LOCALES).find((l) =>
        navigator.language.includes(l)
      )
      if (lng) {
        this.pdfTargetLang = lng
      }
    }

    this.dragPdfModal = dragPdfModal ?? true
    vipService.getTrialPlan()
  }

  public setPdfLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.pdfSourceLang = lang
      storageHelper.set({ pdfSourceLang: lang })
    } else {
      this.pdfTargetLang = lang
      storageHelper.set({ pdfTargetLang: lang })
    }
  }

  /** 主动(手动)登出的情况 */
  public async logout() {
    await api.user.logout()
    this.userData = null
  }

  public async updateUserInfo() {
    try {
      const { userData } = await api.user.getUserInfo()
      console.log('get user info: ', userData)
      runInAction(() => {
        this.userData = userData
      })
      // storageHelper.set({ userData })
    } catch (error) {
      runInAction(() => {
        this.userData = null
      })
      // storageHelper.set({ userData: null })
    }
    runInAction(() => {
      this.initialed = true
    })
    ee.emit('userServiceInitialed', {})
  }

  public openLoginTab(fromPdf?: boolean) {
    if (fromPdf) {
      // const w = 900
      // const h = 550
      // const left = Math.floor((window.innerWidth - w) / 2)
      // const top = Math.floor((window.innerHeight - h) / 2)
      // let params = getLoginParams() || {}
      // params.redirectUrl = window.location.href
      // const url = commonUtils.genApiUrl(DEFAULT_LOGIN_URL, commonUtils.underlize(params))
      // this.loginPopup = window.open(
      //   url,
      //   'loginPage',
      //   `popup=1,width=${w},height=${h},left=${left},top=${top}`
      // )
      // this.startCheckLogin()

      let params = getLoginParams() || {}
      params.redirectUrl = encodeURIComponent(
        commonUtils.genUrl(
          window.location.href,
          {
            from: 'login_succ',
          },
          false
        )
      )
      const url = commonUtils.genApiUrl(DEFAULT_LOGIN_URL, commonUtils.underlize(params))
      uiController.loginModalProps = {
        open: true,
        url,
      }
      return
    }
    window.open(`/login?redirectUrl=${encodeURIComponent(window.location.href)}`)
  }

  private startCheckLogin() {
    this.clearTimeout()
    this.checkLoginTimer = setTimeout(async () => {
      await this.updateUserInfo()
      if (this.isLogin) {
        if (this.loginPopup) {
          this.loginPopup.close()
          this.loginPopup = undefined
        }
        this.clearTimeout()
        const id = storageHelper.get(['lastFileIdWithNoLogin']).lastFileIdWithNoLogin
        if (id) {
          try {
            api.pdf.uploadRelation(id)
          } catch (error) {
            console.log(error)
          }
          storageHelper.set({ lastFileIdWithNoLogin: null })
        }
        return
      }
      this.startCheckLogin()
    }, 3000) as any
  }

  public clearTimeout() {
    if (this.checkLoginTimer) {
      clearTimeout(this.checkLoginTimer)
      this.checkLoginTimer = undefined
    }
  }

  public openPricingTab(type: 'buy' | 'upgrade' | 'renew' | 'wordPack', from: string) {
    const url = `/pricing?type=${type}&from=${from}&v=${
      this.vipVersion === 'free' ? 'pro' : 'pro+'
    }`
    window.open(url, '_blank')
  }

  public trackUserRecord(actionType: string) {
    const token = commonUtils.genVisitorTokenStr()
    try {
      api.user.postVisitorRecord({ token, actionType })
    } catch (error) {
      //do nothing
    }
  }

  public goToInstall() {
    // 新打开一个窗口，并跳转到插件的下载页面
    const { browser } = commonUtils.getBrowserInfo()
    let url = GOOGLE_INSTALL_URL

    if (browser === 'Edge') {
      url = EDGE_INSTALL_URL
    }
    if (browser === 'Firefox') {
      url = FIREFOX_INSTALL_URL
    }
    window.open(url)
  }

  public updateSEMKey() {
    try {
      const { utm, kword, utm_term, bd_vid, bd_url, qhclickid, referer } = commonUtils.getUrlArgs()
      if (utm) {
        storageHelper.set({ utm })
      }
      if (
        document.referrer &&
        document.referrer.indexOf('ad.noam.tools') === -1 &&
        document.referrer.indexOf('localhost') === -1
      ) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: document.referrer })
        }
      }
      if (referer) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: referer })
        }
      }
      if (kword) {
        const decodedKword = decodeFromGb2312(kword)
        storageHelper.set({ kword: decodedKword })
      }

      const storageReferer = storageHelper.get(['referer']).referer || ''
      if (storageReferer) {
        const refererChannel = encodeURIComponent(new URL(storageReferer).hostname)
        // www.so.com
        const { q } = commonUtils.getUrlArgs(storageReferer)
        if (refererChannel === 'www.so.com' && q) {
          storageHelper.set({ kword: q })
        }
        // cn.bing.com
        if (refererChannel === 'cn.bing.com' && utm_term) {
          storageHelper.set({ kword: utm_term })
        }
      }

      // 360 ocpc 投放参数
      if (qhclickid) {
        storageHelper.set({ qhclickid: qhclickid })
      }

      // 并不意味着 location.href 中有 bd_vid 就是从百度投放来的
      // 还有可能是从自定义投放页面来的 此时会携带 bd_url
      if (bd_vid || bd_url) {
        // 优先 bd_url 其次 location.href
        if (bd_url) {
          const bd_url_vid = commonUtils.getQueryFromUrl('bd_vid', bd_url)
          if (bd_url_vid) {
            storageHelper.set({ bdUrl: bd_url, bdVid: bd_url_vid })
            return
          }
        }
        storageHelper.set({ bdUrl: window.location.href, bdVid: bd_vid })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  public trackEvent({
    eventType,
    properties,
  }: {
    eventType: string
    properties?: { [key: string]: string }
  }) {
    try {
      api.user.addEventRecord({
        eventType: eventType,
        properties: properties ? JSON.stringify(properties) : undefined,
        inviteCode: userService.isLogin ? userService.userData?.inviteCode : undefined,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const userService = new UserService()
