import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './BuyModal.module.less'
import { Button, Modal } from 'antd'
import { useTranslationWithType } from '@/i18n/i18n'
import { commonUtils } from '@/helpers/commonUtils'
import { vipService } from '@/services/vipService'
import { userService } from '@/services/userService'
import TrialPlanItem from '../TrialPlanItem/TrialPlanItem'
import { uiController } from '@/controllers/uiController'

/** BuyModal 组件的 Props */
export type BuyModalProps = {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}

const BuyModal: FC<BuyModalProps> = observer(({ open, onCancel, onConfirm }: BuyModalProps) => {
  const { t } = useTranslationWithType()
  const [curStep, setCurStep] = useState('1')
  const options = [
    { label: t('buyModal.yearLabel'), value: 'year_subscription' },
    { label: t('buyModal.monthLabel'), value: 'month_subscription' },
  ]

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (!open) {
      return
    }
    userService.trackUserRecord('show_buy_modal')
  }, [open])
  useEffect(() => {
    if (!open || !vipService.trialPlan) {
      return
    }
    setTimeout(() => {
      setCurStep('2')
    }, 1600)
  }, [open, vipService.trialPlan])
  if (!vipService.trialPlan) {
    return null
  }
  const selectedPlan = vipService.trialPlan
  return (
    <Modal
      open={open}
      centered
      wrapClassName={classNames(style.buyModal)}
      footer={false}
      closable={curStep !== '1'}
      width={'608px'}
      onCancel={onCancel}
      maskClosable={false}
    >
      <div className={classNames(style.trialContent)}>
        <div className={classNames(style.guide, { [style.hide]: curStep === '2' })}>
          <img
            className={classNames(style.gift)}
            src={commonUtils.getStaticFile('trialModal/gift.gif')}
          />
        </div>

        <div
          className={classNames(style.upgradeBoxContent, 'flex-col', {
            [style.show]: curStep === '2',
          })}
        >
          <p className={classNames(style.title)}>{t('buyModal.title')}</p>
          <div className={classNames(style.desc)}>
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('pricing/icon__fireworks.png')}
            />
            <span>{t('buyModal.freeTrial')}</span>
          </div>
          {/* 套餐列表 */}
          <div className={classNames(style.planBox, 'flex-row', 'align-side')}>
            <TrialPlanItem plan={selectedPlan} showBenefits />
          </div>
          {/* 价格 */}
          <div className={classNames(style.priceBox, 'flex-row', 'align-side', 'full-w')}>
            <div className={classNames(style.left)}>
              <p className={classNames(style.desc)}>{t('buyModal.freeTrial', 'full-w')}</p>
              <p className={classNames(style.time)}>{t('buyModal.afterFree')}</p>
            </div>
            <div className={classNames(style.right)}>
              <p className={classNames(style.price)}>
                ${(selectedPlan.trialPrice / 100).toFixed(2)}
              </p>
              <p className={classNames(style.prePrice)}>
                ${commonUtils.getPlanTotalPrice(selectedPlan!)}/
                {options.find((o) => o.value === selectedPlan.timeType)!.label}
              </p>
            </div>
          </div>
          <Button
            loading={loading}
            type='primary'
            className={style.buyBtn}
            onClick={() => {
              if (loading) {
                return
              }
              userService.trackUserRecord('click_buy_modal_btn')
              if (uiController.trialModalNeedCheckInstall && !commonUtils.isInstalledExtension()) {
                userService.goToInstall()
                return
              }
              setLoading(true)
              vipService.checkout(
                { planId: selectedPlan!.id, isTrial: true, source: 'trialModal' },
                {
                  dataErrorCallback: () => {
                    setLoading(false)
                  },
                  dataPopupClosed: () => {
                    setLoading(false)
                  },
                  dataPopupWebhookReceived: () => {
                    setLoading(false)
                    onConfirm()
                  },
                }
              )
            }}
          >
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('pricing/icon__rockets.png')}
            />
            <span>
              {!uiController.trialModalNeedCheckInstall || commonUtils.isInstalledExtension()
                ? t('buyModal.btn')
                : t('trialModal.installBtn')}
            </span>
          </Button>
        </div>
      </div>
    </Modal>
  )
})

export default BuyModal
