const TRANSLATION = {
  slogen: 'Der einfachste KI-Assistent',
  pageHeader: {
    help: 'Hilfezentrum',
    contact: 'Kontaktiere uns',
    pricing: 'Preisgestaltung',
    signUp: 'Anmeldung',
    settings: 'Einstellungen',
    logout: 'Ausloggen',
    logoutSucc: 'Abmeldung erfolgreich',
  },
  pageFooter: {
    product: {
      title: 'Produkte',
      web: 'Webübersetzung und Zusammenfassung',
      pdf: 'PDF-Übersetzung',
      video: 'Videoübersetzung und Zusammenfassung',
      image: 'Bildübersetzung',
    },
    resources: { title: 'Ressourcen' },
    follow: { title: 'Folgen', twitter: 'Twitter', facebook: 'Facebook' },
    legal: { title: 'Legal', policy: 'Datenschutzrichtlinie', terms: 'Nutzungsbedingungen' },
  },
  home: {
    getStart: 'Loslegen',
    getStartedForFree: 'Starten Sie kostenlos durch',
    top: {
      desc: 'Noam ist eine kostenlose KI-Browsererweiterung, die Ihnen bei der Übersetzung zusammengefasster Webseiten, Videos und PDF-Inhalte hilft.\n      Begleiten Sie Noam und genießen Sie die Magie!',
      title2: 'Eine Magie',
      title1: 'Ein Klick',
    },
    howItWork: {
      title: 'Also, wie es funktioniert',
      desc: 'Noam integriert KI-Modelle, um Benutzern ein intelligentes Erlebnis für die sprachübergreifende Übersetzung und Zusammenfassung von Webseiten, PDF- und Bildübersetzungen sowie Videoübersetzungen und -zusammenfassungen zu bieten',
    },
    PDFTranslate: {
      title: 'PDF-Übersetzung',
      desc: 'Zweisprachige Übersetzung + Chat PDF',
      sub1: {
        title: 'Zweisprachige Lektüre',
        desc: 'Zweisprachiges Lesen, sodass Sie beim Lesen den Originaltext und die Übersetzung vergleichen können, um die Leseeffizienz zu verbessern',
      },
      sub2: {
        title: 'Chat-PDF',
        desc: 'KI analysiert und fasst zusammen und kann Ihre Fragen anhand des PDF-Inhalts beantworten',
      },
      sub3: {
        title: 'Genauigkeit und Effizienz',
        desc: 'KI-gestütztes PDF für genauere und effizientere Übersetzungen',
      },
    },
    aiAssistant: {
      title: 'KI-Assistent',
      desc: 'Machen Sie die Webübersetzung intelligenter und bequemer',
      panel1: {
        title: 'Zweisprachige Übersetzung',
        desc: 'Zweisprachige Übersetzungen mit Querverweisen helfen Ihnen, den Originaltext mit der Übersetzung zu vergleichen, damit Sie ihn besser verstehen und Ihre Leseeffizienz verbessern können.',
      },
      panel2: {
        left: {
          title: 'Hover-Übersetzung',
          desc: 'Bewegen Sie beim Durchsuchen einer Webseite Ihre Maus direkt über den Inhalt, der übersetzt werden muss, um das Übersetzungsergebnis zu erhalten.',
        },
        right: {
          title: 'Übersetzung der Textauswahl',
          desc: 'Wählen Sie beim Durchsuchen einer Webseite direkt den zu unterstreichenden Text aus, um das Übersetzungsergebnis zu erhalten.',
        },
      },
      panel3: {
        one: {
          title: 'Zeigen und Klicken',
          desc: 'Übersetzen und fassen Sie Webseiten und Videoinhalte jederzeit, überall und in jedem Szenario für Sie zusammen. für ein reibungsloseres Erlebnis!',
        },
        two: {
          title: 'Umfassender und bequemer',
          desc: 'Mehrsprachige vollständige Abdeckung, zweisprachiges barrierefreies Lesen, One-Key-Übersetzung; Bringen Sie ein unvergleichliches Erlebnis!',
        },
        three: {
          title: 'KI-gesteuert',
          desc: 'KI-gesteuertes großes Modell zur Gewährleistung der Übersetzungsgenauigkeit und -konsistenz und zur Verbesserung des Leseerlebnisses',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Videoübersetzung und Zusammenfassung',
          desc: 'Ihr Effizienzexperte übersetzt und fasst in Kombination mit chatgpt YouTube-Videoinhalte zusammen, um Ihre Lesezeit zu sparen!',
        },
        tab2: {
          key: 'Bild',
          title: 'Bild übersetzen',
          desc: 'Klicken Sie hier, um Bilder beim Surfen im Internet zu übersetzen',
        },
        tab3: {
          key: 'Netz',
          title: 'Web-Übersetzung und -Zusammenfassung',
          desc: 'Extrahieren Sie schnell wichtige Informationen aus Webseiten zur Zusammenfassung und verbessern Sie so Ihre Leseeffizienz',
        },
        tag1: 'Einfach',
        tag2: 'Effizienz',
        tag3: 'Einfach verstanden',
      },
    },
    userReviews: { title: 'Benutzerbewertungen', desc: 'Fünf-Sterne-Bewertung im Chromewebstore' },
    lastBlock: { desc: 'Bessere KI-Übersetzung und flüssigeres Leseerlebnis' },
  },
  login: {
    title: 'Willkommen bei Noam',
    btn: 'Melden Sie sich mit Google an',
    tips: 'Mit meiner Anmeldung stimme ich zu',
    terms: 'Nutzungsbedingungen',
    and: 'Und',
    policy: 'Datenschutzrichtlinie',
    btn1: 'Jetzt einloggen',
    desc: 'Melden Sie sich an, um weitere Erlebnisse freizuschalten',
  },
  pricing: {
    pageTitle: 'Preisgestaltung',
    desc: 'Vergleichen Sie und wählen Sie das Beste für Sie aus',
    yearly: 'Jährlich',
    monthly: 'Monatlich',
    discount: '20% Rabatt',
    preMonth: 'Pro Monat',
    billedYearly: 'Jährliche Abrechnung',
    billedMonthly: 'Monatlich abgerechnet',
    detailedCompare: 'Detaillierter Vergleich',
    FAQ: 'FAQ',
    day: 'Tag',
    month: 'Monat',
    around: 'Um',
    free: {
      benefit1: 'Sofortige Übersetzung 100 Mal pro Tag',
      benefit2: 'Webübersetzung und Zusammenfassung',
      benefit3: '30 Blatt Bildübersetzung',
      benefit4: 'Videoübersetzung und Zusammenfassung',
      benefit5: '10 PDF-Übersetzung',
      benefit6: '5.000 Token',
      recommendText: 'Kostenlose Testphase',
      btn: 'Viel Spaß',
    },
    pro: {
      benefit1: 'Kundendienst, schneller Support',
      benefit2: '1.000.000 Token/Monat',
      benefit3: 'Webübersetzung und Zusammenfassung',
      benefit4: '200 Blatt Bildübersetzung',
      benefit5: 'Videoübersetzung und Zusammenfassung',
      benefit6: '200 PDF-Übersetzung',
      btn: 'Loslegen',
      recommendText: 'Die meisten Menschen wählen',
    },
    proPlus: {
      benefit1: 'Kundendienst, schneller Support',
      benefit2: '3.000.000 Token/Monat',
      benefit3: 'Webübersetzung und Zusammenfassung',
      benefit4: '500 Blatt Bildübersetzung',
      benefit5: 'Videoübersetzung und Zusammenfassung',
      benefit6: '500 PDF-Übersetzungen',
      recommendText: 'Die am besten geeignete Wahl',
    },
    enterprise: {
      benefit1: 'Kundendienst, schneller Support',
      benefit2: 'Mehrbenutzer',
      benefit3: 'Maßgeschneiderte Lösungen',
      benefit5: 'Exklusiver Account Manager',
      recommendText: 'Maßgeschneiderte Pläne（≥3人）',
      talkToUs: 'Rede mit uns',
      btn: 'Kontakt',
    },
    web: {
      contrastiveTranslation: 'Zweisprachige Übersetzung',
      instantTranslation: 'Direkte Übersetzung',
      hoverTranslation: 'Hover-Übersetzung',
      dictionaryTranslation: 'Übersetzung der Textauswahl',
      summary: 'Zusammenfassung der Webseite',
    },
    imageTranslation: 'Bildübersetzung',
    video: { translation: 'Videoübersetzung', summary: 'Videozusammenfassung' },
    pdf: {
      translation: 'PDF-Übersetzung',
      maxPage: 'Maximale Seitenzahl im PDF',
      chat: 'Anzahl der PDF-Anfragen',
    },
    FQAObj: {
      q1: 'Kann ich es kostenlos nutzen?',
      a1: 'Es gibt eine kostenlose Version von Noam, die direkt genutzt werden kann.',
      q2: 'Gibt es verschiedene Mitgliedschaftspläne und Gebührenoptionen?',
      a2: 'Zusätzlich zur kostenlosen Version gibt es drei Mitgliedschaftspläne: Pro, Pro+ und Enterprise; Sie können zwischen zwei Zahlungsmethoden wählen: fortlaufend monatlich und fortlaufend jährlich. Die Gesamtkosten für fortlaufende jährliche Zahlungen sind günstiger.',
      q3: 'Was ist der Unterschied zwischen einem fortlaufenden Monatsabonnement und einem fortlaufenden Jahresabonnement?',
      a3: 'Es gibt keinen Unterschied in der Funktionalität. Die Gesamtkosten eines fortlaufenden Jahresabonnements sind niedriger als die eines fortlaufenden Monatsabonnements; Das Monatsabonnement wird einmal pro Monat abgerechnet, während das Jahresabonnement einmal pro Jahr abgerechnet wird.',
      q4: 'Wie kann ich meinen automatischen Verlängerungsplan kündigen?',
      a4: 'Sie können zur Seite mit den Backend-Einstellungen gehen und sich für ein Abonnement entscheiden, um den Verlängerungsplan zu kündigen.',
    },
  },
  upgradeModal: {
    title: 'Erinnerungen',
    limitAlertStr:
      'Ihr Kontingent für den kostenlosen Plan ist aufgebraucht. Wir empfehlen Ihnen, Ihren Plan zu aktualisieren, um das Erlebnis fortzusetzen~!',
    upgradeAlertStr:
      'Ihr Kontingent für den kostenlosen Plan ist aufgebraucht. Wir empfehlen Ihnen, Ihren Plan zu aktualisieren, um das Erlebnis fortzusetzen~!',
    btn: 'Jetzt upgraden',
    more: 'Weitere Pläne',
    contactStr:
      'Bitte warten Sie auf den nächsten Reset oder kontaktieren Sie uns unter contact@noam.tools.',
    upload: {
      free: 'Sie haben Ihr Limit für die PDF-Übersetzung erreicht',
      pro: 'Sie haben in diesem Monat Ihr Limit für die PDF-Übersetzung erreicht',
    },
    chat: {
      free: 'Sie haben Ihr Limit des Chatpdf-Limits überschritten',
      pro: 'Sie haben in diesem Monat Ihr Limit für das Chatpdf erreicht',
    },
    pageLimit: { free: 'Sie haben Ihr PDF-Seitenlimit überschritten' },
  },
  buyModal: {
    title: 'Abonnementplan',
    freeTrial: '3-tägige kostenlose Testversion',
    selectLabel: 'Wählen Sie einen Plan aus',
    btn: 'Probieren Sie es kostenlos aus',
    yearLabel: 'Jahr',
    monthLabel: 'Monat',
    afterFree: 'Nach dem Prozess',
  },
  privacy: {
    title: 'Datenschutzrichtlinie',
    updateTime: 'Aktualisiert: 15. März 2024',
    block1:
      'Wir, Noam (im Folgenden als „Noam“, „wir“, „unser“ oder „uns“ bezeichnet), respektieren Ihre Privatsphäre und verpflichten uns zum Schutz aller Informationen, die wir von Ihnen oder über Sie erhalten. Diese Datenschutzrichtlinie beschreibt unsere Praktiken hinsichtlich der Erhebung oder Verwendung personenbezogener Daten aus Ihrer Nutzung unserer Website, Anwendungen und Dienste (gemeinsam als „Dienste“ bezeichnet). Diese Datenschutzrichtlinie gilt nicht für die Inhalte, die wir im Namen unserer Geschäftsproduktkunden verarbeiten, wie z. B. unsere API. Die Nutzung dieser Daten unterliegt unserer Kundenvereinbarung, die den Zugang zu und die Nutzung dieser Produkte regelt.',
    subTitle1: 'Persönliche Informationen, die wir sammeln',
    block2:
      'Wir erfassen die folgenden personenbezogenen Daten („personenbezogene Daten“) über Sie:',
    block3:
      'Von Ihnen bereitgestellte Informationen: Wenn Sie ein Konto erstellen, um unsere Dienste zu nutzen oder mit uns zu kommunizieren, erfassen wir personenbezogene Daten wie folgt:',
    subBlock1:
      'Kontoinformationen: Wenn Sie ein Konto auf unserer Plattform erstellen, erfassen wir Informationen zu Ihrem Konto, einschließlich Ihres Namens, Kontaktinformationen, Kontoanmeldeinformationen, Zahlungskarteninformationen und Transaktionsverlauf (im Folgenden als „Kontoinformationen“ bezeichnet).',
    subBlock2:
      'Benutzerinhalte: Wenn Sie unsere Dienste nutzen, erfassen wir personenbezogene Daten aus Ihren Eingaben, Datei-Uploads oder Ihrem Feedback (im Folgenden als „Inhalte“ bezeichnet).',
    subBlock3:
      'Kommunikationsinformationen: Wenn Sie uns Nachrichten senden, erfassen wir Ihren Namen, Ihre Kontaktinformationen und den Inhalt der von Ihnen gesendeten Nachrichten (als „Kommunikationsinformationen“ bezeichnet).',
    subBlock4:
      'Informationen zu sozialen Medien: Wir haben Seiten auf Social-Media-Websites wie Instagram, Facebook, Medium, Twitter, YouTube und LinkedIn. Wenn Sie mit unseren Social-Media-Seiten interagieren, erfassen wir die persönlichen Daten, die Sie uns zur Verfügung stellen, wie zum Beispiel Ihre Kontaktdaten (im Folgenden „Sozialdaten“ genannt). Darüber hinaus stellen uns die Unternehmen, die unsere Social-Media-Seiten hosten, möglicherweise aggregierte Informationen und Analysen über unsere Social-Media-Aktivitäten zur Verfügung.',
    block4:
      'Persönliche Informationen, die wir automatisch durch Ihre Nutzung der Dienste erhalten: Wenn Sie auf die Dienste zugreifen, sie nutzen oder mit ihnen interagieren, erhalten wir die folgenden Informationen über Ihren Zugriff, Ihre Nutzung oder Ihre Interaktion („technische Informationen“):',
    subBlock5:
      'Protokolldaten: Informationen, die Ihr Browser automatisch sendet, wenn Sie unsere Dienste nutzen. Zu den Protokolldaten gehören Ihre Internet Protocol (IP)-Adresse, Browsertyp und -einstellungen, Datum und Uhrzeit Ihrer Anfragen sowie die Art und Weise, wie Sie mit unserer Website interagieren.',
    subBlock6:
      'Nutzungsdaten: Wir erfassen möglicherweise automatisch Informationen über Ihre Nutzung des Dienstes, z. B. die Arten von Inhalten, die Sie ansehen oder mit denen Sie interagieren, die von Ihnen genutzten Funktionen und Ihre durchgeführten Aktionen sowie Ihre Zeitzone, Ihr Land, Datum und Uhrzeit Zugriff, Benutzeragent und Version, Computer- oder Mobilgerätetyp und Ihre Computerverbindung.',
    subBlock7:
      'Geräteinformationen: Dazu gehören der Name des Geräts, das Betriebssystem, die Gerätekennung und der von Ihnen verwendete Browser. Die gesammelten Informationen können von der Art des von Ihnen verwendeten Geräts und seinen Einstellungen abhängen.',
    subBlock8:
      'Cookies: Wir verwenden Cookies, um unsere Dienste zu betreiben und zu verwalten und Ihr Erlebnis zu verbessern. Ein „Cookie“ sind Informationen, die von einer Website, die Sie besuchen, an Ihren Browser gesendet werden. Sie können Ihren Browser so einstellen, dass er alle Cookies akzeptiert, alle Cookies ablehnt oder Sie benachrichtigt, wenn ein Cookie bereitgestellt wird, sodass Sie jedes Mal entscheiden können, ob Sie es akzeptieren möchten. Die Ablehnung von Cookies kann jedoch dazu führen, dass Sie bestimmte Bereiche oder Funktionen der Website nicht nutzen können oder die Anzeige oder Funktionalität beeinträchtigt wird. Weitere Informationen zu Cookies finden Sie unter „Alles über Cookies“.',
    subBlock9:
      'Analytik: Wir verwenden möglicherweise verschiedene Online-Analyseprodukte, die Cookies verwenden, um uns dabei zu helfen, zu analysieren, wie Benutzer unsere Dienste nutzen, und um Ihr Erlebnis bei der Nutzung der Dienste zu verbessern.',
    subTitle2: 'Wie wir personenbezogene Daten verwenden',
    block5: 'Wir können personenbezogene Daten für die folgenden Zwecke verwenden:',
    subBlock10: 'Bereitstellung, Verwaltung, Wartung und/oder Analyse von Dienstleistungen;',
    subBlock26: 'Verbesserung unserer Dienstleistungen und Durchführung von Forschungsarbeiten;',
    subBlock11: 'Mit Ihnen kommunizieren;',
    subBlock12: 'Entwicklung neuer Projekte und Dienstleistungen;',
    subBlock13:
      'Verhinderung von Betrug, kriminellen Aktivitäten oder Missbrauch unserer Dienste und Schutz der Sicherheit unserer IT-Systeme, Architektur und Netzwerke;',
    subBlock14: 'Durchführung von Geschäftsübertragungen;',
    subBlock15:
      'Einhaltung gesetzlicher Verpflichtungen und Gerichtsverfahren, Schutz unserer Rechte, Privatsphäre, Sicherheit oder unseres Eigentums sowie der unserer verbundenen Unternehmen, Ihnen oder anderen Dritten.',
    block6:
      'Zusammenfassende oder anonymisierte Informationen: Wir können personenbezogene Daten aggregieren oder anonymisieren, um ihre Wiederverwendung zu Identifizierungszwecken zu verhindern, und diese Informationen verwenden, um die Wirksamkeit unserer Dienste zu analysieren, unsere Dienste zu verbessern und Funktionen hinzuzufügen, Forschungsarbeiten durchzuführen und für ähnliche Zwecke . Darüber hinaus können wir das allgemeine Verhalten und die Merkmale unserer Servicenutzer analysieren und aggregierte Informationen an Dritte weitergeben, diese aggregierten Informationen veröffentlichen oder sie allgemein verfügbar machen. Wir können aggregierte Informationen über die Dienste, über Cookies und über andere in dieser Datenschutzrichtlinie beschriebene Mittel sammeln. Wir werden nicht identifizierte Informationen in anonymisierter oder nicht identifizierter Form aufbewahren und verwenden und wir werden nicht versuchen, diese Informationen erneut zu identifizieren, es sei denn, dies ist gesetzlich vorgeschrieben.',
    subTitle3: 'Offenlegung personenbezogener Daten',
    block7:
      'In bestimmten Fällen können wir Ihre personenbezogenen Daten ohne weitere Mitteilung an Dritte weitergeben, sofern dies nicht gesetzlich vorgeschrieben ist:',
    subBlock16:
      'Lieferanten und Dienstanbieter: Um uns bei der Erfüllung geschäftlicher Betriebsanforderungen und der Erbringung bestimmter Dienste und Funktionen zu unterstützen, können wir personenbezogene Daten an Lieferanten und Dienstanbieter weitergeben, einschließlich Hosting-Dienstleistern, Cloud-Dienstanbietern und anderen Anbietern von Informationstechnologiediensten und E-Mail-Kommunikationssoftware , und Webanalysedienstanbieter usw. Diese Partner werden personenbezogene Daten nur auf unsere Anweisung zugreifen, verarbeiten oder speichern, während sie ihre Aufgaben für uns erfüllen.',
    subBlock17:
      'Geschäftsübertragungen: Wenn wir uns an strategischen Transaktionen, Umstrukturierungen, Insolvenzen, Insolvenzübernahmen oder der Übertragung von Dienstleistungen an einen anderen Anbieter beteiligen (gemeinsam als „Transaktionen“ bezeichnet), können Ihre personenbezogenen Daten und andere Informationen an Transaktionspartner und andere beteiligte Parteien weitergegeben werden die Transaktionen im Rahmen der Due Diligence überprüft und im Rahmen der Transaktionen zusammen mit anderen Vermögenswerten an ein Nachfolge- oder verbundenes Unternehmen übertragen.',
    subBlock18:
      'Gesetzliche Anforderungen: Wir können Ihre personenbezogenen Daten an Regierungsbehörden, Branchenkollegen oder andere Dritte weitergeben, einschließlich Ihrer Interaktionsdaten mit unseren Diensten, (1) wenn dies gesetzlich vorgeschrieben ist oder wenn wir glauben, dass eine solche Maßnahme zur Einhaltung gesetzlicher Verpflichtungen erforderlich ist; (2) zum Schutz und zur Verteidigung unserer Rechte oder unseres Eigentums; (3) wenn wir einseitig feststellen, dass ein Verstoß gegen unsere Bedingungen, Richtlinien oder Gesetze vorliegt; (4) um Betrug oder andere illegale Aktivitäten aufzudecken oder zu verhindern; (5) zum Schutz unserer Produkte, Mitarbeiter oder Benutzer oder der öffentlichen Sicherheit, Integrität; oder (vi) zur Abwehr rechtlicher Haftung.',
    subTitle4: 'Deine Rechte',
    block8:
      'Abhängig von Ihrem geografischen Standort haben Personen im Europäischen Wirtschaftsraum, im Vereinigten Königreich und weltweit möglicherweise bestimmte gesetzliche Rechte in Bezug auf ihre personenbezogenen Daten. Beispielsweise haben Sie möglicherweise das Recht:',
    subBlock19:
      'Zugriff auf Ihre persönlichen Daten und Informationen darüber, wie diese verarbeitet werden.',
    subBlock20: 'Löschen Sie Ihre persönlichen Daten aus unseren Aufzeichnungen.',
    subBlock21: 'Korrigieren oder aktualisieren Sie Ihre persönlichen Daten.',
    subBlock22: 'Übertragen Sie Ihre persönlichen Daten an Dritte (Datenportabilität).',
    subBlock23:
      'Beschränken Sie die Art und Weise, wie wir Ihre personenbezogenen Daten verarbeiten.',
    subBlock24:
      'Widerrufen Sie Ihre Einwilligung – wenn wir uns auf die Einwilligung als Rechtsgrundlage für die Verarbeitung stützen, können Sie Ihre Einwilligung jederzeit widerrufen.',
    subBlock25: 'Widersprechen Sie der Verarbeitung Ihrer personenbezogenen Daten durch uns.',
    subTitle5: 'Links zu anderen Websites',
    block9:
      'Der Dienst kann Links zu anderen Websites enthalten, die nicht von Noam betrieben oder kontrolliert werden, einschließlich Social-Media-Diensten („Websites Dritter“). Die Informationen, die Sie an Websites Dritter weitergeben, unterliegen den spezifischen Datenschutzrichtlinien und Nutzungsbedingungen der Websites Dritter, nicht dieser Datenschutzrichtlinie. Wir stellen diese Links der Einfachheit halber zur Verfügung und bedeuten nicht, dass wir diese Websites befürworten oder überprüfen. Bitte wenden Sie sich direkt an die Websites Dritter, um Informationen zu deren Datenschutzrichtlinien und -bedingungen zu erhalten.',
    subTitle6: 'Sicherheit und Aufbewahrung von Informationen',
    block10:
      'Wir ergreifen wirtschaftlich angemessene technische, administrative und organisatorische Maßnahmen, um personenbezogene Daten online und offline vor Verlust, Missbrauch und unbefugtem Zugriff, Offenlegung, Änderung oder feindseligen Handlungen zu schützen. Allerdings kann keine Internet- oder E-Mail-Übertragung vollständige Sicherheit oder Genauigkeit garantieren. Insbesondere an uns oder von uns gesendete E-Mails sind möglicherweise nicht sicher. Daher sollten Sie bei der Entscheidung, welche Informationen Sie uns über den Dienst oder per E-Mail senden, besonders vorsichtig sein. Darüber hinaus sind wir nicht verantwortlich für die Beeinträchtigung der Datenschutzeinstellungen oder Sicherheitsmaßnahmen im Dienst oder der Sicherheitseinstellungen oder Sicherheitsmaßnahmen von Websites Dritter. Wir bewahren Ihre personenbezogenen Daten so lange auf, wie es für die Erbringung von Dienstleistungen oder für andere legitime Geschäftszwecke erforderlich ist, beispielsweise zur Beilegung von Streitigkeiten, aus Sicherheitsgründen oder zur Einhaltung unserer gesetzlichen Verpflichtungen. Die Dauer der Aufbewahrung personenbezogener Daten hängt von vielen Faktoren ab, wie z. B. der Menge, der Art, der Sensibilität der Informationen, den potenziellen Risiken einer unbefugten Nutzung oder Offenlegung, den Zwecken, für die wir die Informationen verarbeiten, und anderen rechtsverbindlichen Erwägungen.',
    subTitle7: 'Änderung der Datenschutzrichtlinie',
    block11:
      'Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wenn wir dies tun, werden wir eine aktualisierte Version auf dieser Seite veröffentlichen, sofern das geltende Recht nichts anderes vorschreibt.',
    subTitle8: 'Wie Sie uns erreichen',
    block12:
      'Wenn Sie unbeantwortete Fragen oder Bedenken zu dieser Datenschutzrichtlinie haben, wenden Sie sich bitte an unseren Kundenservice. Email:',
  },
  terms: {
    title: 'Nutzungsbedingungen',
    updateTime: 'Aktualisiert: 15. März 2024',
    block1: 'Vielen Dank, dass Sie Noam nutzen!',
    block2:
      'Diese Nutzungsbedingungen gelten für Ihre Nutzung der Dienste von Noam, einschließlich unserer Anwendungsprogrammierschnittstellen, Software, Tools, Entwicklerdienste, Daten, Dokumentation und Website (gemeinsam als „Dienste“ bezeichnet). Durch die Nutzung unserer Dienste erklären Sie sich mit der Einhaltung dieser Bedingungen einverstanden. Unsere Datenschutzrichtlinie erläutert, wie wir personenbezogene Daten erfassen und verwenden.',
    subTitle1: 'Registrierung und Zugang',
    block3:
      'Wenn Sie unter 18 Jahre alt sind, müssen Sie zur Nutzung des Dienstes die Erlaubnis Ihrer Eltern oder Erziehungsberechtigten einholen. Wenn Sie den Dienst im Namen einer anderen natürlichen oder juristischen Person nutzen, müssen Sie berechtigt sein, diese Bedingungen in deren Namen zu akzeptieren. Sie müssen genaue und vollständige Informationen angeben, um sich für ein Konto zu registrieren. Sie dürfen Ihre Zugangsdaten oder Ihr Konto nicht an Dritte außerhalb Ihrer Organisation weitergeben und sind für alle Aktivitäten verantwortlich, die mit Ihren Zugangsdaten durchgeführt werden.',
    subTitle2: 'Nutzungsanforderungen',
    block4:
      '(a) Nutzung des Dienstes. Sie können im Rahmen dieser Bedingungen auf den Dienst zugreifen und wir gewähren Ihnen ein nicht ausschließliches Recht zur Nutzung des Dienstes. Bei der Nutzung des Dienstes werden Sie diese Bedingungen und alle geltenden Gesetze einhalten. Wir und unsere verbundenen Unternehmen besitzen alle Rechte, Titel und Interessen an dem Dienst.',
    block5:
      '(b) Feedback. Wir freuen uns über Feedback, Kommentare, Ideen, Vorschläge und Verbesserungen. Wenn Sie solche Inhalte bereitstellen, dürfen wir diese ohne Einschränkungen und ohne Vergütung an Sie nutzen.',
    block6:
      '(c) Einschränkungen. Es ist Ihnen nicht gestattet: (1) den Dienst in einer Weise zu nutzen, die die Rechte anderer verletzt, missbraucht oder verletzt; (2) den Quellcode oder die zugrunde liegenden Komponenten der Servicemodelle, Algorithmen und Systeme zurückzuentwickeln, zu dekompilieren, zu disassemblieren, zu übersetzen oder auf andere Weise zu versuchen, sie zu entdecken (es sei denn, solche Einschränkungen sind durch geltendes Recht verboten); (3) die Ergebnisse des Dienstes nutzen, um Modelle zu entwickeln, die mit Noam konkurrieren; (4) Extrahieren von Daten oder Ausgaben aus dem Dienst mithilfe automatisierter Methoden oder Programmiermethoden, sofern dies nicht durch die API gestattet ist, einschließlich Scraping, Web Harvesting oder Web-Datenextraktion; (5) die Ausgabe des Dienstes als künstlich erzeugt darstellen, wenn dies nicht der Fall ist, oder anderweitig gegen unsere Nutzungsrichtlinien verstoßen; (6) API-Schlüssel ohne unsere vorherige Zustimmung kaufen, verkaufen oder übertragen; oder (7) uns personenbezogene Daten von Kindern unter 13 Jahren bzw. dem geltenden Mindestalter für die digitale Einwilligung zur Verfügung stellen. Sie werden etwaige Tarifbegrenzungen und andere Anforderungen in unserer Dokumentation einhalten.',
    block7:
      '(d) Dienste Dritter. Jegliche Software, Dienste oder andere Produkte von Drittanbietern, die mit dem Dienst verbunden sind, unterliegen ihren eigenen Bedingungen und wir sind nicht für Produkte von Drittanbietern verantwortlich.',
    subTitle3: 'Inhalt',
    block8:
      '(a) Ihre Inhalte. Sie können Eingaben („Eingaben“) für den Dienst bereitstellen und Ausgaben („Ausgaben“) erhalten, die auf der Grundlage der Eingaben generiert und zurückgegeben werden (gemeinsam als „Inhalte“ bezeichnet). Zwischen den Parteien und im gesetzlich zulässigen Umfang sind Sie Eigentümer sämtlicher Eingaben. Noam kann Inhalte verwenden, um den Dienst bereitzustellen und aufrechtzuerhalten, geltende Gesetze einzuhalten und unsere Richtlinien durchzusetzen. Sie sind für den Inhalt verantwortlich und stellen unter anderem sicher, dass dieser nicht gegen geltende Gesetze oder diese Bedingungen verstößt.',
    block9:
      '(b) Genauigkeit. Künstliche Intelligenz und maschinelles Lernen sind sich schnell entwickelnde Forschungsfelder. Wir sind ständig bestrebt, unseren Service zu verbessern, um ihn genauer, zuverlässiger, sicherer und nützlicher zu machen. Aufgrund der probabilistischen Natur des maschinellen Lernens kann die Nutzung unseres Dienstes in bestimmten Fällen zu falschen Ergebnissen führen, die möglicherweise nicht genau reale Personen, Orte oder Fakten widerspiegeln. Sie sollten die Genauigkeit aller Ausgaben entsprechend Ihrem Anwendungsfall angemessen beurteilen, unter anderem durch manuelle Überprüfung der Ausgaben.',
    subTitle4: 'Gebühren und Zahlung',
    block10:
      '(a) Gebühren und Abrechnung. Sie zahlen alle Gebühren („Gebühren“) gemäß den Preisen und Bedingungen auf der entsprechenden Preisseite oder wie schriftlich zwischen uns vereinbart. Wir behalten uns das Recht vor, Preisfehler oder Irrtümer zu korrigieren, auch wenn wir Rechnungen ausgestellt oder Zahlungen erhalten haben. Sie stellen vollständige und korrekte Rechnungsinformationen bereit, einschließlich einer gültigen und autorisierten Zahlungsmethode. Wir belasten Ihre Zahlungsmethode in regelmäßigen Abständen wie vereinbart, können das Abrechnungsdatum jedoch angemessen ändern. Sie ermächtigen Noam, seine verbundenen Unternehmen und Drittanbieter, Ihre Zahlungsmethode zu belasten. Wenn Ihre Zahlung fehlschlägt, benachrichtigen wir Sie schriftlich und können den Zugriff auf den Dienst sperren, bis die Zahlung eingegangen ist. Sofern in dieser Vereinbarung nicht anders angegeben, sind Zahlungen nicht erstattungsfähig.',
    block11:
      '(b) Steuern. Sofern nicht anders angegeben, beinhalten die Gebühren keine bundesstaatlichen, staatlichen, lokalen und ausländischen Steuern, Zölle und ähnliche Abgaben („Steuern“). Sie sind für alle mit Ihren Einkäufen verbundenen Steuern verantwortlich, mit Ausnahme der Steuern, die auf unserem Nettoeinkommen basieren und die wir Ihnen in Rechnung stellen. Sie erklären sich damit einverstanden, diese Steuern umgehend zu zahlen, uns rechtsgültige Datenschutzerklärungen zur Verfügung zu stellen und die erforderlichen Einwilligungen für den Umgang mit diesen Daten einzuholen, und Sie garantieren uns, dass Sie diese Daten in Übereinstimmung mit geltendem Recht behandeln.',
    block12:
      '(c) Preisänderungen. Wir können unsere Preise ändern, indem wir Sie über Ihr Konto und/oder unsere Website benachrichtigen. Preiserhöhungen werden sofort nach Veröffentlichung wirksam. Alle Preisänderungen gelten ab dem Datum des Inkrafttretens der Änderung für die Gebühren für Ihr Konto.',
    block13:
      '(d) Streitigkeiten und überfällige Zahlungen. Wenn Sie Gebühren oder Steuern anfechten möchten, wenden Sie sich bitte innerhalb von dreißig (30) Tagen nach Rechnungsdatum an contact@noam.tools.',
    block14:
      '(e) Kostenlose Nutzung. Sie dürfen nicht mehrere Konten erstellen, um Gratisguthaben zu erhalten. Wenn wir feststellen, dass Sie das kostenlose Guthaben nicht in gutem Glauben nutzen, können wir Ihnen Standardgebühren in Rechnung stellen oder den Zugriff auf den Dienst einstellen.',
    subTitle5: 'Vertraulichkeit, Sicherheit und Datenschutz',
    block15:
      '(a) Vertraulichkeit. Möglicherweise haben Sie Zugriff auf vertrauliche Informationen von Noam. Sie dürfen vertrauliche Informationen nur im Rahmen dieser Bedingungen zum Zwecke der Nutzung des Dienstes verwenden. Sie dürfen vertrauliche Informationen nicht an Dritte weitergeben und werden vertrauliche Informationen auf eine Weise schützen, die nicht weniger schützend ist als Ihre eigenen ähnlichen vertraulichen Informationen, zumindest mit angemessener Sorgfalt. Vertrauliche Informationen sind nicht öffentliche Informationen, die von Noam als vertraulich eingestuft werden oder die unter den gegebenen Umständen vernünftigerweise als vertraulich behandelt werden sollten, einschließlich Software, Spezifikationen und anderen nicht öffentlichen Geschäftsinformationen. Zu den vertraulichen Informationen zählen keine Informationen, die: (1) ohne Ihr Verschulden der Öffentlichkeit allgemein zugänglich werden; (2) Sie befanden sich ohne Verpflichtung zur Vertraulichkeit in Ihrem Besitz, bevor Sie sie gemäß diesen Bedingungen erhalten haben; (3) Ihnen von einem Dritten rechtmäßig und ohne Geheimhaltungsverpflichtung offengelegt werden; oder (4) von Ihnen unabhängig entwickelt wurde, ohne die vertraulichen Informationen zu nutzen. Sie können vertrauliche Informationen offenlegen, wenn dies gesetzlich, gerichtlich oder durch eine andere behördliche Anordnung erforderlich ist. Sie müssen Noam jedoch zuvor in angemessener Weise schriftlich benachrichtigen und, soweit möglich, angemessene Anstrengungen unternehmen, um den Umfang der Offenlegung einzuschränken, einschließlich unserer Unterstützung bei der Ablehnung von Offenlegungsanträgen.',
    block16:
      '(b) Sicherheit. Sie müssen angemessene und angemessene Maßnahmen ergreifen, um Ihren Zugriff auf den Dienst und Ihre Nutzung des Dienstes zu schützen. Wenn Sie Schwachstellen oder Verstöße im Zusammenhang mit Ihrer Nutzung des Dienstes entdecken, müssen Sie sich umgehend an Noam wenden und Einzelheiten zu der Schwachstelle oder dem Verstoß angeben.',
    block17:
      '((c) Verarbeitung personenbezogener Daten. Wenn Sie den Dienst zur Verarbeitung personenbezogener Daten nutzen, müssen Sie rechtlich ausreichende Datenschutzerklärungen bereitstellen und die erforderlichen Einwilligungen für die Verarbeitung dieser Daten einholen, und Sie garantieren uns, dass Sie diese Daten in Übereinstimmung mit geltendem Recht behandeln .',
    subTitle6: 'Laufzeit und Kündigung',
    block18:
      '(a) Kündigung; Suspension. Diese Bedingungen treten ab Ihrer ersten Nutzung des Dienstes in Kraft und bleiben bis zu ihrer Kündigung in Kraft. Sie können diese Bedingungen jederzeit und aus beliebigem Grund kündigen, indem Sie die Nutzung des Dienstes und der Inhalte einstellen. Wir können diese Bedingungen aus beliebigem Grund mit vorheriger Ankündigung kündigen. Wir können diese Bedingungen sofort kündigen, indem wir Sie benachrichtigen, wenn Sie erheblich gegen Abschnitt 2 (Nutzungsanforderungen), Abschnitt 5 (Vertraulichkeit, Sicherheit und Datenschutz), Abschnitt 8 (Streitbeilegung) oder Abschnitt 9 (Allgemeine Geschäftsbedingungen) verstoßen oder wenn unsere sich die Beziehung zu externen Technologieanbietern außerhalb unserer Kontrolle ändert oder um die Einhaltung von Gesetzen oder behördlichen Anforderungen zu gewährleisten. Wir können Ihren Zugriff auf den Dienst sperren, wenn Sie diese Bedingungen nicht einhalten oder wenn Ihre Nutzung ein Sicherheitsrisiko für uns oder Dritte darstellt oder wenn wir den Verdacht haben, dass Ihre Nutzung betrügerisch ist oder uns oder Dritte einer Haftung aussetzen könnte .',
    block19:
      '(b) Auswirkungen der Kündigung. Nach der Kündigung werden Sie die Nutzung des Dienstes einstellen und alle vertraulichen Informationen gemäß unseren Anweisungen unverzüglich zurückgeben oder vernichten. Die Bestimmungen dieser Bedingungen, die ihrer Natur nach über die Kündigung oder den Ablauf hinaus bestehen sollten, einschließlich, aber nicht beschränkt auf die Abschnitte 3 und 5–9, bleiben bestehen.',
    subTitle7: 'Entschädigung; Haftungsausschluss; Haftungsbeschränkung',
    block20:
      '(a) Entschädigung. Sie werden uns, unsere verbundenen Unternehmen und unser Personal von jeglichen Ansprüchen, Verlusten und Kosten (einschließlich Anwaltskosten) freistellen, die sich aus oder im Zusammenhang mit Ihrer Nutzung des Dienstes ergeben, einschließlich Ihrer Inhalte, Ihrer Nutzung von Produkten oder Dienstleistungen im Zusammenhang mit dem Service und Ihr Verstoß gegen diese Bedingungen oder geltendes Recht.',
    block21:
      '(b) Haftungsausschluss. Der Dienst wird „wie besehen“ bereitgestellt. Soweit gesetzlich zulässig, geben wir und unsere verbundenen Unternehmen und Lizenzgeber keine Zusicherungen oder Gewährleistungen jeglicher Art in Bezug auf den Dienst ab und übernehmen keine Gewährleistungen, einschließlich, aber nicht beschränkt auf Gewährleistungen der Marktgängigkeit, der Eignung für einen bestimmten Zweck, der zufriedenstellenden Qualität und der Nichtverletzung von Rechten Dritter und stiller Genuss sowie alle Garantien, die sich aus dem Geschäftsverlauf oder der Handelsbrauchbarkeit ergeben. Wir garantieren nicht, dass der Dienst ununterbrochen, genau oder fehlerfrei ist oder dass Inhalte sicher sind, nicht verloren gehen oder nicht verändert werden.',
    block22:
      '(c) Haftungsbeschränkung. Weder wir, unsere verbundenen Unternehmen noch unsere Lizenzgeber haften für indirekte, zufällige, besondere, Folge- oder Strafschäden, einschließlich entgangenen Gewinns, Reputationsverlust, Nutzungsausfall, Datenverlust oder anderen immateriellen Verlusten, selbst wenn Wir wurden auf die Möglichkeit solcher Schäden hingewiesen. Gemäß diesen Bedingungen übersteigt unsere Gesamthaftung nicht die Gebühren, die Sie in den zwölf (12) Monaten vor dem Anspruch an den Dienst gezahlt haben, oder einhundert US-Dollar (100 US-Dollar), je nachdem, welcher Betrag höher ist. Die Einschränkungen in diesem Abschnitt gelten im größtmöglichen gesetzlich zulässigen Umfang.',
    subTitle8: 'Anwendbares Recht und Gerichtsstand',
    block23:
      'Die Bedingungen und Ihre Nutzung der Dienste unterliegen den Gesetzen der Vereinigten Staaten, mit Ausnahme der US-amerikanischen Kollisionsnormen. Ihre Nutzung der Anwendung kann auch anderen lokalen, staatlichen, nationalen oder internationalen Gesetzen unterliegen. Alle rechtlichen Schritte oder Verfahren im Zusammenhang mit diesen Bedingungen werden ausschließlich vor den Gerichten der Vereinigten Staaten verhandelt, und Sie erklären sich damit einverstanden, sich der persönlichen Zuständigkeit dieser Gerichte zu unterwerfen.',
    subTitle9: 'Allgemeine Geschäftsbedingungen',
    block24:
      '(a) Verhältnis der Parteien. Noam und Sie sind unabhängige Vertragspartner und keine der Parteien ist ohne die vorherige schriftliche Zustimmung der anderen Partei befugt, die andere Partei zu vertreten oder zu binden oder Verpflichtungen für die andere Partei einzugehen.',
    block25:
      '(b) Markennutzung. Ohne unsere vorherige schriftliche Zustimmung dürfen Sie den Namen, die Logos oder Marken von Noam oder einem seiner verbundenen Unternehmen nicht verwenden.',
    subTitle10: 'Salvatorische Klausel',
    block26:
      'Sollte sich herausstellen, dass eine Bestimmung dieser Bedingungen ungültig, rechtswidrig oder nicht durchsetzbar ist, bleiben die übrigen Bestimmungen in vollem Umfang in Kraft und wirksam.',
  },
  contact: {
    title: 'Kontaktiere uns',
    block1:
      'Für individuelle Anpassungen oder wenn Sie Fragen oder Anregungen haben, kontaktieren Sie uns bitte unter contact@noam.tools und wir werden umgehend antworten.',
    block2:
      'Unterstützen Sie uns, indem Sie uns im Google Apps Extension Store unter ★★★★★ bewerten.',
    block3: 'Helfen Sie anderen, indem Sie diese Erweiterung teilen:',
  },
  pdf: {
    toast1: 'PDF wird geladen, bitte versuchen Sie es später noch einmal!',
    toast2: 'Da der PDF-Inhalt zu groß ist, wird der Dialog vorerst nicht unterstützt',
    toast3: 'Bitte versuchen Sie es erneut, nachdem der Download abgeschlossen ist.',
    shareMsg1: 'Teilen Linkgenerierung erfolgreich',
    shareMsg2: 'Inhaltsaktualisierungen erfolgreich teilen',
    shareMsg3:
      'Beim Generieren des Freigabelinks ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unseren Kundenservice.',
    shareMsg4:
      'Die Aktualisierung des Freigabeinhalts ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundendienst',
    shareBtnText1: 'Generieren Sie Freigabelinks',
    shareBtnText2: 'Aktualisierte Freigabeinhalte',
    uploading: 'Hochladen',
    uploadingMsg: 'Bitte haben Sie etwas Geduld, da das Hochladen einige Zeit in Anspruch nimmt.',
    cancelUpload: 'Stornieren',
    uploadErr:
      'Das Hochladen der Datei ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundendienst',
    uploadMsg1: 'Bitte ziehen Sie die Datei per Drag & Drop hierher',
    uploadMsg2: 'Unterstützte Dateitypen: PDF |  Maximale Dateigröße: 100 MB',
    uploadMsg3:
      'Klicken Sie hier, um Dateien zum Hochladen auszuwählen oder per Drag-and-Drop auszuwählen',
    searchPlaceholder: 'Suchen',
    collectionEmpty: 'Keine Inhalte in der Sammlung~',
    kw: 'Klammer',
    video: 'Video',
    link: 'Verknüpfung',
    dataLoading: 'Daten werden geladen...',
    freeBannerTips: 'Ende der kostenlosen PDF-Übersetzung',
    vipBannerTips: 'Sie haben in diesem Monat Ihr Limit für die PDF-Übersetzung erreicht.',
    freeBannerBtn: 'Upgraden Sie, um mehr zu genießen',
    vipBannerBtn: 'Upgraden Sie, um mehr zu genießen',
    dialogTitle: 'Aufmerksamkeit',
    dialogContent: 'Sind Sie sicher, dass Sie es löschen möchten?',
    okBtn: 'Löschen',
    cancelBtn: 'Stornieren',
    unLoginToast: 'Bitte melden Sie sich an, um diese Funktion zu nutzen',
    parseErrTips: 'Die Datei kann nicht geparst werden',
    new: 'neue Datei',
    parseErrDialogContent:
      'Der aktuelle PDF-Link unterstützt keine direkte Übersetzung. Bitte laden Sie nach der Übersetzung das Original-Link-PDF herunter',
    okBtn2: 'erkenne',
    notPdf: 'Nur PDF-Dateien',
    uploadingMsg1: 'Datei wird gerade hochgeladen. Bitte versuchen Sie es später noch einmal.',
    uploadingMsg2:
      'Derzeit wird eine Datei konvertiert. Bitte versuchen Sie es später noch einmal.',
    uploadingMsg3:
      'Die Abfrage des Conversion-Fortschritts ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundendienst',
    uploadingMsg4:
      'Noam kann nicht direkt auf Ihre lokalen Dateien zugreifen. Bitte laden Sie manuell ein PDF zur Übersetzung hoch.',
    uploadingMsg5:
      'Die Datei-URL konnte nicht abgerufen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundendienst.',
    fileMsg: 'Aktuelles Dateivolumen:',
    fileMsg2: 'Bitte laden Sie eine Datei hoch, die kleiner als 100 MB ist',
    fileErrMsg:
      'Das Lesen der Datei ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundendienst.',
    parseFileToast:
      'Das Parsen der Datei ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unseren Kundenservice.',
    noId: 'Datei-ID existiert nicht',
    autoDetect: 'Automatische Erkennung',
    hasTransTask: 'Es wird eine Aufgabe übersetzt. Bitte warten.',
    transSucc: 'Die Übersetzung war erfolgreich!',
    entry: 'PDF-Liste',
    listError:
      'Die PDF-Liste konnte nicht geladen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundendienst.',
    collectionTab: 'Memo',
    pdfLoading: 'Parsing...',
    installTips:
      'Installieren Sie das kleine Plug-in, Sie können mehr PDF-Dateien übersetzen und eine reibungslose Webseitenübersetzung erleben:',
    webTrans: 'Vollständiger Querverweis',
    hoverTrans: 'Hover-Absatzübersetzung',
    selectTrans: 'Unterstreiche die Wörter und übersetze sie',
    installText: 'One-Stop-Shop für alle Ihre täglichen Übersetzungsbedürfnisse',
    start: 'Starten Sie Ihr kostenloses Erlebnis',
    shareTips: 'Die PDF-Übersetzung ist abgeschlossen. Teilen Sie sie mit Ihren Freunden!',
    download: {
      loading: 'Gerettet...',
      btn: 'Jetzt sparen',
      trans: 'Nur Übersetzung',
      both: 'Zweisprachigkeit',
      download: 'Herunterladen',
      msg1: 'Einige Übersetzungen des aktuellen Dokuments sind länger als der Originaltext und der Text kann sich nach dem Herunterladen überschneiden. Daher wird empfohlen, ihn auf der Webseite zu lesen.',
      msg2: '1、Der Inhalt wurde nicht vollständig übersetzt. Bitte laden Sie ihn nach der vollständigen Übersetzung herunter.',
      msg3: '2、Der Dokumentteil der Übersetzung ist zu lang. Sie können ihn auf der Webseite nachlesen, um Textüberschneidungen zu vermeiden.',
      msg4: 'Derzeit werden Aufgaben übersetzt. Bitte versuchen Sie es erneut, nachdem die Übersetzung abgeschlossen ist.',
      fileName: 'Dateiname:',
      fileType: 'Dateityp herunterladen:',
      transContinue: 'Übersetzen Sie weiter',
      save: 'Lokal speichern',
      downloadMsg1: 'PDF-Fortschritt bei der Übersetzung:',
      downloadMsg2: 'Voraussichtliche Anforderungen',
      minute: 'Protokoll...',
      downloadSucc: 'Der PDF-Download ist abgeschlossen, klicken Sie auf „Jetzt speichern“!',
    },
    guide: {
      title1: 'Zweisprachige Übersetzung',
      title2: 'Chat-PDF',
      text1: 'Zweisprachiges Lesen',
      text2:
        'Zweisprachiges Lesen, sodass Sie beim Lesen den Originaltext und die Übersetzung vergleichen können, um die Leseeffizienz zu verbessern',
      text3: 'Chat-PDF',
      text4:
        'KI analysiert und fasst zusammen und kann Ihre Fragen anhand des PDF-Inhalts beantworten',
      text5: 'Genauigkeit und Effizienz',
      text6: 'KI-gestütztes PDF für genauere und effizientere Übersetzungen',
      placeholder: 'Bitte füllen Sie den PDF-Link aus',
      start: 'Übersetzung starten',
      toast: 'Bitte geben Sie einen gültigen Online-PDF-Link mit der Endung .pdf ein',
    },
    original: 'Original',
    parallel: 'Parallel',
    trans: 'Übersetzung',
    initial: 'PDF übersetzen',
    thumbnail: 'Kataloge',
    downloadBtn: 'Herunterladen',
    shareBtn: 'Aktie',
    uploadBtn: 'Hochladen',
    installText2: 'Bitte zuerst installieren',
    chatPdf: {
      clearSucc: 'Erfolgreich gelöscht',
      clear: 'Klar',
      clearContent:
        'Nach dem Löschen wird eine neue Konversation erstellt und der Inhalt dieser Konversation wird nicht gespeichert. Sind Sie sicher, dass Sie sie löschen möchten?',
      clearDialogTitle: 'Aufmerksamkeit',
      cancel: 'Stornieren',
      chatTitle: 'Willkommen bei Noam, Ihre PDF-Zusammenfassung ist fertig:',
      summaryTitle: 'Fragen, die Sie interessieren könnten:',
      placeholder: 'Geben Sie Fragen zum PDF ein',
      copy: 'Kopieren',
      copySucc: 'Erfolg kopieren',
      historyTitle: 'Geschichte',
      expand: 'Zusammenbruch',
      noDataDesc: 'Keine Geschichte~',
      noMore: 'Nicht mehr.',
      Search: 'Suchen',
      delete: 'Löschen',
      loading: 'Wird geladen...',
      deleteTitle: 'Tipp',
      deleteContent:
        'Nach dem Löschen kann diese PDF-Datei nicht wiederhergestellt werden. Möchten Sie sie löschen?',
      deleteOk: 'Löschen',
      deleteCancel: 'Stornieren',
      deleteSuccess: 'Erfolgreich löschen',
      listError:
        'Die Leseliste konnte nicht geladen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Kundendienst',
    },
    shareModal: {
      shareSetting: 'Inhaltseinstellungen teilen',
      shareText: 'Aktie',
      text1: 'Einstellungen teilen',
      text2: 'Generieren Sie Freigabelinks',
      range: 'Umfang:',
      custom: 'Anpassung:',
      page: 'Seiten',
      sourceLang: 'Quelle:',
      target: 'Ziel:',
      errMsg:
        'Der Inhalt des aktuellen Freigabebereichs wurde noch nicht vollständig übersetzt. Übersetzen Sie ihn daher bitte vor der Freigabe.',
      pageNuErr: 'Bitte geben Sie den korrekten Wert für die Seitenzahl ein',
      trans: 'Übersetzen',
      cancel: 'Stornieren',
      update: 'Aktualisierte Freigabelinks',
      gen: 'Aktie',
      shareTips: 'Teilen Sie den übersetzten PDF-Link mit Ihren Freunden zum Vorlesen:',
      copySucc: 'Link erfolgreich kopieren',
      copy: 'Kopieren',
      settings: 'Freigabeeinstellungen:',
      modify: 'ändern',
      translateTips:
        'Tipp: Wenn die Übersetzung lange dauert, können Sie das Popup-Fenster schließen und es nach Abschluss der Übersetzung freigeben.',
      translating: 'PDF-Übersetzung...',
      remain: 'übrig',
      remain1: 'Gesamter voraussichtlicher Bedarf',
      totalPage: 'Insgesamt 1 Seite',
      search: 'Suchen',
      notSupport: 'Diese Sprache wird derzeit nicht unterstützt',
    },
    toolbar: { Adjust: 'Anpassen', autoFit: 'Automatische Anpassung' },
    memoTotalCount: 'Insgesamt 1 Inhalt',
    dragTipModal: {
      press: 'Drücken Sie',
      black: 'Raum',
      leftKey: 'linker Boden',
      tips: 'zum Ziehen und Anzeigen.',
      ok: 'Ich habe es',
    },
  },
  locale: {
    zh: 'Chinesisch',
    en: 'Englisch',
    es: 'Spanisch',
    ru: 'Russisch',
    pt: 'Portugiesisch (Brasilien)',
    id: 'Indonesisch',
    cs: 'Tschechisch',
    de: 'Deutsch',
    fr: 'Französisch',
    it: 'Italienisch',
    nl: 'Niederländisch',
    pl: 'Polieren',
    vi: 'Vietnamesisch',
    tr: 'Türkisch',
    uk: 'ukrainisch',
    zhHant: 'Chinesische Tradition',
    ja: 'japanisch',
    ko: 'Koreanisch',
    ar: 'Arabisch',
    fa: 'persisch',
    th: 'Thailändisch',
    hi: 'Hindi',
    noSearchLang: 'Diese Sprache wird nicht unterstützt',
    placeholder: 'Suchen',
    af: 'Afrikaans',
    am: 'Amharisch',
    as: 'Assamesisch',
    az: 'Aserbaidschanisch',
    ba: 'Baschkirisch',
    bg: 'bulgarisch',
    bn: 'Bangla',
    bo: 'Tibetisch',
    bs: 'bosnisch',
    ca: 'katalanisch',
    cy: 'Walisisch',
    da: 'dänisch',
    dsb: 'Niedersorbisch',
    dv: 'Tauchgang',
    el: 'griechisch',
    et: 'estnisch',
    eu: 'baskisch',
    fi: 'finnisch',
    fil: 'Philippinisch',
    fj: 'Fidschianisch',
    frCA: 'Französisch (Kanada)',
    ga: 'irisch',
    gl: 'galizisch',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebräisch',
    hr: 'kroatisch',
    hsb: 'Obersorbisch',
    ht: 'Haitianisches Kreol',
    hu: 'ungarisch',
    hy: 'Armenisch',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'isländisch',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latein)',
    ka: 'georgisch',
    kk: 'Kasachisch',
    km: 'Khmer',
    kmr: 'Kurdisch (Nordisch)',
    kn: 'Kannada',
    ku: 'Kurdisch (Zentral)',
    ky: 'Kirgisisch',
    ln: 'Lingala',
    lo: 'Laotisch',
    lt: 'litauisch',
    lug: 'Ganda',
    lv: 'lettisch',
    lzh: 'Chinesisch (Literatur)',
    mai: 'Maithili',
    mg: 'Madagassisch',
    mi: 'Maori',
    mk: 'mazedonisch',
    ml: 'Malayalam',
    mnCyrl: 'Mongolisch (Kyrillisch)',
    mnMong: 'Mongolisch (traditionell)',
    mr: 'Marathi',
    ms: 'malaiisch',
    mt: 'maltesisch',
    mww: 'Hmong Daw',
    my: 'Myanmar (burmesisch)',
    nb: 'norwegisch',
    ne: 'Nepali',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    prs: 'Dari',
    ps: 'Paschtu',
    ptPT: 'Portugiesisch (Portugal)',
    ro: 'rumänisch',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singhalesisch',
    sk: 'slowakisch',
    sl: 'Slowenisch',
    sm: 'Samoaner',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanisch',
    srCyrl: 'Serbisch (Kyrillisch)',
    srLatn: 'Serbisch (Latein)',
    st: 'Sesotho',
    sv: 'Schwedisch',
    sw: 'Suaheli',
    ta: 'Tamilisch',
    te: 'Telugu',
    ti: 'Tigrinya',
    tk: 'Turkmenisch',
    tlhLatn: 'Klingonisch (Latein)',
    tlhPiqd: 'Klingonisch (pIqaD)',
    tn: 'Setswana',
    to: 'Tonganisch',
    tt: 'Tatarisch',
    ty: 'Tahitianisch',
    ug: 'Uigur',
    ur: 'Urdu',
    uz: 'Usbekisch (Latein)',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonesisch (traditionell)',
    zhHans: 'Vereinfachtes Chinesisch',
    zu: 'Zulu',
    auto: 'Automatische Erkennung',
  },
  stripe: {
    contact: 'Kontaktieren Sie uns bei Fragen unter contact@noam.tools!',
    fail: {
      title: 'Bezahlung fehlgeschlagen',
      text1: 'Leider können wir Ihre Zahlungsanfrage nicht bearbeiten.',
      text2: 'Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
      btn: 'Wiederholen',
    },
    succ: {
      title: 'Glückwunsch',
      text1: 'Willkommen bei Noam,',
      text2: 'lasst uns gemeinsam durchstarten!',
      btn: 'Lass uns etwas zaubern',
    },
  },
  welfare: {
    newUser: {
      label: 'Vorteile für neue Benutzer:',
      joinBtn: 'Jetzt beitreten',
      text: 'Nur 100 Plätze verfügbar! Schnappen Sie sich jetzt Ihr Exemplar!',
      remain: 'Nur noch 30 übrig',
    },
  },
  help: {
    Fixedplugin: 'Festes Plugin',
    Howtouse: 'Wie benutzt man',
    try: 'Versuche es',
    FreeTrialBenefits: 'Vorteile der kostenlosen Testversion',
    pin: 'Pin Noam, für einfacheren Zugriff～～',
    Video: 'Video',
    ParallelTranslation: 'Parallele Übersetzung',
    WebSummary: 'Web-Zusammenfassung',
    ChatPDF: 'Chat-PDF',
    AquickOverviewOfNoam: 'Ein kurzer Überblick über Noam',
    WordSelectionTranslation: 'Wortauswahlübersetzung',
    HoverTranslation: 'Hover-Übersetzung',
    ImageTranslation: 'Bildübersetzung',
    InstantTranslation: 'Direkte Übersetzung',
    VideoTranslation: 'Videoübersetzung',
    benefits: 'Wir haben Vorteile für Sie vorbereitet!',
    Free: 'Frei',
    Membership: 'Mitgliedschaft',
    Translation: 'Übersetzung',
    PDFparallelTranslation: 'PDF-Parallelübersetzung',
    ChatPDF2: 'ChatPDF',
    Summary: 'Zusammenfassung',
    VideoSummary: 'Videozusammenfassung',
    FavoritesManagement: 'Favoritenverwaltung',
    Focusing: 'Fokussieren',
    dayFree: '3-tägige kostenlose Testversion',
    planIsNotExist: 'Plan ist nicht vorhanden',
    TryItForFree: 'Probieren Sie es kostenlos aus',
    startYoutube: 'Beginnen wir mit YouTube~',
    startWiki: 'Beginnen wir mit Wikimedia~',
    tryNow: 'Probieren Sie es jetzt aus~',
    GoWithYouTube: 'Gehen Sie mit YouTube',
    GoWithWikipedia: 'Gehen Sie mit Wikipedia',
    NoamHelp: 'Noam kann Ihnen beim Übersetzen und Zusammenfassen helfen',
    SkipAllSteps: 'Überspringen Sie alle Schritte',
    next: 'Nächste',
    done: 'Erledigt',
  },
  trialModal: {
    title: 'Willkommensgeschenk',
    trialText: '7-Tage-Testversion',
    desc1:
      'Nach Ablauf der Testversion wird Ihnen eine jährliche Gebühr von 180 US-Dollar berechnet',
    desc2: 'Jederzeit kündbar',
    btn: 'Jetzt loslegen (nur 0,99 $)',
    installBtn: 'Jetzt installieren, um Ihr Geschenk zu erhalten',
    web: 'Parallele Übersetzung',
    select: 'Wortauswahlübersetzung',
    hover: 'Hover-Übersetzung',
    image: 'Bildübersetzung',
    realtime: 'Direkte Übersetzung',
    video: 'Videoübersetzung',
    pdf: 'PDF-Parallelübersetzung',
    chat: 'ChatPDF',
    webSummary: 'Web-Zusammenfassung',
    videoSummary: 'Videozusammenfassung',
    memo: 'Favoritenverwaltung',
    focus: 'Fokussieren',
  },
  privacyCollectionPanel: {
    title: 'Diese Website verwendet Cookies',
    text: 'Um Ihr Erlebnis zu verbessern, möchten wir Cookies verwenden, um Inhalte und Dienste zu personalisieren.',
    acceptBtn: 'Akzeptiere alle',
  },
  installTips: {
    text: 'Installieren Sie das „Noam Browser Plugin“, um weitere Funktionen freizuschalten! Web-Querverweise,\n    Zusammenfassung von Webvideos und mehr!',
    btn: 'Probieren Sie es jetzt',
  },
}

export default TRANSLATION
