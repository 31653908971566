import { commonUtils } from '../commonUtils'
import { get, post } from './common'

type ApiBaseResult = ApiTypes.ApiBaseResult

export type FavoriteItem = {
  favoriteTime: number
  isFavorite: boolean
  resContent: string
  resSummarize: string
  source: 'page' | 'video'
  title: string
  url: string
  taskId: string
  iconUrl?: string
  node: HTMLDivElement | null
  overMaxHeight: boolean
  isExpand: boolean
}

export namespace pdf {
  // 获取上传文件列表
  export function getFileList(
    module: PostDataTypes.FileUploadModule,
    limit: number,
    offset: number,
    keyword?: string
  ): Promise<
    ApiBaseResult & {
      totalCount: number
      dataList: Array<ServerDataTypes.PDF.FileInfo>
    }
  > {
    return get('/file_list', { module, limit, offset, keyword })
  }

  // 获取上传文件的下载地址
  export function getFileUrl(
    fileId?: number,
    isLogin?: boolean,
    shareHashId?: string
  ): Promise<
    ApiBaseResult & {
      /** pdf 下载地址 */
      downloadUrl: string
      /** html 下载地址 */
      htmlDownloadUrl: string
      cosDownloadUrl: string
      /** 文件名字 */
      fileName: string
      /** fileId */
      fileId: number
    }
  > {
    const params: Record<string, any> = {}
    if (fileId !== undefined) {
      params['fileId'] = fileId
    }

    if (shareHashId !== undefined) {
      params['shareHashId'] = shareHashId
    }
    const url = isLogin ? '/download' : '/download/no_auth'
    return get(url, params)
  }

  // 获取前端直传签名地址
  export function getUploadSignUrl(
    data: {
      module: PostDataTypes.FileUploadModule
      filename: string
      // 文件大小，单位：字节
      contentLength: number
      md5?: string
      pageNum: number
    },
    isLogin?: boolean,
    controller?: AbortController
  ): Promise<
    ApiBaseResult & {
      data: {
        signUrl: string
        fileId: number
        fileName: string
      }
    }
  > {
    const url = isLogin ? '/upload/sign_url' : '/upload/no_auth/sign_url'
    return post(url, {}, { ...data, status: 'wait' }, false, controller)
  }

  export function uploadRelation(fileId: number) {
    return post('/upload/relation', {}, { fileId })
  }

  export function updateUploadStatus(
    params: { fileId: number; decry?: boolean },
    isLogin: boolean,
    controller?: AbortController
  ) {
    const url = isLogin ? '/upload/update_status' : '/upload/no_auth/update_status'
    return post(url, {}, { ...params }, false, controller)
  }

  export function translate(
    params: PostDataTypes.TranslateOptions,
    isLogin?: boolean
  ): Promise<ApiBaseResult & ServerDataTypes.XunFeiTranslateResult> {
    let url = isLogin ? '/translate/content_v2' : '/page/translate/content'
    return post(
      url,
      {},
      { ...params, token: !isLogin ? commonUtils.genVisitorTokenStr() : undefined }
    )
  }

  // 创建 ai 翻译接口
  export function aiTranslate(
    params: PostDataTypes.TranslateOptions
  ): Promise<ApiBaseResult & { taskId: number }> {
    return post('/translate_content/task', {}, params)
  }

  // 获取 ai 翻译结果
  export function getAiTranslateResult(taskId: number): Promise<
    ApiBaseResult & {
      status: ServerDataTypes.AiTranslateStatus
      data: Array<ServerDataTypes.AiTranslateResult>
    }
  > {
    return post('/translate_content/task_result', {}, { taskId })
  }

  export function getChatFileStatus(
    chatFileId: number
  ): Promise<ApiBaseResult & { fileStatus: 'vectored' | 'over_token_limit' }> {
    return get('/chat/file/status', { chatFileId })
  }

  export function getSummarySteamId(
    chatFileId: number,
    toLang: string
  ): Promise<
    ApiBaseResult & {
      summaryStatus: 'unsummary' | 'done' | 'summarizing'
      streamId: string
      summary: string
    }
  > {
    return get('/chat/file/summary_stream', { chatFileId, toLang })
  }

  export function getChatPDFRecommendQuestions(
    chatFileId: number,
    toLang: string
  ): Promise<ApiBaseResult & { recommendQuestions: string[] }> {
    return get('/chat/file/recommend_questions', { chatFileId, toLang })
  }

  export function getChatPDFSessionList(chatFileId: number): Promise<
    ApiBaseResult & {
      sessionList: ServerDataTypes.ChatPDF.Session[]
    }
  > {
    return get('/chat/file/session/list', { chatFileId })
  }

  export function chat({
    chatFileId,
    chatSessionId,
    question,
    toLang,
  }: {
    chatFileId: number
    chatSessionId?: string
    question?: string
    toLang: string
  }): Promise<
    ApiBaseResult & {
      message: ServerDataTypes.ChatPDF.Message
      latestSession: ServerDataTypes.ChatPDF.Session
    }
  > {
    return post('/chat/file/session/chat', { chatFileId, chatSessionId, toLang }, { question })
  }

  export function getChatStreamId({
    chatFileId,
    chatSessionId,
    question,
    toLang,
  }: {
    chatFileId: number
    chatSessionId?: string
    question?: string
    toLang: string
  }): Promise<
    ApiBaseResult & {
      message: ServerDataTypes.ChatPDF.Message
      latestSession: ServerDataTypes.ChatPDF.Session
      streamId: string
    }
  > {
    return post(
      '/chat/file/session/chat_stream',
      { chatFileId, chatSessionId, toLang },
      { question }
    )
  }

  export function getChatOne(chatSessionId: string): Promise<
    ApiBaseResult & {
      session: ServerDataTypes.ChatPDF.Session
      messageList: ServerDataTypes.ChatPDF.Message[]
    }
  > {
    return get('/chat/file/session/one', { chatSessionId })
  }

  export function newChat(chatFileId: number): Promise<
    ApiBaseResult & {
      latestSession: ServerDataTypes.ChatPDF.Session
    }
  > {
    return post('/chat/file/session/add', { chatFileId }, {})
  }

  export function getChatFileTrunks(chatFileId: number): Promise<
    ApiBaseResult & {
      trunksList: ServerDataTypes.ChatPDF.Trunks[]
    }
  > {
    return get('/chat/file/trunks', { chatFileId })
  }

  /** 创建将 pdf 转换为 html 的任务 */
  export function createPdf2htmlTask(
    pdfFileId: number,
    controller: AbortController
  ): Promise<ApiBaseResult> {
    return post('/pdf_to_html', {}, { fileId: pdfFileId }, false, controller)
  }

  /** 检测 pdf2html 任务的状态 */
  export function checkPdf2HtmlStatus(controller: AbortController): Promise<
    ApiBaseResult & {
      status: ServerDataTypes.PDF.Pdf2HtmlStatus
      downloadUrl: string
    }
  > {
    return get('/pdf_to_html/check', {}, false, controller)
  }

  type FieldData = {
    /**
     * 源语言
     */
    fromLang?: string
    /**
     * 设置的要分享的页数
     */
    sharePageNumber?: number
    /**
     * 最新分享时间
     */
    shareTime?: number
    /**
     * 目标语言
     */
    toLang?: string
    /**
     * 总页数
     */
    totalPageNumber?: number
    /**
     * 已经翻译的页数
     */
    translatePageNumber?: number
  }

  export type ShareConfig = {
    fieldsDataList?: FieldData[]
    hashId: string
  }

  /** 获取分享的配置 */
  export function shareConfig(fileId: number): Promise<
    ApiBaseResult & {
      shared: boolean
      configInfo: ShareConfig
    }
  > {
    return get('/pdf_share/config', { fileId })
  }

  /** 更新分享的配置 */
  export function updateShareConfig(
    fileId: number,
    fromLang: string,
    toLang: string,
    pageNumber: number
  ): Promise<
    ApiBaseResult & {
      hashId: string
    }
  > {
    return post('/pdf_share/config/update', {}, { fileId, fromLang, toLang, pageNumber })
  }

  export function getTranslateStatus(fileId: number): Promise<
    ApiBaseResult & {
      data: {
        fields: Array<{
          fromLang: string
          toLang: string
          totalPageNumber: number
          translatePageNumber: number
        }>
      }
    }
  > {
    return get('/pdf_file/info', { fileId })
  }

  export function recordTranslateStatus(params: {
    fileId: number
    fromLang: string
    toLang: string
    /** 已翻译页数 */
    pageNumber: number
    /** 文件总页数 */
    totalPageNumber: number
  }) {
    return post('/pdf_translate/record', {}, { ...params })
  }

  export function fetchFavoriteList(params: {
    keyword?: string
    limit?: number
    offset?: number
  }): Promise<
    ApiBaseResult & {
      totalCount: number
      dataList: FavoriteItem[]
    }
  > {
    return get('/summarize/favorite/list', { ...params })
  }

  export function deleteFavoriteItem(params: {
    taskId: string
    cancel: boolean
  }): Promise<ApiBaseResult> {
    return post('/summarize/favorite', {}, { ...params })
  }

  export function deletePdfHistory(fileId: number): Promise<ApiBaseResult> {
    return get('/file/delete', { fileId })
  }

  // 该接口支持无登录态翻译
  export function pageTranslate(
    data: PostDataTypes.TranslateOptions
  ): Promise<ApiBaseResult & ServerDataTypes.XunFeiTranslateResult> {
    return post('/page/translate/content', {}, data)
  }
}
