import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './TrialPlanItem.module.less'
import { useTranslationWithType } from '@/i18n/i18n'
import { pricingModal } from '@/helpers/api/pricing'
import { commonUtils } from '@/helpers/commonUtils'
import AmeIcon from '../AmeIcon/AmeIcon'
/** TrialPlanItem 组件的 Props */
export type TrialPlanItemProps = {
  plan: pricingModal.VipPlan
  showBenefits?: boolean
}

const FUNC_LIST: Array<{ icon: string; key: string }> = [
  { icon: commonUtils.getStaticFile('trialModal/web.png'), key: 'trialModal.web' },
  { icon: commonUtils.getStaticFile('trialModal/select.png'), key: 'trialModal.select' },
  { icon: commonUtils.getStaticFile('trialModal/img.png'), key: 'trialModal.image' },
  { icon: commonUtils.getStaticFile('trialModal/video.png'), key: 'trialModal.video' },
  { icon: commonUtils.getStaticFile('trialModal/pdf.png'), key: 'trialModal.pdf' },
]

const PRO_BENEFITS = [
  { icon: commonUtils.getStaticFile('trialModal/token.png'), key: 'pricing.pro.benefit2' },
  { icon: commonUtils.getStaticFile('trialModal/web.png'), key: 'pricing.pro.benefit3' },
  { icon: commonUtils.getStaticFile('trialModal/img.png'), key: 'pricing.pro.benefit4' },
  { icon: commonUtils.getStaticFile('trialModal/video.png'), key: 'pricing.pro.benefit5' },
  { icon: commonUtils.getStaticFile('trialModal/pdf.png'), key: 'pricing.pro.benefit6' },
]
const PROV_BENEFITS = [
  { icon: commonUtils.getStaticFile('trialModal/token.png'), key: 'pricing.proPlus.benefit2' },
  { icon: commonUtils.getStaticFile('trialModal/web.png'), key: 'pricing.proPlus.benefit3' },
  { icon: commonUtils.getStaticFile('trialModal/img.png'), key: 'pricing.proPlus.benefit4' },
  { icon: commonUtils.getStaticFile('trialModal/video.png'), key: 'pricing.proPlus.benefit5' },
  { icon: commonUtils.getStaticFile('trialModal/pdf.png'), key: 'pricing.proPlus.benefit6' },
]

const TrialPlanItem: FC<TrialPlanItemProps> = observer(
  ({ plan, showBenefits }: TrialPlanItemProps) => {
    const { t } = useTranslationWithType()
    const [benefits, setBenefits] = useState(FUNC_LIST)
    useEffect(() => {
      if (!showBenefits) {
        setBenefits(FUNC_LIST)
        return
      }
      setBenefits(plan.version === 'pro' ? PRO_BENEFITS : PROV_BENEFITS)
    }, [showBenefits, plan.version])
    const getVersionImg = () => {
      if (plan.version === 'pro') {
        return commonUtils.getStaticFile('pricing/icon__pro.png')
      }
      return commonUtils.getStaticFile('pricing/icon__prov.png')
    }
    const getHotImg = () => {
      if (plan.version === 'pro') {
        return commonUtils.getStaticFile('trialModal/pro-hot.png')
      }
      return commonUtils.getStaticFile('trialModal/prov-hot.png')
    }
    return (
      <div
        className={classNames(style.planItem, {
          [style.pro]: plan.version === 'pro',
          [style.proV]: plan.version === 'pro+',
        })}
      >
        <div className={classNames(style.itemBox)}>
          {/* 版本号 */}
          <div className={classNames(style.versionInfo)}>
            <img className={classNames(style.icon)} src={getVersionImg()} />
            <span>{plan.version === 'pro' ? 'Pro' : 'Pro+'}</span>
            <p className={classNames(style.tag)}>Free Trial</p>
          </div>
          <img className={classNames(style.hotImg)} src={getHotImg()} />
          <div className={classNames(style.bList)}>
            {/* 权益列表 */}
            {benefits.map((item) => {
              return (
                <div key={item.key} className={classNames(style.bItem)}>
                  <AmeIcon
                    className={classNames(style.ameIcon, {
                      [style.pro]: plan.version === 'pro',
                      [style.proV]: plan.version === 'pro+',
                    })}
                    type='icon-line-global_check'
                  ></AmeIcon>
                  <img className={classNames(style.img)} src={item.icon} />
                  <span>{t(item.key as any)}</span>
                </div>
              )
            })}
          </div>
          {!showBenefits && (
            <div className={classNames(style.originalBox)}>
              <span>${commonUtils.getPlanTotalPrice(plan)}</span>
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default TrialPlanItem
