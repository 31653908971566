import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './TrialModal.module.less'
import { Button, Divider, Modal } from 'antd'
import { commonUtils } from '@/helpers/commonUtils'
import { useTranslationWithType } from '@/i18n/i18n'
import { pricingModal } from '@/helpers/api/pricing'
import { userService } from '@/services/userService'
import { vipService } from '@/services/vipService'
import { uiController } from '@/controllers/uiController'
import TrialPlanItemV1 from '../TrialPlanItemV1/TrialPlanItemV1'

/** TrialModal 组件的 Props */
export type TrialModalProps = { plan: pricingModal.VipPlan; open: boolean }

const TrialModal: FC<TrialModalProps> = observer((props: TrialModalProps) => {
  const { t } = useTranslationWithType()
  const [loading, setLoading] = useState(false)
  const [curStep, setCurStep] = useState('1')
  const { plan, open } = props

  useEffect(() => {
    if (!open || !plan) {
      return
    }
    setTimeout(() => {
      setCurStep('2')
    }, 1600)
  }, [open, plan])
  if (!open) {
    return null
  }
  return (
    <Modal
      open={open}
      centered
      wrapClassName={style.trialModal}
      footer={false}
      width={'768px'}
      closable={curStep !== '1'}
      maskClosable={false}
      onCancel={() => {
        uiController.showTrialModal = false
      }}
    >
      <div className={classNames(style.trialContent)}>
        <div className={classNames(style.guide, { [style.hide]: curStep === '2' })}>
          <img
            className={classNames(style.gift)}
            src={commonUtils.getStaticFile('trialModal/gift.gif')}
          />
        </div>
        <div className={classNames(style.trialPlanBox, { [style.show]: curStep === '2' })}>
          <img
            className={classNames(style.bg)}
            src={commonUtils.getStaticFile('trialModal/bg4.png')}
          />
          <div className={classNames(style.box)}>
            <div className={classNames(style.title)}>
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('pricing/icon__fireworks.png')}
              />
              {t('trialModal.title')}
            </div>
            <div className={classNames(style.priceRow)}>
              <span>${plan.trialPrice / 100}</span>
              <div className={classNames(style.trialText)}>
                {t('trialModal.trialText').replace('7', plan.trialDays.toString())}
              </div>
            </div>
            <TrialPlanItemV1 plan={plan} />
            <Button
              type='primary'
              loading={loading}
              className={style.buyBtn}
              onClick={() => {
                if (!plan) {
                  return
                }
                if (loading) {
                  return
                }
                userService.trackUserRecord('click_upgrade_modal_btn')
                if (
                  uiController.trialModalNeedCheckInstall &&
                  !commonUtils.isInstalledExtension()
                ) {
                  userService.goToInstall()
                  return
                }
                setLoading(true)
                vipService.checkout(
                  { planId: plan!.id, isTrial: true, source: 'trialModalV1' },
                  {
                    dataErrorCallback: () => {
                      setLoading(false)
                    },
                    dataPopupClosed: () => {
                      setLoading(false)
                    },
                    dataPopupWebhookReceived: () => {
                      setLoading(false)
                      uiController.showTrialModal = false
                    },
                  }
                )
              }}
            >
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('pricing/icon__rockets.png')}
              />
              <span>
                {!uiController.trialModalNeedCheckInstall || commonUtils.isInstalledExtension()
                  ? t('trialModal.btn').replace('0.99', (plan.trialPrice / 100).toString())
                  : t('trialModal.installBtn')}
              </span>
            </Button>
            <div className={classNames(style.text)}>
              {t('trialModal.desc1').replace('180', commonUtils.getPlanTotalPrice(plan).toString())}
              <Divider type='vertical' />
              {t('trialModal.desc2')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default TrialModal
