import { LoginModalProps } from '@/components/LoginModal'
import { api } from '@/helpers/api'
import { userService } from '@/services/userService'
import { vipService } from '@/services/vipService'
import { ModalProps } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { makeObservable, observable } from 'mobx'

interface CommonModalProps extends ModalProps {
  content?: string
}
class UiController {
  @observable message!: MessageInstance
  @observable commonModalProps: CommonModalProps = { open: false }
  @observable showTrialModal = false

  @observable pdfUploadTimeLimited = false
  @observable pdfChatTimeLimited = false
  @observable pdfUploadPageLimited = false

  @observable showUpgradeModal = false
  @observable showBuyModal = false

  @observable trialModalNeedCheckInstall = true

  @observable loginModalProps: LoginModalProps = { open: false }

  public init() {
    makeObservable(this)
  }

  public async checkTrialPlan(fromPdf?: boolean) {
    if (!userService.isLogin) {
      return
    }
    const {
      versionData: { version, vipTimeData },
      createdAt,
    } = userService.userData!
    const endTime = createdAt * 1000 + 3 * 24 * 60 * 60 * 1000
    if (endTime >= Date.now() && userService.enableNewUserDiscount) {
      return
    }
    // 从来没有买过会员的用户进入定价页面自动弹出引导购买弹窗
    if (version !== 'free' || vipTimeData) {
      return
    }
    const { data, type } = await vipService.getTrialPlan()
    if (!type) {
      return
    }
    if (type !== 'trail') {
      return
    }
    if (fromPdf) {
      // PDF 页面，等用户试用过PDF之后，在弹试用弹窗
      const { totalCount } = await api.pdf.getFileList('translate_pdf', 1, 0)
      if (totalCount === 0) {
        return
      }
    }
    this.trialModalNeedCheckInstall = !fromPdf
    setTimeout(() => {
      if (!data.trialPrice) {
        // 免费试用
        this.showBuyModal = true
      } else {
        this.showTrialModal = true
      }
    }, 2000)
  }
}

export const uiController = new UiController()
