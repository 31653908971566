import { RouterServiceTypes } from '@/services/routerService/serviceTypes'
import { MD5 } from 'crypto-js'
import * as dompurify from 'dompurify'
import numeral from 'numeral'
import { v4 as uuidV4 } from 'uuid'
import * as QRCode from 'qrcode'
import { storageHelper } from './storageHelper'
import { pricingModal } from './api/pricing'
import { NEW_USER_DISCOUNT_DAY } from '@/services/userService'

type InputValue = string | number | null | undefined

class CommonUtils {
  /**
   * 根据给定的链接地址绘制一个二维码
   * @param url 链接的地址
   * @param width  二维码宽度
   * @param border 二维码的边框
   */
  paseUrlToQrcode(url: string, width: number, border: number): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const qrcode = await QRCode.toDataURL(url, {
        errorCorrectionLevel: 'H',
        margin: border,
        width,
      })
      resolve(qrcode)
    })
  }
  getDeviceDpi() {
    // const devicePixelRatio = window.devicePixelRatio || 1
    const tmpNode = document.createElement('DIV')
    tmpNode.style.cssText = 'height: 1in; left: -100%; position: absolute; top: -100%; width: 1in;'
    document.body.appendChild(tmpNode)
    const dpi = parseInt(`${tmpNode.offsetWidth}`)
    if (tmpNode.parentNode) {
      tmpNode.parentNode.removeChild(tmpNode)
    }
    return dpi
  }
  getOsType(): 'OSX' | 'IOS' | 'Windows' | 'Linux' | 'Android' {
    const userAgent = navigator.userAgent
    const platform = (navigator as any).userAgentData?.platform || navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'OSX'
    }
    if (iosPlatforms.indexOf(platform) !== -1) {
      return 'IOS'
    }
    if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows'
    }
    if (/Android/.test(userAgent)) {
      return 'Android'
    }
    if (/Linux/.test(platform)) {
      return 'Linux'
    }
    console.error('unable to detect os type, use Windows as default', platform, userAgent)
    return 'Windows'
  }

  getIsMobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  }

  /**
   * check if browser is chrome
   * 注意这个方法并不是完全可靠，在一些情况下edge会伪装自己的userAgent，使其和chrome一致，具体条件不明
   */
  isChrome() {
    const userAgent = navigator.userAgent
    const isChromiumBased = userAgent.includes('Chrome') && userAgent.includes('Safari')
    const isEdge = userAgent.includes('Edg')
    const isOpera = userAgent.includes('OPR')

    return isChromiumBased && !isEdge && !isOpera
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isPromise(obj: any): obj is Promise<unknown> {
    return (
      !!obj &&
      (typeof obj === 'object' || typeof obj === 'function') &&
      typeof obj.then === 'function'
    )
  }

  /** 按照指定 query 参数构建 url */
  genUrl(base: string, query: { [key: string]: string } = {}, encode = true): string {
    const keys = Object.keys(query)
    if (keys.length === 0) {
      return base
    }
    let url = base
    if (/:[a-zA-Z]/.test(base)) {
      // eslint-disable-next-line no-param-reassign
      // 处理 pattern 中声明的 restful 风格参数
      const patternParts = base.split('/')
      patternParts.forEach((p) => {
        if (p.startsWith(':')) {
          const name = p.slice(1)
          url = url.replace(p, query[name])
          delete query[name]
        }
      })
    }
    url += '?'
    for (const k of keys) {
      let v = query[k]
      if (!v) {
        continue
      }
      v = encode ? encodeURIComponent(v) : v
      url += `${k}=${v}&`
    }
    return url.slice(0, -1) // cut off last '&' or '?'
  }

  removeQueryFromUrl(url: string) {
    return url.split('?')[0]
  }

  genId(short = false) {
    const uuid = uuidV4()
    return short ? uuid.split('-')[0] : uuid
  }

  md5(content: string) {
    return MD5(content).toString()
  }

  onDOMReady(callback: () => void) {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', callback)
    } else {
      callback()
    }
  }

  /**
   * 将key字符串转换成驼峰方式命名（如 "someName"） 的字符串
   * @param key string类型
   * @param separators key分隔符 "-"中划线/"_"下划线
   */
  camelizeKey(key: string, separators: string[] = ['-', '_']): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const out: any = []
    let i = 0
    const separatorsSet = new Set(separators)
    while (i < key.length) {
      if (separatorsSet.has(key[i])) {
        out.push(key[i + 1].toUpperCase())
        i++
      } else {
        out.push(key[i])
      }
      i++
    }
    return out.join('')
  }

  /**
   * 将对象键值对中的 key 转换为按照驼峰方式命名的 key
   * @param obj
   */
  camelize(obj: object): { [key: string]: any } {
    if (obj === null || obj === undefined) {
      return null as any
    }
    if (obj instanceof Array) {
      return obj.map((item) => {
        return this.camelize(item)
      })
    }
    if (typeof obj === 'object') {
      const out: any = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj as any) {
        const v = (obj as any)[key]
        out[this.camelizeKey(key)] = this.camelize(v)
      }
      return out
    }
    return obj
  }

  /**
   * 将key字符串转换成下划线方式命名 (如 "some_name") 的字符串
   * @param key 对象字符串
   * @param ignoreFirst 是否忽略第一个大写字母，如果忽略，会将其当成小写字母处理
   */
  underlizeKey(key: string, ignoreFirst = false): string {
    const out: string[] = []
    let i = 0
    const lowerCasedStr: string = key.toString().toLowerCase()
    while (i < key.length) {
      if (key[i] !== lowerCasedStr[i]) {
        if (!ignoreFirst || i !== 0) {
          out.push('_')
          out.push(lowerCasedStr[i])
          i++
          continue
        }
      }
      out.push(key[i].toLocaleLowerCase())
      i++
    }
    return out.join('')
  }

  /**
   * 将对象键值对中的 key 转换为按照下划线方式命名的 key
   * @param obj 需要转换的对象
   */
  underlize(obj: object): {} {
    if (obj === null || obj === undefined) {
      return null as any
    }
    if (obj instanceof Array) {
      return obj.map((item) => {
        return this.underlize(item)
      })
    }
    if (typeof obj === 'object') {
      const out: any = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj as any) {
        const v = (obj as any)[key]
        const underlizeKey = this.underlizeKey(key)
        const value = this.underlize(v)
        if (value !== null && value !== undefined) {
          out[underlizeKey] = value
        }
      }
      return out
    }
    return obj
  }

  /**
   * 将字符串转为首字母大写
   * @param str
   * @returns
   */
  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  copyToClipboard(text: string) {
    const input = document.createElement('textarea')
    input.id = 'copy-to-clipboard'
    const container = document.body
    container.appendChild(input)
    input.oncopy = (e) => {
      e.stopPropagation()
    }
    input.value = text
    input.select()
    document.execCommand('copy')
    container.removeChild(input)
  }

  getOffsetOfPageTop(element: HTMLElement) {
    return (
      document.documentElement.clientHeight -
      (element.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top)
    )
  }

  async delay(time: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }

  formatNumber(number: InputValue) {
    if (number === undefined || number === null) {
      return '-'
    }

    const format = numeral(number).format('0,0.0')

    const key = '.0'
    const isInteger = format.includes(key)

    return isInteger ? format.replace(key, '') : format
  }

  selectFile(
    callBack: (fileList: File[]) => void,
    options?: {
      accept?: string[]
      multiple?: boolean
    }
  ) {
    const { multiple = false, accept = [] } = options || {}
    const input = document.createElement('input')

    input.type = 'file'
    if (multiple) {
      input.multiple = true
    }
    input.style.display = 'none'
    input.accept = accept
      .map((el) => {
        if (el === '.txt') {
          return 'text/plain'
        }
        return el
      })
      .join(', ')

    document.body.appendChild(input)

    const handler = () => {
      if (input.files) {
        callBack(Array.from(input.files))
      }
      input.removeEventListener('change', handler)
      input.remove()
    }

    input.addEventListener('change', handler)
    input.click()
  }

  async downloadFile(url: string) {
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  getQueryFromUrl(key: string, url: string) {
    const urlObj = new URL(url)
    return urlObj.searchParams.get(key)
  }

  convertUnixToHms(num: number) {
    const h = num < 3600 ? 14 : 11
    return new Date(num * 1000).toISOString().substring(h, 19).toString()
  }

  getRealRandomNumber(range = 10) {
    const arr = new Uint32Array(1)
    window.crypto.getRandomValues(arr)
    return arr[0] % range
  }

  /**
   * 获取 url 中的参数, 以 dict 形式返回
   * @param pattern url 范式，比如 /file/:id，会自动识别其中的参数并抽取到结果 dict 中
   * @param url url，默认为当前页面 url
   */
  getUrlParams(
    pattern: string | null = null,
    url = window.location.href
  ): { [key: string]: string } {
    let u: URL
    if (url.startsWith('http:') || url.startsWith('https:')) {
      u = new URL(url)
    } else {
      u = new URL(window.location.origin + url)
    }
    const res: { [key: string]: string } = {}
    if (pattern) {
      // 处理 pattern 中声明的 restful 风格参数
      const patternParts = pattern.split('/')
      const pathParts = u.pathname.split('/')
      patternParts.forEach((p, i) => {
        if (p.startsWith(':')) {
          const name = p.slice(1)
          res[name] = pathParts[i]
        }
      })
    }
    const params = new URLSearchParams(u.search)
    for (const k of params.keys()) {
      // params.get 会自动处理 decodeURLComponent，不需要手动调用
      const v = params.get(k)
      if (v) {
        res[k] = v
      }
    }
    return res
  }

  isInputNode(el: HTMLElement): boolean {
    if (el instanceof HTMLInputElement) {
      return true
    }
    if (el instanceof HTMLTextAreaElement) {
      return true
    }
    if (el.isContentEditable) {
      return true
    }
    return false
  }

  formatErrorMsg(err: any, defaultMsg = '未知错误，请重试或联系客服') {
    if (err instanceof Error) {
      return err.message
    }
    if (typeof err === 'string') {
      return err
    }
    if (typeof err === 'object') {
      return err.statusMessage || err.message || defaultMsg
    }
    return defaultMsg
  }

  formatFileSize(bytes: number, decimalPoint = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))
    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  getRandomIntForRange(min: number, max: number) {
    const _min = Math.ceil(min)
    const _max = Math.floor(max)
    return Math.floor(Math.random() * (_max - _min) + _min)
  }

  getTextWidth(text: string, style: Partial<CSSStyleDeclaration>) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      return 0
    }
    const defaultFontFamily =
      style.fontFamily ||
      " -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, 'Noto Sans JP', 'Noto Sans KR', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'"
    const fontWeight = style.fontWeight || 'normal'
    context.font = `${fontWeight} ${style.fontSize} ${defaultFontFamily}`
    const metrics = context.measureText(text)

    return metrics.width
  }

  /**
   * @author mpc
   * 将项目中原本声明的非正式的 locale key 转换为国际通用的 locale key
   * 如：zh_CN -> zh-Hans
   */
  standardizeLocale(locale: string): string {
    switch (locale) {
      case 'pt_BR':
        return 'pt'
      case 'pt_PT':
        return 'pt-PT'
      case 'zh_TW':
      case 'zh_HK':
        return 'zh-Hant'
      case 'zh':
      case 'zh_CN':
        return 'zh-Hans'
      case 'es_419':
        return 'es-419'
      default:
        return locale
    }
  }

  /**
   * 生成URL链接
   * @param path URL中的路径
   * @param queryArgs URL中 “?” 后面的参数
   */
  genApiUrl(path: string, queryArgs: object, underlizeKeys = false): string {
    if (!queryArgs) {
      queryArgs = {}
    }
    if (underlizeKeys) {
      queryArgs = commonUtils.underlize(queryArgs)
    }
    const args: string[] = []
    for (const key in queryArgs as any) {
      const v = (queryArgs as any)[key]
      if (v !== null) {
        args.push(`${key}=${v}`)
      }
    }
    if (args.length === 0) {
      return path
    }
    return `${path}?${args.join('&')}`
  }

  transformNumberToKilo(num: number, fixed = 2) {
    if (num < 1000) {
      return num
    }
    return `${(num / 1000).toFixed(fixed)}k`
  }

  public genUrlWithSearch(base: string) {
    return `${base}${window.location.search}`
  }

  public genUrlWithParam(base: string) {
    return `${base}&${window.location.search.substring(1)}`
  }

  isLocalFile(url: string) {
    if (!url) {
      return false
    }
    return url.startsWith('file://')
  }

  isPdfUrl(url: string) {
    if (!url) {
      return false
    }
    try {
      const regex = /^(http|https):\/\/[^\s]+\.pdf(\?[^#\s]+)?(#\S+)?$/i
      return regex.test(url)
    } catch (error) {
      return false
    }
  }

  isPdfFile(file: File) {
    return file.type === 'application/pdf'
  }

  getStaticFile(name: string) {
    return require(`@/assets/${name}`)
  }

  getExtensionId(): string | null {
    return document.body.getAttribute('noam-id') || null
  }

  getExtensionVersion(): string | null {
    return document.body.getAttribute('noam-version') || null
  }

  /**
   * 向插件发送事件
   */
  sendExtensionMessage(
    event: ExternalEventTypes.EventName,
    data: ExternalEventTypes.EventData,
    callback?: (result: ExternalEventTypes.EventResult) => void
  ): boolean {
    const extensionId = commonUtils.getExtensionId()
    if (!extensionId) {
      console.warn("sending extension message failed -- can't find extension id")
      return false
    }
    const msg = { type: 'webEvent', data: { event, payload: { ...data } } }
    console.log(`try sending extension message to ${extensionId} ${msg}`)
    if (!navigator.userAgent.toLowerCase().includes('firefox')) {
      try {
        chrome.runtime.sendMessage(extensionId, msg, (res: ExternalEventTypes.EventResult) => {
          if (res && (res as any).success) {
            console.log('sending extension message success')
            callback?.(res)
          } else {
            console.warn('sending extension message failed -- not handled on extension side')
            callback?.({ success: false, result: null })
          }
        })
      } catch (error) {
        //
      }
    } else {
      try {
        window.postMessage(msg, window.location.origin)
        console.log('sending extension message success')
      } catch (error) {
        console.warn('sending extension message failed -- not handled on extension side')
      }
    }
    return true
  }

  isInstalledExtension() {
    return !!commonUtils.getExtensionId()
  }

  getDefaultAvatar() {
    return 'https://assets.weibanzhushou.com/web/pt-web/icon__default-avatar.abe2bc528afb.png'
  }

  /** 同步获取指定元素
   * @param selector 选择器, e.g.#root .icon section
   */
  asyncGetTargetElementBySelector(selector: string): Promise<HTMLElement> {
    const queryElement = (selector: string): HTMLElement => {
      return document.querySelector(selector) as HTMLElement
    }
    const targetEl: HTMLElement = queryElement(selector)
    if (targetEl) {
      return Promise.resolve(targetEl)
    }
    return new Promise((resolve) => {
      const observer = new MutationObserver((mutationRecords) => {
        for (const rec of mutationRecords) {
          if (rec.type !== 'childList') {
            return
          }
          const targetEl = rec.target as HTMLElement
          if (targetEl?.matches?.(selector) || targetEl?.querySelector(selector)) {
            observer.disconnect()
            resolve(queryElement(selector))
            return
          }
          commonUtils.forEachElement(rec.addedNodes as NodeListOf<Node>, (node) => {
            const el = node as HTMLElement
            if (el?.matches?.(selector)) {
              observer.disconnect()
              resolve(queryElement(selector))
              return true
            }
            return null
          })
        }
      })
      observer.observe(document, { childList: true, subtree: true })
    })
  }

  forEachElement<T extends Node>(list: NodeListOf<T>, handler: (item: T, idx: number) => void) {
    for (let arr = list, i = 0; i < arr.length; i++) {
      const el = arr[i]
      handler(el, i)
    }
  }

  async asyncDelay(ms: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }
  /**
   * 生成随机字符串,并指定长度
   */
  genSessionIdByTime(length: number = 10): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let result = ''
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  checkExtensionVersionShouldUpdate(version: string) {
    const cVersion = commonUtils.getExtensionVersion()
    if (!cVersion) {
      return false
    }
    const [a, b, c] = version.split('.').map((v) => parseInt(v))
    const [_a, _b, _c] = cVersion.split('.').map((v) => parseInt(v))
    return _a * 100 + _b * 10 + _c < a * 100 + b * 10 + c
  }

  /**
   * 删除给定url的参数
   */
  removeUrlArgs(url: string, args: string[]) {
    const [baseUrl] = url.split('#')
    if (baseUrl.indexOf('?') === -1) {
      return url
    }
    const urlObj = new URL(url)
    const queryArr = urlObj.search.substring(1).split('&')
    const parts: string[] = []
    queryArr.forEach((q) => {
      const [k] = q.split('=')
      if (!args.some((a) => k === a) && parts.indexOf(q) === -1) {
        parts.push(q)
      }
    })
    const { protocol, host, pathname, hash } = urlObj
    const base = protocol + '//' + host + pathname
    if (parts.length > 0) {
      return base + '?' + parts.join('&') + hash
    } else {
      return base + hash
    }
  }

  getChromeVersion() {
    const ua = window.navigator.userAgent
    if (ua.indexOf('Chrome/') === -1) {
      return
    } else {
      const v = ua.match(/Chrome\/([\d.]{3})/)![1]
      return parseInt(v)
    }
  }

  browserIs360() {
    const ua = window.navigator.userAgent
    const chromeVersion = parseInt(ua.replace(/^.*Chrome\/([\d]+).*$/, '$1'))
    const hash360se: { [key: number]: string } = {
      63: '10.0',
      55: '9.1',
      45: '8.1',
      42: '8.0',
      31: '7.0',
      21: '6.3',
    }
    const hash360ee: { [key: number]: string } = {
      69: '11.0',
      63: '9.5',
      55: '9.0',
      50: '8.7',
      30: '7.5',
    }
    const old360VersionCheck = hash360ee[chromeVersion] || hash360se[chromeVersion]
    const new360BrowserCheck = commonUtils.getBrowserInfo().browser.indexOf('360') > -1
    return old360VersionCheck || new360BrowserCheck
  }

  browserIsQQ() {
    return window.navigator.userAgent.indexOf('QQBrowser') > -1
  }

  browserIsEdge() {
    return window.navigator.userAgent.indexOf('Edg') > -1
  }

  getBrowserInfo(): BrowserInfo {
    let chromeEngineVersion: number = -1
    try {
      const navigator = window.navigator
      const ua = navigator.userAgent
      const browserInfo: BrowserInfo = {} as any

      const _mime = function (option: any, value: any) {
        const mimeTypes = navigator.mimeTypes
        for (const mt in mimeTypes) {
          if ((mimeTypes as any)[mt][option] === value) {
            return true
          }
        }
        return false
      }

      const match: any = {
        // 内核
        Trident: ua.indexOf('Trident') > -1 || ua.indexOf('NET CLR') > -1,
        Presto: ua.indexOf('Presto') > -1,
        WebKit: ua.indexOf('AppleWebKit') > -1,
        Gecko: ua.indexOf('Gecko/') > -1,
        // 浏览器
        Safari: ua.indexOf('Safari') > -1,
        Chrome: ua.indexOf('Chrome') > -1 || ua.indexOf('CriOS') > -1,
        IE: ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1,
        Edge: ua.indexOf('Edge') > -1 || ua.indexOf('Edg/') > -1,
        Firefox: ua.indexOf('Firefox') > -1 || ua.indexOf('FxiOS') > -1,
        'Firefox Focus': ua.indexOf('Focus') > -1,
        Chromium: ua.indexOf('Chromium') > -1,
        Opera: ua.indexOf('Opera') > -1 || ua.indexOf('OPR') > -1,
        Vivaldi: ua.indexOf('Vivaldi') > -1,
        Yandex: ua.indexOf('YaBrowser') > -1,
        Arora: ua.indexOf('Arora') > -1,
        Lunascape: ua.indexOf('Lunascape') > -1,
        QupZilla: ua.indexOf('QupZilla') > -1,
        'Coc Coc': ua.indexOf('coc_coc_browser') > -1,
        Kindle: ua.indexOf('Kindle') > -1 || ua.indexOf('Silk/') > -1,
        Iceweasel: ua.indexOf('Iceweasel') > -1,
        Konqueror: ua.indexOf('Konqueror') > -1,
        Iceape: ua.indexOf('Iceape') > -1,
        SeaMonkey: ua.indexOf('SeaMonkey') > -1,
        Epiphany: ua.indexOf('Epiphany') > -1,
        360: ua.indexOf('QihooBrowser') > -1 || ua.indexOf('QHBrowser') > -1,
        '360EE': ua.indexOf('360EE') > -1,
        '360SE': ua.indexOf('360SE') > -1,
        UC: ua.indexOf('UC') > -1 || ua.indexOf(' UBrowser') > -1,
        QQBrowser: ua.indexOf('QQBrowser') > -1,
        QQ: ua.indexOf('QQ/') > -1,
        Baidu:
          ua.indexOf('Baidu') > -1 ||
          ua.indexOf('BIDUBrowser') > -1 ||
          ua.indexOf('baiduboxapp') > -1,
        Maxthon: ua.indexOf('Maxthon') > -1,
        Sogou: ua.indexOf('MetaSr') > -1 || ua.indexOf('Sogou') > -1,
        LBBROWSER: ua.indexOf('LBBROWSER') > -1,
        '2345Explorer': ua.indexOf('2345Explorer') > -1 || ua.indexOf('Mb2345Browser') > -1,
        '115Browser': ua.indexOf('115Browser') > -1,
        TheWorld: ua.indexOf('TheWorld') > -1,
        XiaoMi: ua.indexOf('MiuiBrowser') > -1,
        Quark: ua.indexOf('Quark') > -1,
        Qiyu: ua.indexOf('Qiyu') > -1,
        Wechat: ua.indexOf('MicroMessenger') > -1,
        Taobao: ua.indexOf('AliApp(TB') > -1,
        Alipay: ua.indexOf('AliApp(AP') > -1,
        Weibo: ua.indexOf('Weibo') > -1,
        Douban: ua.indexOf('com.douban.frodo') > -1,
        Suning: ua.indexOf('SNEBUY-APP') > -1,
        iQiYi: ua.indexOf('IqiyiApp') > -1,
        DingTalk: ua.indexOf('DingTalk') > -1,
        Huawei: ua.indexOf('HuaweiBrowser') > -1 || ua.indexOf('HUAWEI') > -1,
        // 系统或平台
        Windows: ua.indexOf('Windows') > -1,
        Linux: ua.indexOf('Linux') > -1 || ua.indexOf('X11') > -1,
        'Mac OS': ua.indexOf('Macintosh') > -1,
        Android: ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1,
        Ubuntu: ua.indexOf('Ubuntu') > -1,
        FreeBSD: ua.indexOf('FreeBSD') > -1,
        Debian: ua.indexOf('Debian') > -1,
        'Windows Phone': ua.indexOf('IEMobile') > -1 || ua.indexOf('Windows Phone') > -1,
        BlackBerry: ua.indexOf('BlackBerry') > -1 || ua.indexOf('RIM') > -1,
        MeeGo: ua.indexOf('MeeGo') > -1,
        Symbian: ua.indexOf('Symbian') > -1,
        iOS: ua.indexOf('like Mac OS X') > -1,
        'Chrome OS': ua.indexOf('CrOS') > -1,
        WebOS: ua.indexOf('hpwOS') > -1,
        // 设备
        Mobile: ua.indexOf('Mobi') > -1 || ua.indexOf('iPh') > -1 || ua.indexOf('480') > -1,
        Tablet: ua.indexOf('Tablet') > -1 || ua.indexOf('Pad') > -1 || ua.indexOf('Nexus 7') > -1,
      }
      let is360 = false
      if (window.chrome) {
        const chromeVersion = parseFloat(ua.replace(/^.*Chrome\/([\d]+).*$/, '$1'))
        if ((window.chrome as any).adblock2345 || (window.chrome as any).common2345) {
          match['2345Explorer'] = true
        } else if (
          _mime('type', 'application/360softmgrplugin') ||
          _mime('type', 'application/mozilla-npqihooquicklogin')
        ) {
          is360 = true
        } else if (chromeVersion > 36 && (window as any).showModalDialog) {
          is360 = true
        } else if (chromeVersion > 45) {
          is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
          if (!is360 && chromeVersion >= 69) {
            is360 =
              _mime('type', 'application/hwepass2001.installepass2001') ||
              _mime('type', 'application/asx')
          }
        }
        chromeEngineVersion = chromeVersion
        browserInfo.chromeEngineVersion = chromeVersion
      }
      // 修正
      if (match.Mobile) {
        match.Mobile = !(ua.indexOf('iPad') > -1)
      } else if (is360) {
        if (_mime('type', 'application/gameplugin')) {
          match['360SE'] = true
        } else if (
          navigator &&
          typeof (navigator as any).connection !== 'undefined' &&
          typeof (navigator as any).connection.saveData === 'undefined'
        ) {
          match['360SE'] = true
        } else {
          match['360EE'] = true
        }
      }
      if (match.IE || match.Edge) {
        const navigatorTop = window.screenTop - window.screenY
        switch (navigatorTop) {
          case 71: // 无收藏栏,贴边
          case 99: // 有收藏栏,贴边
          case 102: // 有收藏栏,非贴边
            match['360EE'] = true
            break
          case 75: // 无收藏栏,贴边
          case 105: // 有收藏栏,贴边
          case 104: // 有收藏栏,非贴边
            match['360SE'] = true
            break
        }
      }
      if (match.Baidu && match.Opera) {
        match.Baidu = false
      } else if (match.iOS) {
        match.Safari = true
      }
      // 基本信息
      const hash: any = {
        engine: ['WebKit', 'Trident', 'Gecko', 'Presto'],
        browser: [
          'Safari',
          'Chrome',
          'Edge',
          'IE',
          'Firefox',
          'Firefox Focus',
          'Chromium',
          'Opera',
          'Vivaldi',
          'Yandex',
          'Arora',
          'Lunascape',
          'QupZilla',
          'Coc Coc',
          'Kindle',
          'Iceweasel',
          'Konqueror',
          'Iceape',
          'SeaMonkey',
          'Epiphany',
          'XiaoMi',
          'Huawei',
          '360',
          '360SE',
          '360EE',
          'UC',
          'QQBrowser',
          'QQ',
          'Baidu',
          'Maxthon',
          'Sogou',
          'LBBROWSER',
          '2345Explorer',
          '115Browser',
          'TheWorld',
          'Quark',
          'Qiyu',
          'Wechat',
          'Taobao',
          'Alipay',
          'Weibo',
          'Douban',
          'Suning',
          'iQiYi',
          'DingTalk',
        ],
        os: [
          'Windows',
          'Linux',
          'Mac OS',
          'Android',
          'Ubuntu',
          'FreeBSD',
          'Debian',
          'iOS',
          'Windows Phone',
          'BlackBerry',
          'MeeGo',
          'Symbian',
          'Chrome OS',
          'WebOS',
        ],
        device: ['Mobile', 'Tablet'],
      }
      browserInfo.device = 'PC'
      browserInfo.language = (function () {
        const g = (navigator as any).browserLanguage || navigator.language
        const arr = g.split('-')
        if (arr[1]) {
          arr[1] = arr[1].toUpperCase()
        }
        return arr.join('_')
      })()
      for (const s in hash) {
        for (let i = 0; i < hash[s].length; i++) {
          const value = hash[s][i]
          if (match[value]) {
            ;(browserInfo as any)[s] = value
          }
        }
      }
      // 系统版本信息
      const osVersion: any = {
        Windows() {
          const v = ua.replace(/^Mozilla\/\d.0 \(Windows NT ([\d.]+);.*$/, '$1')
          const hash: any = {
            10: '10',
            6.4: '10',
            6.3: '8.1',
            6.2: '8',
            6.1: '7',
            '6.0': 'Vista',
            5.2: 'XP',
            5.1: 'XP',
            '5.0': '2000',
          }
          return hash[v] || v
        },
        Android() {
          return ua.replace(/^.*Android ([\d.]+);.*$/, '$1')
        },
        iOS() {
          return ua.replace(/^.*OS ([\d_]+) like.*$/, '$1').replace(/_/g, '.')
        },
        Debian() {
          return ua.replace(/^.*Debian\/([\d.]+).*$/, '$1')
        },
        'Windows Phone'() {
          return ua.replace(/^.*Windows Phone( OS)? ([\d.]+);.*$/, '$2')
        },
        'Mac OS'() {
          return ua.replace(/^.*Mac OS X ([\d_]+).*$/, '$1').replace(/_/g, '.')
        },
        WebOS() {
          return ua.replace(/^.*hpwOS\/([\d.]+);.*$/, '$1')
        },
      }
      browserInfo.osVersion = ''
      if (osVersion[browserInfo.os]) {
        browserInfo.osVersion = osVersion[browserInfo.os]()
        if (browserInfo.osVersion === ua) {
          browserInfo.osVersion = ''
        }
      }
      // 浏览器版本信息
      const version = {
        Safari() {
          return ua.replace(/^.*Version\/([\d.]+).*$/, '$1')
        },
        Chrome() {
          return ua.replace(/^.*Chrome\/([\d.]+).*$/, '$1').replace(/^.*CriOS\/([\d.]+).*$/, '$1')
        },
        IE() {
          return ua.replace(/^.*MSIE ([\d.]+).*$/, '$1').replace(/^.*rv:([\d.]+).*$/, '$1')
        },
        Edge() {
          return ua.replace(/^.*Edge\/([\d.]+).*$/, '$1').replace(/^.*Edg\/([\d.]+).*$/, '$1')
        },
        Firefox() {
          return ua.replace(/^.*Firefox\/([\d.]+).*$/, '$1').replace(/^.*FxiOS\/([\d.]+).*$/, '$1')
        },
        'Firefox Focus'() {
          return ua.replace(/^.*Focus\/([\d.]+).*$/, '$1')
        },
        Chromium() {
          return ua.replace(/^.*Chromium\/([\d.]+).*$/, '$1')
        },
        Opera() {
          return ua.replace(/^.*Opera\/([\d.]+).*$/, '$1').replace(/^.*OPR\/([\d.]+).*$/, '$1')
        },
        Vivaldi() {
          return ua.replace(/^.*Vivaldi\/([\d.]+).*$/, '$1')
        },
        Yandex() {
          return ua.replace(/^.*YaBrowser\/([\d.]+).*$/, '$1')
        },
        Arora() {
          return ua.replace(/^.*Arora\/([\d.]+).*$/, '$1')
        },
        Lunascape() {
          return ua.replace(/^.*Lunascape[\/\s]([\d.]+).*$/, '$1')
        },
        QupZilla() {
          return ua.replace(/^.*QupZilla[\/\s]([\d.]+).*$/, '$1')
        },
        'Coc Coc'() {
          return ua.replace(/^.*coc_coc_browser\/([\d.]+).*$/, '$1')
        },
        Kindle() {
          return ua.replace(/^.*Version\/([\d.]+).*$/, '$1')
        },
        Iceweasel() {
          return ua.replace(/^.*Iceweasel\/([\d.]+).*$/, '$1')
        },
        Konqueror() {
          return ua.replace(/^.*Konqueror\/([\d.]+).*$/, '$1')
        },
        Iceape() {
          return ua.replace(/^.*Iceape\/([\d.]+).*$/, '$1')
        },
        SeaMonkey() {
          return ua.replace(/^.*SeaMonkey\/([\d.]+).*$/, '$1')
        },
        Epiphany() {
          return ua.replace(/^.*Epiphany\/([\d.]+).*$/, '$1')
        },
        360() {
          return ua.replace(/^.*QihooBrowser\/([\d.]+).*$/, '$1')
        },
        '360SE'() {
          const hash: any = {
            78: '12.1',
            69: '11.1',
            63: '10.0',
            55: '9.1',
            45: '8.1',
            42: '8.0',
            31: '7.0',
            21: '6.3',
          }
          const chromeVersion = ua.replace(/^.*Chrome\/([\d]+).*$/, '$1')
          return hash[chromeVersion] || ''
        },
        '360EE'() {
          const hash: any = {
            78: '12.0',
            69: '11.0',
            63: '9.5',
            55: '9.0',
            50: '8.7',
            30: '7.5',
          }
          const chromeVersion = ua.replace(/^.*Chrome\/([\d]+).*$/, '$1')
          return hash[chromeVersion] || ''
        },
        Maxthon() {
          return ua.replace(/^.*Maxthon\/([\d.]+).*$/, '$1')
        },
        QQBrowser() {
          return ua.replace(/^.*QQBrowser\/([\d.]+).*$/, '$1')
        },
        QQ() {
          return ua.replace(/^.*QQ\/([\d.]+).*$/, '$1')
        },
        Baidu() {
          return ua
            .replace(/^.*BIDUBrowser[\s\/]([\d.]+).*$/, '$1')
            .replace(/^.*baiduboxapp\/([\d.]+).*$/, '$1')
        },
        UC() {
          return ua.replace(/^.*UC?Browser\/([\d.]+).*$/, '$1')
        },
        Sogou() {
          return ua
            .replace(/^.*SE ([\d.X]+).*$/, '$1')
            .replace(/^.*SogouMobileBrowser\/([\d.]+).*$/, '$1')
        },
        LBBROWSER() {
          const hash: any = {
            57: '6.5',
            49: '6.0',
            46: '5.9',
            42: '5.3',
            39: '5.2',
            34: '5.0',
            29: '4.5',
            21: '4.0',
          }
          const chromeVersion = navigator.userAgent.replace(/^.*Chrome\/([\d]+).*$/, '$1')
          return hash[chromeVersion] || ''
        },
        '2345Explorer'() {
          const hash: any = { 69: '10.0', 55: '9.9' }
          const chromeVersion = navigator.userAgent.replace(/^.*Chrome\/([\d]+).*$/, '$1')
          return (
            hash[chromeVersion] ||
            ua
              .replace(/^.*2345Explorer\/([\d.]+).*$/, '$1')
              .replace(/^.*Mb2345Browser\/([\d.]+).*$/, '$1')
          )
        },
        '115Browser'() {
          return ua.replace(/^.*115Browser\/([\d.]+).*$/, '$1')
        },
        TheWorld() {
          return ua.replace(/^.*TheWorld ([\d.]+).*$/, '$1')
        },
        XiaoMi() {
          return ua.replace(/^.*MiuiBrowser\/([\d.]+).*$/, '$1')
        },
        Quark() {
          return ua.replace(/^.*Quark\/([\d.]+).*$/, '$1')
        },
        Qiyu() {
          return ua.replace(/^.*Qiyu\/([\d.]+).*$/, '$1')
        },
        Wechat() {
          return ua.replace(/^.*MicroMessenger\/([\d.]+).*$/, '$1')
        },
        Taobao() {
          return ua.replace(/^.*AliApp\(TB\/([\d.]+).*$/, '$1')
        },
        Alipay() {
          return ua.replace(/^.*AliApp\(AP\/([\d.]+).*$/, '$1')
        },
        Weibo() {
          return ua.replace(/^.*weibo__([\d.]+).*$/, '$1')
        },
        Douban() {
          return ua.replace(/^.*com.douban.frodo\/([\d.]+).*$/, '$1')
        },
        Suning() {
          return ua.replace(/^.*SNEBUY-APP([\d.]+).*$/, '$1')
        },
        iQiYi() {
          return ua.replace(/^.*IqiyiVersion\/([\d.]+).*$/, '$1')
        },
        DingTalk() {
          return ua.replace(/^.*DingTalk\/([\d.]+).*$/, '$1')
        },
        Huawei() {
          return ua
            .replace(/^.*Version\/([\d.]+).*$/, '$1')
            .replace(/^.*HuaweiBrowser\/([\d.]+).*$/, '$1')
        },
      }
      browserInfo.version = ''
      if ((version as any)[browserInfo.browser]) {
        browserInfo.version = (version as any)[browserInfo.browser]()
        if (browserInfo.version === ua) {
          browserInfo.version = ''
        }
      }
      // 修正
      if (browserInfo.browser === 'Edge') {
        if (browserInfo.version > '75') {
          browserInfo.engine = 'Blink'
        } else {
          browserInfo.engine = 'EdgeHTML'
        }
      } else if (
        match.Chrome &&
        browserInfo.engine === 'WebKit' &&
        parseInt(version.Chrome()) > 27
      ) {
        browserInfo.engine = 'Blink'
      } else if (browserInfo.browser === 'Opera' && parseInt(browserInfo.version) > 12) {
        browserInfo.engine = 'Blink'
      } else if (browserInfo.browser === 'Yandex') {
        browserInfo.engine = 'Blink'
      }
      if (browserInfo.os === 'Mac OS') {
        const chromeVersion = ua.replace(/^.*Chrome\/([\d]+).*$/, '$1')
        if (chromeVersion === '69') {
          browserInfo.browser = '360EE'
        }
        /** 如果到这里还没检测出是 360 时，补充检测一下,目前发现 mac 上 360 会在浏览器中植入两个 mimeType  */
        if (
          _mime('type', 'application/x-shockwave-flash') ||
          _mime('type', 'application/futuresplash')
        ) {
          /** Mac 端目前仅可安装急速浏览器 */
          browserInfo.browser = '360EE'
        }
      }
      return browserInfo
    } catch (error) {
      console.error('browser info parse error', error)
      // 如果上面的解析逻辑出错了，则给一个默认值，保证后续的逻辑能正常进行
      return {
        browser: 'Chrome',
        engine: 'WebKit',
        os: 'Windows',
        device: 'PC',
        version: '-',
        osVersion: '-',
        language: '-',
        chromeEngineVersion: chromeEngineVersion || 72,
      }
    }
  }

  /** 防 xss 注入 */
  cleanHtml(htmlStr: string) {
    return dompurify.sanitize(htmlStr)
  }

  /** 向query中设置/添加参数，同时不会造成页面跳转，也不会增加历史记录 */
  setQueryParam<P extends RouterServiceTypes.PageType>(
    /** 此参数目中只是为了定义，没有实际作用 */
    pageName: P,
    key: keyof RouterServiceTypes.PageParams[P],
    value: string
  ) {
    const queryParams = commonUtils.getUrlParams(window.location.href)
    queryParams[key as string] = value
    const newUrl =
      commonUtils.genApiUrl(window.location.pathname, queryParams) + window.location.hash
    const lastHashState = {
      id: commonUtils.genId(),
      path: newUrl,
      createdAt: new Date(),
      wingedHistoryState: true,
    }
    window.history.replaceState(lastHashState, '', newUrl)
  }

  jsonClone<T>(target: T): T {
    return JSON.parse(JSON.stringify(target))
  }

  parsePriceStr(price: number) {
    return (price / 100).toString()
  }

  genVisitorTokenStr(): string {
    let token = storageHelper.get(['tokenStr']).tokenStr
    if (!token) {
      token = commonUtils.genId() + commonUtils.genSessionIdByTime()
      storageHelper.set({ tokenStr: token })
    }
    return token
  }

  getNewUserRemainCount(remainTime: number, hasBuy?: boolean) {
    // 如果remainTime少于等于1天，直接显示3个
    const oneDay = 24 * 60 * 60 * 1000
    let count = 0
    if (remainTime <= oneDay) {
      count = 3
    } else {
      // 否则count=剩余时间里每12个小时减1
      count = 55 - Math.floor((oneDay * NEW_USER_DISCOUNT_DAY - remainTime) / (60 * 60 * 1000 * 12))
    }
    if (hasBuy) {
      count--
    }
    return count
  }
  getUrlArgs(url?: string, camelizeParamKeys = false, allowList = false): { [k: string]: any } {
    if (!url) {
      url = window.location.href
    }
    // remove url hash
    const [href] = url.split('#')
    const s = href.split('?')[1]
    if (!s) {
      return {}
    }
    let query: {
      [key: string]: string | string[]
    } = {}
    for (const a of s.split('&')) {
      const [k, v] = a.split('=')
      let _s: string
      try {
        _s = decodeURIComponent(v)
      } catch (error) {
        _s = v
      }
      if (query[k]) {
        if (query[k] instanceof Array) {
          ;(query[k] as string[]).push(_s)
        } else {
          // query[k] is string
          if (allowList) {
            query[k] = [query[k] as string, _s]
          } else {
            continue
          }
        }
      } else {
        query[k] = _s
      }
    }
    if (camelizeParamKeys) {
      query = this.camelize(query)
    }
    return query
  }

  getPlanTotalPrice(plan: pricingModal.VipPlan) {
    const { price, timeType, priceType } = plan
    if (timeType === 'year_subscription' && priceType !== 'year') {
      return (price * 12) / 100
    }
    return price / 100
  }
}

export const commonUtils = new CommonUtils()
