import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, forwardRef, useEffect, useState } from 'react'
import PageFooter, {
  LegalItemClickListener,
  ProductItemClickListener,
  ResourceItemClickListener,
} from '../PageFooter/PageFooter'
import PageHeader from '../PageHeader/PageHeader'
import style from './Layout.module.less'
import { useMount } from 'react-use'
import { userService } from '@/services/userService'
import { ee } from '@/services/eventEmitter/eventEmitter'
import { Button, Spin } from 'antd'
import NewUserCountdownAlert from '../NewUserCountdownAlert/NewUserCountdownAlert'
import { useTranslationWithType } from '@/i18n/i18n'
import { storageHelper } from '@/helpers/storageHelper'

/** Layout 组件的 Props */
export type LayoutProps = {
  rootClass?: string
  contentClass?: string
  hideFooter?: boolean
  children: React.ReactNode
  // 隐藏顶部新人福利倒计时提示条
  hideNewUserCountdownAlert?: boolean
  // 隐藏隐私协议收集面板
  hidePrivacyCollectionPanel?: boolean
  onFooterProductItemClick?: ProductItemClickListener
  onFooterResourceItemClick?: ResourceItemClickListener
  onFooterLegalItemClick?: LegalItemClickListener
}

const Layout = observer(
  forwardRef<HTMLDivElement, LayoutProps>(
    (
      {
        children,
        hideFooter,
        rootClass: pageClass,
        contentClass,
        hideNewUserCountdownAlert,
        hidePrivacyCollectionPanel,
        onFooterProductItemClick,
        onFooterResourceItemClick,
        onFooterLegalItemClick,
      },
      ref
    ) => {
      const [scrolling, setScrolling] = useState(false)
      const [userInfoInited, setInited] = useState(false)
      const [newUserDiscountTime, setNewUserDiscountTime] = useState(0)
      useMount(() => {
        if (userService.initialed) {
          setInited(true)
          return
        }
        ee.on('userServiceInitialed', () => {
          setInited(true)
        })
      })
      useEffect(() => {
        if (!userService.enableNewUserDiscount) {
          return
        }
        if (!userInfoInited || hideNewUserCountdownAlert || !userService.isLogin) {
          setNewUserDiscountTime(0)
          return
        }
        setNewUserDiscountTime(userService.newUserDiscountTime)
      }, [userInfoInited, hideNewUserCountdownAlert, userService.enableNewUserDiscount])
      if (!userInfoInited) {
        return (
          <div className={classNames(style.loadingBox)}>
            <Spin></Spin>
          </div>
        )
      }

      return (
        <div
          ref={ref}
          className={classNames(style.hyLayout, pageClass)}
          onScroll={(e) => {
            setScrolling(e.currentTarget.scrollTop > 0)
          }}
        >
          <div
            className={classNames(style.wrapper, 'flex-col', {
              [style.withAlert]: newUserDiscountTime > 0,
            })}
          >
            {newUserDiscountTime > 0 && (
              <NewUserCountdownAlert
                endTime={newUserDiscountTime}
                onClose={() => {
                  setNewUserDiscountTime(0)
                }}
              ></NewUserCountdownAlert>
            )}
            <PageHeader fixedTop={scrolling}></PageHeader>
            <section id='page-content' className={classNames('col-1', contentClass)}>
              {children}
            </section>
            {!hideFooter && (
              <PageFooter
                onResourcesItemClick={onFooterResourceItemClick}
                onProductItemClick={onFooterProductItemClick}
                onLegalItemClick={onFooterLegalItemClick}
              ></PageFooter>
            )}
            {!hidePrivacyCollectionPanel && <PrivacyCollectionPanel></PrivacyCollectionPanel>}
          </div>
        </div>
      )
    }
  )
)

const PrivacyCollectionPanel = observer(() => {
  const { t } = useTranslationWithType()
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const accept = storageHelper.get(['acceptPrivacyCollection']).acceptPrivacyCollection
    if (accept) {
      setVisible(false)
      return
    }
    setVisible(true)
  }, [])
  if (!visible) {
    return null
  }
  return (
    <div className={classNames(style.privacyCollectionPanel)}>
      <div className={classNames(style.title)}>{t('privacyCollectionPanel.title')}</div>
      <div className={classNames(style.text)}>
        {t('privacyCollectionPanel.text')}
        <a href='/policy' target='_black'>
          {t('pageFooter.legal.policy')}
        </a>
      </div>
      <div className={classNames('flex-row', 'align-right')}>
        <Button
          className={classNames(style.acceptBtn)}
          type='primary'
          onClick={() => {
            storageHelper.set({ acceptPrivacyCollection: true })
            setVisible(false)
          }}
        >
          {t('privacyCollectionPanel.acceptBtn')}
        </Button>
      </div>
    </div>
  )
})
export default Layout
