interface BenefitsInfo {
  tokenCount: string
  pdfUploadCount: string
  pdfChatCount: string
  pdfMaxPage: string
  imageCount: string
  realTimeCount?: string
}

export const FREE_BENEFITS: BenefitsInfo = {
  tokenCount: '5,000',
  pdfUploadCount: '10',
  pdfChatCount: '30',
  pdfMaxPage: '30',
  imageCount: '30',
  realTimeCount: '100',
}
export const PRO_BENEFITS: BenefitsInfo = {
  tokenCount: '1,000,000',
  pdfUploadCount: '200',
  pdfChatCount: '1000',
  pdfMaxPage: '200',
  imageCount: '200',
}
export const PRO_V_BENEFITS: BenefitsInfo = {
  tokenCount: '3,000,000',
  pdfUploadCount: '500',
  pdfChatCount: '3000',
  pdfMaxPage: '500',
  imageCount: '500',
}
