const TRANSLATION = {
  slogen: '最も簡単な AI アシスタント',
  pageHeader: {
    help: 'ヘルプセンター',
    contact: 'お問い合わせ',
    pricing: '価格設定',
    signUp: 'ログイン',
    settings: '設定',
    logout: 'ログアウト',
    logoutSucc: 'ログアウト成功',
  },
  pageFooter: {
    product: {
      title: '製品',
      web: 'ウェブ翻訳と要約',
      pdf: 'PDF翻訳',
      video: 'ビデオの翻訳と要約',
      image: '画像翻訳',
    },
    resources: { title: 'リソース' },
    follow: { title: 'フォローする', twitter: 'ツイッター', facebook: 'フェイスブック' },
    legal: { title: '法律上の', policy: 'プライバシーポリシー', terms: '利用規約' },
  },
  home: {
    getStart: '始めましょう',
    getStartedForFree: '無料で始めましょう',
    top: {
      desc: 'Noam は、要約された Web ページ、ビデオ、PDF コンテンツの翻訳に役立つ無料の AI ブラウザ拡張機能です。\n      ノームと一緒に魔法を楽しみましょう!',
      title2: 'ワンマジック',
      title1: 'ワンクリック',
    },
    howItWork: {
      title: 'それで、それはどのように機能するか',
      desc: 'Noam は AI モデルを統合し、言語を越えた Web ページの翻訳と要約、PDF と画像の翻訳、ビデオの翻訳と要約のインテリジェントなエクスペリエンスをユーザーに提供します。',
    },
    PDFTranslate: {
      title: 'PDF翻訳',
      desc: 'バイリンガル翻訳+チャットPDF',
      sub1: {
        title: 'バイリンガル読書',
        desc: '対訳読みなので、原文と訳文を比較しながら読むことができ、読書効率が向上します。',
      },
      sub2: { title: 'チャットPDF', desc: 'PDFの内容をもとにAIが分析・要約し、質問に回答します' },
      sub3: {
        title: '精度と効率',
        desc: 'AI を活用した PDf により、より正確かつ効率的な翻訳を実現',
      },
    },
    aiAssistant: {
      title: 'AIアシスタント',
      desc: 'Web翻訳をよりスマートに、より便利に',
      panel1: {
        title: 'バイリンガル翻訳',
        desc: '対訳の相互参照翻訳は、原文と翻訳を比較するのに役立つため、理解を深め、読書効率を向上させることができます。',
      },
      panel2: {
        left: {
          title: 'ホバー翻訳',
          desc: 'Web ページを閲覧しているときに、翻訳が必要なコンテンツの上にマウスを直接置くと、翻訳結果が表示されます。',
        },
        right: {
          title: 'テキスト選択の翻訳',
          desc: 'Web ページを閲覧しているときに、下線を引くテキストを直接選択すると、翻訳結果が表示されます。',
        },
      },
      panel3: {
        one: {
          title: 'ポイントアンドクリック',
          desc: 'いつでも、どこでも、どんなシナリオでも、Web ページやビデオ コンテンツを翻訳して要約します。よりスムーズな体験をお届けします！',
        },
        two: {
          title: 'より包括的に、より便利に',
          desc: '多言語完全対応、二か国語バリアフリー読書、ワンキー翻訳。比類のない体験をお届けします！',
        },
        three: {
          title: 'AI主導',
          desc: 'AI 主導のビッグモデルにより、翻訳の精度と一貫性を確保し、読書体験を向上させます',
        },
      },
      panel4: {
        tab1: {
          key: 'ビデオ',
          title: 'ビデオの翻訳と要約',
          desc: '効率化のエキスパートと chatgpt を組み合わせて、YouTube ビデオ コンテンツを翻訳して要約し、読書時間を節約します。',
        },
        tab2: {
          key: '画像',
          title: '画像翻訳',
          desc: 'Web 閲覧中にクリックして画像を翻訳します',
        },
        tab3: {
          key: 'ウェブ',
          title: 'ウェブ翻訳と要約',
          desc: 'Web ページから重要な情報を素早く抽出して要約し、読書効率を高めます。',
        },
        tag1: '単純',
        tag2: '効率',
        tag3: '簡単に理解できる',
      },
    },
    userReviews: { title: 'ユーザーレビュー', desc: 'chromewebstore での 5 つ星のレビュー' },
    lastBlock: { desc: 'より優れた AI 翻訳とよりスムーズな読書体験' },
  },
  login: {
    title: 'ノームへようこそ',
    btn: 'Googleでログイン',
    tips: 'サインアップすると、次のことに同意します',
    terms: '利用規約',
    and: 'そして',
    policy: 'プライバシーポリシー',
    btn1: 'ここでログイン',
    desc: 'ログインしてさらに多くのエクスペリエンスをロック解除します',
  },
  pricing: {
    pageTitle: '価格設定',
    desc: '比較して最適なものをお選びください',
    yearly: '毎年',
    monthly: '毎月',
    discount: '20％オフ',
    preMonth: '月あたり',
    billedYearly: '毎年請求',
    billedMonthly: '毎月請求',
    detailedCompare: '詳細な比較',
    FAQ: 'よくある質問',
    day: '日',
    month: '月',
    around: 'その周り',
    free: {
      benefit1: '即時翻訳 100回/日',
      benefit2: 'Web翻訳＆要約',
      benefit3: '画像翻訳 30枚',
      benefit4: '動画の翻訳と要約',
      benefit5: '10 PDF翻訳',
      benefit6: '5,000トークン',
      recommendText: '無料トライアル',
      btn: '楽しむ',
    },
    pro: {
      benefit1: 'カスタマーサービスの迅速なサポート',
      benefit2: '1,000,000 トークン/月',
      benefit3: 'Web翻訳＆要約',
      benefit4: '画像翻訳 200枚',
      benefit5: '動画の翻訳と要約',
      benefit6: '200 PDF 翻訳',
      btn: '始めましょう',
      recommendText: 'ほとんどの人が選ぶ',
    },
    proPlus: {
      benefit1: 'カスタマーサービスの迅速なサポート',
      benefit2: '3,000,000 トークン/月',
      benefit3: 'Web翻訳＆要約',
      benefit4: '500枚 画像翻訳',
      benefit5: '動画の翻訳と要約',
      benefit6: '500 PDF 翻訳',
      recommendText: '最適な選択',
    },
    enterprise: {
      benefit1: 'カスタマーサービスの迅速なサポート',
      benefit2: 'マルチユーザー',
      benefit3: 'カスタマイズされたソリューション',
      benefit5: '専属アカウントマネージャー',
      recommendText: 'カスタマイズプラン（3名以上）',
      talkToUs: '私たちに話してください',
      btn: '接触',
    },
    web: {
      contrastiveTranslation: 'バイリンガル翻訳',
      instantTranslation: '即時翻訳',
      hoverTranslation: 'ホバー翻訳',
      dictionaryTranslation: 'テキスト選択の翻訳',
      summary: 'Webページの概要',
    },
    imageTranslation: '画像翻訳',
    video: { translation: 'ビデオ翻訳', summary: 'ビデオの概要' },
    pdf: { translation: 'PDF翻訳', maxPage: 'PDF の最大ページ数', chat: 'PDFお問い合わせ件数' },
    FQAObj: {
      q1: '無料で使えますか？',
      a1: '直接使用できる Noam の無料バージョンがあります。',
      q2: 'さまざまな会員プランや料金オプションはありますか?',
      a2: '無料版に加えて、Pro、Pro+、Enterprise の 3 つのメンバーシップ プランがあります。継続月額と継続年間の2つの支払い方法が選択でき、総合コストは継続年間の方が有利です。',
      q3: '継続的な月間サブスクリプションと継続的な年間サブスクリプションの違いは何ですか?',
      a3: '機能に違いはありません。継続的な年間サブスクリプションの総合コストは、継続的な月次サブスクリプションの総合コストよりも低くなります。月次サブスクリプションは月に 1 回請求されますが、年間サブスクリプションは年に 1 回請求されます。',
      q4: '自動更新プランをキャンセルするにはどうすればよいですか?',
      a4: 'バックエンド設定ページに移動し、購読を選択して更新プランをキャンセルできます。',
    },
  },
  upgradeModal: {
    title: 'リマインダー',
    limitAlertStr:
      '無料プランの割り当てを使い果たしました。体験を継続するには、プランをアップグレードすることをお勧めします~!',
    upgradeAlertStr:
      '無料プランの割り当てを使い果たしました。体験を継続するには、プランをアップグレードすることをお勧めします~!',
    btn: '今すぐアップグレード',
    more: 'その他のプラン',
    contactStr: '次回のリセットを待つか、contact@noam.tools までご連絡ください。',
    upload: { free: 'PDF 翻訳の制限に達しました', pro: '今月の PDF 翻訳の制限に達しました' },
    chat: { free: 'Chatpdf の制限を超えました', pro: '今月の Chatpdf の制限に達しました' },
    pageLimit: { free: 'PDF ページの制限を超えました。' },
  },
  buyModal: {
    title: '定期購読プラン',
    freeTrial: '3日間の無料トライアル',
    selectLabel: 'プランを選択してください',
    btn: '無料で試してみる',
    yearLabel: '年',
    monthLabel: '月',
    afterFree: '裁判後',
  },
  privacy: {
    title: 'プライバシーポリシー',
    updateTime: '更新日: 2024 年 3 月 15 日',
    block1:
      '私たち Noam (「Noam」、「当社」、「当社」、または「当社」といいます) は、お客様のプライバシーを尊重し、お客様から取得した情報、またはお客様に関する情報を保護することに全力で取り組んでいます。このプライバシー ポリシーでは、お客様による当社の Web サイト、アプリケーション、およびサービス (総称して「サービス」といいます) の使用による個人情報の収集または使用に関する当社の慣行について説明します。このプライバシー ポリシーは、API など、ビジネス製品の顧客に代わって当社が取り扱うコンテンツには適用されません。このデータの使用には、これらの製品へのアクセスと使用を対象とする顧客契約が適用されます。',
    subTitle1: '当社が収集する個人情報',
    block2: '当社は、お客様に関する以下の個人情報 (「個人情報」) を収集します。',
    block3:
      'お客様から提供される情報: お客様が当社のサービスを使用するため、または当社と通信するためにアカウントを作成する場合、当社は以下のように個人情報を収集します。',
    subBlock1:
      'アカウント情報: お客様が当社のプラットフォームでアカウントを作成すると、お客様の名前、連絡先情報、アカウント資格情報、支払いカード情報、取引履歴などのアカウントに関連する情報 (「アカウント情報」といいます) が収集されます。',
    subBlock2:
      'ユーザー コンテンツ: お客様が当社のサービスをご利用になると、当社はお客様の入力、ファイルのアップロード、または当社に提供されたフィードバック (「コンテンツ」といいます) から個人情報を収集します。',
    subBlock3:
      '通信情報: お客様が当社にメッセージを送信すると、当社はお客様の名前、連絡先情報、および送信したメッセージの内容 (「通信情報」といいます) を収集します。',
    subBlock4:
      'ソーシャル メディア情報: Instagram、Facebook、Medium、Twitter、YouTube、LinkedIn などのソーシャル メディア Web サイトにページがあります。お客様が当社のソーシャル メディア ページを操作すると、当社は、連絡先の詳細など、お客様が当社に提供することを選択した個人情報 (「ソーシャル情報」といいます) を収集します。さらに、当社のソーシャル メディア ページをホストする企業は、当社のソーシャル メディア活動に関する集計情報と分析を提供する場合があります。',
    block4:
      'お客様によるサービスの使用により自動的に取得される個人情報: お客様がサービスにアクセス、使用、または操作する場合、当社は、お客様のアクセス、使用、または操作に関する以下の情報 (「技術情報」) を受け取ります。',
    subBlock5:
      'ログデータ: 当社のサービスを使用するときにブラウザによって自動的に送信される情報。ログ データには、インターネット プロトコル (IP) アドレス、ブラウザの種類と設定、リクエストの日時、当社 Web サイトとのやり取り方法が含まれます。',
    subBlock6:
      '使用状況データ: 当社は、お客様が表示または操作するコンテンツの種類、使用する機能、実行されたアクション、タイムゾーン、国、日時など、お客様のサービスの使用に関する情報を自動的に収集する場合があります。アクセス、ユーザー エージェントとバージョン、コンピューターまたはモバイル デバイスの種類、コンピューターの接続。',
    subBlock7:
      'デバイス情報: デバイスの名前、オペレーティング システム、デバイス識別子、使用しているブラウザが含まれます。収集される情報は、使用しているデバイスの種類とその設定によって異なる場合があります。',
    subBlock8:
      'Cookie: 当社はサービスを運用および管理し、エクスペリエンスを向上させるために Cookie を使用します。 「Cookie」とは、訪問したWebサイトからブラウザに送信される情報です。すべての Cookie を受け入れるか、すべての Cookie を拒否するか、Cookie が提供されたときに通知するようにブラウザを設定して、Cookie を受け入れるかどうかを毎回決定することができます。ただし、Cookie を拒否すると、Web サイトの特定の領域や機能の表示や機能が使用できなくなったり、悪影響を及ぼしたりする可能性があります。 Cookie の詳細については、「Cookie について」をご覧ください。',
    subBlock9:
      '分析: 当社は、ユーザーが当社のサービスをどのように利用しているかを分析し、サービス使用時のエクスペリエンスを向上させるために、Cookie を使用するさまざまなオンライン分析製品を使用する場合があります。',
    subTitle2: '個人情報の使用方法',
    block5: '当社は個人情報を以下の目的で使用する場合があります。',
    subBlock10: 'サービスの提供、管理、保守、分析。',
    subBlock26: '当社のサービスの改善と調査の実施。',
    subBlock11: 'あなたとのコミュニケーション;',
    subBlock12: '新しいプロジェクトやサービスの開発。',
    subBlock13:
      '詐欺、犯罪行為、または当社のサービスの悪用を防止し、当社の IT システム、アーキテクチャ、およびネットワークのセキュリティを保護する。',
    subBlock14: '事業譲渡の実施;',
    subBlock15:
      '法的義務および法的手続きを遵守し、当社の権利、プライバシー、セキュリティ、財産だけでなく、当社の関連会社、お客様、またはその他の第三者の権利、プライバシー、セキュリティ、財産を保護します。',
    block6:
      '要約情報または匿名化情報: 当社は、個人情報を集約または匿名化して、識別目的での再利用を防止し、そのような情報を当社のサービスの有効性の分析、当社のサービスの機能の改善および追加、調査の実施、および同様の目的に使用する場合があります。 。さらに、当社はサービスユーザーの一般的な行動や特徴を分析し、集約された情報を第三者と共有したり、そのような集約された情報を公開したり、一般に利用可能にしたりする場合があります。当社は、サービス、Cookie、および本プライバシー ポリシーに記載されているその他の手段を通じて、集約された情報を収集する場合があります。当社は、匿名化された情報を匿名または匿名化された形式で維持および使用し、法律で要求されない限り、そのような情報を再識別しようとはしません。',
    subTitle3: '個人情報の開示',
    block7:
      '場合によっては、法律で義務付けられている場合を除き、当社はお客様に別途通知することなく、お客様の個人情報を第三者に提供することがあります。',
    subBlock16:
      'サプライヤーおよびサービスプロバイダー: 事業運営上のニーズを満たし、特定のサービスおよび機能を実行するのを支援するために、当社は、ホスティングサービスプロバイダー、クラウドサービスプロバイダー、その他の情報技術サービスプロバイダー、電子メール通信ソフトウェアを含むサプライヤーおよびサービスプロバイダーに個人情報を提供する場合があります。これらのパートナーは、当社に代わって職務を遂行する際に、当社の指示がある場合にのみ個人情報にアクセス、処理、または保存します。',
    subBlock17:
      '事業譲渡：当社が戦略的取引、組織再編、破産、破産引き継ぎ、または別のプロバイダーへのサービス移行（総称して「取引」といいます）に従事する場合、お客様の個人情報およびその他の情報は、取引相手および事業を支援するその他の関係者と共有されることがあります。デューデリジェンス中に取引が行われ、他の資産とともに取引の一部として後継会社または関連会社に譲渡されます。',
    subBlock18:
      '法的要件: 当社は、(1) 法律で義務付けられている場合、または法的義務を遵守するためにそのような措置が必要であると当社が判断する場合、当社のサービスとのやり取り情報を含む、お客様の個人情報を政府当局、同業他社、またはその他の第三者と共有する場合があります。 (2) 当社の権利または財産を保護および擁護するため。 (3) 当社の規約、ポリシー、または法律に違反していると当社が一方的に判断した場合。 (4) 詐欺またはその他の違法行為を検出または防止するため。 (5) 当社の製品、従業員、ユーザー、または公共の安全、セキュリティ、完全性を保護するため。または (vi) 法的責任から防御するため。',
    subTitle4: 'あなたの権利',
    block8:
      '地理的位置に応じて、欧州経済領域、英国、および世界中の個人は、個人情報に関して特定の法的権利を有する場合があります。たとえば、次の権利がある場合があります。',
    subBlock19: '自分の個人情報とその処理方法に関する情報にアクセスします。',
    subBlock20: 'あなたの個人情報を当社の記録から削除してください。',
    subBlock21: '個人情報を修正または更新します。',
    subBlock22: '個人情報を第三者に転送します (データポータビリティ)。',
    subBlock23: '個人情報の処理方法を制限します。',
    subBlock24:
      '同意の撤回 — 当社が処理の法的根拠として同意に依存している場合、いつでも同意を撤回できます。',
    subBlock25: '当社によるあなたの個人情報の処理方法に反対します。',
    subTitle5: '他のウェブサイトへのリンク',
    block9:
      'このサービスには、ソーシャル メディア サービス (「サードパーティ Web サイト」) など、Noam が運営または管理していない他の Web サイトへのリンクが含まれる場合があります。お客様が第三者 Web サイトと共有する情報には、本プライバシー ポリシーではなく、第三者 Web サイトの特定のプライバシー ポリシーおよび利用規約が適用されます。これらのリンクは便宜のために提供されており、これらの Web サイトを承認またはレビューすることを意味するものではありません。サードパーティの Web サイトのプライバシー ポリシーと条件については、その Web サイトに直接お問い合わせください。',
    subTitle6: '情報のセキュリティと保持',
    block10:
      '当社は、オンラインおよびオフラインの個人情報を紛失、誤用、不正アクセス、開示、改ざん、または敵対行為から保護するために、商業上合理的な技術的、管理的、および組織的な対策を実施します。ただし、インターネットや電子メールによる送信では、完全なセキュリティや正確性を保証することはできません。特に、当社に送信される電子メール、または当社から送信される電子メールは安全ではない可能性があります。したがって、サービスまたは電子メールを通じてどのような情報を当社に送信するかを決定する際には、特に注意する必要があります。また、当社は、本サービスにおけるプライバシー設定やセキュリティ対策、または第三者ウェブサイトのセキュリティ設定やセキュリティ対策の妨害については責任を負いません。当社は、お客様にサービスを提供するため、または紛争の解決、セキュリティおよび安全上の理由、または当社の法的義務の遵守など、その他の正当な事業目的のために必要な限り、お客様の個人情報を保管します。当社が個人情報を保持する期間は、情報の量、性質、機密性、不正使用または開示の潜在的なリスク、情報を処理する目的、その他の法的拘束力のある考慮事項など、多くの要因によって異なります。',
    subTitle7: 'プライバシーポリシーの変更',
    block11:
      '当社は、このプライバシー ポリシーを随時更新することがあります。その際、適用される法律で別途義務付けられている場合を除き、このページに更新版を掲載します。',
    subTitle8: 'お問い合わせ方法',
    block12:
      'このプライバシー ポリシーに関して回答のない質問や懸念事項がある場合は、当社のカスタマー サービスまでお問い合わせください。 Eメール：',
  },
  terms: {
    title: '利用規約',
    updateTime: '更新日:2024 年 3 月 15 日',
    block1: 'ノームをご利用いただき誠にありがとうございます。',
    block2:
      'これらの利用規約は、アプリケーション プログラミング インターフェイス、ソフトウェア、ツール、開発者サービス、データ、ドキュメント、Web サイト (総称して「サービス」といいます) を含む、Noam のサービスの使用に適用されます。当社のサービスを使用することにより、お客様はこれらの規約に従うことに同意したものとみなされます。当社のプライバシーポリシーでは、当社が個人情報を収集および使用する方法について説明します。',
    subTitle1: '登録とアクセス',
    block3:
      '18 歳未満の場合、本サービスを使用するには両親または法定後見人の許可を得る必要があります。別の個人または団体に代わってサービスを使用する場合は、その人に代わって本規約に同意する権限を得る必要があります。アカウントに登録するには、正確かつ完全な情報を提供する必要があります。あなたは、自分のアクセス資格情報やアカウントを組織外の人物に提供することはできません。また、自分の資格情報を使用して行われるすべてのアクティビティについては、あなたが責任を負います。',
    subTitle2: '使用要件',
    block4:
      '(a) サービスの使用。お客様は本規約に基づいて本サービスにアクセスすることができ、当社はお客様に本サービスを使用する非独占的権利を付与します。サービスを使用する場合、お客様はこれらの規約および適用されるすべての法律を遵守するものとします。当社および当社の関連会社は、本サービスにおけるすべての権利、権原、および利益を所有します。',
    block5:
      '(b) フィードバック。フィードバック、コメント、アイデア、提案、改善を歓迎します。あなたがそのようなコンテンツを提供した場合、当社はそれを制限なく、またあなたに補償することなく使用することができます。',
    block6:
      '(c) 制限。 (1) 他者の権利を侵害、悪用、侵害する方法で本サービスを使用することはできません。 (2) リバース エンジニアリング、逆コンパイル、逆アセンブル、翻訳、またはサービス モデル、アルゴリズム、およびシステムのソース コードまたは基礎となるコンポーネントを発見しようとする試み (かかる制限が適用法で禁止されている場合を除く)。 (3) Noam と競合するモデルを開発するためにサービスの出力を使用する。 (4) スクレイピング、Web ハーベスティング、Web データ抽出など、API を通じて許可されていない限り、自動化された方法またはプログラミングされた方法を使用してサービスからデータまたは出力を抽出すること。 (5) 人工的に生成されたものではないのにサービスの出力を人工的に生成されたものとして表現すること、または当社の利用ポリシーに違反すること。 (6) 当社の事前の同意なしに API キーを購入、販売、または譲渡すること。または (7) 13 歳未満、またはデジタル同意が適用される年齢未満の子供の個人情報を当社に提供する場合。お客様は、当社の文書に記載されているレート制限およびその他の要件に従うものとします。',
    block7:
      '(d) サードパーティのサービス。本サービスに関連するサードパーティのソフトウェア、サービス、またはその他の製品には独自の条件が適用され、当社はサードパーティ製品に対して責任を負いません。',
    subTitle3: 'コンテンツ',
    block8:
      '(a) お客様のコンテンツ。お客様は、サービスに入力 (「入力」) を提供し、入力 (総称して「コンテンツ」と呼びます) に基づいて生成および返される出力 (「出力」) を受け取ることができます。当事者間では、適用法で認められる範囲で、お客様はすべての入力を所有します。 Noam は、サービスを提供および維持し、適用される法律を遵守し、当社のポリシーを執行するためにコンテンツを使用することがあります。あなたは、コンテンツが適用される法律または本規約に違反していないことを確認することを含め、コンテンツに対して責任を負います。',
    block9:
      '(b) 精度。人工知能と機械学習は、急速に進化している研究分野です。当社はサービスをより正確、信頼性、安全性、有益なものにするために継続的に改善に努めています。機械学習の確率的な性質を考慮すると、当社のサービスを使用すると、場合によっては不正確な出力が発生する可能性があり、実際の個人、場所、事実を正確に反映していない可能性があります。出力を手動で確認するなど、ユースケースに基づいて出力の精度を適切に評価する必要があります。',
    subTitle4: '料金と支払い',
    block10:
      '(a) 料金と請求。お客様は、該当する価格設定ページの価格設定および条件に従って、または当社との間で書面で合意されたとおりに、すべての料金（「料金」）を支払うものとします。当社は、請求書を発行したり支払いを受領した場合でも、価格設定の誤りや間違いを修正する権利を留保します。お客様は、有効かつ承認された支払い方法を含む、完全かつ正確な請求情報を提供するものとします。当社は、合意に従って定期的にお客様のお支払い方法に請求を行いますが、請求日は合理的に変更される場合があります。お客様は、Noam、その関連会社、およびサードパーティの支払い処理業者がお客様の支払い方法に請求することを承認します。お支払いが失敗した場合、当社はお客様に書面による通知を行い、支払いが受領されるまでサービスへのアクセスを一時停止することがあります。本契約に別段の定めがある場合を除き、支払いは返金できません。',
    block11:
      '(b) 税金。特に明記されていない限り、料金には連邦税、州税、地方税、外国税、関税、および同様の賦課額（「税金」）は含まれません。お客様は、当社がお客様に請求する当社の純利益に基づく税金を除き、ご購入に関連するすべての税金に対して責任を負います。お客様は、かかる税金を速やかに支払い、法的に有効なプライバシーに関する声明を当社に提供し、かかるデータの取り扱いに必要な同意を取得することに同意し、かかるデータを適用法に従って取り扱うことを当社に保証します。',
    block12:
      '(c) 価格の変更。当社は、お客様のアカウントおよび/または当社ウェブサイトを通じてお客様に通知することにより、価格を変更する場合があります。価格の値上げは投稿後すぐに有効になります。価格変更は、変更発効日以降にアカウントの料金に適用されます。',
    block13:
      '(d) 紛争および支払い遅延。手数料または税金について異議を申し立てたい場合は、請求日から 30 日以内に contact@noam.tools までご連絡ください。',
    block14:
      '(e) 無料の使用。無料クレジットを取得するために複数のアカウントを作成することはできません。お客様が無料クレジットを誠実に使用していないと当社が判断した場合、当社はお客様に標準料金を請求するか、サービスへのアクセスの提供を停止することがあります。',
    subTitle5: '機密保持、セキュリティ、データ保護',
    block15:
      '(a) 機密保持。あなたは Noam の機密情報にアクセスできる可能性があります。お客様は、本規約で許可されている範囲でのみ、サービスを使用する目的で機密情報を使用することができます。お客様は機密情報を第三者に開示してはならず、少なくとも合理的な注意を払って、自分の同様の機密情報を保護するのと同等の方法で機密情報を保護するものとします。機密情報とは、Noam によって機密として指定された非公開情報、またはソフトウェア、仕様、およびその他の非公開のビジネス情報を含む、状況下で合理的に機密として扱われる必要がある非公開情報です。機密情報には、次の情報は含まれません。(1) お客様側の過失なしに一般に公開される。 (2) 本規約に基づいて受け取る前に、機密保持の義務を負わずに所有していたもの。 (3) 機密保持義務を負うことなく、第三者によって合法的に開示されます。または (4) 機密情報を使用せずにお客様が独自に開発したもの。法律、裁判所、またはその他の政府命令によって要求された場合、お客様は機密情報を開示することができますが、合理的な方法で事前に Noam に書面による通知を提供し、可能な限り開示の範囲を制限するための合理的な努力をしなければなりません。開示請求に反対する場合。',
    block16:
      '(b) セキュリティ。お客様は、サービスへのアクセスと使用を保護するために、合理的かつ適切な措置を講じる必要があります。本サービスの使用に関連する脆弱性または違反を発見した場合は、直ちに Noam に連絡し、脆弱性または違反の詳細を提供する必要があります。',
    block17:
      '((c) 個人データの処理。個人データを処理するためにサービスを使用する場合、法的に十分なプライバシーに関する声明を提供し、かかるデータの処理に必要な同意を得る必要があり、適用法に従ってかかるデータを処理することを当社に対して保証する必要があります。 。',
    subTitle6: '期間と終了',
    block18:
      '(a) 終了。サスペンション。これらの規約は、サービスの最初の使用から有効となり、終了するまで有効です。お客様は、理由を問わず、サービスおよびコンテンツの使用を中止することにより、いつでも本規約を終了することができます。当社は、理由の如何を問わず、事前の通知をもって本規約を終了することがあります。当社は、お客様が第 2 条 (使用要件)、第 5 条 (機密保持、セキュリティ、およびデータ保護)、第 8 条 (紛争解決)、または第 9 条 (一般規約) に重大な違反をした場合、または当社の当社の制御の範囲を超えたサードパーティのテクノロジープロバイダーとの関係変更、または法律や政府の要請の遵守。お客様がこれらの規約を遵守しない場合、またはお客様の使用が当社または第三者にセキュリティ上のリスクをもたらす場合、またはお客様の使用が不正であると当社が疑う場合、または当社または第三者が責任を負う可能性がある場合、当社はお客様のサービスへのアクセスを一時停止することがあります。 。',
    block19:
      '(b) 終了の影響。終了後、お客様はサービスの使用を中止し、当社の指示に従って機密情報を速やかに返却または破棄するものとします。性質上、終了または満了後も存続する必要がある本規約の条項 (第 3 条および第 5 条から第 9 条を含みますがこれらに限定されません) は存続するものとします。',
    subTitle7: '補償;免責事項;責任の制限',
    block20:
      '(a) 補償。お客様は、コンテンツ、サービスを含むサービスの使用に起因または関連して生じるあらゆる請求、損失、および費用 (弁護士費用を含む) から、当社、当社の関連会社、および当社職員を弁護し、補償し、免責するものとします。本サービスに関連する製品またはサービスの使用、およびこれらの規約または適用される法律の違反。',
    block21:
      '(b) 免責事項。本サービスは「現状のまま」提供されます。法律で認められる範囲において、当社、当社の関連会社およびライセンサーは、本サービスに関していかなる種類の表明または保証も行わず、商品性、特定目的への適合性、満足のいく品質、非侵害の保証を含むがこれらに限定されない保証も行いません。 、静かな楽しみ、および取引または取引の利用の過程で生じる保証。当社は、サービスが中断されないこと、正確であること、エラーがないこと、またはコンテンツが安全であること、紛失または改変されていないことを保証しません。',
    block22:
      '(c) 責任の制限。当社、当社の関連会社、またはライセンサーは、利益の損失、評判の損失、使用の損失、データの損失、またはその他の無形の損失を含む、間接的、偶発的、特別、結果的、または懲罰的損害に対して、たとえ次のような場合でも責任を負いません。私たちはそのような損害が発生する可能性について報告を受けています。本規約に基づき、当社の責任総額は、請求に先立つ 12 か月間にお客様がサービスに支払った料金または 100 米ドル ($100) のいずれか大きい方を超えません。このセクションの制限は、適用される法律で許可される最大限の範囲に適用されます。',
    subTitle8: '準拠法および管轄',
    block23:
      '本規約およびお客様によるサービスの使用は、米国の法の抵触に関する規則を除き、米国の法律に準拠します。アプリケーションの使用には、他の地域法、州法、国内法、または国際法が適用される場合もあります。本規約に関連するあらゆる法的訴訟または手続きは、もっぱら米国の裁判所で提起され、お客様はかかる裁判所の対人管轄権に従うことに同意するものとします。',
    subTitle9: '一般規約',
    block24:
      '(a) 当事者の関係。 Noam とお客様は独立した契約者であり、いずれの当事者も、相手方の事前の書面による同意がない限り、相手方を代表したり拘束したり、相手方に対する義務を負ったりする権限はありません。',
    block25:
      '(b) ブランドの使用。当社の事前の書面による同意がない限り、Noam またはその関連会社の名前、ロゴ、または商標を使用することはできません。',
    subTitle10: '可分性',
    block26:
      '本規約のいずれかの条項が無効、違法、または法的強制力がないと判断された場合でも、残りの条項は引き続き完全に有効となります。',
  },
  contact: {
    title: 'お問い合わせ',
    block1:
      'カスタマイズについて、またはご質問やご提案がある場合は、contact@noam.tools までご連絡ください。すぐに対応させていただきます。',
    block2: 'Google Apps Extension ストアで ★★★★★ の評価をしてサポートしてください。',
    block3: 'この拡張機能を共有して他の人を助けてください:',
  },
  pdf: {
    toast1: 'PDF を読み込んでいます。後でもう一度お試しください。',
    toast2: 'PDF コンテンツが多すぎるため、当面はダイアログをサポートしません',
    toast3: 'ダウンロード中です。ダウンロードが完了してからもう一度お試しください。',
    shareMsg1: '共有リンクの生成が成功しました',
    shareMsg2: 'コンテンツ更新を正常に共有する',
    shareMsg3:
      '共有リンクの生成に失敗しました。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    shareMsg4:
      '共有コンテンツの更新に失敗しました。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    shareBtnText1: '共有リンクの生成',
    shareBtnText2: '更新された共有コンテンツ',
    uploading: 'アップロード中',
    uploadingMsg: 'アップロードには時間がかかりますので、しばらくお待ちください。',
    cancelUpload: 'キャンセル',
    uploadErr:
      'ファイルのアップロードに失敗しました。後でもう一度試すか、カスタマーサービスにお問い合わせください',
    uploadMsg1: 'ここにファイルをドラッグ＆ドロップしてください',
    uploadMsg2: 'サポートされているファイルの種類: PDF |  最大ファイルサイズ: 100MB',
    uploadMsg3:
      'ここにアップロードするファイルをクリックして選択するか、ドラッグ アンド ドロップします。',
    searchPlaceholder: '検索',
    collectionEmpty: 'コレクションにコンテンツがありません~',
    kw: 'ステープル',
    video: 'ビデオ',
    link: 'リンク',
    dataLoading: 'データのロード...',
    freeBannerTips: '無料PDF翻訳の終了',
    vipBannerTips: '今月の PDF 翻訳の制限に達しました。',
    freeBannerBtn: 'アップグレードしてさらに楽しむ',
    vipBannerBtn: 'アップグレードしてさらに楽しむ',
    dialogTitle: '注意',
    dialogContent: '削除してもよろしいですか?',
    okBtn: '消去',
    cancelBtn: 'キャンセル',
    unLoginToast: 'この機能を使用するにはログインしてください',
    parseErrTips: 'ファイルを解析できません',
    new: '新しいファイル',
    parseErrDialogContent:
      '現在の PDF リンクは直接翻訳をサポートしていません。翻訳後の元のリンク PDF をダウンロードしてください。',
    okBtn2: '認識する',
    notPdf: 'PDFファイルのみ',
    uploadingMsg1: 'ファイルのアップロード中です。後でもう一度試してください。',
    uploadingMsg2: '現在変換中のファイルがあります。後でもう一度試してください。',
    uploadingMsg3:
      '変換進行状況の問い合わせに失敗しました。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    uploadingMsg4:
      'Noam はローカル ファイルに直接アクセスできません。翻訳用の PDF を手動でアップロードしてください。',
    uploadingMsg5:
      'ファイルの URL を取得できませんでした。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    fileMsg: '現在のファイル容量:',
    fileMsg2: '100M 未満のファイルをアップロードしてください',
    fileErrMsg:
      'ファイルの読み取りに失敗しました。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    parseFileToast:
      'ファイルの解析に失敗しました。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    noId: 'ファイルIDが存在しません',
    autoDetect: '自動検出',
    hasTransTask: '翻訳中のタスクがあります。お待ちください。',
    transSucc: '翻訳は成功しました！',
    entry: 'PDFリスト',
    listError:
      'PDF リストの読み込みに失敗しました。後でもう一度試すか、カスタマー サービスにお問い合わせください。',
    collectionTab: 'メモ',
    pdfLoading: '解析中...',
    installTips:
      '小さなプラグインをインストールすると、より多くの PDF ファイルを翻訳できるほか、スムーズな Web ページの翻訳も体験できます。',
    webTrans: '完全な相互参照',
    hoverTrans: 'ホバー段落翻訳',
    selectTrans: '単語に下線を引いて翻訳する',
    installText: '日常の翻訳ニーズすべてにワンストップ ショップで対応',
    start: '無料体験を始めましょう',
    shareTips: 'PDF 翻訳が完了しました。友達と共有してください。',
    download: {
      loading: '保存しました...',
      btn: '今すぐ保存',
      trans: '翻訳のみ',
      both: 'バイリンガリズム',
      download: 'ダウンロード',
      msg1: '現在のドキュメントの翻訳の一部は原文より長く、ダウンロード後にテキストが重複する可能性があるため、Web ページで読むことをお勧めします。',
      msg2: '1、コンテンツは完全に翻訳されていません。完全に翻訳してからダウンロードしてください。',
      msg3: '2、翻訳の文書部分が長すぎるため、テキストの重複を避けるためにWebページで読むことができます。',
      msg4: '現在翻訳中のタスクがあります。翻訳が完了してからもう一度お試しください。',
      fileName: 'ファイル名：',
      fileType: 'ダウンロードファイルの種類:',
      transContinue: '翻訳を続ける',
      save: 'ローカルに保存',
      downloadMsg1: 'PDF 翻訳の進捗状況:',
      downloadMsg2: '予測される要件',
      minute: '分...',
      downloadSucc: 'PDF のダウンロードが完了しました。今すぐ保存をクリックしてください。',
    },
    guide: {
      title1: 'バイリンガル翻訳',
      title2: 'チャットPDF',
      text1: 'バイリンガル読書',
      text2: '対訳読みなので、原文と訳文を比較しながら読むことができ、読書効率が向上します。',
      text3: 'チャットPDF',
      text4: 'PDFの内容をもとにAIが分析・要約し、質問に回答します',
      text5: '精度と効率',
      text6: 'AI を活用した PDf により、より正確かつ効率的な翻訳を実現',
      placeholder: 'PDFリンクに記入してください',
      start: '翻訳を開始する',
      toast: '.pdf で終わる有効なオンライン PDF リンクを入力してください',
    },
    original: 'オリジナル',
    parallel: '平行',
    trans: '翻訳',
    initial: 'PDFを翻訳',
    thumbnail: 'カタログ',
    downloadBtn: 'ダウンロード',
    shareBtn: '共有',
    uploadBtn: 'アップロード',
    installText2: '最初にインストールしてください',
    chatPdf: {
      clearSucc: '無事クリアしました',
      clear: 'クリア',
      clearContent:
        'クリアすると新しい会話が作成され、会話の内容は保存されませんが、クリアしてもよろしいですか？',
      clearDialogTitle: '注意',
      cancel: 'キャンセル',
      chatTitle: 'Noam へようこそ。PDF の概要が完成しました。',
      summaryTitle: '興味のある質問:',
      placeholder: 'PDF に関する質問があれば入力してください',
      copy: 'コピー',
      copySucc: 'コピー成功',
      historyTitle: '歴史',
      expand: '崩壊',
      noDataDesc: '履歴はありません〜',
      noMore: 'もうない。',
      Search: '検索',
      delete: '消去',
      loading: '読み込み中...',
      deleteTitle: 'ヒント',
      deleteContent: 'この PDF は削除すると復元できなくなります。削除しますか?',
      deleteOk: '消去',
      deleteCancel: 'キャンセル',
      deleteSuccess: '正常に削除されました',
      listError:
        '読書リストの読み込みに失敗しました。後でもう一度試すか、カスタマーサポートにお問い合わせください',
    },
    shareModal: {
      shareSetting: 'コンテンツの共有設定',
      shareText: '共有',
      text1: '共有設定',
      text2: '共有リンクの生成',
      range: '範囲：',
      custom: 'カスタマイズ:',
      page: 'ページ',
      sourceLang: 'ソース：',
      target: '対象:',
      errMsg: '現在の共有範囲の内容は完全に翻訳されていないため、共有する前に翻訳してください。',
      pageNuErr: '正しいページ番号の値を入力してください',
      trans: '翻訳する',
      cancel: 'キャンセル',
      update: '更新された共有リンク',
      gen: '共有',
      shareTips: '翻訳された PDF リンクを友達と共有して読んでください。',
      copySucc: 'リンクのコピーに成功しました',
      copy: 'コピー',
      settings: '共有設定:',
      modify: '修正する',
      translateTips:
        'ヒント: 翻訳に時間がかかる場合は、ポップアップ ウィンドウを閉じて、翻訳終了後に共有できます。',
      translating: 'PDF 翻訳...',
      remain: '残り',
      remain1: '予想される要件の合計',
      totalPage: '合計1ページ',
      search: '検索',
      notSupport: 'この言語は現在サポートされていません',
    },
    toolbar: { Adjust: '調整する', autoFit: '自動フィット' },
    memoTotalCount: '合計1コンテンツ',
    dragTipModal: {
      press: 'プレス',
      black: '空間',
      leftKey: '左ボタン',
      tips: 'ドラッグして表示します。',
      ok: 'わかった',
    },
  },
  locale: {
    zh: '中国語',
    en: '英語',
    es: 'スペイン語',
    ru: 'ロシア',
    pt: 'ポルトガル語 (ブラジル)',
    id: 'インドネシア語',
    cs: 'チェコ語',
    de: 'ドイツ人',
    fr: 'フランス語',
    it: 'イタリアの',
    nl: 'オランダの',
    pl: '研磨',
    vi: 'ベトナム語',
    tr: 'トルコ語',
    uk: 'ウクライナ語',
    zhHant: '中国の伝統的な',
    ja: '日本語',
    ko: '韓国語',
    ar: 'アラビア語',
    fa: 'ペルシア語',
    th: 'タイ語',
    hi: 'ヒンディー語',
    noSearchLang: 'この言語はサポートされていません',
    placeholder: '検索',
    af: 'アフリカーンス語',
    am: 'アムハラ語',
    as: 'アッサム語',
    az: 'アゼルバイジャン語',
    ba: 'バシキール語',
    bg: 'ブルガリア語',
    bn: 'バングラ語',
    bo: 'チベット語',
    bs: 'ボスニア語',
    ca: 'カタルーニャ語',
    cy: 'ウェールズ語',
    da: 'デンマーク語',
    dsb: '下ソルブ語',
    dv: 'ディベヒ',
    el: 'ギリシャ語',
    et: 'エストニア語',
    eu: 'バスク語',
    fi: 'フィンランド語',
    fil: 'フィリピン人',
    fj: 'フィジー語',
    frCA: 'フランス語 (カナダ)',
    ga: 'アイルランド人',
    gl: 'ガリシア語',
    gom: 'コンカニ',
    gu: 'グジャラート語',
    ha: 'ハウサ語',
    he: 'ヘブライ語',
    hr: 'クロアチア語',
    hsb: '上ソルブ語',
    ht: 'ハイチ クレオール',
    hu: 'ハンガリー語',
    hy: 'アルメニア語',
    ig: 'イボ語',
    ikt: 'イヌイナクトゥン',
    is: 'アイスランド語',
    iu: 'イヌクティトゥット島',
    iuLatn: 'イヌクティトゥット語 (ラテン語)',
    ka: 'ジョージア語',
    kk: 'カザフ語',
    km: 'クメール語',
    kmr: 'クルド人（北部）',
    kn: 'カンナダ語',
    ku: 'クルド人 (中部)',
    ky: 'キルギス',
    ln: 'リンガラ',
    lo: 'ラオ語',
    lt: 'リトアニア語',
    lug: 'ガンダ',
    lv: 'ラトビア語',
    lzh: '中国語（文学）',
    mai: 'マイティリ語',
    mg: 'マダガスカル',
    mi: 'マオリ語',
    mk: 'マケドニアの',
    ml: 'マラヤーラム語',
    mnCyrl: 'モンゴル語 (キリル文字)',
    mnMong: 'モンゴル語 (繁体字)',
    mr: 'マラーティー語',
    ms: 'マレー語',
    mt: 'マルタ語',
    mww: 'モンドー',
    my: 'ミャンマー（ビルマ語）',
    nb: 'ノルウェー語',
    ne: 'ネパール語',
    nso: 'セソト サ レボア',
    nya: 'ニャンジャ',
    or: 'オディア',
    otq: 'ケレタロ・オトミ',
    pa: 'パンジャブ語',
    prs: 'ダリ',
    ps: 'パシュトゥー語',
    ptPT: 'ポルトガル語 (ポルトガル)',
    ro: 'ルーマニア語',
    run: 'ルンディ',
    rw: 'ルワンダ',
    sd: 'シンド語',
    si: 'シンハラ語',
    sk: 'スロバキア語',
    sl: 'スロベニア語',
    sm: 'サモア語',
    sn: 'ショナ',
    so: 'ソマリ語',
    sq: 'アルバニア人',
    srCyrl: 'セルビア語 (キリル文字)',
    srLatn: 'セルビア語 (ラテン語)',
    st: 'セソト',
    sv: 'スウェーデンの',
    sw: 'スワヒリ語',
    ta: 'タミル語',
    te: 'テルグ語',
    ti: 'ティグリニャ',
    tk: 'トルクメン語',
    tlhLatn: 'クリンゴン語 (ラテン語)',
    tlhPiqd: 'クリンゴン (pIqaD)',
    tn: 'セツワナ',
    to: 'トンガ語',
    tt: 'タタール語',
    ty: 'タヒチ語',
    ug: 'ウイグル語',
    ur: 'ウルドゥー語',
    uz: 'ウズベク語 (ラテン語)',
    xh: 'コサ語',
    yo: 'ヨルバ語',
    yua: 'ユカテック マヤ',
    yue: '広東語 (繁体字)',
    zhHans: '中国語（簡体字',
    zu: 'ズールー語',
    auto: '自動検出',
  },
  stripe: {
    contact: 'ご質問がございましたら、contact@noam.tools までお問い合わせください。',
    fail: {
      title: '支払いに失敗しました',
      text1: '残念ながら、お支払いリクエストを処理できません。',
      text2: 'インターネット接続を確認して、もう一度試してください。',
      btn: 'リトライ',
    },
    succ: {
      title: 'おめでとう',
      text1: 'ノームさん、ようこそ。',
      text2: '一緒に始めましょう！',
      btn: '魔法をかけてみましょう',
    },
  },
  welfare: {
    newUser: {
      label: '新規ユーザーのメリット:',
      joinBtn: '今すぐ参加',
      text: '100スポットのみご利用いただけます！今すぐ手に入れましょう！',
      remain: '残り 30 個のみ',
    },
  },
  help: {
    Fixedplugin: 'プラグインを修正',
    Howtouse: '使い方',
    try: '試してみる',
    FreeTrialBenefits: '無料トライアルの特典',
    pin: 'アクセスしやすいようにピン Noam ～～',
    Video: 'ビデオ',
    ParallelTranslation: '対訳翻訳',
    WebSummary: 'ウェブサマリー',
    ChatPDF: 'チャットPDF',
    AquickOverviewOfNoam: 'ノームの概要',
    WordSelectionTranslation: '単語選択翻訳',
    HoverTranslation: 'ホバー翻訳',
    ImageTranslation: '画像翻訳',
    InstantTranslation: '即時翻訳',
    VideoTranslation: 'ビデオ翻訳',
    benefits: '特典をご用意しました！',
    Free: '無料',
    Membership: 'メンバーシップ',
    Translation: '翻訳',
    PDFparallelTranslation: 'PDF対訳',
    ChatPDF2: 'チャットPDF',
    Summary: 'まとめ',
    VideoSummary: 'ビデオの概要',
    FavoritesManagement: 'お気に入りの管理',
    Focusing: 'フォーカシング',
    dayFree: '3日間の無料トライアル',
    planIsNotExist: '計画は存在しません',
    TryItForFree: '無料で試してみる',
    startYoutube: 'まずはYouTubeから始めましょう~',
    startWiki: 'ウィキメディアから始めましょう~',
    tryNow: '今すぐ試してみてください~',
    GoWithYouTube: 'YouTube を利用する',
    GoWithWikipedia: 'ウィキペディアを参照してください',
    NoamHelp: 'Noam が翻訳と要約をお手伝いします',
    SkipAllSteps: 'すべての手順をスキップ',
    next: '次',
    done: '終わり',
  },
  trialModal: {
    title: 'ウェルカムギフト',
    trialText: '7日間のトライアル',
    desc1: 'トライアル終了後は年会費180ドルがかかります',
    desc2: 'いつでもキャンセル',
    btn: '今すぐ始めましょう（わずか $0.99）',
    installBtn: '今すぐインストールしてギフトを受け取りましょう',
    web: '対訳翻訳',
    select: '単語選択翻訳',
    hover: 'ホバー翻訳',
    image: '画像翻訳',
    realtime: '即時翻訳',
    video: 'ビデオ翻訳',
    pdf: 'PDF対訳',
    chat: 'チャットPDF',
    webSummary: 'ウェブサマリー',
    videoSummary: 'ビデオの概要',
    memo: 'お気に入りの管理',
    focus: 'フォーカシング',
  },
  privacyCollectionPanel: {
    title: 'このウェブサイトは Cookie を使用しています',
    text: 'エクスペリエンスを向上させるために、コンテンツとサービスをパーソナライズするために Cookie を使用したいと考えています。',
    acceptBtn: 'すべてを受け入れる',
  },
  installTips: {
    text: '「Noam Browser Plugin」をインストールすると、さらに多くの機能が利用できるようになります。 Web 相互参照、\n    Web動画まとめなど！',
    btn: 'やってみよう',
  },
}

export default TRANSLATION
