class EnvHelper {
  get extensionPrefix() {
    return 'noam_webapp'
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get productName() {
    return 'Noam'
  }

  get host() {
    return process.env.REACT_APP_HOST
  }

  get webHost() {
    return process.env.REACT_APP_WEB_HOST
  }

  get apiHost() {
    return this.webHost
  }

  get apiBasePath() {
    return '/hy_api'
  }
}

export const envHelper = new EnvHelper()
