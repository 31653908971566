import Layout from '@/components/Layout/Layout'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import classNames from 'classnames'
import style from './LoginPage.module.less'
import { useTranslationWithType } from '@/i18n/i18n'
import { useEffect, useState } from 'react'
import { storageHelper } from '@/helpers/storageHelper'
import { PAGE_LINKS } from '@/helpers/commonConsts'

export const DEFAULT_LOGIN_URL = `${envHelper.apiHost}/foreign${envHelper.apiBasePath}/google/oauth2/link`
const HELP_PAGE = `${envHelper.apiHost}${PAGE_LINKS.help}`
export const getLoginParams = () => {
  try {
    let {
      utm,
      referer,
      kword,
      inviteCode,
      bdVid,
      bdUrl,
      qhclickid,
      installSource,
      from,
      redirectUrl,
    } = commonUtils.getUrlParams()

    utm = utm || storageHelper.get(['utm']).utm || ''
    referer = referer || storageHelper.get(['referer']).referer || ''
    kword = kword || storageHelper.get(['kword']).kword || ''
    inviteCode = inviteCode || storageHelper.get(['inviteCode']).inviteCode
    bdVid = bdVid || storageHelper.get(['bdVid']).bdVid || ''
    bdUrl = bdUrl || storageHelper.get(['bdUrl']).bdUrl || ''
    qhclickid = qhclickid || storageHelper.get(['qhclickid']).qhclickid || ''
    installSource = installSource || storageHelper.get(['installSource']).installSource || ''
    let refererChannel = ''
    if (referer) {
      refererChannel = encodeURIComponent(new URL(referer).hostname)
    }
    return {
      utm,
      kword,
      refererChannel,
      refererChannelDetail: encodeURIComponent(referer),
      /** 增加浏览器类型 */
      browserType: commonUtils.getBrowserInfo().browser,
      /** 增加百度ocpc投放参数 */
      bdVid,
      bdUrl: encodeURIComponent(bdUrl),
      /** 增加 360 ocpc 投放参数 */
      qhclickid,
      registerChannel: utm || installSource,
      ...(inviteCode ? { inviteCode } : {}),
      redirectUrl: encodeURIComponent(
        from === 'extension_install' ? `${HELP_PAGE}` : redirectUrl || ''
      ),
    }
  } catch (error) {
    return {
      utm: '',
      kword: '',
      refererChannel: '',
      refererChannelDetail: '',
      redirectUrl: '',
    }
  }
}
const LoginPage = () => {
  const [loginUrl, setLoginUrl] = useState(DEFAULT_LOGIN_URL)
  useEffect(() => {
    setLoginUrl(
      commonUtils.genApiUrl(DEFAULT_LOGIN_URL, commonUtils.underlize(getLoginParams() || {}))
    )
  }, [])
  const { t } = useTranslationWithType()
  return (
    <Layout hideFooter rootClass={classNames(style.loginPage)}>
      <img
        className={classNames(style.bg)}
        src={commonUtils.getStaticFile('login/bg__login.png')}
      />
      <section className={classNames(style.content, 'flex-col')}>
        <img className={classNames(style.logo)} src={commonUtils.getStaticFile('logo.png')} />
        <p className={classNames(style.title)}>{t('login.title')}</p>
        <a className={classNames(style.loginBtn, 'flex-row')} href={loginUrl}>
          <img
            className={classNames(style.icon)}
            src={commonUtils.getStaticFile('login/icon__google.png')}
          />
          {t('login.btn')}
        </a>
        <p className={classNames(style.smallText)}>
          {t('login.tips')}
          <a href='/terms' target='_black'>
            {t('login.terms')}
          </a>
          {t('login.and')}
          <a href='/policy' target='_black'>
            {t('login.policy')}
          </a>
        </p>
      </section>
    </Layout>
  )
}

export default LoginPage
