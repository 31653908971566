import { observer } from 'mobx-react'
import { FC, useEffect } from 'react'
import UpgradeModal from '../UpgradeModal/UpgradeModal'
import { userService } from '@/services/userService'
// import { PRO_BENEFITS, PRO_V_BENEFITS } from '@/services/userService/const'
import { useTranslationWithType } from '@/i18n/i18n'

export type PdfUpgradeModalType = 'upload' | 'chat' | 'pageLimit'
interface PdfUpgradeModalProps {
  open: boolean
  type: PdfUpgradeModalType
  onClose: () => void
}

const PdfUpgradeModal: FC<PdfUpgradeModalProps> = observer(
  ({ open, type, onClose }: PdfUpgradeModalProps) => {
    const vipVersion = userService.vipVersion
    const { t } = useTranslationWithType()
    const getText = () => {
      switch (type) {
        case 'upload':
          if (vipVersion === 'free') {
            return t('upgradeModal.upload.free')
          }
          return t('upgradeModal.upload.pro')
        case 'chat':
          if (vipVersion === 'free') {
            return t('upgradeModal.chat.free')
          }
          return t('upgradeModal.chat.pro')
        case 'pageLimit':
          return t('upgradeModal.pageLimit.free')
        default:
          return ''
      }
    }
    const limitAlertStr = getText()
    useEffect(() => {
      if (!open) {
        return
      }
      userService.trackEvent({
        eventType: `trigger_pdfLimit_${type}`,
      })
    }, [open])
    return (
      <UpgradeModal
        open={open}
        limitAlertStr={limitAlertStr}
        triggerType={type}
        // upgradeAlertStr={upgradeAlertStr}
        onCancel={() => {
          onClose()
        }}
        onPaid={() => {
          userService.updateUserInfo()
          onClose()
        }}
      />
    )
  }
)

export default PdfUpgradeModal
