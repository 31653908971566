import { storageHelper } from '@/helpers/storageHelper'
import { _i18n } from '@/i18n/i18nService'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import style from './PageHeader.module.less'
import { commonUtils } from '@/helpers/commonUtils'
import { userService } from '@/services/userService'
import { toastController } from '@/controllers/toastController'
import { routerService } from '@/services/routerService'
import { PAGE_LINKS, TranslateServiceConsts } from '@/helpers/commonConsts'
import { useTranslationWithType } from '@/i18n/i18n'
import { LanguageItem, LanguagePopover } from '../LanguagePopover/LanguagePopover'
import { DownOutlined } from '@ant-design/icons'
import { envHelper } from '@/helpers/envHelper'

/** PageHeader 组件的 Props */
export type PageHeaderProps = {
  fixedTop?: boolean
}
const en = {
  key: 'en',
  name: 'English',
  nativeName: 'English',
  chineseName: '',
}
const zh = {
  key: 'zh',
  name: '简体中文',
  nativeName: '简体中文',
  chineseName: '',
}

const map = commonUtils.jsonClone(TranslateServiceConsts.I18N_LOCALES)
const zhHantIndex = TranslateServiceConsts.I18N_LOCALES.findIndex((l) => l.key === 'zh-Hant')
map.splice(zhHantIndex, 0, zh)

const LANGS = [en, ...map]

const PageHeader: FC<PageHeaderProps> = observer(({ fixedTop }: PageHeaderProps) => {
  const { t, i18n } = useTranslationWithType()
  const [lang, setLang] = useState(_i18n.uiLang)
  const [allLocalList, setAllLocaleList] = useState<LanguageItem[]>([])
  const [loginUrl, setLoginUrl] = useState(PAGE_LINKS.login)
  useEffect(() => {
    setAllLocaleList(
      LANGS.map((l) => {
        const key = 'locale.' + (l.key === 'zh-Hant' ? 'zhHant' : l.key)
        return {
          value: l.key,
          mainName: l.nativeName,
          secondaryName: `${t(key as any)} `,
        }
      })
    )
  }, [lang])
  useLayoutEffect(() => {
    setLoginUrl(
      `${envHelper.apiHost}${PAGE_LINKS.login}?redirectUrl=${encodeURIComponent(
        commonUtils.genUrl(
          window.location.href,
          {
            from: 'login_succ',
          },
          false
        )
      )}`
    )
  })
  return (
    <div
      className={classNames(style.pageHeader, 'noam-page-header', 'flex-row', 'align-side', {
        [style.fixedTop]: fixedTop,
      })}
    >
      <div className={classNames(style.left, 'flex-row')}>
        <img
          className={classNames(style.logo)}
          src={commonUtils.getStaticFile('logo.png')}
          onClick={() => {
            routerService.navigateTo('home')
          }}
        />
        {allLocalList.length > 0 && (
          <>
            <LanguagePopover
              placement='bottomLeft'
              placeholder={t('pdf.shareModal.search')}
              emptyPlaceholder={t('pdf.shareModal.notSupport')}
              showSecondaryName={true}
              languageList={allLocalList}
              value={lang}
              onLanguageChange={(value) => {
                storageHelper.set({ uiLang: value })
                i18n.changeLanguage(value)
                setLang(value)
              }}
              onLoadChildren={(languageItem) => {
                return (
                  <div className={classNames(style.languageItem)}>
                    <p className={classNames(style.name)} title={languageItem.mainName}>
                      {languageItem.mainName}
                    </p>
                    <DownOutlined className={classNames(style.arrow)} />
                  </div>
                )
              }}
            ></LanguagePopover>
          </>
        )}
      </div>
      <div className={classNames(style.right, 'flex-row', 'align-right')}>
        {/* <a className={classNames(style.link)} href='/help'>
          {t('pageHeader.help')}
        </a> */}
        <a className={classNames(style.link)} href='/contact'>
          {t('pageHeader.contact')}
        </a>
        <a className={classNames(style.link)} href='/pricing'>
          {t('pageHeader.pricing')}
        </a>
        <a className={classNames(style.pdfBtn)} href='/webapp/pdf' target='_black'>
          <img
            className={classNames(style.icon)}
            src={commonUtils.getStaticFile('pricing/icon__chat-btn.png')}
          />
          ChatPDF
        </a>
        {!userService.isLogin && (
          <a className={classNames(style.loginBtn)} href={loginUrl}>
            {t('pageHeader.signUp')}
          </a>
        )}
        {userService.isLogin && <UserInfoBox></UserInfoBox>}
      </div>
    </div>
  )
})

const UserInfoBox = observer(() => {
  const [showJumpToSetting, setShowJumpToSetting] = useState(false)
  const { t } = useTranslationWithType()
  useEffect(() => {
    checkPDFAndExtensionOptionPageVisible()
    commonUtils.asyncDelay(500).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
    commonUtils.asyncDelay(2000).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
    commonUtils.asyncDelay(3000).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
  }, [])

  const checkPDFAndExtensionOptionPageVisible = () => {
    const show = commonUtils.isInstalledExtension()
    setShowJumpToSetting(show)
  }
  const userInfo = userService.userData
  if (!userInfo) {
    return null
  }

  const { wxAvatar } = userInfo
  return (
    <div className={classNames(style.userInfoBox)}>
      {wxAvatar ? (
        <img className={classNames(style.avatar)} src={wxAvatar} />
      ) : (
        <img className={classNames(style.avatar)} src={commonUtils.getDefaultAvatar()} />
      )}
      <img
        className={classNames(style.iconArrow)}
        src='https://assets.weibanzhushou.com/web/pt-web/page-header_triangle-icon.9d78d402ad4f.png'
      />
      <div className={classNames(style.menuBoxWrapper)}>
        <div className={classNames(style.menuBox)}>
          {showJumpToSetting && (
            <>
              <div
                className={classNames(style.menuLi)}
                onClick={() => {
                  commonUtils.sendExtensionMessage('openOptionPage', {})
                }}
              >
                <img
                  className={classNames(style.icon)}
                  src='https://assets.weibanzhushou.com/web/pt-web/page-header_setup-center.6e0079fe237e.png'
                />
                <span className={classNames(style.text)}>{t('pageHeader.settings')}</span>
              </div>
              <div className={classNames(style.line)}></div>
            </>
          )}
          <div
            className={classNames(style.menuLi)}
            onClick={async () => {
              await userService.logout()
              toastController.success(t('pageHeader.logoutSucc'))
              commonUtils.sendExtensionMessage('logout', {})
              await commonUtils.asyncDelay(1000)
              routerService.navigateTo('home')
            }}
          >
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/page-header_icon__logout.0d3a4d0087e0.png'
            />
            <span className={classNames(style.text)}>{t('pageHeader.logout')}</span>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PageHeader
