import { Popover, PopoverProps } from 'antd'
import classNames from 'classnames'
import { useEffect, useState, CSSProperties, useRef } from 'react'
import style from './LanguagePopover.module.less'
import { commonUtils } from '@/helpers/commonUtils'

export type LanguageItem = {
  mainName: string
  secondaryName?: string
  value: string
}
export interface LanguagePopoverProps extends PopoverProps {
  languageList: LanguageItem[]
  value: string
  placeholder?: string
  emptyPlaceholder?: string
  showSecondaryName?: boolean
  className?: string
  styles?: CSSProperties
  onLanguageChange: (value: string) => void
  onLoadChildren: (languageItem: LanguageItem) => React.ReactNode
}

export const LanguagePopover = (props: LanguagePopoverProps) => {
  const {
    placeholder = '',
    emptyPlaceholder = '',
    showSecondaryName = false,
    languageList,
    value,
    className,
    styles,
    onLanguageChange,
    onLoadChildren,
  } = props

  const [currentLanguageString, setCurrentLanguageString] = useState(value)
  const [open, setOpen] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [languageListInner, setLanguageInner] = useState<LanguageItem[]>([])
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus()
      })
    }
  }, [open])

  useEffect(() => {
    setCurrentLanguageString(value)
  }, [value])

  useEffect(() => {
    setLanguageInner(commonUtils.jsonClone(languageList))
  }, [JSON.stringify(languageList)])

  useEffect(() => {
    const keywordStr = keyword.toLowerCase()
    const searchResult = languageList.filter((item) => {
      if (item.secondaryName) {
        return (
          !keywordStr ||
          item.mainName.toLowerCase().indexOf(keywordStr) > -1 ||
          item.secondaryName.toLowerCase().indexOf(keywordStr) > -1
        )
      }
      return !keywordStr || item.mainName.toLowerCase().indexOf(keywordStr) > -1
    })
    setLanguageInner(searchResult)
  }, [keyword, languageList])

  const currentLanguageItem = languageList.find((item) => item.value === currentLanguageString)

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const content = (
    <div
      className={classNames(
        style.languageWrap,
        { [style.singleLine]: !showSecondaryName },
        className
      )}
      style={{ ...styles }}
    >
      <div className={classNames(style.searchBox)}>
        <input
          ref={inputRef}
          onKeyDown={(e) => e.stopPropagation()}
          className={classNames(style.input)}
          value={keyword}
          placeholder={placeholder}
          type='text'
          onChange={(e) => {
            setKeyword(e.target.value)
          }}
        />

        <div className={classNames(style.iconBox)}>
          {!!keyword && (
            <img
              className={classNames(style.closeIcon)}
              src={commonUtils.getStaticFile('option/icon__close-with-border.png')}
              onClick={() => {
                setKeyword('')
              }}
            />
          )}
          {!keyword && (
            <img
              className={classNames(style.searchIcon)}
              src={commonUtils.getStaticFile('option/icon__search.png')}
            />
          )}
        </div>
      </div>
      <div className={classNames(style.languageList)}>
        {languageListInner.map((item) => {
          return (
            <div
              className={classNames(style.languageItem, {
                [style.singleLine]: !showSecondaryName,
                [style.doubleLine]: showSecondaryName,
                [style.active]: currentLanguageString === item.value,
              })}
              key={item.value}
              onClick={() => {
                onLanguageChange(item.value)
                setKeyword('')
                setOpen(false)
              }}
            >
              <p className={classNames(style.main)}>{item.mainName}</p>
              {showSecondaryName && (
                <p className={classNames(style.secondary)}>{item.secondaryName}</p>
              )}
            </div>
          )
        })}

        {languageListInner.length === 0 && (
          <div className={classNames(style.emptyBox)}>
            <img
              className={classNames(style.emptyIcon)}
              src={commonUtils.getStaticFile('icon__lang-empty.png')}
            />
            {emptyPlaceholder}
          </div>
        )}
      </div>
    </div>
  )

  return (
    <Popover
      arrow={false}
      content={content}
      trigger={'click'}
      open={open}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={{
        padding: 0,
        background: '#FFFFFF',
        boxShadow: '0px 2px 16px 0px rgba(93,93,93,0.15)',
        borderRadius: '10px',
      }}
      {...props}
    >
      {/* {children} */}
      {currentLanguageItem && onLoadChildren(currentLanguageItem)}
    </Popover>
  )
}
