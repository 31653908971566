import { PAGE_LINKS } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import type { Location } from '@remix-run/router'
import { makeObservable, observable } from 'mobx'
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom'
import { RouterServiceTypes as Types } from './serviceTypes'

export class RouterService {
  public navigate!: NavigateFunction
  public location!: Location<any>
  public setURLSearchParams!: SetURLSearchParams

  @observable params: {
    [key: string]: string
  } = {}

  constructor() {
    makeObservable(this)
  }

  public back() {
    this.navigate(-1)
  }

  public navigateTo<P extends Types.PageType>(pageName: P, payload?: Types.PageParams[P]) {

    const url = commonUtils.genUrl(PAGE_LINKS[pageName], payload || {})
    return this.navigate(url)
  }
  public redirectTo<P extends Types.PageType>(pageName: P, payload?: Types.PageParams[P]) {
    const url = commonUtils.genUrl(PAGE_LINKS[pageName], payload || {})
    return this.navigate(url, { replace: true })
  }

}

export const routerService = new RouterService()
