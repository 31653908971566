// import { TranslationData } from '../translation'

import { FREE_BENEFITS, PRO_BENEFITS, PRO_V_BENEFITS } from '../../../services/userService/const'

const EN_TRANSLATION = {
  slogen: 'The easiest AI assistant',
  pageHeader: {
    help: 'Help Center',
    contact: 'Contact Us',
    pricing: 'Pricing',
    signUp: 'Log In',
    settings: 'Settings',
    logout: 'Logout',
    logoutSucc: 'Logout successful',
  },
  pageFooter: {
    product: {
      title: 'Products',
      web: 'Web Translation & Summary',
      pdf: 'PDF Translation',
      video: 'Video Translation & Summary',
      image: 'Image Translation',
    },
    resources: {
      title: 'Resources',
    },
    follow: {
      title: 'Follow',
      twitter: 'Twitter',
      facebook: 'Facebook',
    },
    legal: {
      title: 'Legal',
      policy: 'Privacy Policy',
      terms: 'Terms of Service',
    },
  },
  home: {
    getStart: 'Get Started',
    getStartedForFree: 'Get  started for free',
    top: {
      desc: `Noam is a free  AI browser extension  that helps you translate summarized web pages, videos and PDF content.
      Join Noam and enjoy the magic!`,
      title2: 'One Magic',
      title1: 'One Click',
    },
    howItWork: {
      title: 'So，how it works',
      desc: 'Noam integrates AI models to provide users with an intelligent experience for cross-language web page translation and summarisation, PDF and image translation, video translation and summarisation',
    },
    PDFTranslate: {
      title: 'PDF Translate',
      desc: 'Bilingual Translation + Chat PDF',
      sub1: {
        title: 'Bilingual reading',
        desc: 'Bilingual reading, so you can compare the original text and the translation while reading to improve reading efficiency',
      },
      sub2: {
        title: 'Chat PDF',
        desc: 'AI analyzes and summarizes, and can answer your questions based on the PDF content',
      },
      sub3: {
        title: 'Accuracy and Efficiency',
        desc: 'AI powered PDf for more accurate and efficient translations',
      },
    },
    aiAssistant: {
      title: 'AI Assistant',
      desc: 'Making Web Translation Smarter and More Convenient',
      panel1: {
        title: 'Bilingual Translation',
        desc: 'Bilingual cross-referencing translations help you compare the original text with the translation so you can understand better and improve your reading efficiency.',
      },
      panel2: {
        left: {
          title: 'Hover Translation',
          desc: 'When browsing a web page, hover your mouse directly over the content that needs to be translated to get the translation result.',
        },
        right: {
          title: 'Text Selection Translation',
          desc: 'When browsing a web page, directly select the text to be underlined to get the translation result.',
        },
      },
      panel3: {
        one: {
          title: 'Point And Click',
          desc: 'Translate and summarize web pages and video content for you anytime, anywhere, in any scenario; bringing you a smoother experience!',
        },
        two: {
          title: 'More Comprehensive And Convenient',
          desc: 'Multi-language full coverage, bi-lingual barrier-free reading, one-key translation; bring you an unparalleled experience!',
        },
        three: {
          title: 'AI-Driven',
          desc: 'AI-driven big model to ensure translation accuracy and consistency and enhance reading experience',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Video Translation & Summary',
          desc: 'Your efficiency expert, combined with chatgpt translates and summarizes YouTube video content to save your reading time!',
        },
        tab2: {
          key: 'Image',
          title: 'Image Translate',
          desc: 'Click to translate images while browsing the web',
        },
        tab3: {
          key: 'Web',
          title: 'Web Translate & Summary',
          desc: 'Swiftly extracting key information from web pages for summarization, enhancing your reading efficiency',
        },
        tag1: 'Simple',
        tag2: 'Efficiency',
        tag3: 'Easily Understood',
      },
    },
    userReviews: {
      title: 'User Reviews',
      desc: 'Five-star review on the chromewebstore',
    },
    lastBlock: {
      desc: 'Better AI translation and smoother reading experience',
    },
  },
  login: {
    title: 'Welcome to Noam',
    btn: 'Log In with Google',
    tips: 'By signing up, I agree to ',
    terms: 'Terms of Use ',
    and: '  and',
    policy: ' Privacy Policy',
    btn1: 'Log In Now',
    desc: 'Log in to unlock more experiences',
  },
  pricing: {
    pageTitle: 'Pricing',
    desc: 'Compare and Choose the best for you',
    yearly: 'Annually',
    monthly: 'Monthly',
    discount: '20%off',
    preMonth: 'Per month',
    billedYearly: 'Billed Yearly',
    billedMonthly: 'Billed Monthly',
    detailedCompare: 'Detailed Compare ',
    FAQ: 'FAQ',
    day: 'day',
    month: 'month',
    around: 'Around',
    free: {
      benefit1: `Instant translation ${FREE_BENEFITS.realTimeCount} times/day`,
      benefit2: 'Web translation & summary',
      benefit3: `${FREE_BENEFITS.imageCount} sheets Image Translation`,
      benefit4: 'Video translation & summary',
      benefit5: `${FREE_BENEFITS.pdfUploadCount} PDF Translation`,
      benefit6: `${FREE_BENEFITS.tokenCount} token`,
      recommendText: 'Free Trial',
      btn: 'Have Fun',
    },
    pro: {
      benefit1: 'Customer Service Rapid Support',
      benefit2: `${PRO_BENEFITS.tokenCount} tokens/month`,
      benefit3: 'Web translation & summary',
      benefit4: `${PRO_BENEFITS.imageCount} sheets Image Translation`,
      benefit5: 'Video translation & summary',
      benefit6: `${PRO_BENEFITS.pdfUploadCount} PDF Translation`,
      btn: 'Get Started',
      recommendText: 'Most people choose',
    },
    proPlus: {
      benefit1: 'Customer Service Rapid Support',
      benefit2: `${PRO_V_BENEFITS.tokenCount} tokens/month`,
      benefit3: 'Web translation & summary',
      benefit4: `${PRO_V_BENEFITS.imageCount} sheets Image Translation`,
      benefit5: 'Video translation & summary',
      benefit6: `${PRO_V_BENEFITS.pdfUploadCount} PDF Translation`,
      recommendText: 'The most suitable choice',
    },
    enterprise: {
      benefit1: 'Customer Service Rapid Support',
      benefit2: 'Multi-user',
      benefit3: 'Customized solutions',
      benefit5: 'Exclusive Account Manager',
      recommendText: 'Customized Plans（≥3人）',
      talkToUs: 'Talk to us',
      btn: 'Contact',
    },
    web: {
      contrastiveTranslation: 'Bilingual Translation',
      instantTranslation: 'Instant Translation',
      hoverTranslation: 'Hover Translation',
      dictionaryTranslation: 'Text Selection Translation',
      summary: 'Web page summary',
    },
    imageTranslation: 'Image translation',
    video: {
      translation: 'Video translation',
      summary: 'Video summary',
    },
    pdf: {
      translation: 'PDF translation',
      maxPage: 'Maximum number of pages in PDF',
      chat: 'Number of PDF inquiries',
    },
    FQAObj: {
      q1: 'Can I use it for free?',
      a1: 'There is a free version of Noam that can be used directly.',
      q2: 'Are there different membership plans and fee options?',
      a2: 'In addition to the free version there are three membership plans, Pro, Pro+ and Enterprise; you can choose between two payment methods, continuous monthly and continuous annual, and the comprehensive cost of continuous annual is more favorable.',
      q3: `What's the difference between a continuous monthly subscription and a continuous annual subscription?`,
      a3: 'There is no difference in functionality. The comprehensive cost of continuous annual subscription is lower than that of continuous monthly subscription; monthly subscription is billed once per month, while annual subscription is billed once per year.',
      q4: 'How do I cancel my auto-renewal plan?',
      a4: 'You can go to the backend settings page and choose to subscribe to cancel the renewal plan.',
    },
  },
  upgradeModal: {
    title: 'Reminders',
    limitAlertStr:
      'Your free plan quota has been used up, we recommend you to upgrade your plan to continue the experience~!',
    upgradeAlertStr:
      'Your free plan quota has been used up, we recommend you to upgrade your plan to continue the experience~!',
    btn: 'Upgrade Now',
    more: 'More Plans',
    contactStr: 'Please wait for the next reset or contact us at contact@noam.tools.',
    upload: {
      free: `You've hit your limit of the pdf translation`,
      pro: `You've hit your limit of the pdf translation in this month`,
    },
    chat: {
      free: `You've hit your limit of the Chatpdf limit exceeded`,
      pro: `You've hit your limit of the  Chatpdf in this month`,
    },
    pageLimit: {
      free: `You've hit your limit of the PDF pages limit exceeded`,
    },
  },
  buyModal: {
    title: 'Subscription Plan',
    freeTrial: '3-Day Free Trial',
    selectLabel: 'Select a plan',
    btn: 'Try It For Free',
    yearLabel: 'year',
    monthLabel: 'month',
    afterFree: 'After the trial',
  },

  privacy: {
    title: 'Privacy Policy',
    updateTime: 'Updated: March 15, 2024',
    block1:
      'We, Noam (referred to as "Noam," "we," "our," or "us"), respect your privacy and are committed to protecting any information we obtain from you or about you. This Privacy Policy describes our practices regarding the collection or use of personal information from your use of our website, applications, and services (collectively referred to as the "Services"). This Privacy Policy does not apply to the content we handle on behalf of our business product customers, such as our API. The use of this data is subject to our customer agreement, which covers access to and use of these products.',
    subTitle1: 'Personal Information We Collect',
    block2:
      'We collect the following personal information ("Personal Information") related to you:',
    block3:
      'Information Provided by You: If you create an account to use our services or communicate with us, we will collect personal information as follows:',
    subBlock1:
      'Account Information: When you create an account on our platform, we collect information related to your account, including your name, contact information, account credentials, payment card information, and transaction history (referred to as "Account Information").',
    subBlock2:
      'User Content: When you use our services, we collect personal information from your inputs, file uploads, or feedback provided to us (referred to as "Content").',
    subBlock3:
      'Communication Information: If you send messages to us, we will collect your name, contact information, and the content of the messages you send (referred to as "Communication Information").',
    subBlock4:
      'Social Media Information: We have pages on social media websites such as Instagram, Facebook, Medium, Twitter, YouTube, and LinkedIn. When you interact with our social media pages, we collect the personal information you choose to provide to us, such as your contact details (referred to as "Social Information"). Additionally, the companies hosting our social media pages may provide us with aggregated information and analysis about our social media activities.',
    block4:
      'Personal Information Automatically Received from Your Use of Services: When you access, use, or interact with the services, we receive the following information about your access, use, or interaction ("Technical Information"):',
    subBlock5:
      'Log Data: Information automatically sent by your browser when using our services. Log data includes your Internet Protocol (IP) address, browser type and settings, the date and time of your requests, and how you interact with our website.',
    subBlock6:
      'Usage Data: We may automatically collect information about your use of the service, such as the types of content you view or interact with, the features you use, and your actions taken, as well as your time zone, country, date and time of access, user agent and version, computer or mobile device type, and your computer connection.',
    subBlock7: `Device Information: Includes the device's name, operating system, device identifier, and the browser you are using. The information collected may depend on the type of device you are using and its settings.`,
    subBlock8:
      'Cookies: We use cookies to operate and manage our services and improve your experience. A "cookie" is information sent by a website you visit to your browser. You can set your browser to accept all cookies, reject all cookies, or notify you when a cookie is provided so that you can decide whether to accept it each time. However, refusing cookies may prevent you from using or negatively affecting the display or functionality of certain areas or features of the website. For more information about cookies, please visit All About Cookies.',
    subBlock9:
      'Analytics: We may use various online analytics products that use cookies to help us analyze how users use our services and improve your experience when using the services.',
    subTitle2: 'How We Use Personal Information',
    block5: 'We may use personal information for the following purposes:',
    subBlock10: 'Providing, managing, maintaining, and/or analyzing services;',
    subBlock26: 'Improving our services and conducting research;',
    subBlock11: 'Communicating with you;',
    subBlock12: 'Developing new projects and services;',
    subBlock13:
      'Preventing fraud, criminal activity, or misuse of our services, and protecting the security of our IT systems, architecture, and networks;',
    subBlock14: 'Conducting business transfers;',
    subBlock15:
      'Complying with legal obligations and legal proceedings, protecting our rights, privacy, security, or property, as well as those of our affiliates, you, or other third parties.',
    block6:
      'Summary or De-identified Information: We may aggregate or de-identify personal information to prevent its reuse for identification purposes and use such information to analyze the effectiveness of our services, improve and add features to our services, conduct research, and for similar purposes. Additionally, we may analyze the general behavior and characteristics of our service users and share aggregated information with third parties, publish such aggregated information, or make it generally available. We may collect aggregated information through the services, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in an anonymous or de-identified form, and we will not attempt to re-identify such information unless required by law.',
    subTitle3: 'Disclosure of Personal Information',
    block7:
      'In certain cases, we may provide your personal information to third parties without further notice to you, unless required by law:',
    subBlock16:
      'Suppliers and Service Providers: To assist us in meeting business operational needs and performing certain services and functions, we may provide personal information to suppliers and service providers, including hosting service providers, cloud service providers, and other information technology service providers, email communication software, and web analytics service providers, etc. These partners will access, process, or store personal information only at our direction while performing their duties for us.',
    subBlock17:
      'Business Transfers: If we engage in strategic transactions, reorganizations, bankruptcies, bankruptcy takeovers, or services transition to another provider (collectively referred to as "Transactions"), your personal information and other information may be shared with transaction counterparts and other parties assisting in the Transactions during due diligence and transferred as part of the Transactions along with other assets to a successor or affiliate company.',
    subBlock18:
      'Legal Requirements: We may share your personal information with government authorities, industry peers, or other third parties, including your interaction information with our services, (1) if required by law or if we believe such action is necessary to comply with legal obligations; (2) to protect and defend our rights or property; (3) if we unilaterally determine that there is a violation of our terms, policies, or laws; (4) to detect or prevent fraud or other illegal activities; (5) to protect our products, employees, or users, or public safety, security, integrity; or (vi) to defend against legal liability.',
    subTitle4: 'Your Rights',
    block8:
      'Depending on your geographic location, individuals in the European Economic Area, the United Kingdom, and globally may have certain statutory rights regarding their personal information. For example, you may have the right to:',
    subBlock19: 'Access your personal information and information about how it is processed.',
    subBlock20: 'Delete your personal information from our records.',
    subBlock21: 'Correct or update your personal information.',
    subBlock22: 'Transfer your personal information to a third party (data portability).',
    subBlock23: 'Limit how we process your personal information.',
    subBlock24:
      'Withdraw your consent—where we rely on consent as the legal basis for processing, you can withdraw your consent at any time.',
    subBlock25: 'Object to how we process your personal information.',
    subTitle5: 'Links to Other Websites',
    block9:
      'The service may contain links to other websites not operated or controlled by Noam, including social media services ("Third Party Websites"). The information you share with Third Party Websites will be governed by the specific privacy policies and terms of service of the Third Party Websites, not by this Privacy Policy. We provide these links for convenience and do not imply our endorsement or review of these websites. Please contact Third Party Websites directly for information about their privacy policies and terms.',
    subTitle6: 'Security and Retention of Information',
    block10: `We implement commercially reasonable technical, administrative, and organizational measures to protect online and offline personal information from loss, misuse, and unauthorized access, disclosure, alteration, or hostile acts. However, no internet or email transmission can guarantee complete security or accuracy. In particular, emails sent to us or from us may not be secure. So, you should be particularly careful when deciding what information to send to us through the service or email. Additionally, we are not responsible for any obstruction of privacy settings or security measures in the service or Third Party Websites' security settings or security measures. We will retain your personal information for as long as necessary for us to provide you with services or for other legitimate business purposes, such as resolving disputes, security and safety reasons, or complying with our legal obligations. The length of time we retain personal information will depend on many factors, such as the amount, nature, sensitivity of the information, the potential risks of unauthorized use or disclosure, the purposes for which we process the information, and other legally binding considerations.`,
    subTitle7: 'Modification of Privacy Policy',
    block11:
      'We may update this Privacy Policy from time to time. When we do so, we will post an updated version on this page, unless otherwise required by applicable law.',
    subTitle8: 'How to Contact Us',
    block12:
      'If you have any unanswered questions or concerns about this Privacy Policy, please contact our customer service. Email: ',
  },
  terms: {
    title: 'Terms of Service',
    updateTime: 'Updated:March15,2024',
    block1: 'Thank you for using Noam!',
    block2: `These Terms of Use apply to your use of Noam's services, including our application programming interfaces, software, tools, developer services, data, documentation, and website (collectively referred to as the "Services"). By using our Services, you agree to abide by these terms. Our Privacy Policy explains how we collect and use personal information.`,
    subTitle1: 'Registration and Access',
    block3:
      'If you are under 18 years old, you must obtain permission from your parents or legal guardians to use the Service. If you are using the Service on behalf of another person or entity, you must be authorized to accept these terms on their behalf. You must provide accurate and complete information to register for an account. You may not provide your access credentials or account to anyone outside of your organization, and you are responsible for all activities conducted using your credentials.',
    subTitle2: 'Usage Requirements',
    block4:
      '(a) Use of Service. You may access the Service under these terms, and we grant you a non-exclusive right to use the Service. When using the Service, you will comply with these terms and all applicable laws. We and our affiliates own all rights, titles, and interests in the Service.',
    block5:
      '(b) Feedback. We welcome feedback, comments, ideas, suggestions, and improvements. If you provide any such content, we may use it without any restrictions and without compensation to you.',
    block6:
      '(c) Restrictions. You may not: (1) use the Service in a manner that infringes, misappropriates, or violates any rights of others; (2) reverse engineer, decompile, disassemble, translate, or otherwise attempt to discover the source code or underlying components of the Service models, algorithms, and systems (unless such restrictions are prohibited by applicable law); (3) use the output of the Service to develop models competing with Noam; (4) extract data or output from the Service using any automated or programming methods unless permitted through the API, including scraping, web harvesting, or web data extraction; (5) represent the output of the Service as artificially generated when it is not, or otherwise violate our usage policies; (6) purchase, sell, or transfer API keys without our prior consent; or (7) provide us with any personal information of children under 13 years old or applicable age of digital consent. You will comply with any rate limits and other requirements in our documentation.',
    block7:
      '(d) Third-Party Services. Any third-party software, services, or other products associated with the Service are governed by their own terms, and we are not responsible for third-party products.',
    subTitle3: 'Content',
    block8:
      '(a) Your Content. You may provide input ("Input") to the Service and receive output ("Output") generated and returned based on the Input (collectively referred to as "Content"). Between the parties, and to the extent permitted by applicable law, you own all Input. Noam may use Content to provide and maintain the Service, comply with applicable laws, and enforce our policies. You are responsible for the Content, including ensuring that it does not violate any applicable laws or these terms.',
    block9:
      '(b) Accuracy. Artificial intelligence and machine learning are rapidly evolving fields of research. We continuously strive to improve our service to make it more accurate, reliable, secure, and beneficial. Given the probabilistic nature of machine learning, using our service may result in incorrect outputs in certain cases, which may not accurately reflect real individuals, places, or facts. You should appropriately assess the accuracy of any output based on your use case, including by manually reviewing outputs.',
    subTitle4: 'Fees and Payment',
    block10:
      '(a) Fees and Billing. You will pay all fees ("Fees") according to the pricing and terms on the applicable pricing page or as agreed upon in writing between us. We reserve the right to correct pricing errors or mistakes even if we have issued invoices or received payment. You will provide complete and accurate billing information, including a valid and authorized payment method. We will charge your payment method on a periodic basis as agreed upon, but we may reasonably change the billing date. You authorize Noam, its affiliates, and third-party payment processors to charge your payment method. If your payment fails, we will provide you with written notice and may suspend access to the Service until payment is received. Except as otherwise specified in this agreement, payments are non-refundable.',
    block11:
      '(b) Taxes. Unless otherwise stated, Fees do not include any federal, state, local, and foreign taxes, duties, and similar assessments ("Taxes"). You are responsible for all Taxes associated with your purchases, excluding Taxes based on our net income for which we will invoice you. You agree to promptly pay such Taxes and provide us with legally valid privacy statements and obtain necessary consents for handling such data, and you warrant to us that you handle such data in accordance with applicable law.',
    block12:
      '(c) Price Changes. We may change our prices by notifying you through your account and/or our website. Price increases will be effective immediately upon posting. Any price changes will apply to Fees for your account after the effective date of the change.',
    block13:
      '(d) Disputes and Overdue Payments. If you wish to dispute any Fees or Taxes, please contact contact@noam.tools within thirty (30) days of the invoice date.',
    block14:
      '(e) Free Usage. You may not create multiple accounts to obtain free credits. If we determine that you are not using the free credits in good faith, we may charge you standard fees or stop providing access to the Service.',
    subTitle5: ' Confidentiality, Security, and Data Protection',
    block15:
      '(a) Confidentiality. You may have access to confidential information of Noam. You may only use confidential information as permitted under these terms for the purpose of using the Service. You may not disclose confidential information to any third party, and you will protect confidential information in a manner no less protective than you protect your own similar confidential information, at least with reasonable care. Confidential information is non-public information designated as confidential by Noam or that should reasonably be treated as confidential under the circumstances, including software, specifications, and other non-public business information. Confidential information does not include information that: (1) becomes generally available to the public without fault on your part; (2) you had in your possession without any obligation of confidentiality before receiving it under these terms; (3) is lawfully disclosed to you by a third party without any confidentiality obligations; or (4) is independently developed by you without using the confidential information. You may disclose confidential information if required by law, court, or other governmental order, but you must provide prior written notice to Noam in a reasonable manner and, to the extent possible, make reasonable efforts to limit the scope of disclosure, including assisting us in opposing disclosure requests.',
    block16:
      '(b) Security. You must implement reasonable and appropriate measures to help safeguard your access to and use of the Service. If you discover any vulnerabilities or violations related to your use of the Service, you must promptly contact Noam and provide details of the vulnerability or violation.',
    block17:
      '((c) Processing of Personal Data. If you use the Service to process personal data, you must provide legally sufficient privacy statements and obtain necessary consents for processing such data, and you warrant to us that you handle such data in accordance with applicable law.',
    subTitle6: 'Term and Termination',
    block18:
      '(a) Termination; Suspension. These terms will be effective from your first use of the Service and will remain in effect until terminated. You may terminate these terms at any time for any reason by discontinuing use of the Service and Content. We may terminate these terms with prior notice for any reason. We may immediately terminate these terms by notifying you if you materially breach Section 2 (Usage Requirements), Section 5 (Confidentiality, Security, and Data Protection), Section 8 (Dispute Resolution), or Section 9 (General Terms), or if our relationship with any third-party technology provider beyond our control changes, or for compliance with law or government requests. We may suspend your access to the Service if you fail to comply with these terms, or if your use poses a security risk to us or any third party, or if we suspect your use is fraudulent or may expose us or any third party to liability.',
    block19:
      '(b) Effects of Termination. Upon termination, you will cease using the Service and promptly return or destroy any confidential information as directed by us. The provisions of these terms that by their nature should survive termination or expiration, including but not limited to Sections 3 and 5-9, shall survive.',
    subTitle7: 'Indemnification; Disclaimers; Limitation of Liability',
    block20: `(a) Indemnification. You will defend, indemnify, and hold us, our affiliates, and our personnel harmless from and against any claims, losses, and expenses (including attorneys' fees) arising out of or related to your use of the Service, including your Content, your use of products or services related to the Service, and your violation of these terms or applicable law.`,
    block21: `(b) Disclaimers. The Service is provided "as is." To the extent permitted by law, we and our affiliates and licensors make no representations or warranties of any kind regarding the Service and make no warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, satisfactory quality, non-infringement, and quiet enjoyment, and any warranties arising out of course of dealing or usage of trade. We do not warrant that the Service will be uninterrupted, accurate, or error-free, or that any Content will be secure, not lost, or not altered.`,
    block22:
      '(c) Limitation of Liability. Neither we, our affiliates, nor our licensors will be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, loss of reputation, loss of use, loss of data, or other intangible losses, even if we have been advised of the possibility of such damages. Under these terms, our total liability will not exceed the Fees you paid to the Service in the twelve (12) months preceding the claim or one hundred US dollars ($100), whichever is greater. The limitations in this section apply to the maximum extent permitted by applicable law.',
    subTitle8: 'Governing Law and Jurisdiction',
    block23:
      'The Terms and your use of the Services are governed by the laws of the United States, except for the U.S. conflict of laws rules. Your use of the Application may also be subject to other local, state, national or international laws. Any legal action or proceeding relating to these Terms will be brought exclusively in the courts of the United States, and you agree to submit to the personal jurisdiction of such courts.',
    subTitle9: 'General Terms',
    block24: `(a) Relationship of the Parties. Noam and you are independent contractors, and neither party has the power to represent or bind the other party or assume obligations for the other party without the other party's prior written consent.`,
    block25:
      '(b) Brand Usage. You may not use the name, logos, or trademarks of Noam or any of its affiliates without our prior written consent.',
    subTitle10: 'Severability',
    block26:
      'If any provision of these terms is determined to be invalid, illegal, or unenforceable, the remaining provisions will remain in full force and effect.',
  },
  contact: {
    title: 'Contact Us',
    block1:
      'For customization or if you have any questions or suggestions, please contact us at contact@noam.tools and we will respond immediately.',
    block2: 'Support us by rating us on the Google Apps Extension store at ★★★★★.',
    block3: 'Help others by sharing this extension:',
  },
  pdf: {
    toast1: 'PDF is loading, please try again later!',
    toast2: 'PDF content is too much, do not support the dialog for the time being',
    toast3: 'Downloading, please try again after the download is complete.',
    shareMsg1: 'Share Link Generation Successful',
    shareMsg2: 'Share Content Updates Successfully',
    shareMsg3:
      'Sharing link generation failed, please try again later or contact our customer service.',
    shareMsg4: 'Sharing content update failed, please try again later or contact customer service',
    shareBtnText1: 'Generate Share Links',
    shareBtnText2: 'Updated sharing content',
    uploading: 'Uploading',
    uploadingMsg: 'Please be patient as it takes some time to upload.',
    cancelUpload: 'Cancel',
    uploadErr: 'File upload failed. Please try again later or contact customer service',
    uploadMsg1: 'Please drag and drop the file here',
    uploadMsg2: 'File types supported: PDF  |  Max file size: 100MB',
    uploadMsg3: 'Click to select or drag and drop files to upload here',
    searchPlaceholder: 'Search',
    collectionEmpty: 'No contents in the collection~',
    kw: 'staple',
    video: 'video',
    link: 'link',
    dataLoading: 'Loading data...',
    freeBannerTips: 'End of Free PDF Translation',
    vipBannerTips: `You've hit your limit of the pdf translation in this month.`,
    freeBannerBtn: 'Upgrade to enjoy more',
    vipBannerBtn: 'Upgrade to enjoy more',
    dialogTitle: 'Attention',
    dialogContent: 'Are you sure you want to delete it?',
    okBtn: 'Delete',
    cancelBtn: 'Cancel',
    unLoginToast: 'Please login to use this function',
    parseErrTips: 'Unable to parse the file',
    new: 'new file',
    parseErrDialogContent:
      'The current PDF link does not support direct translation, please download the original link PDF after translation',
    okBtn2: 'recognize',
    notPdf: 'PDF files only',
    uploadingMsg1: `File uploading in progress. Please try again later.`,
    uploadingMsg2: `You currently have a file being converted, please try again later.`,
    uploadingMsg3:
      'Conversion progress inquiry failed, please try again later or contact customer service',
    uploadingMsg4:
      'Noam cannot access your local files directly, please manually upload a PDF for translation.',
    uploadingMsg5:
      'Failed to get the file url, please try again later or contact customer service.',
    fileMsg: 'Current file volume:',
    fileMsg2: 'Please upload a file smaller than 100M',
    fileErrMsg: 'File reading failed, please try again later or contact customer service.',
    parseFileToast: 'File parsing failed, please try again later or contact our customer service.',
    noId: 'File ID does not exist',
    autoDetect: 'Auto detect',
    hasTransTask: `There's a task being translated. Please wait.`,
    transSucc: 'The translation was successful!',
    entry: 'PDF List',
    listError: 'PDF list failed to load, please try again later or contact customer service.',
    collectionTab: 'Memo',
    pdfLoading: 'Parsing...',
    installTips:
      'Install the small plug-in, you can translate more PDF files, you can also experience smooth web page translation:',
    webTrans: 'full cross-reference',
    hoverTrans: 'Hover paragraph translation',
    selectTrans: 'underline the words and translate',
    installText: 'One-stop shop for all your daily translation needs',
    start: 'Start your free experience',
    shareTips: 'PDF Translation is finished, go ahead and share it with your friends!',
    download: {
      loading: 'Saved...',
      btn: 'Save Now',
      trans: 'Translation only',
      both: 'bilingualism',
      download: 'Download',
      msg1: 'Some of the translations of the current document are longer than the original text, and the text may overlap after downloading, so it is recommended to read it on the web page.',
      msg2: '1、The content has not been fully translated, please download it after translation completely.',
      msg3: '2、Document part of the translation is too long, you can read in the web page to avoid text overlap.',
      msg4: 'There are currently tasks being translated, please try again after the translation is complete.',
      fileName: 'File Name:',
      fileType: 'Download file type:',
      transContinue: 'Continue translating',
      save: 'Save to local',
      downloadMsg1: 'PDF Progress in translation:',
      downloadMsg2: 'Projected requirements',
      minute: 'Minutes...',
      downloadSucc: 'PDF download is complete, click save now!',
    },
    guide: {
      title1: 'Bilingual Translation ',
      title2: 'Chat PDF',
      text1: 'Bilingual Reading',
      text2:
        'Bilingual reading, so you can compare the original text and the translation while reading to improve reading efficiency',
      text3: 'Chat PDF',
      text4: 'AI analyzes and summarizes, and can answer your questions based on the PDF content',
      text5: 'Accuracy and Efficiency',
      text6: 'AI powered PDf for more accurate and efficient translations',
      placeholder: 'Please fill in the PDF link',
      start: 'Start translation',
      toast: 'Please enter a valid online pdf link ending in .pdf',
    },
    original: 'Original',
    parallel: 'Parallel',
    trans: 'Translation',
    initial: 'Translate PDF',
    thumbnail: 'catalogs',
    downloadBtn: 'Download',
    shareBtn: 'Share',
    uploadBtn: 'Upload',
    installText2: 'Please install  first',
    chatPdf: {
      clearSucc: 'Cleared successfully',
      clear: 'Clear',
      clearContent:
        'After clearing, a new conversation will be created and the contents of this conversation will not be saved, are you sure you want to clear it?',
      clearDialogTitle: 'Attention',
      cancel: 'Cancel',
      chatTitle: 'Welcome to Noam, your PDF summary is ready:',
      summaryTitle: 'Questions you may be interested in:',
      placeholder: 'Enter any question about the PDF',
      copy: 'Copy',
      copySucc: 'Copy Success',
      historyTitle: 'History',
      expand: 'Collapse',
      noDataDesc: 'No history~ ',
      noMore: 'No more.',
      Search: 'Search',
      delete: 'Delete',
      loading: 'loading...',
      deleteTitle: 'Tip',
      deleteContent: 'After deletion, this PDF is not recoverable, do you want to delete it?',
      deleteOk: 'Delete',
      deleteCancel: 'Cancel',
      deleteSuccess: 'Delete successfully',
      listError:
        'The reading list failed to load. Please try again later or contact customer support',
    },
    shareModal: {
      shareSetting: 'Share Content Settings',
      shareText: 'Share',
      text1: 'Sharing Settings',
      text2: 'Generate Share Links',
      range: 'Scope:',
      custom: 'Customization:',
      page: 'pages',
      sourceLang: 'Source:',
      target: 'Trarget:',
      errMsg:
        'The content of the current sharing range has not been fully translated, so please translate it before sharing.',
      pageNuErr: 'Please enter the correct page number value',
      trans: 'Translate',
      cancel: 'Cancel',
      update: 'Updated sharing links',
      gen: 'Share',
      shareTips: 'Share the translated PDF link with your friends to read against:',
      copySucc: 'Copy Link Success',
      copy: 'Copy',
      settings: 'Share Settings:',
      modify: 'modify',
      translateTips:
        'Tip: If the translation takes a long time, you can close the pop-up window and share it after the translation is finished.',
      translating: 'PDF Translation...',
      remain: 'remaining',
      remain1: 'Total projected requirements',
      totalPage: '1 pages in total',
      search: 'Search',
      notSupport: 'This language is not currently supported',
    },
    toolbar: {
      Adjust: 'Adjust',
      autoFit: 'Auto fit',
    },
    memoTotalCount: 'Total of 1 content',
    dragTipModal: {
      press: 'Press',
      black: 'space',
      leftKey: 'left botton',
      tips: 'to drag and view.',
      ok: 'I got it',
    },
  },
  locale: {
    zh: 'Chinese',
    en: 'English',
    es: 'Spanish',
    ru: 'Russian',
    pt: 'Portuguese (Brazil)',
    id: 'Indonesian',
    cs: 'Czech',
    de: 'German',
    fr: 'French',
    it: 'Italian',
    nl: 'Dutch',
    pl: 'Polish',
    vi: 'Vietnamese',
    tr: 'Turkish',
    uk: 'Ukrainian',
    zhHant: 'Chinese Traditional',
    ja: 'Japanese',
    ko: 'Korean',
    ar: 'Arabic',
    fa: 'Persian',
    th: 'Thai',
    hi: 'Hindi',
    noSearchLang: 'This language is not supported',
    placeholder: 'Search',
    af: 'Afrikaans',
    am: 'Amharic',
    as: 'Assamese',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    bg: 'Bulgarian',
    bn: 'Bangla',
    bo: 'Tibetan',
    bs: 'Bosnian',
    ca: 'Catalan',
    cy: 'Welsh',
    da: 'Danish',
    dsb: 'Lower Sorbian',
    dv: 'Divehi',
    el: 'Greek',
    et: 'Estonian',
    eu: 'Basque',
    fi: 'Finnish',
    fil: 'Filipino',
    fj: 'Fijian',
    frCA: 'French (Canada)',
    ga: 'Irish',
    gl: 'Galician',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Hebrew',
    hr: 'Croatian',
    hsb: 'Upper Sorbian',
    ht: 'Haitian Creole',
    hu: 'Hungarian',
    hy: 'Armenian',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'Icelandic',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latin)',
    ka: 'Georgian',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurdish (Northern)',
    kn: 'Kannada',
    ku: 'Kurdish (Central)',
    ky: 'Kyrgyz',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuanian',
    lug: 'Ganda',
    lv: 'Latvian',
    lzh: 'Chinese (Literary)',
    mai: 'Maithili',
    mg: 'Malagasy',
    mi: 'Māori',
    mk: 'Macedonian',
    ml: 'Malayalam',
    mnCyrl: 'Mongolian (Cyrillic)',
    mnMong: 'Mongolian (Traditional)',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Maltese',
    mww: 'Hmong Daw',
    my: 'Myanmar (Burmese)',
    nb: 'Norwegian',
    ne: 'Nepali',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    prs: 'Dari',
    ps: 'Pashto',
    ptPT: 'Portuguese (Portugal)',
    ro: 'Romanian',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovenian',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Albanian',
    srCyrl: 'Serbian (Cyrillic)',
    srLatn: 'Serbian (Latin)',
    st: 'Sesotho',
    sv: 'Swedish',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tlhLatn: 'Klingon (Latin)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongan',
    tt: 'Tatar',
    ty: 'Tahitian',
    ug: 'Uyghur',
    ur: 'Urdu',
    uz: 'Uzbek (Latin)',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Cantonese (Traditional)',
    zhHans: 'Chinese Simplified',
    zu: 'Zulu',
    auto: 'Auto detect',
  },
  stripe: {
    contact: 'Contact us at contact@noam.tools  with any questions!',
    fail: {
      title: 'Payment failed',
      text1: `Unfortunately, we can't process your payment request.`,
      text2: 'Please check your Internet connection and try again.',
      btn: 'Retry',
    },
    succ: {
      title: 'Congratulations',
      text1: 'welcome to Noam, ',
      text2: `let's get started together!`,
      btn: `Let's do some magic`,
    },
  },
  welfare: {
    newUser: {
      label: 'NewUser Benefits:',
      joinBtn: 'Join Now',
      text: 'Only 100 Spots Available! Grab Yours Now!',
      remain: 'Only 30 left',
    },
  },
  help: {
    Fixedplugin: 'Fixed plugin',
    Howtouse: 'How to use',
    try: 'Give it a try',
    FreeTrialBenefits: 'Free trial benefits',
    pin: 'Pin Noam, for easier access～～',
    Video: 'Video',
    ParallelTranslation: 'Parallel Translation',
    WebSummary: 'Web Summary',
    ChatPDF: 'Chat PDF',
    AquickOverviewOfNoam: 'A quick overview of Noam',
    WordSelectionTranslation: 'Word selection translation',
    HoverTranslation: 'Hover translation',
    ImageTranslation: 'Image translation',
    InstantTranslation: 'Instant translation',
    VideoTranslation: 'Video translation',
    benefits: 'We have prepared benefits for you!',
    Free: 'Free',
    Membership: 'Membership',
    Translation: 'Translation',
    PDFparallelTranslation: 'PDF parallel translation',
    ChatPDF2: 'ChatPDF',
    Summary: 'Summary',
    VideoSummary: 'Video summary',
    FavoritesManagement: 'Favorites management',
    Focusing: 'Focusing',
    dayFree: '3-Day Free Trial',
    planIsNotExist: 'plan is not exist',
    TryItForFree: 'Try It For Free',
    startYoutube: `Let's start with YouTube~`,
    startWiki: `Let's start with Wikimedia~`,
    tryNow: 'Give it a try now~',
    GoWithYouTube: 'Go with YouTube',
    GoWithWikipedia: 'Go with Wikipedia',
    NoamHelp: 'Noam can help you translate and summarize',
    SkipAllSteps: 'Skip all steps',
    next: 'Next',
    done: 'Done',
  },
  trialModal: {
    title: 'Welcome gift',
    trialText: '7-Day Trial',
    desc1: 'After the trial ends, you will be charged an annual fee of $180',
    desc2: 'Cancel Anytime',
    btn: 'Get Started Now（Only $0.99）',
    installBtn: 'Install now to receive your gift',
    web: 'Parallel Translation',
    select: 'Word selection translation',
    hover: 'Hover translation',
    image: 'Image translation',
    realtime: 'Instant translation',
    video: 'Video translation',
    pdf: 'PDF parallel translation',
    chat: 'ChatPDF',
    webSummary: 'Web summary',
    videoSummary: 'Video summary',
    memo: 'Favorites management',
    focus: 'Focusing',
  },
  privacyCollectionPanel: {
    title: 'This website uses cookies',
    text: `To improve your experience, we'd like to use cookies to personalize content and services.`,
    acceptBtn: 'Accept all',
  },
  installTips: {
    text: `Install “Noam Browser Plugin” to unlock more features! Web cross-referencing,
    web video summarization, and more!`,
    btn: 'Try It Now',
  },
}
export default EN_TRANSLATION
