import { commonUtils } from '@/helpers/commonUtils'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import style from './PageFooter.module.less'
import { useTranslationWithType } from '@/i18n/i18n'

export type ProductItemType = 'web' | 'pdf' | 'video' | 'image'
export type ProductItemClickListener = (item: ProductItemType) => void

export type ResourceItemType = 'price' | 'contact'
export type ResourceItemClickListener = (item: ResourceItemType) => void

export type LegalItemType = 'privacy' | 'terms'
export type LegalItemClickListener = (item: LegalItemType) => void

/** PageFooter 组件的 Props */
export type PageFooterProps = {
  onProductItemClick?: ProductItemClickListener
  onResourcesItemClick?: ResourceItemClickListener
  onLegalItemClick?: LegalItemClickListener
}

const PageFooter: FC<PageFooterProps> = observer((props: PageFooterProps) => {
  const { onProductItemClick, onResourcesItemClick, onLegalItemClick } = props

  const { t } = useTranslationWithType()
  return (
    <div className={classNames(style.pageFooter)}>
      <div className={classNames(style.footerContent)}>
        <div className={classNames(style.left)}>
          <img className={classNames(style.logo)} src={commonUtils.getStaticFile('logo.png')} />
          <p className={classNames(style.text)}>{t('slogen')}</p>
          {/* <p className={classNames(style.text)}>{t('pageFooter.owner')}</p>
          <p className={classNames(style.icp)}>{t('pageFooter.icp')}</p> */}
        </div>
        <div className={classNames(style.right, 'flex-row', 'align-right', 'justify-start')}>
          <div className={classNames(style.box, 'mr-88')}>
            <p className={classNames(style.title)}>{t('pageFooter.product.title')}</p>
            <a
              href='/?to=web'
              onClick={(e) => {
                if (onProductItemClick) {
                  e.preventDefault()
                  onProductItemClick('web')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageFooter.product.web')}
            </a>
            <a
              href='/?to=pdf'
              onClick={(e) => {
                if (onProductItemClick) {
                  e.preventDefault()
                  onProductItemClick('pdf')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageFooter.product.pdf')}
            </a>
            <a
              href='/?to=video'
              onClick={(e) => {
                if (onProductItemClick) {
                  e.preventDefault()
                  onProductItemClick('video')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageFooter.product.video')}
            </a>
            <a
              href='/?to=image'
              onClick={(e) => {
                if (onProductItemClick) {
                  e.preventDefault()
                  onProductItemClick('image')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageFooter.product.image')}
            </a>
          </div>
          <div className={classNames(style.box, 'mr-83')}>
            <p className={classNames(style.title)}>{t('pageFooter.resources.title')}</p>
            <a
              href='/pricing'
              onClick={(e) => {
                if (onResourcesItemClick) {
                  e.preventDefault()
                  onResourcesItemClick('price')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageHeader.pricing')}
            </a>
            {/* <a href='/' className={classNames(style.link)}>
              {t('pageHeader.help')}
            </a> */}
            <a
              href='/contact'
              onClick={(e) => {
                if (onResourcesItemClick) {
                  e.preventDefault()
                  onResourcesItemClick('contact')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageHeader.contact')}
            </a>
          </div>
          {/* <div className={classNames(style.box, 'mr-88')}>
            <p className={classNames(style.title)}>{t('pageFooter.follow.title')}</p>
            <a href='https://twitter.com/' className={classNames(style.link)}>
              {t('pageFooter.follow.twitter')}
            </a>
            <a href='https://www.facebook.com/' className={classNames(style.link)}>
              {t('pageFooter.follow.facebook')}
            </a>
          </div> */}
          <div className={classNames(style.box)}>
            <p className={classNames(style.title)}>{t('pageFooter.legal.title')}</p>
            <a
              href='/policy'
              target='_black'
              onClick={(e) => {
                if (onLegalItemClick) {
                  e.preventDefault()
                  onLegalItemClick('privacy')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageFooter.legal.policy')}
            </a>
            <a
              href='/terms'
              target='_black'
              onClick={(e) => {
                if (onLegalItemClick) {
                  e.preventDefault()
                  onLegalItemClick('terms')
                }
              }}
              className={classNames(style.link)}
            >
              {t('pageFooter.legal.terms')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PageFooter
