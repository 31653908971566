const TRANSLATION = {
  slogen: 'Asisten AI termudah',
  pageHeader: {
    help: 'Pusat Bantuan',
    contact: 'Hubungi kami',
    pricing: 'Harga',
    signUp: 'Gabung',
    settings: 'Pengaturan',
    logout: 'Keluar',
    logoutSucc: 'Logout berhasil',
  },
  pageFooter: {
    product: {
      title: 'Produk',
      web: 'Terjemahan & Ringkasan Web',
      pdf: 'Terjemahan PDF',
      video: 'Terjemahan & Ringkasan Video',
      image: 'Terjemahan Gambar',
    },
    resources: { title: 'Sumber daya' },
    follow: { title: 'Mengikuti', twitter: 'Twitter', facebook: 'Facebook' },
    legal: { title: 'Hukum', policy: 'Kebijakan pribadi', terms: 'Ketentuan Layanan' },
  },
  home: {
    getStart: 'Memulai',
    getStartedForFree: 'Mulailah secara gratis',
    top: {
      desc: 'Noam adalah ekstensi browser AI gratis yang membantu Anda menerjemahkan ringkasan halaman web, video, dan konten PDF.\n      Bergabunglah dengan Noam dan nikmati keajaibannya!',
      title2: 'Satu Keajaiban',
      title1: 'Satu klik',
    },
    howItWork: {
      title: 'Jadi, bagaimana cara kerjanya',
      desc: 'Noam mengintegrasikan model AI untuk memberikan pengalaman cerdas kepada pengguna untuk terjemahan dan ringkasan halaman web lintas bahasa, terjemahan PDF dan gambar, terjemahan dan ringkasan video',
    },
    PDFTranslate: {
      title: 'Terjemahan PDF',
      desc: 'Terjemahan Bilingual + Obrolan PDF',
      sub1: {
        title: 'Membaca bilingual',
        desc: 'Membaca bilingual, sehingga Anda dapat membandingkan teks asli dan terjemahannya saat membaca untuk meningkatkan efisiensi membaca',
      },
      sub2: {
        title: 'Obrolan PDF',
        desc: 'AI menganalisis dan merangkum, serta dapat menjawab pertanyaan Anda berdasarkan konten PDF',
      },
      sub3: {
        title: 'Akurasi dan Efisiensi',
        desc: 'PDF yang didukung AI untuk terjemahan yang lebih akurat dan efisien',
      },
    },
    aiAssistant: {
      title: 'Asisten AI',
      desc: 'Menjadikan Terjemahan Web Lebih Cerdas dan Nyaman',
      panel1: {
        title: 'Terjemahan Bilingual',
        desc: 'Terjemahan referensi silang bilingual membantu Anda membandingkan teks asli dengan terjemahannya sehingga Anda dapat memahami lebih baik dan meningkatkan efisiensi membaca Anda.',
      },
      panel2: {
        left: {
          title: 'Arahkan Terjemahan',
          desc: 'Saat menelusuri halaman web, arahkan mouse Anda langsung ke konten yang perlu diterjemahkan untuk mendapatkan hasil terjemahan.',
        },
        right: {
          title: 'Terjemahan Pemilihan Teks',
          desc: 'Saat menelusuri halaman web, langsung pilih teks yang akan digarisbawahi untuk mendapatkan hasil terjemahan.',
        },
      },
      panel3: {
        one: {
          title: 'Arahkan Dan Klik',
          desc: 'Terjemahkan dan rangkum halaman web dan konten video untuk Anda kapan saja, di mana saja, dalam skenario apa pun; memberi Anda pengalaman yang lebih lancar!',
        },
        two: {
          title: 'Lebih Komprehensif Dan Nyaman',
          desc: 'Cakupan penuh multi-bahasa, bacaan bebas hambatan dua bahasa, terjemahan satu tombol; memberi Anda pengalaman yang tak tertandingi!',
        },
        three: {
          title: 'Didorong oleh AI',
          desc: 'Model besar yang digerakkan oleh AI untuk memastikan keakuratan dan konsistensi terjemahan serta meningkatkan pengalaman membaca',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Terjemahan & Ringkasan Video',
          desc: 'Pakar efisiensi Anda, dikombinasikan dengan chatgpt menerjemahkan dan merangkum konten video YouTube untuk menghemat waktu membaca Anda!',
        },
        tab2: {
          key: 'Gambar',
          title: 'Terjemahan Gambar',
          desc: 'Klik untuk menerjemahkan gambar saat menjelajahi web',
        },
        tab3: {
          key: 'jaring',
          title: 'Terjemahan & Ringkasan Web',
          desc: 'Mengekstraksi informasi penting dengan cepat dari halaman web untuk diringkas, meningkatkan efisiensi membaca Anda',
        },
        tag1: 'Sederhana',
        tag2: 'Efisiensi',
        tag3: 'Mudah Dipahami',
      },
    },
    userReviews: { title: 'Ulasan Pengguna', desc: 'Ulasan bintang lima di chromewebstore' },
    lastBlock: { desc: 'Terjemahan AI yang lebih baik dan pengalaman membaca yang lebih lancar' },
  },
  login: {
    title: 'Selamat datang di Noam',
    btn: 'Masuk dengan Google',
    tips: 'Dengan mendaftar, saya setuju',
    terms: 'Syarat Penggunaan',
    and: 'Dan',
    policy: 'Kebijakan pribadi',
    btn1: 'Masuk sekarang',
    desc: 'Masuk untuk membuka lebih banyak pengalaman',
  },
  pricing: {
    pageTitle: 'Harga',
    desc: 'Bandingkan dan Pilih yang terbaik untuk Anda',
    yearly: 'Setiap tahun',
    monthly: 'Bulanan',
    discount: 'Diskon 20%.',
    preMonth: 'Per bulan',
    billedYearly: 'Ditagih Setiap Tahun',
    billedMonthly: 'Ditagih Bulanan',
    detailedCompare: 'Bandingkan Terperinci',
    FAQ: 'Pertanyaan Umum',
    day: 'hari',
    month: 'bulan',
    around: 'Sekitar',
    free: {
      benefit1: 'Terjemahan instan 100 kali/hari',
      benefit2: 'Terjemahan & ringkasan web',
      benefit3: 'Terjemahan Gambar 30 lembar',
      benefit4: 'Terjemahan & ringkasan video',
      benefit5: '10 Terjemahan PDF',
      benefit6: '5.000 token',
      recommendText: 'Uji Coba Gratis',
      btn: 'Selamat bersenang-senang',
    },
    pro: {
      benefit1: 'Dukungan Cepat Layanan Pelanggan',
      benefit2: '1.000.000 token/bulan',
      benefit3: 'Terjemahan & ringkasan web',
      benefit4: 'Terjemahan Gambar 200 lembar',
      benefit5: 'Terjemahan & ringkasan video',
      benefit6: '200 Terjemahan PDF',
      btn: 'Memulai',
      recommendText: 'Kebanyakan orang memilih',
    },
    proPlus: {
      benefit1: 'Dukungan Cepat Layanan Pelanggan',
      benefit2: '3.000.000 token/bulan',
      benefit3: 'Terjemahan & ringkasan web',
      benefit4: 'Terjemahan Gambar 500 lembar',
      benefit5: 'Terjemahan & ringkasan video',
      benefit6: '500 Terjemahan PDF',
      recommendText: 'Pilihan yang paling cocok',
    },
    enterprise: {
      benefit1: 'Dukungan Cepat Layanan Pelanggan',
      benefit2: 'Multi-pengguna',
      benefit3: 'Solusi yang disesuaikan',
      benefit5: 'Manajer Akun Eksklusif',
      recommendText: 'Paket Khusus（≥3人）',
      talkToUs: 'Bicaralah dengan kami',
      btn: 'Kontak',
    },
    web: {
      contrastiveTranslation: 'Terjemahan Bilingual',
      instantTranslation: 'Terjemahan instan',
      hoverTranslation: 'Arahkan Terjemahan',
      dictionaryTranslation: 'Terjemahan Pemilihan Teks',
      summary: 'Ringkasan halaman web',
    },
    imageTranslation: 'Terjemahan gambar',
    video: { translation: 'Terjemahan video', summary: 'Ringkasan video' },
    pdf: {
      translation: 'Terjemahan PDF',
      maxPage: 'Jumlah halaman maksimum dalam PDF',
      chat: 'Jumlah pertanyaan PDF',
    },
    FQAObj: {
      q1: 'Bisakah saya menggunakannya secara gratis?',
      a1: 'Ada Noam versi gratis yang bisa langsung digunakan.',
      q2: 'Apakah ada paket keanggotaan dan pilihan biaya yang berbeda?',
      a2: 'Selain versi gratis, ada tiga paket keanggotaan, Pro, Pro+ dan Enterprise; Anda dapat memilih antara dua metode pembayaran, bulanan berkelanjutan dan tahunan berkelanjutan, dan biaya komprehensif tahunan berkelanjutan lebih menguntungkan.',
      q3: 'Apa perbedaan antara langganan bulanan berkelanjutan dan langganan tahunan berkelanjutan?',
      a3: 'Tidak ada perbedaan fungsi. Biaya komprehensif untuk langganan tahunan berkelanjutan lebih rendah dibandingkan biaya berlangganan bulanan berkelanjutan; langganan bulanan ditagih satu kali per bulan, sedangkan langganan tahunan ditagih satu kali per tahun.',
      q4: 'Bagaimana cara membatalkan paket perpanjangan otomatis saya?',
      a4: 'Anda dapat membuka halaman pengaturan backend dan memilih berlangganan untuk membatalkan paket perpanjangan.',
    },
  },
  upgradeModal: {
    title: 'Pengingat',
    limitAlertStr:
      'Kuota paket gratis Anda telah habis, kami menyarankan Anda untuk meningkatkan paket Anda untuk melanjutkan pengalaman~!',
    upgradeAlertStr:
      'Kuota paket gratis Anda telah habis, kami menyarankan Anda untuk meningkatkan paket Anda untuk melanjutkan pengalaman~!',
    btn: 'Tingkatkan sekarang',
    more: 'Rencana Lainnya',
    contactStr: 'Silakan tunggu reset berikutnya atau hubungi kami di contact@noam.tools.',
    upload: {
      free: 'Anda telah mencapai batas terjemahan pdf Anda',
      pro: 'Anda telah mencapai batas terjemahan pdf di bulan ini',
    },
    chat: {
      free: 'Anda telah mencapai batas melebihi batas Chatpdf',
      pro: 'Anda telah mencapai batas Chatpdf di bulan ini',
    },
    pageLimit: { free: 'Anda telah mencapai batas terlampauinya batas halaman PDF' },
  },
  buyModal: {
    title: 'Paket Berlangganan',
    freeTrial: 'Uji Coba Gratis 3 Hari',
    selectLabel: 'Pilih rencana',
    btn: 'Cobalah Secara Gratis',
    yearLabel: 'tahun',
    monthLabel: 'bulan',
    afterFree: 'Setelah persidangan',
  },
  privacy: {
    title: 'Kebijakan pribadi',
    updateTime: 'Diperbarui: 15 Maret 2024',
    block1:
      'Kami, Noam (disebut sebagai "Noam", "kita", "milik kami", atau "kami"), menghormati privasi Anda dan berkomitmen untuk melindungi informasi apa pun yang kami peroleh dari Anda atau tentang Anda. Kebijakan Privasi ini menjelaskan praktik kami mengenai pengumpulan atau penggunaan informasi pribadi dari penggunaan Anda terhadap situs web, aplikasi, dan layanan kami (secara kolektif disebut sebagai "Layanan"). Kebijakan Privasi ini tidak berlaku untuk konten yang kami tangani atas nama pelanggan produk bisnis kami, seperti API kami. Penggunaan data ini tunduk pada perjanjian pelanggan kami, yang mencakup akses dan penggunaan produk ini.',
    subTitle1: 'Informasi Pribadi yang Kami Kumpulkan',
    block2:
      'Kami mengumpulkan informasi pribadi berikut ("Informasi Pribadi") yang berkaitan dengan Anda:',
    block3:
      'Informasi yang Anda Berikan: Jika Anda membuat akun untuk menggunakan layanan kami atau berkomunikasi dengan kami, kami akan mengumpulkan informasi pribadi sebagai berikut:',
    subBlock1:
      'Informasi Akun: Saat Anda membuat akun di platform kami, kami mengumpulkan informasi terkait akun Anda, termasuk nama Anda, informasi kontak, kredensial akun, informasi kartu pembayaran, dan riwayat transaksi (disebut sebagai "Informasi Akun").',
    subBlock2:
      'Konten Pengguna: Saat Anda menggunakan layanan kami, kami mengumpulkan informasi pribadi dari masukan Anda, unggahan file, atau umpan balik yang diberikan kepada kami (disebut sebagai "Konten").',
    subBlock3:
      'Informasi Komunikasi: Jika Anda mengirim pesan kepada kami, kami akan mengumpulkan nama Anda, informasi kontak, dan isi pesan yang Anda kirim (disebut sebagai "Informasi Komunikasi").',
    subBlock4:
      'Informasi Media Sosial: Kami memiliki halaman di situs media sosial seperti Instagram, Facebook, Medium, Twitter, YouTube, dan LinkedIn. Saat Anda berinteraksi dengan halaman media sosial kami, kami mengumpulkan informasi pribadi yang Anda pilih untuk diberikan kepada kami, seperti rincian kontak Anda (disebut sebagai "Informasi Sosial"). Selain itu, perusahaan yang menghosting halaman media sosial kami dapat memberi kami kumpulan informasi dan analisis tentang aktivitas media sosial kami.',
    block4:
      'Informasi Pribadi yang Secara Otomatis Diterima dari Penggunaan Layanan oleh Anda: Saat Anda mengakses, menggunakan, atau berinteraksi dengan layanan, kami menerima informasi berikut tentang akses, penggunaan, atau interaksi Anda ("Informasi Teknis"):',
    subBlock5:
      'Data Log: Informasi yang dikirim secara otomatis oleh browser Anda saat menggunakan layanan kami. Data log mencakup alamat Protokol Internet (IP) Anda, jenis dan pengaturan browser, tanggal dan waktu permintaan Anda, dan cara Anda berinteraksi dengan situs web kami.',
    subBlock6:
      'Data Penggunaan: Kami dapat secara otomatis mengumpulkan informasi tentang penggunaan Anda atas layanan, seperti jenis konten yang Anda lihat atau berinteraksi dengannya, fitur yang Anda gunakan, dan tindakan yang Anda ambil, serta zona waktu, negara, tanggal dan waktu Anda. akses, agen pengguna dan versinya, jenis komputer atau perangkat seluler, dan koneksi komputer Anda.',
    subBlock7:
      'Informasi Perangkat: Termasuk nama perangkat, sistem operasi, pengidentifikasi perangkat, dan browser yang Anda gunakan. Informasi yang dikumpulkan mungkin bergantung pada jenis perangkat yang Anda gunakan dan pengaturannya.',
    subBlock8:
      'Cookie: Kami menggunakan cookie untuk mengoperasikan dan mengelola layanan kami dan meningkatkan pengalaman Anda. "Cookie" adalah informasi yang dikirim oleh situs web yang Anda kunjungi ke browser Anda. Anda dapat mengatur browser Anda untuk menerima semua cookie, menolak semua cookie, atau memberi tahu Anda ketika cookie disediakan sehingga Anda dapat memutuskan apakah akan menerimanya setiap saat. Namun, menolak cookie dapat mencegah Anda menggunakan atau memberikan dampak negatif terhadap tampilan atau fungsi area atau fitur tertentu di situs web. Untuk informasi lebih lanjut tentang cookie, silakan kunjungi Semua Tentang Cookie.',
    subBlock9:
      'Analisis: Kami dapat menggunakan berbagai produk analisis online yang menggunakan cookie untuk membantu kami menganalisis cara pengguna menggunakan layanan kami dan meningkatkan pengalaman Anda saat menggunakan layanan.',
    subTitle2: 'Bagaimana Kami Menggunakan Informasi Pribadi',
    block5: 'Kami dapat menggunakan informasi pribadi untuk tujuan berikut:',
    subBlock10: 'Menyediakan, mengelola, memelihara, dan/atau menganalisis layanan;',
    subBlock26: 'Meningkatkan layanan kami dan melakukan penelitian;',
    subBlock11: 'Berkomunikasi dengan Anda;',
    subBlock12: 'Mengembangkan proyek dan layanan baru;',
    subBlock13:
      'Mencegah penipuan, aktivitas kriminal, atau penyalahgunaan layanan kami, dan melindungi keamanan sistem, arsitektur, dan jaringan TI kami;',
    subBlock14: 'Melakukan transfer bisnis;',
    subBlock15:
      'Mematuhi kewajiban hukum dan proses hukum, melindungi hak, privasi, keamanan, atau properti kami, serta afiliasi kami, Anda, atau pihak ketiga lainnya.',
    block6:
      'Ringkasan atau Informasi De-identifikasi: Kami dapat mengumpulkan atau mende-identifikasi informasi pribadi untuk mencegah penggunaan kembali untuk tujuan identifikasi dan menggunakan informasi tersebut untuk menganalisis efektivitas layanan kami, meningkatkan dan menambahkan fitur pada layanan kami, melakukan penelitian, dan untuk tujuan serupa . Selain itu, kami dapat menganalisis perilaku umum dan karakteristik pengguna layanan kami dan membagikan informasi gabungan dengan pihak ketiga, mempublikasikan informasi gabungan tersebut, atau menyediakannya secara umum. Kami dapat mengumpulkan informasi agregat melalui layanan, melalui cookie, dan melalui cara lain yang dijelaskan dalam Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan informasi yang tidak teridentifikasi dalam bentuk anonim atau tidak teridentifikasi, dan kami tidak akan mencoba mengidentifikasi ulang informasi tersebut kecuali diwajibkan oleh hukum.',
    subTitle3: 'Pengungkapan Informasi Pribadi',
    block7:
      'Dalam kasus tertentu, kami dapat memberikan informasi pribadi Anda kepada pihak ketiga tanpa pemberitahuan lebih lanjut kepada Anda, kecuali diwajibkan oleh hukum:',
    subBlock16:
      'Pemasok dan Penyedia Layanan: Untuk membantu kami memenuhi kebutuhan operasional bisnis dan menjalankan layanan dan fungsi tertentu, kami dapat memberikan informasi pribadi kepada pemasok dan penyedia layanan, termasuk penyedia layanan hosting, penyedia layanan cloud, dan penyedia layanan teknologi informasi lainnya, perangkat lunak komunikasi email , dan penyedia layanan analisis web, dll. Mitra ini akan mengakses, memproses, atau menyimpan informasi pribadi hanya atas arahan kami saat menjalankan tugas mereka untuk kami.',
    subBlock17:
      'Transfer Bisnis: Jika kami terlibat dalam transaksi strategis, reorganisasi, kebangkrutan, pengambilalihan kebangkrutan, atau transisi layanan ke penyedia lain (secara kolektif disebut sebagai "Transaksi"), informasi pribadi Anda dan informasi lainnya dapat dibagikan dengan mitra transaksi dan pihak lain yang membantu dalam hal ini. Transaksi selama uji tuntas dan dialihkan sebagai bagian dari Transaksi beserta aset lainnya kepada perusahaan penerus atau afiliasi.',
    subBlock18:
      'Persyaratan Hukum: Kami dapat membagikan informasi pribadi Anda dengan otoritas pemerintah, rekan industri, atau pihak ketiga lainnya, termasuk informasi interaksi Anda dengan layanan kami, (1) jika diwajibkan oleh hukum atau jika kami yakin tindakan tersebut diperlukan untuk mematuhi kewajiban hukum; (2) untuk melindungi dan mempertahankan hak atau properti kami; (3) jika kami secara sepihak menentukan adanya pelanggaran terhadap ketentuan, kebijakan, atau hukum kami; (4) untuk mendeteksi atau mencegah penipuan atau aktivitas ilegal lainnya; (5) untuk melindungi produk, karyawan, atau pengguna kami, atau keselamatan, keamanan, integritas publik; atau (vi) untuk membela diri dari tanggung jawab hukum.',
    subTitle4: 'Hak Anda',
    block8:
      'Tergantung pada lokasi geografis Anda, individu di Wilayah Ekonomi Eropa, Inggris, dan secara global mungkin memiliki hak hukum tertentu mengenai informasi pribadi mereka. Misalnya, Anda mungkin berhak untuk:',
    subBlock19: 'Akses informasi pribadi Anda dan informasi tentang cara pemrosesannya.',
    subBlock20: 'Hapus informasi pribadi Anda dari catatan kami.',
    subBlock21: 'Perbaiki atau perbarui informasi pribadi Anda.',
    subBlock22: 'Mentransfer informasi pribadi Anda ke pihak ketiga (portabilitas data).',
    subBlock23: 'Batasi cara kami memproses informasi pribadi Anda.',
    subBlock24:
      'Mencabut persetujuan Anda—jika kami mengandalkan persetujuan sebagai dasar hukum untuk pemrosesan, Anda dapat membatalkan persetujuan Anda kapan saja.',
    subBlock25: 'Menolak cara kami memproses informasi pribadi Anda.',
    subTitle5: 'Tautan ke Situs Web Lain',
    block9:
      'Layanan ini mungkin berisi tautan ke situs web lain yang tidak dioperasikan atau dikendalikan oleh Noam, termasuk layanan media sosial (“Situs Web Pihak Ketiga”). Informasi yang Anda bagikan dengan Situs Web Pihak Ketiga akan diatur oleh kebijakan privasi khusus dan ketentuan layanan Situs Web Pihak Ketiga, bukan oleh Kebijakan Privasi ini. Kami menyediakan tautan ini untuk kenyamanan dan tidak menyiratkan dukungan atau peninjauan kami terhadap situs web ini. Silakan menghubungi Situs Web Pihak Ketiga secara langsung untuk mendapatkan informasi tentang kebijakan privasi dan ketentuan mereka.',
    subTitle6: 'Keamanan dan Penyimpanan Informasi',
    block10:
      'Kami menerapkan langkah-langkah teknis, administratif, dan organisasi yang wajar secara komersial untuk melindungi informasi pribadi online dan offline dari kehilangan, penyalahgunaan, dan akses, pengungkapan, perubahan, atau tindakan permusuhan yang tidak sah. Namun, tidak ada transmisi internet atau email yang dapat menjamin keamanan atau keakuratan sepenuhnya. Khususnya, email yang dikirimkan kepada kami atau dari kami mungkin tidak aman. Jadi, Anda harus sangat berhati-hati saat memutuskan informasi apa yang akan dikirimkan kepada kami melalui layanan atau email. Selain itu, kami tidak bertanggung jawab atas hambatan apa pun terhadap pengaturan privasi atau tindakan keamanan dalam layanan atau pengaturan keamanan atau tindakan keamanan Situs Web Pihak Ketiga. Kami akan menyimpan informasi pribadi Anda selama diperlukan untuk menyediakan layanan kepada Anda atau untuk tujuan bisnis sah lainnya, seperti menyelesaikan perselisihan, alasan keamanan dan keselamatan, atau mematuhi kewajiban hukum kami. Lamanya waktu kami menyimpan informasi pribadi akan bergantung pada banyak faktor, seperti jumlah, sifat, sensitivitas informasi, potensi risiko penggunaan atau pengungkapan tanpa izin, tujuan kami memproses informasi tersebut, dan pertimbangan mengikat lainnya secara hukum.',
    subTitle7: 'Modifikasi Kebijakan Privasi',
    block11:
      'Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Ketika kami melakukannya, kami akan memposting versi terbaru di halaman ini, kecuali diwajibkan lain oleh hukum yang berlaku.',
    subTitle8: 'Cara Menghubungi Kami',
    block12:
      'Jika Anda memiliki pertanyaan atau kekhawatiran yang belum terjawab tentang Kebijakan Privasi ini, silakan hubungi layanan pelanggan kami. Surel:',
  },
  terms: {
    title: 'Ketentuan Layanan',
    updateTime: 'Diperbarui:15 Maret 2024',
    block1: 'Terima kasih telah menggunakan Noam!',
    block2:
      'Ketentuan Penggunaan ini berlaku untuk penggunaan Anda atas layanan Noam, termasuk antarmuka pemrograman aplikasi, perangkat lunak, alat, layanan pengembang, data, dokumentasi, dan situs web kami (secara kolektif disebut sebagai "Layanan"). Dengan menggunakan Layanan kami, Anda setuju untuk mematuhi ketentuan ini. Kebijakan Privasi kami menjelaskan cara kami mengumpulkan dan menggunakan informasi pribadi.',
    subTitle1: 'Pendaftaran dan Akses',
    block3:
      'Jika Anda berusia di bawah 18 tahun, Anda harus mendapatkan izin dari orang tua atau wali sah Anda untuk menggunakan Layanan. Jika Anda menggunakan Layanan atas nama orang atau entitas lain, Anda harus diberi wewenang untuk menerima ketentuan ini atas nama mereka. Anda harus memberikan informasi yang akurat dan lengkap untuk mendaftar akun. Anda tidak boleh memberikan kredensial akses atau akun Anda kepada siapa pun di luar organisasi Anda, dan Anda bertanggung jawab atas semua aktivitas yang dilakukan menggunakan kredensial Anda.',
    subTitle2: 'Persyaratan Penggunaan',
    block4:
      '(a) Penggunaan Layanan. Anda dapat mengakses Layanan berdasarkan ketentuan ini, dan kami memberi Anda hak non-eksklusif untuk menggunakan Layanan. Saat menggunakan Layanan, Anda akan mematuhi ketentuan ini dan semua hukum yang berlaku. Kami dan afiliasi kami memiliki semua hak, kepemilikan, dan kepentingan dalam Layanan.',
    block5:
      '(b) Umpan balik. Kami menyambut umpan balik, komentar, ide, saran, dan perbaikan. Jika Anda menyediakan konten tersebut, kami dapat menggunakannya tanpa batasan apa pun dan tanpa kompensasi kepada Anda.',
    block6:
      '(c) Pembatasan. Anda tidak boleh: (1) menggunakan Layanan dengan cara yang melanggar, menyalahgunakan, atau melanggar hak orang lain; (2) merekayasa balik, mendekompilasi, membongkar, menerjemahkan, atau berupaya menemukan kode sumber atau komponen dasar model, algoritma, dan sistem Layanan (kecuali pembatasan tersebut dilarang oleh hukum yang berlaku); (3) menggunakan keluaran Layanan untuk mengembangkan model yang bersaing dengan Noam; (4) mengekstrak data atau output dari Layanan menggunakan metode otomatis atau pemrograman apa pun kecuali diizinkan melalui API, termasuk scraping, pengumpulan web, atau ekstraksi data web; (5) menyatakan keluaran Layanan sebagai hasil buatan padahal sebenarnya tidak, atau melanggar kebijakan penggunaan kami; (6) membeli, menjual, atau mentransfer kunci API tanpa persetujuan kami sebelumnya; atau (7) memberi kami informasi pribadi apa pun mengenai anak-anak di bawah 13 tahun atau usia persetujuan digital yang berlaku. Anda akan mematuhi batasan tarif dan persyaratan lainnya dalam dokumentasi kami.',
    block7:
      '(d) Layanan Pihak Ketiga. Perangkat lunak, layanan, atau produk pihak ketiga lainnya yang terkait dengan Layanan diatur oleh ketentuannya sendiri, dan kami tidak bertanggung jawab atas produk pihak ketiga.',
    subTitle3: 'Isi',
    block8:
      '(a) Konten Anda. Anda dapat memberikan masukan ("Input") ke Layanan dan menerima keluaran ("Output") yang dihasilkan dan dikembalikan berdasarkan Masukan tersebut (secara kolektif disebut sebagai "Konten"). Di antara para pihak, dan sejauh diizinkan oleh hukum yang berlaku, Anda memiliki seluruh Masukan. Noam dapat menggunakan Konten untuk menyediakan dan memelihara Layanan, mematuhi hukum yang berlaku, dan menegakkan kebijakan kami. Anda bertanggung jawab atas Konten tersebut, termasuk memastikan bahwa Konten tersebut tidak melanggar hukum yang berlaku atau ketentuan ini.',
    block9:
      '(b) Akurasi. Kecerdasan buatan dan pembelajaran mesin merupakan bidang penelitian yang berkembang pesat. Kami terus berupaya meningkatkan layanan kami agar lebih akurat, andal, aman, dan bermanfaat. Mengingat sifat pembelajaran mesin yang bersifat probabilistik, penggunaan layanan kami dapat menghasilkan keluaran yang salah dalam kasus tertentu, yang mungkin tidak secara akurat mencerminkan individu, tempat, atau fakta sebenarnya. Anda harus menilai dengan tepat keakuratan keluaran apa pun berdasarkan kasus penggunaan Anda, termasuk dengan meninjau keluaran secara manual.',
    subTitle4: 'Biaya dan Pembayaran',
    block10:
      '(a) Biaya dan Penagihan. Anda akan membayar seluruh biaya (“Biaya”) sesuai dengan harga dan ketentuan pada halaman harga yang berlaku atau sesuai kesepakatan tertulis di antara kita. Kami berhak memperbaiki kesalahan atau kesalahan penetapan harga meskipun kami telah menerbitkan faktur atau menerima pembayaran. Anda akan memberikan informasi penagihan yang lengkap dan akurat, termasuk metode pembayaran yang valid dan resmi. Kami akan menagih metode pembayaran Anda secara berkala sesuai kesepakatan, namun kami dapat mengubah tanggal penagihan secara wajar. Anda memberi wewenang kepada Noam, afiliasinya, dan pemroses pembayaran pihak ketiga untuk menagih metode pembayaran Anda. Jika pembayaran Anda gagal, kami akan memberi Anda pemberitahuan tertulis dan dapat menangguhkan akses ke Layanan hingga pembayaran diterima. Kecuali ditentukan lain dalam perjanjian ini, pembayaran tidak dapat dikembalikan.',
    block11:
      '(b) Pajak. Kecuali dinyatakan lain, Biaya tidak termasuk pajak federal, negara bagian, lokal, dan luar negeri, bea, dan penilaian serupa ("Pajak"). Anda bertanggung jawab atas semua Pajak yang terkait dengan pembelian Anda, tidak termasuk Pajak berdasarkan pendapatan bersih kami yang akan kami tagih kepada Anda. Anda setuju untuk segera membayar Pajak tersebut dan memberi kami pernyataan privasi yang sah secara hukum dan memperoleh persetujuan yang diperlukan untuk menangani data tersebut, dan Anda menjamin kepada kami bahwa Anda menangani data tersebut sesuai dengan hukum yang berlaku.',
    block12:
      '(c) Perubahan Harga. Kami dapat mengubah harga kami dengan memberi tahu Anda melalui akun Anda dan/atau situs web kami. Kenaikan harga akan berlaku segera setelah diposting. Perubahan harga apa pun akan berlaku pada Biaya akun Anda setelah tanggal efektif perubahan.',
    block13:
      '(d) Sengketa dan Keterlambatan Pembayaran. Jika Anda ingin menyengketakan Biaya atau Pajak apa pun, silakan hubungi contact@noam.tools dalam waktu tiga puluh (30) hari sejak tanggal faktur.',
    block14:
      '(e) Penggunaan Gratis. Anda tidak boleh membuat banyak akun untuk mendapatkan kredit gratis. Jika kami menentukan bahwa Anda tidak menggunakan kredit gratis dengan itikad baik, kami mungkin membebankan biaya standar kepada Anda atau berhenti memberikan akses ke Layanan.',
    subTitle5: 'Kerahasiaan, Keamanan, dan Perlindungan Data',
    block15:
      '(a) Kerahasiaan. Anda mungkin memiliki akses ke informasi rahasia Noam. Anda hanya dapat menggunakan informasi rahasia sebagaimana diizinkan berdasarkan ketentuan ini untuk tujuan penggunaan Layanan. Anda tidak boleh mengungkapkan informasi rahasia kepada pihak ketiga mana pun, dan Anda akan melindungi informasi rahasia dengan cara yang tidak kalah protektifnya dengan melindungi informasi rahasia serupa milik Anda, setidaknya dengan kehati-hatian yang wajar. Informasi rahasia adalah informasi non-publik yang ditetapkan sebagai rahasia oleh Noam atau yang seharusnya diperlakukan sebagai rahasia dalam kondisi tertentu, termasuk perangkat lunak, spesifikasi, dan informasi bisnis non-publik lainnya. Informasi rahasia tidak mencakup informasi yang: (1) tersedia secara umum untuk publik tanpa kesalahan Anda; (2) yang Anda miliki tanpa kewajiban kerahasiaan apa pun sebelum menerimanya berdasarkan ketentuan ini; (3) diungkapkan secara sah kepada Anda oleh pihak ketiga tanpa kewajiban kerahasiaan apa pun; atau (4) dikembangkan secara mandiri oleh Anda tanpa menggunakan informasi rahasia. Anda dapat mengungkapkan informasi rahasia jika diwajibkan oleh hukum, pengadilan, atau perintah pemerintah lainnya, namun Anda harus memberikan pemberitahuan tertulis sebelumnya kepada Noam dengan cara yang wajar dan, sejauh mungkin, melakukan upaya wajar untuk membatasi ruang lingkup pengungkapan, termasuk membantu kami dalam menentang permintaan pengungkapan.',
    block16:
      '(b) Keamanan. Anda harus menerapkan langkah-langkah yang wajar dan tepat untuk membantu menjaga akses Anda dan penggunaan Layanan. Jika Anda menemukan kerentanan atau pelanggaran apa pun terkait dengan penggunaan Layanan oleh Anda, Anda harus segera menghubungi Noam dan memberikan rincian kerentanan atau pelanggaran tersebut.',
    block17:
      '((c) Pemrosesan Data Pribadi. Jika Anda menggunakan Layanan untuk memproses data pribadi, Anda harus memberikan pernyataan privasi yang memadai secara hukum dan mendapatkan persetujuan yang diperlukan untuk memproses data tersebut, dan Anda menjamin kepada kami bahwa Anda menangani data tersebut sesuai dengan hukum yang berlaku .',
    subTitle6: 'Jangka Waktu dan Penghentian',
    block18:
      '(a) Penghentian; Penangguhan. Ketentuan ini akan berlaku sejak pertama kali Anda menggunakan Layanan dan akan tetap berlaku hingga diakhiri. Anda dapat mengakhiri ketentuan ini kapan saja karena alasan apa pun dengan menghentikan penggunaan Layanan dan Konten. Kami dapat mengakhiri ketentuan ini dengan pemberitahuan sebelumnya karena alasan apa pun. Kami dapat segera mengakhiri ketentuan ini dengan memberi tahu Anda jika Anda melanggar Pasal 2 (Persyaratan Penggunaan), Pasal 5 (Kerahasiaan, Keamanan, dan Perlindungan Data), Pasal 8 (Penyelesaian Sengketa), atau Pasal 9 (Ketentuan Umum), atau jika kami hubungan dengan penyedia teknologi pihak ketiga mana pun di luar kendali kami berubah, atau untuk mematuhi hukum atau permintaan pemerintah. Kami dapat menangguhkan akses Anda ke Layanan jika Anda gagal mematuhi ketentuan ini, atau jika penggunaan Anda menimbulkan risiko keamanan bagi kami atau pihak ketiga mana pun, atau jika kami mencurigai penggunaan Anda adalah penipuan atau dapat membuat kami atau pihak ketiga mana pun bertanggung jawab. .',
    block19:
      '(b) Dampak Pengakhiran. Setelah penghentian, Anda akan berhenti menggunakan Layanan dan segera mengembalikan atau menghancurkan informasi rahasia apa pun sebagaimana diarahkan oleh kami. Ketentuan-ketentuan dalam persyaratan ini yang menurut sifatnya harus tetap berlaku setelah pengakhiran atau habis masa berlakunya, termasuk namun tidak terbatas pada Bagian 3 dan 5-9, akan tetap berlaku.',
    subTitle7: 'Ganti Rugi; Penafian; Batasan Tanggung Jawab',
    block20:
      '(a) Ganti Rugi. Anda akan membela, mengganti kerugian, dan membebaskan kami, afiliasi kami, dan personel kami dari dan terhadap klaim, kerugian, dan biaya apa pun (termasuk biaya pengacara) yang timbul dari atau terkait dengan penggunaan Anda atas Layanan, termasuk Konten Anda, Anda penggunaan produk atau layanan yang terkait dengan Layanan, dan pelanggaran Anda terhadap ketentuan ini atau hukum yang berlaku.',
    block21:
      '(b) Penafian. Layanan disediakan "sebagaimana adanya." Sejauh diizinkan oleh undang-undang, kami dan afiliasi serta pemberi lisensi kami tidak membuat pernyataan atau jaminan apa pun mengenai Layanan dan tidak memberikan jaminan, termasuk namun tidak terbatas pada jaminan kelayakan untuk diperdagangkan, kesesuaian untuk tujuan tertentu, kualitas yang memuaskan, tidak adanya pelanggaran. , dan kenikmatan yang tenang, dan jaminan apa pun yang timbul selama transaksi atau penggunaan perdagangan. Kami tidak menjamin bahwa Layanan tidak akan terganggu, akurat, atau bebas dari kesalahan, atau bahwa Konten apa pun akan aman, tidak hilang, atau tidak diubah.',
    block22:
      '(c) Batasan Tanggung Jawab. Baik kami, afiliasi kami, maupun pemberi lisensi kami tidak akan bertanggung jawab atas kerugian tidak langsung, insidental, khusus, konsekuensial, atau hukuman, termasuk hilangnya keuntungan, hilangnya reputasi, hilangnya penggunaan, hilangnya data, atau kerugian tidak berwujud lainnya, bahkan jika kami telah diberitahu tentang kemungkinan kerusakan tersebut. Berdasarkan ketentuan ini, total tanggung jawab kami tidak akan melebihi Biaya yang Anda bayarkan ke Layanan dalam dua belas (12) bulan sebelum klaim atau seratus dolar AS ($100), mana saja yang lebih besar. Pembatasan dalam bagian ini berlaku sejauh yang diizinkan oleh hukum yang berlaku.',
    subTitle8: 'Hukum dan Yurisdiksi yang Mengatur',
    block23:
      'Ketentuan dan penggunaan Layanan oleh Anda diatur oleh undang-undang Amerika Serikat, kecuali aturan pertentangan undang-undang AS. Penggunaan Anda atas Aplikasi mungkin juga tunduk pada hukum lokal, negara bagian, nasional, atau internasional lainnya. Segala tindakan atau proses hukum terkait dengan Ketentuan ini akan diajukan secara eksklusif ke pengadilan Amerika Serikat, dan Anda setuju untuk tunduk pada yurisdiksi pribadi pengadilan tersebut.',
    subTitle9: 'Ketentuan Umum',
    block24:
      '(a) Hubungan Para Pihak. Noam dan Anda adalah kontraktor independen, dan tidak ada pihak yang memiliki kekuasaan untuk mewakili atau mengikat pihak lain atau memikul kewajiban untuk pihak lain tanpa persetujuan tertulis sebelumnya dari pihak lainnya.',
    block25:
      '(b) Penggunaan Merek. Anda tidak boleh menggunakan nama, logo, atau merek dagang Noam atau afiliasinya tanpa izin tertulis sebelumnya dari kami.',
    subTitle10: 'Keterpisahan',
    block26:
      'Jika ada ketentuan dalam ketentuan ini yang dianggap tidak sah, ilegal, atau tidak dapat dilaksanakan, ketentuan lainnya akan tetap mempunyai kekuatan dan pengaruh penuh.',
  },
  contact: {
    title: 'Hubungi kami',
    block1:
      'Untuk penyesuaian atau jika Anda memiliki pertanyaan atau saran, silakan hubungi kami di contact@noam.tools dan kami akan segera merespons.',
    block2: 'Dukung kami dengan memberi peringkat pada kami di toko Ekstensi Google Apps di ★★★★★.',
    block3: 'Bantu orang lain dengan membagikan ekstensi ini:',
  },
  pdf: {
    toast1: 'PDF sedang dimuat, coba lagi nanti!',
    toast2: 'Konten PDF terlalu banyak, untuk saat ini tidak mendukung dialog',
    toast3: 'Mengunduh, silakan coba lagi setelah unduhan selesai.',
    shareMsg1: 'Bagikan Pembuatan Tautan Berhasil',
    shareMsg2: 'Bagikan Pembaruan Konten Berhasil',
    shareMsg3:
      'Pembuatan tautan berbagi gagal, coba lagi nanti atau hubungi layanan pelanggan kami.',
    shareMsg4: 'Gagal berbagi pembaruan konten, coba lagi nanti atau hubungi layanan pelanggan',
    shareBtnText1: 'Hasilkan Tautan Bagikan',
    shareBtnText2: 'Konten berbagi yang diperbarui',
    uploading: 'Mengunggah',
    uploadingMsg: 'Harap bersabar karena perlu beberapa waktu untuk mengunggah.',
    cancelUpload: 'Membatalkan',
    uploadErr: 'Gagal mengunggah file. Silakan coba lagi nanti atau hubungi layanan pelanggan',
    uploadMsg1: 'Silakan drag dan drop file di sini',
    uploadMsg2: 'Jenis file yang didukung: PDF |  Ukuran file maksimal: 100MB',
    uploadMsg3: 'Klik untuk memilih atau drag dan drop file untuk diunggah di sini',
    searchPlaceholder: 'Mencari',
    collectionEmpty: 'Tidak ada konten dalam koleksi~',
    kw: 'pokok',
    video: 'video',
    link: 'tautan',
    dataLoading: 'Memuat data...',
    freeBannerTips: 'Akhir dari Terjemahan PDF Gratis',
    vipBannerTips: 'Anda telah mencapai batas terjemahan pdf di bulan ini.',
    freeBannerBtn: 'Tingkatkan untuk menikmati lebih banyak',
    vipBannerBtn: 'Tingkatkan untuk menikmati lebih banyak',
    dialogTitle: 'Perhatian',
    dialogContent: 'Apakah Anda yakin ingin menghapusnya?',
    okBtn: 'Menghapus',
    cancelBtn: 'Membatalkan',
    unLoginToast: 'Silakan login untuk menggunakan fungsi ini',
    parseErrTips: 'Tidak dapat menguraikan file',
    new: 'file baru',
    parseErrDialogContent:
      'Tautan PDF saat ini tidak mendukung terjemahan langsung, silakan unduh tautan PDF asli setelah terjemahan',
    okBtn2: 'mengenali',
    notPdf: 'Hanya file PDF',
    uploadingMsg1: 'Pengunggahan file sedang berlangsung. Silakan coba lagi nanti.',
    uploadingMsg2: 'Saat ini Anda memiliki file yang sedang dikonversi, silakan coba lagi nanti.',
    uploadingMsg3:
      'Permintaan kemajuan konversi gagal, coba lagi nanti atau hubungi layanan pelanggan',
    uploadingMsg4:
      'Noam tidak dapat mengakses file lokal Anda secara langsung, harap unggah PDF secara manual untuk diterjemahkan.',
    uploadingMsg5:
      'Gagal mendapatkan url file, silakan coba lagi nanti atau hubungi layanan pelanggan.',
    fileMsg: 'Volume berkas saat ini:',
    fileMsg2: 'Silakan unggah file yang lebih kecil dari 100M',
    fileErrMsg: 'Pembacaan file gagal, coba lagi nanti atau hubungi layanan pelanggan.',
    parseFileToast: 'Penguraian file gagal, coba lagi nanti atau hubungi layanan pelanggan kami.',
    noId: 'ID berkas tidak ada',
    autoDetect: 'Deteksi otomatis',
    hasTransTask: 'Ada tugas yang sedang diterjemahkan. Harap tunggu.',
    transSucc: 'Terjemahan berhasil!',
    entry: 'Daftar PDF',
    listError: 'Daftar PDF gagal dimuat, coba lagi nanti atau hubungi layanan pelanggan.',
    collectionTab: 'Memo',
    pdfLoading: 'Mengurai...',
    installTips:
      'Instal plug-in kecil, Anda dapat menerjemahkan lebih banyak file PDF, Anda juga dapat merasakan terjemahan halaman web yang lancar:',
    webTrans: 'referensi silang penuh',
    hoverTrans: 'Arahkan terjemahan paragraf',
    selectTrans: 'garis bawahi kata-katanya dan terjemahkan',
    installText: 'Toko serba ada untuk semua kebutuhan terjemahan harian Anda',
    start: 'Mulai pengalaman gratis Anda',
    shareTips: 'Terjemahan PDF selesai, lanjutkan dan bagikan dengan teman-teman Anda!',
    download: {
      loading: 'Diselamatkan...',
      btn: 'Simpan sekarang',
      trans: 'Terjemahan saja',
      both: 'dua bahasa',
      download: 'Unduh',
      msg1: 'Beberapa terjemahan dokumen saat ini lebih panjang dari teks aslinya, dan teks mungkin tumpang tindih setelah diunduh, jadi disarankan untuk membacanya di halaman web.',
      msg2: '1、Konten belum sepenuhnya diterjemahkan, silakan unduh setelah terjemahan lengkap.',
      msg3: '2、Dokumen bagian terjemahan terlalu panjang, Anda dapat membaca di halaman web untuk menghindari teks yang tumpang tindih.',
      msg4: 'Saat ini ada tugas yang sedang diterjemahkan, silakan coba lagi setelah terjemahan selesai.',
      fileName: 'Nama file:',
      fileType: 'Jenis file unduhan:',
      transContinue: 'Lanjutkan menerjemahkan',
      save: 'Simpan ke lokal',
      downloadMsg1: 'Kemajuan PDF dalam terjemahan:',
      downloadMsg2: 'Persyaratan yang diproyeksikan',
      minute: 'Menit...',
      downloadSucc: 'Pengunduhan PDF selesai, klik simpan sekarang!',
    },
    guide: {
      title1: 'Terjemahan Bilingual',
      title2: 'Obrolan PDF',
      text1: 'Membaca Bilingual',
      text2:
        'Membaca bilingual, sehingga Anda dapat membandingkan teks asli dan terjemahannya saat membaca untuk meningkatkan efisiensi membaca',
      text3: 'Obrolan PDF',
      text4:
        'AI menganalisis dan merangkum, serta dapat menjawab pertanyaan Anda berdasarkan konten PDF',
      text5: 'Akurasi dan Efisiensi',
      text6: 'PDF yang didukung AI untuk terjemahan yang lebih akurat dan efisien',
      placeholder: 'Silakan isi tautan PDF',
      start: 'Mulai terjemahan',
      toast: 'Silakan masukkan tautan pdf online yang valid yang diakhiri dengan .pdf',
    },
    original: 'Asli',
    parallel: 'Paralel',
    trans: 'Terjemahan',
    initial: 'Terjemahkan PDF',
    thumbnail: 'katalog',
    downloadBtn: 'Unduh',
    shareBtn: 'Membagikan',
    uploadBtn: 'Mengunggah',
    installText2: 'Silakan instal terlebih dahulu',
    chatPdf: {
      clearSucc: 'Berhasil dibersihkan',
      clear: 'Jernih',
      clearContent:
        'Setelah dihapus, percakapan baru akan dibuat dan isi percakapan ini tidak akan disimpan, apakah Anda yakin ingin menghapusnya?',
      clearDialogTitle: 'Perhatian',
      cancel: 'Membatalkan',
      chatTitle: 'Selamat datang di Noam, ringkasan PDF Anda sudah siap:',
      summaryTitle: 'Pertanyaan yang mungkin menarik bagi Anda:',
      placeholder: 'Masukkan pertanyaan apa pun tentang PDF',
      copy: 'Menyalin',
      copySucc: 'Salin Sukses',
      historyTitle: 'Sejarah',
      expand: 'Runtuh',
      noDataDesc: 'Tidak ada sejarah~',
      noMore: 'Tidak lagi.',
      Search: 'Mencari',
      delete: 'Menghapus',
      loading: 'memuat...',
      deleteTitle: 'Tip',
      deleteContent:
        'Setelah dihapus, PDF ini tidak dapat dipulihkan, apakah Anda ingin menghapusnya?',
      deleteOk: 'Menghapus',
      deleteCancel: 'Membatalkan',
      deleteSuccess: 'Hapus berhasil',
      listError:
        'Daftar bacaan gagal dimuat. Silakan coba lagi nanti atau hubungi dukungan pelanggan',
    },
    shareModal: {
      shareSetting: 'Bagikan Pengaturan Konten',
      shareText: 'Membagikan',
      text1: 'Pengaturan Berbagi',
      text2: 'Hasilkan Tautan Bagikan',
      range: 'Cakupan:',
      custom: 'Kustomisasi:',
      page: 'halaman',
      sourceLang: 'Sumber:',
      target: 'Sasaran:',
      errMsg:
        'Konten rentang berbagi saat ini belum sepenuhnya diterjemahkan, jadi harap terjemahkan sebelum dibagikan.',
      pageNuErr: 'Silakan masukkan nilai nomor halaman yang benar',
      trans: 'Menerjemahkan',
      cancel: 'Membatalkan',
      update: 'Tautan berbagi yang diperbarui',
      gen: 'Membagikan',
      shareTips: 'Bagikan tautan PDF terjemahan dengan teman Anda untuk dibaca:',
      copySucc: 'Salin Tautan Berhasil',
      copy: 'Menyalin',
      settings: 'Bagikan Pengaturan:',
      modify: 'memodifikasi',
      translateTips:
        'Tip: Jika terjemahan memerlukan waktu lama, Anda dapat menutup jendela pop-up dan membagikannya setelah terjemahan selesai.',
      translating: 'Terjemahan PDF...',
      remain: 'tersisa',
      remain1: 'Total kebutuhan yang diproyeksikan',
      totalPage: 'Totalnya 1 halaman',
      search: 'Mencari',
      notSupport: 'Bahasa ini saat ini tidak didukung',
    },
    toolbar: { Adjust: 'Menyesuaikan', autoFit: 'Cocok otomatis' },
    memoTotalCount: 'Total 1 konten',
    dragTipModal: {
      press: 'Tekan',
      black: 'ruang angkasa',
      leftKey: 'tombol kiri',
      tips: 'untuk menyeret dan melihat.',
      ok: 'Saya mendapatkannya',
    },
  },
  locale: {
    zh: 'Cina',
    en: 'Bahasa inggris',
    es: 'Orang Spanyol',
    ru: 'Rusia',
    pt: 'Portugis (Brasil)',
    id: 'bahasa Indonesia',
    cs: 'Ceko',
    de: 'Jerman',
    fr: 'Perancis',
    it: 'Italia',
    nl: 'Belanda',
    pl: 'Polandia',
    vi: 'Orang Vietnam',
    tr: 'Turki',
    uk: 'Orang Ukraina',
    zhHant: 'Cina tradisional',
    ja: 'Jepang',
    ko: 'Korea',
    ar: 'Arab',
    fa: 'Orang Persia',
    th: 'Thai',
    hi: 'Hindi',
    noSearchLang: 'Bahasa ini tidak didukung',
    placeholder: 'Mencari',
    af: 'Afrikanas',
    am: 'Amharik',
    as: 'orang Assam',
    az: 'Azerbaijan',
    ba: 'Bashkir',
    bg: 'Bulgaria',
    bn: 'Bangla',
    bo: 'Tibet',
    bs: 'Bosnia',
    ca: 'Katalan',
    cy: 'bahasa Wales',
    da: 'Orang Denmark',
    dsb: 'Sorbia Bawah',
    dv: 'Divehi',
    el: 'Orang yunani',
    et: 'Estonia',
    eu: 'Basque',
    fi: 'Finlandia',
    fil: 'Filipina',
    fj: 'Fiji',
    frCA: 'Prancis (Kanada)',
    ga: 'orang Irlandia',
    gl: 'Galisia',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'rumah',
    he: 'Ibrani',
    hr: 'Kroasia',
    hsb: 'Sorbia Atas',
    ht: 'Kreol Haiti',
    hu: 'Hongaria',
    hy: 'orang Armenia',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'Islandia',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latin)',
    ka: 'orang Georgia',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurdi (Utara)',
    kn: 'Kannada',
    ku: 'Kurdi (Tengah)',
    ky: 'Kirgistan',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'Lithuania',
    lug: 'Ganda',
    lv: 'Latvia',
    lzh: 'Cina (Sastra)',
    mai: 'Maithili',
    mg: 'Malagasi',
    mi: 'Māori',
    mk: 'Makedonia',
    ml: 'Malayalam',
    mnCyrl: 'Mongolia (Sirilik)',
    mnMong: 'Mongolia (Tradisional)',
    mr: 'Marathi',
    ms: 'Melayu',
    mt: 'Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Burma)',
    nb: 'Norwegia',
    ne: 'Nepal',
    nso: 'Sesotho di Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    prs: 'Dari',
    ps: 'pasto',
    ptPT: 'Portugis (Portugal)',
    ro: 'Rumania',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Orang Slovakia',
    sl: 'Slovenia',
    sm: 'Samoa',
    sn: 'Shona',
    so: 'Somalia',
    sq: 'bahasa Albania',
    srCyrl: 'Serbia (Sirilik)',
    srLatn: 'Serbia (Latin)',
    st: 'Sesoto',
    sv: 'Orang Swedia',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    ti: 'harimaunya',
    tk: 'orang Turkmenistan',
    tlhLatn: 'Klingon (Latin)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tonga',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'Uyghur',
    ur: 'bahasa Urdu',
    uz: 'Uzbekistan (Latin)',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Bahasa Kanton (Tradisional)',
    zhHans: 'Bahasa Tiongkok yang disederhanakan',
    zu: 'Zulu',
    auto: 'Deteksi otomatis',
  },
  stripe: {
    contact: 'Hubungi kami di contact@noam.tools jika ada pertanyaan!',
    fail: {
      title: 'Pembayaran gagal',
      text1: 'Sayangnya, kami tidak dapat memproses permintaan pembayaran Anda.',
      text2: 'Silakan periksa koneksi internet Anda dan coba lagi.',
      btn: 'Mencoba kembali',
    },
    succ: {
      title: 'Selamat',
      text1: 'selamat datang di Noam,',
      text2: 'mari kita mulai bersama!',
      btn: 'Ayo lakukan keajaiban',
    },
  },
  welfare: {
    newUser: {
      label: 'Manfaat Pengguna Baru:',
      joinBtn: 'Bergabung sekarang',
      text: 'Hanya 100 Tempat yang Tersedia! Raih Milikmu Sekarang!',
      remain: 'Hanya tersisa 30',
    },
  },
  help: {
    Fixedplugin: 'Memperbaiki plugin',
    Howtouse: 'Cara Penggunaan',
    try: 'Cobalah',
    FreeTrialBenefits: 'Manfaat uji coba gratis',
    pin: 'Pin Noam, untuk akses lebih mudah～～',
    Video: 'Video',
    ParallelTranslation: 'Terjemahan Paralel',
    WebSummary: 'Ringkasan Web',
    ChatPDF: 'Obrolan PDF',
    AquickOverviewOfNoam: 'Ikhtisar singkat tentang Noam',
    WordSelectionTranslation: 'Terjemahan pemilihan kata',
    HoverTranslation: 'Arahkan terjemahan',
    ImageTranslation: 'Terjemahan gambar',
    InstantTranslation: 'Terjemahan instan',
    VideoTranslation: 'Terjemahan video',
    benefits: 'Kami telah menyiapkan manfaatnya untuk Anda!',
    Free: 'Bebas',
    Membership: 'Keanggotaan',
    Translation: 'Terjemahan',
    PDFparallelTranslation: 'Terjemahan paralel PDF',
    ChatPDF2: 'ObrolanPDF',
    Summary: 'Ringkasan',
    VideoSummary: 'Ringkasan video',
    FavoritesManagement: 'Manajemen favorit',
    Focusing: 'Fokus',
    dayFree: 'Uji Coba Gratis 3 Hari',
    planIsNotExist: 'rencana tidak ada',
    TryItForFree: 'Cobalah Secara Gratis',
    startYoutube: 'Mari kita mulai dengan YouTube~',
    startWiki: 'Mari kita mulai dengan Wikimedia~',
    tryNow: 'Cobalah sekarang~',
    GoWithYouTube: 'Gunakan YouTube',
    GoWithWikipedia: 'Gunakan Wikipedia',
    NoamHelp: 'Noam dapat membantu Anda menerjemahkan dan meringkas',
    SkipAllSteps: 'Lewati semua langkah',
    next: 'Berikutnya',
    done: 'Selesai',
  },
  trialModal: {
    title: 'Hadiah selamat datang',
    trialText: 'Uji Coba 7 Hari',
    desc1: 'Setelah uji coba berakhir, Anda akan dikenakan biaya tahunan sebesar $180',
    desc2: 'Batalkan Kapan Saja',
    btn: 'Mulai Sekarang（Hanya $0,99）',
    installBtn: 'Instal sekarang untuk menerima hadiah Anda',
    web: 'Terjemahan Paralel',
    select: 'Terjemahan pemilihan kata',
    hover: 'Arahkan terjemahan',
    image: 'Terjemahan gambar',
    realtime: 'Terjemahan instan',
    video: 'Terjemahan video',
    pdf: 'Terjemahan paralel PDF',
    chat: 'ObrolanPDF',
    webSummary: 'Ringkasan web',
    videoSummary: 'Ringkasan video',
    memo: 'Manajemen favorit',
    focus: 'Fokus',
  },
  privacyCollectionPanel: {
    title: 'Situs web ini menggunakan cookie',
    text: 'Untuk meningkatkan pengalaman Anda, kami ingin menggunakan cookie untuk mempersonalisasi konten dan layanan.',
    acceptBtn: 'Terima semua',
  },
  installTips: {
    text: 'Instal “Noam Browser Plugin” untuk membuka lebih banyak fitur! Referensi silang web,\n    ringkasan video web, dan banyak lagi!',
    btn: 'Coba sekarang',
  },
}

export default TRANSLATION
