import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace user {
  export function getLoginLink() {
    return get('/google/oauth2/link', {}, true)
  }

  export function getUserInfo(): Promise<ApiBaseResult & { userData: ServerDataTypes.UserInfo }> {
    return get('/user/me')
  }
  export function logout(): Promise<ApiBaseResult> {
    return get('/user/logout')
  }

  // 记录用户访问行为
  export function postVisitorRecord(data: { token: string; actionType: string }) {
    return post('/visitor/record', {}, data)
  }

  export function getUrlConfig(): Promise<
    ApiBaseResult & {
      version: string
      enableUpgradeProxy: boolean
      /** 用于控制 popup 里面邀请的按钮展示 */
      enableInviteProxy: boolean
      // 用于控制是否在插件内调起付费弹窗
      enablePayDialog: boolean
      // 是否能购买加量包
      enableTokenPlan: boolean
      // 是否对 shadowRoot 节点进行翻译
      enableShadowRootTranslate: boolean
      // 控制图片翻译时，对图片大小的判断，值为：宽 * 高
      imageSize: number
      // 是否使用缓存接口
      useTranslateCache: boolean
      // 是否全量使用旧版本付费弹窗
      applyBuyVipDialogOld: boolean
      // 是否全量使用 v1 版本付费弹窗
      applyBuyVipDialogV1: boolean
      // 图片翻译禁止的url
      disableImageUrl: string[]
      // // youtube引导页
      // guideYoutubeUrl: string
      // // 网页引导页
      // guideWebUrl: string
      noReduceTokenUrlList: {
        name: 'guide_youtube_url' | 'guide_web_url'
        url: string
      }[]
    }
  > {
    return get('/url_config')
  }

  export function addEventRecord(data: {
    eventType: string
    properties?: string
    inviteCode?: string
  }) {
    return post('/event_record/add', {}, data)
  }
}
