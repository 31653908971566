import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './LoginModal.module.less'
import { Modal } from 'antd'
import { commonUtils } from '@/helpers/commonUtils'
import { DEFAULT_LOGIN_URL, getLoginParams } from '@/pages/LoginPage'
import { useTranslationWithType } from '@/i18n/i18n'
import { uiController } from '@/controllers/uiController'

/** LoginModal 组件的 Props */
export type LoginModalProps = {
  open: boolean
  url?: string
}

const LoginModal: FC<LoginModalProps> = observer(({ open, url }: LoginModalProps) => {
  const { t } = useTranslationWithType()
  const [loginUrl, setLoginUrl] = useState(DEFAULT_LOGIN_URL)
  useEffect(() => {
    setLoginUrl(
      url || commonUtils.genApiUrl(DEFAULT_LOGIN_URL, commonUtils.underlize(getLoginParams() || {}))
    )
  }, [url])
  return (
    <Modal
      open={open}
      centered
      wrapClassName={style.loginModal}
      footer={false}
      width={'470px'}
      closable
      maskClosable={false}
      onCancel={() => {
        uiController.loginModalProps = { open: false }
      }}
    >
      <div className={classNames('flex-col', style.content)}>
        <img className={classNames(style.logo)} src={commonUtils.getStaticFile('logo.png')} />
        <div className={classNames(style.desc, 'flex-row', 'align-center')}>
          <img
            className={classNames(style.bg)}
            src={commonUtils.getStaticFile('login/bg__login-modal.png')}
          />
          <span>{t('login.desc')}</span>
        </div>
        <a className={classNames(style.loginBtn, 'flex-row')} href={loginUrl}>
          <img
            className={classNames(style.icon)}
            src={commonUtils.getStaticFile('login/icon__google.png')}
          />
          {t('login.btn1')}
        </a>
        <p className={classNames(style.smallText)}>
          {t('login.tips')}
          <a href='/terms' target='_black'>
            {t('login.terms')}
          </a>
          {t('login.and')}
          <a href='/policy' target='_black'>
            {t('login.policy')}
          </a>
        </p>
      </div>
    </Modal>
  )
})

export default LoginModal
