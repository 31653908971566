import AR from './locales/ar/translation'
import CS from './locales/cs/translation'
import DE from './locales/de/translation'
import EN from './locales/en/translation'
import ES from './locales/es/translation'
import FA from './locales/fa/translation'
import FR from './locales/fr/translation'
import HI from './locales/hi/translation'
import ID from './locales/id/translation'
import IT from './locales/it/translation'
import JA from './locales/ja/translation'
import KO from './locales/ko/translation'
import NL from './locales/nl/translation'
import PL from './locales/pl/translation'
import PT from './locales/pt/translation'
import RU from './locales/ru/translation'
import TH from './locales/th/translation'
import TR from './locales/tr/translation'
import UK from './locales/uk/translation'
import VI from './locales/vi/translation'
import ZH from './locales/zh/translation'
import ZH_HANT from './locales/zh-Hant/translation'

const resources = {
  ar: { translation: AR },
  cs: { translation: CS },
  de: { translation: DE },
  en: { translation: EN },
  es: { translation: ES },
  fa: { translation: FA },
  fr: { translation: FR },
  hi: { translation: HI },
  id: { translation: ID },
  it: { translation: IT },
  ja: { translation: JA },
  ko: { translation: KO },
  nl: { translation: NL },
  pl: { translation: PL },
  pt: { translation: PT },
  ru: { translation: RU },
  th: { translation: TH },
  tr: { translation: TR },
  uk: { translation: UK },
  vi: { translation: VI },
  zh: { translation: ZH },
  'zh-Hant': { translation: ZH_HANT },
}

export default resources
