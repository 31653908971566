import { message, notification } from 'antd'
import { ArgsProps, NotificationInstance } from 'antd/es/notification/interface'
import { v4 as uuidV4 } from 'uuid'
import './toast.less'

export class ToastController {
  public notification: NotificationInstance | null = null

  private toast = message

  public notify(
    type: 'success' | 'error',
    { message, duration, description }: ArgsProps & { btnText?: string }
  ) {
    notification[type]({
      duration,
      message,
      description,
    })
  }

  public warning(content: string | React.ReactNode, duration?: number) {
    const uid = uuidV4()
    message.config({
      getContainer: () => this.getContainer(),
      prefixCls: 'ant-message',
      duration,
    })

    this.toast.warning({
      content,
      onClose: () => this.handleToastClose(uid),
      className: `toast-${uid}`,
    })

    return {
      close: () => this.handleToastClose(uid),
    }
  }

  public error(content: string | React.ReactNode, duration?: number) {
    const uid = uuidV4()
    message.config({
      getContainer: () => this.getContainer(),
      prefixCls: 'ant-message',
      duration,
    })

    this.toast.error({
      content,
      onClose: () => this.handleToastClose(uid),
      className: `toast-${uid}`,
    })

    return {
      close: () => this.handleToastClose(uid),
    }
  }

  public success(content: string | React.ReactNode, duration?: number) {
    const uid = uuidV4()
    message.config({
      getContainer: () => this.getContainer(),
      prefixCls: 'ant-message',
      duration,
    })
    this.toast.success({
      content,
      onClose: () => this.handleToastClose(uid),
      className: `toast-${uid}`,
    })
    return {
      close: () => this.handleToastClose(uid),
    }
  }

  /**
   * 由于 shadow dom 的限制, antd 的 message 无法自己 remove, 要帮他一把
   */
  private handleToastClose = (toastId: string) => {
    const toast = this.getContainer().querySelector(`.toast-${toastId}`)
    if (toast) {
      toast.remove()
    }
  }

  private getContainer() {
    return document.body
  }
}
