import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import style from './NewUserCountdownAlert.module.less'
import { commonUtils } from '@/helpers/commonUtils'
import AmeIcon from '../AmeIcon/AmeIcon'
import Countdown from 'react-countdown'
import { userService } from '@/services/userService'
import { useTranslationWithType } from '@/i18n/i18n'
import { Button } from 'antd'
import { routerService } from '@/services/routerService'

/** NewUserCountdownAlert 组件的 Props */
export type NewUserCountdownAlertProps = {
  endTime: number
  onClose: () => void
}

const NewUserCountdownAlert: FC<NewUserCountdownAlertProps> = observer(
  ({ endTime, onClose }: NewUserCountdownAlertProps) => {
    const { t } = useTranslationWithType()
    const [enableClose, setEnableClose] = useState(false)
    if (!userService.isLogin) {
      return null
    }
    const parseTimeToStr = (time: number) => {
      return time < 10 ? '0' + time : '' + time
    }
    return (
      <div className={classNames(style.newUserCountdownAlert)}>
        <img
          src={commonUtils.getStaticFile('newUser/bg__alert-v1.png')}
          alt=''
          className={classNames(style.bg)}
        />
        <div className={classNames(style.box)}>
          <img
            className={classNames(style.countdownIcon)}
            src={commonUtils.getStaticFile('newUser/icon__countdown.png')}
          />

          <Countdown
            date={endTime}
            onTick={({ days }) => {
              setEnableClose(days > 0)
            }}
            onComplete={() => {
              onClose()
            }}
            renderer={({ hours, minutes, seconds, days }) => {
              return (
                <div className={classNames(style.countdownBox)}>
                  <CountdownItem text={parseTimeToStr(days)}></CountdownItem>
                  <span className={classNames(style.colon)}>:</span>
                  <CountdownItem text={parseTimeToStr(hours)}></CountdownItem>
                  <span className={classNames(style.colon)}>:</span>
                  <CountdownItem text={parseTimeToStr(minutes)}></CountdownItem>
                  <span className={classNames(style.colon)}>:</span>
                  <CountdownItem text={parseTimeToStr(seconds)}></CountdownItem>
                </div>
              )
            }}
          />

          <div className={classNames(style.center)}>
            <img
              className={classNames(style.starIcon)}
              src={commonUtils.getStaticFile('newUser/icon__star.png')}
            />
            <span className={classNames(style.label)}>{t('welfare.newUser.label')}</span>
            <span className={classNames(style.strong)}>
              <span>20%off</span>
            </span>
          </div>
          <Button
            className={style.joinBtn}
            onClick={() => {
              routerService.navigateTo('pricing')
            }}
          >
            {t('welfare.newUser.joinBtn')}
            <img
              className={classNames(style.arrowIcon)}
              src={commonUtils.getStaticFile('icon__arrow-primary-color.png')}
            />
          </Button>
        </div>
        {enableClose && (
          <AmeIcon
            type='icon-line-global_colse'
            className={style.closeIcon}
            onClick={onClose}
          ></AmeIcon>
        )}
      </div>
    )
  }
)

const CountdownItem = observer(({ text }: { text: string }) => {
  return (
    <>
      <span className={classNames(style.countdownItem)}>{text}</span>
    </>
  )
})
export default NewUserCountdownAlert
