const TRANSLATION = {
  slogen: 'Trợ lý AI dễ dàng nhất',
  pageHeader: {
    help: 'Trung tâm trợ giúp',
    contact: 'Liên hệ chúng tôi',
    pricing: 'Định giá',
    signUp: 'Đăng nhập',
    settings: 'Cài đặt',
    logout: 'Đăng xuất',
    logoutSucc: 'Đăng xuất thành công',
  },
  pageFooter: {
    product: {
      title: 'Các sản phẩm',
      web: 'Dịch Web & Tổng Hợp',
      pdf: 'Dịch PDF',
      video: 'Dịch & Tổng Hợp Video',
      image: 'Dịch hình ảnh',
    },
    resources: { title: 'Tài nguyên' },
    follow: { title: 'Theo', twitter: 'Twitter', facebook: 'Facebook' },
    legal: { title: 'Hợp pháp', policy: 'Chính sách bảo mật', terms: 'Điều khoản dịch vụ' },
  },
  home: {
    getStart: 'Bắt đầu',
    getStartedForFree: 'Bắt đầu miễn phí',
    top: {
      desc: 'Noam là một tiện ích mở rộng trình duyệt AI miễn phí giúp bạn dịch các trang web, video và nội dung PDF tóm tắt.\n      Tham gia Noam và tận hưởng sự kỳ diệu!',
      title2: 'Một phép thuật',
      title1: 'Một cú nhấp chuột',
    },
    howItWork: {
      title: 'Vậy, nó hoạt động như thế nào',
      desc: 'Noam tích hợp các mô hình AI để cung cấp cho người dùng trải nghiệm thông minh về dịch và tóm tắt trang web đa ngôn ngữ, dịch PDF và hình ảnh, dịch video và tóm tắt',
    },
    PDFTranslate: {
      title: 'Dịch PDF',
      desc: 'Dịch song ngữ + Chat PDF',
      sub1: {
        title: 'Đọc song ngữ',
        desc: 'Đọc song ngữ, vì vậy bạn có thể so sánh văn bản gốc và bản dịch trong khi đọc để nâng cao hiệu quả đọc',
      },
      sub2: {
        title: 'Trò chuyện PDF',
        desc: 'AI phân tích và tóm tắt và có thể trả lời câu hỏi của bạn dựa trên nội dung PDF',
      },
      sub3: {
        title: 'Độ chính xác và hiệu quả',
        desc: 'PDF được hỗ trợ bởi AI để có bản dịch chính xác và hiệu quả hơn',
      },
    },
    aiAssistant: {
      title: 'Trợ lý AI',
      desc: 'Làm cho việc dịch web thông minh hơn và thuận tiện hơn',
      panel1: {
        title: 'Dịch thuật song ngữ',
        desc: 'Các bản dịch tham khảo chéo song ngữ giúp bạn so sánh văn bản gốc với bản dịch để bạn có thể hiểu rõ hơn và nâng cao hiệu quả đọc của mình.',
      },
      panel2: {
        left: {
          title: 'Dịch di chuột',
          desc: 'Khi duyệt một trang web, hãy di chuột trực tiếp lên nội dung cần dịch để nhận được kết quả dịch.',
        },
        right: {
          title: 'Dịch lựa chọn văn bản',
          desc: 'Khi duyệt trang web, chọn trực tiếp đoạn văn bản cần gạch chân để có kết quả dịch.',
        },
      },
      panel3: {
        one: {
          title: 'Điểm và nhấp chuột',
          desc: 'Dịch và tóm tắt các trang web, nội dung video cho bạn mọi lúc, mọi nơi, trong mọi tình huống; mang đến cho bạn trải nghiệm mượt mà hơn!',
        },
        two: {
          title: 'Toàn diện và thuận tiện hơn',
          desc: 'Bảo hiểm đầy đủ đa ngôn ngữ, đọc không rào cản song ngữ, dịch một phím; mang đến cho bạn một trải nghiệm tuyệt vời!',
        },
        three: {
          title: 'Điều khiển bằng AI',
          desc: 'Mô hình lớn do AI điều khiển để đảm bảo tính chính xác và nhất quán của bản dịch, đồng thời nâng cao trải nghiệm đọc',
        },
      },
      panel4: {
        tab1: {
          key: 'Băng hình',
          title: 'Dịch & Tổng Hợp Video',
          desc: 'Chuyên gia hiệu quả của bạn, kết hợp với chatgpt dịch và tóm tắt nội dung video YouTube để tiết kiệm thời gian đọc của bạn!',
        },
        tab2: {
          key: 'Hình ảnh',
          title: 'Dịch hình ảnh',
          desc: 'Bấm để dịch hình ảnh khi duyệt web',
        },
        tab3: {
          key: 'Web',
          title: 'Dịch & Tóm tắt Web',
          desc: 'Trích xuất nhanh chóng những thông tin quan trọng từ trang web để tóm tắt, nâng cao hiệu quả đọc của bạn',
        },
        tag1: 'Đơn giản',
        tag2: 'Hiệu quả',
        tag3: 'Được hiểu dễ dàng',
      },
    },
    userReviews: { title: 'Đánh giá của người dùng', desc: 'Đánh giá năm sao trên chromewebstore' },
    lastBlock: { desc: 'Bản dịch AI tốt hơn và trải nghiệm đọc mượt mà hơn' },
  },
  login: {
    title: 'Chào mừng đến với Noam',
    btn: 'Đăng nhập bằng Google',
    tips: 'Bằng cách đăng ký, tôi đồng ý với',
    terms: 'Điều khoản sử dụng',
    and: 'Và',
    policy: 'Chính sách bảo mật',
    btn1: 'Đăng nhập ngay bây giờ',
    desc: 'Đăng nhập để mở khóa thêm trải nghiệm',
  },
  pricing: {
    pageTitle: 'Định giá',
    desc: 'So sánh và lựa chọn tốt nhất cho bạn',
    yearly: 'Hàng năm',
    monthly: 'hàng tháng',
    discount: 'giảm giá 20',
    preMonth: 'Mỗi tháng',
    billedYearly: 'Thanh toán hàng năm',
    billedMonthly: 'Thanh toán hàng tháng',
    detailedCompare: 'So sánh chi tiết',
    FAQ: 'Câu hỏi thường gặp',
    day: 'ngày',
    month: 'tháng',
    around: 'Xung quanh',
    free: {
      benefit1: 'Dịch tức thời 100 lần/ngày',
      benefit2: 'Dịch web & tóm tắt',
      benefit3: '30 tờ Dịch Hình Ảnh',
      benefit4: 'Dịch thuật & tóm tắt video',
      benefit5: '10 bản dịch PDF',
      benefit6: '5.000 mã thông báo',
      recommendText: 'Dùng thử miễn phí',
      btn: 'Chúc vui vẻ',
    },
    pro: {
      benefit1: 'Dịch vụ khách hàng Hỗ trợ nhanh chóng',
      benefit2: '1.000.000 token/tháng',
      benefit3: 'Dịch web & tóm tắt',
      benefit4: '200 tờ Dịch Hình Ảnh',
      benefit5: 'Dịch thuật & tóm tắt video',
      benefit6: '200 bản dịch PDF',
      btn: 'Bắt đầu',
      recommendText: 'Hầu hết mọi người chọn',
    },
    proPlus: {
      benefit1: 'Dịch vụ khách hàng Hỗ trợ nhanh chóng',
      benefit2: '3.000.000 token/tháng',
      benefit3: 'Dịch web & tóm tắt',
      benefit4: '500 tờ Dịch Hình Ảnh',
      benefit5: 'Dịch thuật & tóm tắt video',
      benefit6: '500 bản dịch PDF',
      recommendText: 'Sự lựa chọn phù hợp nhất',
    },
    enterprise: {
      benefit1: 'Dịch vụ khách hàng Hỗ trợ nhanh chóng',
      benefit2: 'Nhiều người dùng',
      benefit3: 'Giải pháp tùy chỉnh',
      benefit5: 'Người quản lý tài khoản độc quyền',
      recommendText: 'Kế hoạch tùy chỉnh（ ≥3人）',
      talkToUs: 'Nói với chúng tôi',
      btn: 'Liên hệ',
    },
    web: {
      contrastiveTranslation: 'Dịch thuật song ngữ',
      instantTranslation: 'Dịch nhanh',
      hoverTranslation: 'Dịch di chuột',
      dictionaryTranslation: 'Dịch lựa chọn văn bản',
      summary: 'Tóm tắt trang web',
    },
    imageTranslation: 'Dịch hình ảnh',
    video: { translation: 'Dịch video', summary: 'Tóm tắt video' },
    pdf: {
      translation: 'Bản dịch PDF',
      maxPage: 'Số trang tối đa trong PDF',
      chat: 'Số lượng yêu cầu PDF',
    },
    FQAObj: {
      q1: 'Tôi có thể sử dụng nó miễn phí không?',
      a1: 'Có một phiên bản Noam miễn phí có thể được sử dụng trực tiếp.',
      q2: 'Có các gói thành viên và lựa chọn phí khác nhau không?',
      a2: 'Ngoài phiên bản miễn phí, còn có ba gói thành viên là Pro, Pro+ và Enterprise; bạn có thể chọn giữa hai phương thức thanh toán, liên tục hàng tháng và liên tục hàng năm, và chi phí toàn diện của liên tục hàng năm sẽ thuận lợi hơn.',
      q3: 'Sự khác biệt giữa đăng ký liên tục hàng tháng và đăng ký liên tục hàng năm là gì?',
      a3: 'Không có sự khác biệt về chức năng. Chi phí toàn diện của thuê bao liên tục hàng năm thấp hơn so với thuê bao liên tục hàng tháng; thuê bao hàng tháng được thanh toán một lần mỗi tháng, trong khi thuê bao hàng năm được thanh toán một lần mỗi năm.',
      q4: 'Làm cách nào để hủy gói tự động gia hạn của tôi?',
      a4: 'Bạn có thể truy cập trang cài đặt phụ trợ và chọn đăng ký để hủy gói gia hạn.',
    },
  },
  upgradeModal: {
    title: 'Lời nhắc',
    limitAlertStr:
      'Hạn ngạch gói miễn phí của bạn đã được sử dụng hết, chúng tôi khuyên bạn nên nâng cấp gói của mình để tiếp tục trải nghiệm~!',
    upgradeAlertStr:
      'Hạn ngạch gói miễn phí của bạn đã được sử dụng hết, chúng tôi khuyên bạn nên nâng cấp gói của mình để tiếp tục trải nghiệm~!',
    btn: 'Nâng cấp ngay bây giờ',
    more: 'Thêm kế hoạch',
    contactStr:
      'Vui lòng đợi lần đặt lại tiếp theo hoặc liên hệ với chúng tôi theo địa chỉ contact@noam.tools.',
    upload: {
      free: 'Bạn đã đạt đến giới hạn của bản dịch pdf',
      pro: 'Bạn đã đạt đến giới hạn bản dịch pdf trong tháng này',
    },
    chat: {
      free: 'Bạn đã đạt đến giới hạn vượt quá giới hạn Chatpdf',
      pro: 'Bạn đã đạt đến giới hạn Chatpdf trong tháng này',
    },
    pageLimit: { free: 'Bạn đã đạt đến giới hạn vượt quá giới hạn trang PDF' },
  },
  buyModal: {
    title: 'Gói đăng ký',
    freeTrial: 'Dùng thử miễn phí 3 ngày',
    selectLabel: 'Chọn một kế hoạch',
    btn: 'Dùng thử miễn phí',
    yearLabel: 'năm',
    monthLabel: 'tháng',
    afterFree: 'Sau phiên tòa',
  },
  privacy: {
    title: 'Chính sách bảo mật',
    updateTime: 'Cập nhật: ngày 15 tháng 3 năm 2024',
    block1:
      'Chúng tôi, Noam (được gọi là "Noam", "chúng tôi", "của chúng tôi" hoặc "của chúng tôi"), tôn trọng quyền riêng tư của bạn và cam kết bảo vệ mọi thông tin chúng tôi thu được từ bạn hoặc về bạn. Chính sách quyền riêng tư này mô tả các hoạt động của chúng tôi liên quan đến việc thu thập hoặc sử dụng thông tin cá nhân từ việc bạn sử dụng trang web, ứng dụng và dịch vụ của chúng tôi (gọi chung là "Dịch vụ"). Chính sách quyền riêng tư này không áp dụng cho nội dung mà chúng tôi xử lý thay mặt cho khách hàng sử dụng sản phẩm kinh doanh của chúng tôi, chẳng hạn như API của chúng tôi. Việc sử dụng dữ liệu này phải tuân theo thỏa thuận khách hàng của chúng tôi, bao gồm quyền truy cập và sử dụng các sản phẩm này.',
    subTitle1: 'Thông tin cá nhân chúng tôi thu thập',
    block2: 'Chúng tôi thu thập thông tin cá nhân sau ("Thông tin cá nhân") liên quan đến bạn:',
    block3:
      'Thông tin do bạn cung cấp: Nếu bạn tạo tài khoản để sử dụng dịch vụ của chúng tôi hoặc liên lạc với chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân như sau:',
    subBlock1:
      'Thông tin tài khoản: Khi bạn tạo tài khoản trên nền tảng của chúng tôi, chúng tôi sẽ thu thập thông tin liên quan đến tài khoản của bạn, bao gồm tên, thông tin liên hệ, thông tin xác thực tài khoản, thông tin thẻ thanh toán và lịch sử giao dịch (gọi tắt là "Thông tin tài khoản").',
    subBlock2:
      'Nội dung của Người dùng: Khi bạn sử dụng dịch vụ của chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân từ thông tin đầu vào, tệp tải lên hoặc phản hồi của bạn cung cấp cho chúng tôi (gọi tắt là "Nội dung").',
    subBlock3:
      'Thông tin liên lạc: Nếu bạn gửi tin nhắn cho chúng tôi, chúng tôi sẽ thu thập tên, thông tin liên hệ của bạn và nội dung tin nhắn bạn gửi (gọi tắt là "Thông tin liên lạc").',
    subBlock4:
      'Thông tin trên mạng xã hội: Chúng tôi có các trang trên các trang web truyền thông xã hội như Instagram, Facebook, Medium, Twitter, YouTube và LinkedIn. Khi bạn tương tác với các trang truyền thông xã hội của chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân mà bạn chọn cung cấp cho chúng tôi, chẳng hạn như chi tiết liên hệ của bạn (gọi là "Thông tin xã hội"). Ngoài ra, các công ty lưu trữ các trang truyền thông xã hội của chúng tôi có thể cung cấp cho chúng tôi thông tin và phân tích tổng hợp về các hoạt động truyền thông xã hội của chúng tôi.',
    block4:
      'Thông tin cá nhân được tự động nhận từ việc sử dụng dịch vụ của bạn: Khi bạn truy cập, sử dụng hoặc tương tác với các dịch vụ, chúng tôi sẽ nhận được thông tin sau về quyền truy cập, sử dụng hoặc tương tác của bạn ("Thông tin kỹ thuật"):',
    subBlock5:
      'Dữ liệu nhật ký: Thông tin được trình duyệt của bạn tự động gửi khi sử dụng dịch vụ của chúng tôi. Dữ liệu nhật ký bao gồm địa chỉ Giao thức Internet (IP), loại và cài đặt trình duyệt, ngày và giờ yêu cầu của bạn cũng như cách bạn tương tác với trang web của chúng tôi.',
    subBlock6:
      'Dữ liệu sử dụng: Chúng tôi có thể tự động thu thập thông tin về việc bạn sử dụng dịch vụ, chẳng hạn như loại nội dung bạn xem hoặc tương tác, các tính năng bạn sử dụng và hành động của bạn đã thực hiện, cũng như múi giờ, quốc gia, ngày và giờ của bạn. quyền truy cập, tác nhân người dùng và phiên bản, loại máy tính hoặc thiết bị di động và kết nối máy tính của bạn.',
    subBlock7:
      'Thông tin thiết bị: Bao gồm tên thiết bị, hệ điều hành, mã nhận dạng thiết bị và trình duyệt bạn đang sử dụng. Thông tin được thu thập có thể phụ thuộc vào loại thiết bị bạn đang sử dụng và cài đặt của thiết bị đó.',
    subBlock8:
      'Cookie: Chúng tôi sử dụng cookie để vận hành và quản lý các dịch vụ của mình cũng như cải thiện trải nghiệm của bạn. "Cookie" là thông tin được gửi bởi một trang web mà bạn truy cập tới trình duyệt của mình. Bạn có thể đặt trình duyệt của mình chấp nhận tất cả cookie, từ chối tất cả cookie hoặc thông báo cho bạn khi cookie được cung cấp để bạn có thể quyết định có chấp nhận nó mỗi lần hay không. Tuy nhiên, việc từ chối cookie có thể ngăn cản bạn sử dụng hoặc ảnh hưởng tiêu cực đến việc hiển thị hoặc chức năng của một số khu vực hoặc tính năng nhất định của trang web. Để biết thêm thông tin về cookie, vui lòng truy cập Tất cả về cookie.',
    subBlock9:
      'Phân tích: Chúng tôi có thể sử dụng nhiều sản phẩm phân tích trực tuyến khác nhau sử dụng cookie để giúp chúng tôi phân tích cách người dùng sử dụng dịch vụ của chúng tôi và cải thiện trải nghiệm của bạn khi sử dụng dịch vụ.',
    subTitle2: 'Cách chúng tôi sử dụng thông tin cá nhân',
    block5: 'Chúng tôi có thể sử dụng thông tin cá nhân cho các mục đích sau:',
    subBlock10: 'Cung cấp, quản lý, bảo trì và/hoặc phân tích dịch vụ;',
    subBlock26: 'Cải thiện dịch vụ của chúng tôi và tiến hành nghiên cứu;',
    subBlock11: 'Giao tiếp với bạn;',
    subBlock12: 'Phát triển các dự án và dịch vụ mới;',
    subBlock13:
      'Ngăn chặn gian lận, hoạt động tội phạm hoặc lạm dụng dịch vụ của chúng tôi và bảo vệ tính bảo mật của hệ thống, kiến ​​trúc và mạng CNTT của chúng tôi;',
    subBlock14: 'Thực hiện chuyển nhượng kinh doanh;',
    subBlock15:
      'Tuân thủ các nghĩa vụ pháp lý và thủ tục pháp lý, bảo vệ quyền, quyền riêng tư, bảo mật hoặc tài sản của chúng tôi cũng như của các chi nhánh của chúng tôi, bạn hoặc các bên thứ ba khác.',
    block6:
      'Tóm tắt hoặc Thông tin không xác định: Chúng tôi có thể tổng hợp hoặc hủy thông tin nhận dạng để ngăn chặn việc sử dụng lại thông tin đó cho mục đích nhận dạng và sử dụng thông tin đó để phân tích hiệu quả dịch vụ của chúng tôi, cải thiện và thêm tính năng cho dịch vụ của chúng tôi, tiến hành nghiên cứu và cho các mục đích tương tự . Ngoài ra, chúng tôi có thể phân tích hành vi và đặc điểm chung của người dùng dịch vụ của chúng tôi và chia sẻ thông tin tổng hợp với bên thứ ba, công bố thông tin tổng hợp đó hoặc cung cấp thông tin đó một cách rộng rãi. Chúng tôi có thể thu thập thông tin tổng hợp thông qua các dịch vụ, thông qua cookie và thông qua các phương tiện khác được mô tả trong Chính sách quyền riêng tư này. Chúng tôi sẽ duy trì và sử dụng thông tin đã hủy nhận dạng ở dạng ẩn danh hoặc không xác định và chúng tôi sẽ không cố gắng xác định lại thông tin đó trừ khi luật pháp yêu cầu.',
    subTitle3: 'Tiết lộ thông tin cá nhân',
    block7:
      'Trong một số trường hợp nhất định, chúng tôi có thể cung cấp thông tin cá nhân của bạn cho bên thứ ba mà không cần thông báo thêm cho bạn, trừ khi pháp luật yêu cầu:',
    subBlock16:
      'Nhà cung cấp và nhà cung cấp dịch vụ: Để hỗ trợ chúng tôi đáp ứng nhu cầu hoạt động kinh doanh và thực hiện các dịch vụ và chức năng nhất định, chúng tôi có thể cung cấp thông tin cá nhân cho các nhà cung cấp và nhà cung cấp dịch vụ, bao gồm nhà cung cấp dịch vụ lưu trữ, nhà cung cấp dịch vụ đám mây và các nhà cung cấp dịch vụ công nghệ thông tin khác, phần mềm liên lạc qua email và các nhà cung cấp dịch vụ phân tích trang web, v.v. Các đối tác này sẽ chỉ truy cập, xử lý hoặc lưu trữ thông tin cá nhân theo chỉ đạo của chúng tôi trong khi thực hiện nghĩa vụ của họ đối với chúng tôi.',
    subBlock17:
      'Chuyển giao kinh doanh: Nếu chúng tôi tham gia vào các giao dịch chiến lược, tổ chức lại, phá sản, tiếp quản phá sản hoặc chuyển đổi dịch vụ sang nhà cung cấp khác (gọi chung là "Giao dịch"), thông tin cá nhân của bạn và các thông tin khác có thể được chia sẻ với các đối tác giao dịch và các bên khác hỗ trợ trong việc chuyển giao kinh doanh. Giao dịch trong quá trình thẩm định và được chuyển giao như một phần của Giao dịch cùng với các tài sản khác cho công ty kế thừa hoặc công ty liên kết.',
    subBlock18:
      'Yêu cầu pháp lý: Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các cơ quan chính phủ, các đồng nghiệp trong ngành hoặc các bên thứ ba khác, bao gồm thông tin tương tác của bạn với các dịch vụ của chúng tôi, (1) nếu pháp luật yêu cầu hoặc nếu chúng tôi tin rằng hành động đó là cần thiết để tuân thủ nghĩa vụ pháp lý; (2) để bảo vệ các quyền hoặc tài sản của chúng tôi; (3) nếu chúng tôi đơn phương xác định rằng có sự vi phạm các điều khoản, chính sách hoặc luật pháp của chúng tôi; (4) để phát hiện hoặc ngăn chặn gian lận hoặc các hoạt động bất hợp pháp khác; (5) để bảo vệ sản phẩm, nhân viên hoặc người dùng của chúng tôi hoặc an toàn, bảo mật, tính toàn vẹn của công cộng; hoặc (vi) để chống lại trách nhiệm pháp lý.',
    subTitle4: 'Quyền lợi của bạn',
    block8:
      'Tùy thuộc vào vị trí địa lý của bạn, các cá nhân ở Khu vực Kinh tế Châu Âu, Vương quốc Anh và trên toàn cầu có thể có một số quyền theo luật định liên quan đến thông tin cá nhân của họ. Ví dụ: bạn có thể có quyền:',
    subBlock19: 'Truy cập thông tin cá nhân của bạn và thông tin về cách nó được xử lý.',
    subBlock20: 'Xóa thông tin cá nhân của bạn khỏi hồ sơ của chúng tôi.',
    subBlock21: 'Chỉnh sửa hoặc cập nhật thông tin cá nhân của bạn.',
    subBlock22: 'Chuyển thông tin cá nhân của bạn cho bên thứ ba (khả năng di chuyển dữ liệu).',
    subBlock23: 'Giới hạn cách chúng tôi xử lý thông tin cá nhân của bạn.',
    subBlock24:
      'Rút lại sự đồng ý của bạn—trong trường hợp chúng tôi dựa vào sự đồng ý làm cơ sở pháp lý để xử lý, bạn có thể rút lại sự đồng ý của mình bất kỳ lúc nào.',
    subBlock25: 'Phản đối cách chúng tôi xử lý thông tin cá nhân của bạn.',
    subTitle5: 'Liên kết đến các trang web khác',
    block9:
      'Dịch vụ này có thể chứa các liên kết đến các trang web khác không do Noam điều hành hoặc kiểm soát, bao gồm các dịch vụ truyền thông xã hội ("Trang web của bên thứ ba"). Thông tin bạn chia sẻ với Trang web của bên thứ ba sẽ chịu sự điều chỉnh của các chính sách quyền riêng tư và điều khoản dịch vụ cụ thể của Trang web bên thứ ba, không phải bởi Chính sách quyền riêng tư này. Chúng tôi cung cấp các liên kết này để thuận tiện và không ngụ ý sự chứng thực hoặc đánh giá của chúng tôi về các trang web này. Vui lòng liên hệ trực tiếp với các Trang web của Bên thứ ba để biết thông tin về các chính sách và điều khoản về quyền riêng tư của họ.',
    subTitle6: 'Bảo mật và lưu giữ thông tin',
    block10:
      'Chúng tôi thực hiện các biện pháp kỹ thuật, hành chính và tổ chức hợp lý về mặt thương mại để bảo vệ thông tin cá nhân trực tuyến và ngoại tuyến khỏi bị mất, lạm dụng và truy cập trái phép, tiết lộ, thay đổi hoặc hành động thù địch. Tuy nhiên, không có đường truyền internet hoặc email nào có thể đảm bảo tính bảo mật hoặc độ chính xác hoàn toàn. Đặc biệt, các email gửi cho chúng tôi hoặc từ chúng tôi có thể không an toàn. Vì vậy, bạn nên đặc biệt cẩn thận khi quyết định gửi thông tin nào cho chúng tôi thông qua dịch vụ hoặc email. Ngoài ra, chúng tôi không chịu trách nhiệm về bất kỳ sự cản trở nào đối với cài đặt quyền riêng tư hoặc biện pháp bảo mật trong dịch vụ hoặc cài đặt bảo mật hoặc biện pháp bảo mật của Trang web bên thứ ba. Chúng tôi sẽ lưu giữ thông tin cá nhân của bạn trong khoảng thời gian cần thiết để chúng tôi cung cấp cho bạn các dịch vụ hoặc cho các mục đích kinh doanh hợp pháp khác, chẳng hạn như giải quyết tranh chấp, lý do an ninh và an toàn hoặc tuân thủ các nghĩa vụ pháp lý của chúng tôi. Khoảng thời gian chúng tôi lưu giữ thông tin cá nhân sẽ phụ thuộc vào nhiều yếu tố, chẳng hạn như số lượng, tính chất, độ nhạy cảm của thông tin, rủi ro tiềm ẩn của việc sử dụng hoặc tiết lộ trái phép, mục đích mà chúng tôi xử lý thông tin và các cân nhắc ràng buộc về mặt pháp lý khác.',
    subTitle7: 'Sửa đổi chính sách quyền riêng tư',
    block11:
      'Thỉnh thoảng chúng tôi có thể cập nhật Chính sách quyền riêng tư này. Khi làm như vậy, chúng tôi sẽ đăng phiên bản cập nhật trên trang này, trừ khi luật hiện hành có yêu cầu khác.',
    subTitle8: 'Liên lạc với chúng tôi bằng cách nào',
    block12:
      'Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào chưa được giải đáp về Chính sách quyền riêng tư này, vui lòng liên hệ với bộ phận dịch vụ khách hàng của chúng tôi. E-mail:',
  },
  terms: {
    title: 'Điều khoản dịch vụ',
    updateTime: 'Đã cập nhật:15 tháng 3 năm 2024',
    block1: 'Cảm ơn bạn đã sử dụng Noam!',
    block2:
      'Các Điều khoản sử dụng này áp dụng cho việc bạn sử dụng các dịch vụ của Noam, bao gồm giao diện lập trình ứng dụng, phần mềm, công cụ, dịch vụ dành cho nhà phát triển, dữ liệu, tài liệu và trang web của chúng tôi (gọi chung là "Dịch vụ"). Bằng cách sử dụng Dịch vụ của chúng tôi, bạn đồng ý tuân theo các điều khoản này. Chính sách quyền riêng tư của chúng tôi giải thích cách chúng tôi thu thập và sử dụng thông tin cá nhân.',
    subTitle1: 'Đăng ký và truy cập',
    block3:
      'Nếu bạn dưới 18 tuổi, bạn phải được cha mẹ hoặc người giám hộ hợp pháp cho phép sử dụng Dịch vụ. Nếu bạn đang sử dụng Dịch vụ thay mặt cho một cá nhân hoặc tổ chức khác, bạn phải được ủy quyền để thay mặt họ chấp nhận các điều khoản này. Bạn phải cung cấp thông tin chính xác và đầy đủ để đăng ký tài khoản. Bạn không được cung cấp thông tin xác thực truy cập hoặc tài khoản của mình cho bất kỳ ai bên ngoài tổ chức của bạn và bạn chịu trách nhiệm về mọi hoạt động được thực hiện bằng thông tin xác thực của mình.',
    subTitle2: 'Yêu cầu sử dụng',
    block4:
      '(a) Sử dụng Dịch vụ. Bạn có thể truy cập Dịch vụ theo các điều khoản này và chúng tôi cấp cho bạn quyền không độc quyền để sử dụng Dịch vụ. Khi sử dụng Dịch vụ, bạn sẽ tuân thủ các điều khoản này và tất cả các luật hiện hành. Chúng tôi và các chi nhánh của chúng tôi sở hữu mọi quyền, quyền sở hữu và lợi ích đối với Dịch vụ.',
    block5:
      '(b) Phản hồi. Chúng tôi hoan nghênh phản hồi, nhận xét, ý tưởng, đề xuất và cải tiến. Nếu bạn cung cấp bất kỳ nội dung nào như vậy, chúng tôi có thể sử dụng nội dung đó mà không có bất kỳ hạn chế nào và không phải bồi thường cho bạn.',
    block6:
      '(c) Hạn chế. Bạn không được: (1) sử dụng Dịch vụ theo cách xâm phạm, chiếm dụng hoặc vi phạm bất kỳ quyền nào của người khác; (2) đảo ngược kỹ thuật, dịch ngược, tháo rời, dịch hoặc cố gắng khám phá mã nguồn hoặc các thành phần cơ bản của mô hình, thuật toán và hệ thống Dịch vụ (trừ khi những hạn chế đó bị cấm theo luật hiện hành); (3) sử dụng đầu ra của Dịch vụ để phát triển các mô hình cạnh tranh với Noam; (4) trích xuất dữ liệu hoặc đầu ra từ Dịch vụ bằng bất kỳ phương pháp lập trình hoặc tự động nào trừ khi được API cho phép, bao gồm cả việc thu thập dữ liệu, thu thập web hoặc trích xuất dữ liệu web; (5) thể hiện đầu ra của Dịch vụ là được tạo một cách giả tạo trong khi thực tế không phải vậy, hoặc vi phạm chính sách sử dụng của chúng tôi; (6) mua, bán hoặc chuyển giao khóa API mà không có sự đồng ý trước của chúng tôi; hoặc (7) cung cấp cho chúng tôi bất kỳ thông tin cá nhân nào của trẻ em dưới 13 tuổi hoặc độ tuổi chấp thuận kỹ thuật số hiện hành. Bạn sẽ tuân thủ mọi giới hạn tỷ lệ và các yêu cầu khác trong tài liệu của chúng tôi.',
    block7:
      '(d) Dịch vụ của bên thứ ba. Mọi phần mềm, dịch vụ hoặc sản phẩm khác của bên thứ ba liên quan đến Dịch vụ đều chịu sự điều chỉnh của các điều khoản riêng và chúng tôi không chịu trách nhiệm về các sản phẩm của bên thứ ba.',
    subTitle3: 'Nội dung',
    block8:
      '(a) Nội dung của bạn. Bạn có thể cung cấp đầu vào ("Đầu vào") cho Dịch vụ và nhận đầu ra ("Đầu ra") được tạo và trả về dựa trên Đầu vào (gọi chung là "Nội dung"). Giữa các bên và trong phạm vi được luật pháp hiện hành cho phép, bạn sở hữu tất cả Thông tin đầu vào. Noam có thể sử dụng Nội dung để cung cấp và duy trì Dịch vụ, tuân thủ luật pháp hiện hành và thực thi các chính sách của chúng tôi. Bạn chịu trách nhiệm về Nội dung, bao gồm cả việc đảm bảo rằng Nội dung đó không vi phạm bất kỳ luật hiện hành nào hoặc các điều khoản này.',
    block9:
      '(b) Độ chính xác. Trí tuệ nhân tạo và học máy là những lĩnh vực nghiên cứu đang phát triển nhanh chóng. Chúng tôi không ngừng nỗ lực cải thiện dịch vụ của mình để làm cho dịch vụ đó chính xác hơn, đáng tin cậy hơn, an toàn hơn và mang lại lợi ích hơn. Do tính chất xác suất của học máy, việc sử dụng dịch vụ của chúng tôi có thể dẫn đến kết quả đầu ra không chính xác trong một số trường hợp nhất định, điều này có thể không phản ánh chính xác các cá nhân, địa điểm hoặc sự kiện thực tế. Bạn nên đánh giá một cách thích hợp độ chính xác của bất kỳ kết quả đầu ra nào dựa trên trường hợp sử dụng của mình, bao gồm cả việc xem xét các kết quả đầu ra theo cách thủ công.',
    subTitle4: 'Phí và thanh toán',
    block10:
      '(a) Phí và Thanh toán. Bạn sẽ thanh toán tất cả các khoản phí ("Phí") theo giá cả và các điều khoản trên trang định giá hiện hành hoặc theo thỏa thuận bằng văn bản giữa chúng ta. Chúng tôi có quyền sửa các lỗi hoặc sai sót về giá ngay cả khi chúng tôi đã xuất hóa đơn hoặc nhận được khoản thanh toán. Bạn sẽ cung cấp thông tin thanh toán đầy đủ và chính xác, bao gồm phương thức thanh toán hợp lệ và được ủy quyền. Chúng tôi sẽ tính phí theo phương thức thanh toán của bạn định kỳ theo thỏa thuận, nhưng chúng tôi có thể thay đổi ngày thanh toán một cách hợp lý. Bạn ủy quyền cho Noam, các chi nhánh của Noam và bộ xử lý thanh toán bên thứ ba tính phí vào phương thức thanh toán của bạn. Nếu khoản thanh toán của bạn không thành công, chúng tôi sẽ cung cấp cho bạn thông báo bằng văn bản và có thể tạm dừng quyền truy cập vào Dịch vụ cho đến khi nhận được khoản thanh toán. Trừ khi có quy định khác trong thỏa thuận này, các khoản thanh toán sẽ không được hoàn lại.',
    block11:
      '(b) Thuế. Trừ khi có quy định khác, Phí không bao gồm bất kỳ khoản thuế, nghĩa vụ và đánh giá tương tự nào của liên bang, tiểu bang, địa phương và nước ngoài ("Thuế"). Bạn chịu trách nhiệm thanh toán tất cả các loại Thuế liên quan đến giao dịch mua hàng của mình, ngoại trừ Thuế dựa trên thu nhập ròng mà chúng tôi sẽ lập hóa đơn cho bạn. Bạn đồng ý thanh toán kịp thời các khoản Thuế đó và cung cấp cho chúng tôi các tuyên bố về quyền riêng tư có giá trị về mặt pháp lý cũng như nhận được sự đồng ý cần thiết để xử lý dữ liệu đó và bạn đảm bảo với chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành.',
    block12:
      '(c) Thay đổi giá. Chúng tôi có thể thay đổi giá bằng cách thông báo cho bạn thông qua tài khoản của bạn và/hoặc trang web của chúng tôi. Việc tăng giá sẽ có hiệu lực ngay sau khi đăng. Mọi thay đổi về giá sẽ áp dụng cho Phí cho tài khoản của bạn sau ngày thay đổi có hiệu lực.',
    block13:
      '(d) Tranh chấp và thanh toán quá hạn. Nếu bạn muốn tranh chấp bất kỳ khoản Phí hoặc Thuế nào, vui lòng liên hệ với contact@noam.tools trong vòng ba mươi (30) ngày kể từ ngày lập hóa đơn.',
    block14:
      '(e) Sử dụng miễn phí. Bạn không được tạo nhiều tài khoản để nhận tín dụng miễn phí. Nếu chúng tôi xác định rằng bạn không sử dụng các khoản tín dụng miễn phí một cách thiện chí, chúng tôi có thể tính phí tiêu chuẩn cho bạn hoặc ngừng cung cấp quyền truy cập vào Dịch vụ.',
    subTitle5: 'Bảo mật, bảo mật và bảo vệ dữ liệu',
    block15:
      '(a) Tính bảo mật. Bạn có thể có quyền truy cập vào thông tin bí mật của Noam. Bạn chỉ có thể sử dụng thông tin bí mật được cho phép theo các điều khoản này cho mục đích sử dụng Dịch vụ. Bạn không được tiết lộ thông tin bí mật cho bất kỳ bên thứ ba nào và bạn sẽ bảo vệ thông tin bí mật theo cách bảo vệ không kém gì việc bạn bảo vệ thông tin bí mật tương tự của chính mình, ít nhất là với sự cẩn trọng hợp lý. Thông tin bí mật là thông tin không công khai được Noam chỉ định là bí mật hoặc cần được coi là bí mật một cách hợp lý trong các trường hợp, bao gồm phần mềm, thông số kỹ thuật và thông tin kinh doanh không công khai khác. Thông tin bí mật không bao gồm thông tin: (1) được công bố rộng rãi mà không phải do lỗi của bạn; (2) bạn đã sở hữu nó mà không có bất kỳ nghĩa vụ bảo mật nào trước khi nhận nó theo các điều khoản này; (3) được bên thứ ba tiết lộ hợp pháp cho bạn mà không có bất kỳ nghĩa vụ bảo mật nào; hoặc (4) được bạn phát triển độc lập mà không sử dụng thông tin bí mật. Bạn có thể tiết lộ thông tin bí mật nếu luật pháp, tòa án hoặc lệnh khác của chính phủ yêu cầu, nhưng bạn phải cung cấp thông báo trước bằng văn bản cho Noam theo cách hợp lý và trong phạm vi có thể, thực hiện những nỗ lực hợp lý để hạn chế phạm vi tiết lộ, bao gồm cả việc hỗ trợ chúng tôi phản đối yêu cầu tiết lộ thông tin.',
    block16:
      '(b) An ninh. Bạn phải thực hiện các biện pháp hợp lý và phù hợp để giúp bảo vệ quyền truy cập và sử dụng Dịch vụ của bạn. Nếu phát hiện bất kỳ lỗ hổng hoặc vi phạm nào liên quan đến việc bạn sử dụng Dịch vụ, bạn phải liên hệ kịp thời với Noam và cung cấp thông tin chi tiết về lỗ hổng hoặc vi phạm.',
    block17:
      '((c) Xử lý dữ liệu cá nhân. Nếu bạn sử dụng Dịch vụ để xử lý dữ liệu cá nhân, bạn phải cung cấp các tuyên bố về quyền riêng tư đầy đủ về mặt pháp lý và có được sự đồng ý cần thiết để xử lý dữ liệu đó và bạn đảm bảo với chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành .',
    subTitle6: 'Thời hạn và chấm dứt',
    block18:
      '(a) Chấm dứt; Đình chỉ. Các điều khoản này sẽ có hiệu lực kể từ lần đầu tiên bạn sử dụng Dịch vụ và sẽ tiếp tục có hiệu lực cho đến khi chấm dứt. Bạn có thể chấm dứt các điều khoản này bất kỳ lúc nào vì bất kỳ lý do gì bằng cách ngừng sử dụng Dịch vụ và Nội dung. Chúng tôi có thể chấm dứt các điều khoản này nếu có thông báo trước vì bất kỳ lý do gì. Chúng tôi có thể chấm dứt ngay các điều khoản này bằng cách thông báo cho bạn nếu bạn vi phạm nghiêm trọng Mục 2 (Yêu cầu sử dụng), Mục 5 (Bảo mật, bảo mật và bảo vệ dữ liệu), Mục 8 (Giải quyết tranh chấp) hoặc Mục 9 (Điều khoản chung) hoặc nếu chúng tôi mối quan hệ với bất kỳ nhà cung cấp công nghệ bên thứ ba nào ngoài những thay đổi trong tầm kiểm soát của chúng tôi hoặc để tuân thủ luật pháp hoặc yêu cầu của chính phủ. Chúng tôi có thể tạm dừng quyền truy cập Dịch vụ của bạn nếu bạn không tuân thủ các điều khoản này hoặc nếu việc sử dụng của bạn gây rủi ro bảo mật cho chúng tôi hoặc bất kỳ bên thứ ba nào hoặc nếu chúng tôi nghi ngờ việc sử dụng của bạn là gian lận hoặc có thể khiến chúng tôi hoặc bất kỳ bên thứ ba nào phải chịu trách nhiệm pháp lý .',
    block19:
      '(b) Ảnh hưởng của việc chấm dứt. Sau khi chấm dứt, bạn sẽ ngừng sử dụng Dịch vụ và nhanh chóng trả lại hoặc hủy mọi thông tin bí mật theo chỉ dẫn của chúng tôi. Các quy định của các điều khoản này mà về bản chất sẽ có hiệu lực khi chấm dứt hoặc hết hạn, bao gồm nhưng không giới hạn ở Mục 3 và 5-9, sẽ vẫn có hiệu lực.',
    subTitle7: 'Sự bồi thường; Tuyên bố miễn trừ trách nhiệm; Trách nhiệm hữu hạn',
    block20:
      '(a) Bồi thường. Bạn sẽ bảo vệ, bồi thường và giữ cho chúng tôi, các chi nhánh và nhân viên của chúng tôi không bị tổn hại trước mọi khiếu nại, tổn thất và chi phí (bao gồm cả phí luật sư) phát sinh từ hoặc liên quan đến việc bạn sử dụng Dịch vụ, bao gồm cả Nội dung, của bạn. việc sử dụng các sản phẩm hoặc dịch vụ liên quan đến Dịch vụ và việc bạn vi phạm các điều khoản này hoặc luật hiện hành.',
    block21:
      '(b) Tuyên bố từ chối trách nhiệm. Dịch vụ được cung cấp "nguyên trạng". Trong phạm vi được pháp luật cho phép, chúng tôi, các chi nhánh và người cấp phép của chúng tôi không đưa ra bất kỳ tuyên bố hay bảo đảm nào liên quan đến Dịch vụ và không đưa ra bảo đảm nào, bao gồm nhưng không giới hạn ở các bảo đảm về khả năng bán được, sự phù hợp cho một mục đích cụ thể, chất lượng đạt yêu cầu, không vi phạm và sự tận hưởng yên tĩnh cũng như mọi bảo đảm phát sinh trong quá trình giao dịch hoặc sử dụng giao dịch. Chúng tôi không đảm bảo rằng Dịch vụ sẽ không bị gián đoạn, chính xác hoặc không có lỗi hoặc bất kỳ Nội dung nào sẽ được bảo mật, không bị mất hoặc không bị thay đổi.',
    block22:
      '(c) Giới hạn trách nhiệm pháp lý. Chúng tôi, các chi nhánh hoặc người cấp phép của chúng tôi sẽ không chịu trách nhiệm về bất kỳ thiệt hại gián tiếp, ngẫu nhiên, đặc biệt, do hậu quả hoặc trừng phạt nào, bao gồm mất lợi nhuận, mất danh tiếng, mất sử dụng, mất dữ liệu hoặc các tổn thất vô hình khác, ngay cả khi chúng tôi đã được thông báo về khả năng xảy ra những thiệt hại như vậy. Theo các điều khoản này, tổng trách nhiệm pháp lý của chúng tôi sẽ không vượt quá Phí bạn đã trả cho Dịch vụ trong mười hai (12) tháng trước yêu cầu bồi thường hoặc một trăm đô la Mỹ ($100), tùy theo số tiền nào lớn hơn. Những hạn chế trong phần này áp dụng trong phạm vi tối đa được pháp luật hiện hành cho phép.',
    subTitle8: 'Luật chính quyền và quyền tài phán',
    block23:
      'Các Điều khoản và việc bạn sử dụng Dịch vụ chịu sự điều chỉnh của luật pháp Hoa Kỳ, ngoại trừ các quy định về xung đột luật pháp của Hoa Kỳ. Việc bạn sử dụng Ứng dụng cũng có thể phải tuân theo luật pháp địa phương, tiểu bang, quốc gia hoặc quốc tế khác. Mọi hành động pháp lý hoặc thủ tục tố tụng liên quan đến các Điều khoản này sẽ được đưa ra độc quyền tại các tòa án của Hoa Kỳ và bạn đồng ý tuân theo thẩm quyền cá nhân của các tòa án đó.',
    subTitle9: 'Điều khoản chung',
    block24:
      '(a) Mối quan hệ của các Bên. Noam và bạn là những nhà thầu độc lập và không bên nào có quyền đại diện hoặc ràng buộc bên kia hoặc đảm nhận nghĩa vụ cho bên kia mà không có sự đồng ý trước bằng văn bản của bên kia.',
    block25:
      '(b) Sử dụng thương hiệu. Bạn không được sử dụng tên, logo hoặc nhãn hiệu của Noam hoặc bất kỳ chi nhánh nào của nó mà không có sự đồng ý trước bằng văn bản của chúng tôi.',
    subTitle10: 'Tính tách rời',
    block26:
      'Nếu bất kỳ điều khoản nào trong các điều khoản này được xác định là không hợp lệ, bất hợp pháp hoặc không thể thi hành thì các điều khoản còn lại sẽ vẫn có đầy đủ hiệu lực.',
  },
  contact: {
    title: 'Liên hệ chúng tôi',
    block1:
      'Để tùy chỉnh hoặc nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào, vui lòng liên hệ với chúng tôi theo địa chỉ contact@noam.tools và chúng tôi sẽ phản hồi ngay lập tức.',
    block2:
      'Hãy hỗ trợ chúng tôi bằng cách xếp hạng chúng tôi trên cửa hàng Tiện ích mở rộng của Google Apps tại ★★★★★.',
    block3: 'Giúp đỡ người khác bằng cách chia sẻ tiện ích mở rộng này:',
  },
  pdf: {
    toast1: 'Đang tải PDF, vui lòng thử lại sau!',
    toast2: 'Nội dung PDF quá nhiều, tạm thời không hỗ trợ hộp thoại',
    toast3: 'Đang tải xuống, vui lòng thử lại sau khi tải xuống hoàn tất.',
    shareMsg1: 'Chia sẻ tạo liên kết thành công',
    shareMsg2: 'Chia sẻ cập nhật nội dung thành công',
    shareMsg3:
      'Việc tạo liên kết chia sẻ không thành công, vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng của chúng tôi.',
    shareMsg4:
      'Chia sẻ cập nhật nội dung không thành công, vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng',
    shareBtnText1: 'Tạo liên kết chia sẻ',
    shareBtnText2: 'Nội dung chia sẻ được cập nhật',
    uploading: 'Đang tải lên',
    uploadingMsg: 'Hãy kiên nhẫn vì phải mất một thời gian để tải lên.',
    cancelUpload: 'Hủy bỏ',
    uploadErr:
      'Tải tệp lên không thành công. Vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng',
    uploadMsg1: 'Vui lòng kéo và thả tập tin vào đây',
    uploadMsg2: 'Các loại tệp được hỗ trợ: PDF |  Kích thước tệp tối đa: 100MB',
    uploadMsg3: 'Bấm chọn hoặc kéo thả file để upload lên đây',
    searchPlaceholder: 'Tìm kiếm',
    collectionEmpty: 'Không có nội dung trong bộ sưu tập~',
    kw: 'kẹp giấy',
    video: 'băng hình',
    link: 'liên kết',
    dataLoading: 'Đang tải dữ liệu...',
    freeBannerTips: 'Kết thúc dịch PDF miễn phí',
    vipBannerTips: 'Bạn đã đạt đến giới hạn bản dịch pdf trong tháng này.',
    freeBannerBtn: 'Nâng cấp để tận hưởng nhiều hơn',
    vipBannerBtn: 'Nâng cấp để tận hưởng nhiều hơn',
    dialogTitle: 'Chú ý',
    dialogContent: 'Bạn có chắc chắn muốn xóa nó?',
    okBtn: 'Xóa bỏ',
    cancelBtn: 'Hủy bỏ',
    unLoginToast: 'Vui lòng đăng nhập để sử dụng chức năng này',
    parseErrTips: 'Không thể phân tích tập tin',
    new: 'tập tin mới',
    parseErrDialogContent:
      'Link PDF hiện tại không hỗ trợ dịch trực tiếp, vui lòng tải link PDF gốc sau khi dịch',
    okBtn2: 'nhận ra',
    notPdf: 'Chỉ các tệp PDF',
    uploadingMsg1: 'Đang tải tập tin lên. Vui lòng thử lại sau.',
    uploadingMsg2: 'Bạn hiện có một tập tin đang được chuyển đổi, vui lòng thử lại sau.',
    uploadingMsg3:
      'Yêu cầu tiến trình chuyển đổi không thành công, vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng',
    uploadingMsg4:
      'Noam không thể truy cập trực tiếp các tệp cục bộ của bạn, vui lòng tải lên bản PDF theo cách thủ công để dịch.',
    uploadingMsg5:
      'Không lấy được url của tệp, vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng.',
    fileMsg: 'Dung lượng tập tin hiện tại:',
    fileMsg2: 'Vui lòng tải lên tệp nhỏ hơn 100M',
    fileErrMsg:
      'Đọc tệp không thành công, vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng.',
    parseFileToast:
      'Phân tích cú pháp tệp không thành công, vui lòng thử lại sau hoặc liên hệ với dịch vụ khách hàng của chúng tôi.',
    noId: 'ID tệp không tồn tại',
    autoDetect: 'Tự động phát hiện',
    hasTransTask: 'Có một nhiệm vụ đang được dịch. Vui lòng chờ.',
    transSucc: 'Bản dịch đã thành công!',
    entry: 'Danh sách PDF',
    listError:
      'Không tải được danh sách PDF, vui lòng thử lại sau hoặc liên hệ với bộ phận dịch vụ khách hàng.',
    collectionTab: 'Bản ghi nhớ',
    pdfLoading: 'Đang phân tích...',
    installTips:
      'Cài đặt plugin nhỏ, bạn có thể dịch nhiều tệp PDF hơn, bạn cũng có thể trải nghiệm dịch trang web mượt mà:',
    webTrans: 'tham khảo chéo đầy đủ',
    hoverTrans: 'Dịch đoạn di chuột',
    selectTrans: 'gạch chân các từ và dịch',
    installText: 'Cửa hàng tổng hợp đáp ứng mọi nhu cầu dịch thuật hàng ngày của bạn',
    start: 'Bắt đầu trải nghiệm miễn phí của bạn',
    shareTips: 'Bản dịch PDF đã hoàn tất, hãy tiếp tục và chia sẻ nó với bạn bè của bạn!',
    download: {
      loading: 'Đã lưu...',
      btn: 'Lưu bây giờ',
      trans: 'Chỉ dịch',
      both: 'song ngữ',
      download: 'Tải xuống',
      msg1: 'Một số bản dịch của tài liệu hiện tại dài hơn văn bản gốc và văn bản có thể chồng lên nhau sau khi tải xuống, vì vậy bạn nên đọc trên trang web.',
      msg2: '1, Nội dung chưa được dịch đầy đủ, vui lòng tải xuống sau khi dịch xong.',
      msg3: '2、Phần tài liệu của bản dịch quá dài, bạn có thể đọc trên trang web để tránh chồng chéo văn bản.',
      msg4: 'Hiện có một số tác vụ đang được dịch, vui lòng thử lại sau khi dịch xong.',
      fileName: 'Tên tập tin:',
      fileType: 'Loại tệp tải xuống:',
      transContinue: 'Tiếp tục dịch',
      save: 'Lưu vào địa phương',
      downloadMsg1: 'PDF Tiến độ dịch thuật:',
      downloadMsg2: 'Yêu cầu dự kiến',
      minute: 'Phút...',
      downloadSucc: 'Tải xuống bản PDF hoàn tất, nhấp vào lưu ngay!',
    },
    guide: {
      title1: 'Dịch thuật song ngữ',
      title2: 'Trò chuyện PDF',
      text1: 'Đọc song ngữ',
      text2:
        'Đọc song ngữ, vì vậy bạn có thể so sánh văn bản gốc và bản dịch trong khi đọc để nâng cao hiệu quả đọc',
      text3: 'Trò chuyện PDF',
      text4: 'AI phân tích và tóm tắt và có thể trả lời câu hỏi của bạn dựa trên nội dung PDF',
      text5: 'Độ chính xác và hiệu quả',
      text6: 'PDF được hỗ trợ bởi AI để có bản dịch chính xác và hiệu quả hơn',
      placeholder: 'Vui lòng điền vào liên kết PDF',
      start: 'Bắt đầu dịch',
      toast: 'Vui lòng nhập liên kết pdf trực tuyến hợp lệ có đuôi .pdf',
    },
    original: 'Nguyên bản',
    parallel: 'Song song',
    trans: 'Dịch',
    initial: 'Dịch PDF',
    thumbnail: 'danh mục sản phẩm',
    downloadBtn: 'Tải xuống',
    shareBtn: 'Chia sẻ',
    uploadBtn: 'Tải lên',
    installText2: 'Vui lòng cài đặt trước',
    chatPdf: {
      clearSucc: 'Đã xóa thành công',
      clear: 'Thông thoáng',
      clearContent:
        'Sau khi xóa, một cuộc trò chuyện mới sẽ được tạo và nội dung của cuộc trò chuyện này sẽ không được lưu, bạn có chắc chắn muốn xóa nó không?',
      clearDialogTitle: 'Chú ý',
      cancel: 'Hủy bỏ',
      chatTitle: 'Chào mừng bạn đến với Noam, bản tóm tắt PDF của bạn đã sẵn sàng:',
      summaryTitle: 'Các câu hỏi có thể bạn quan tâm:',
      placeholder: 'Nhập bất kỳ câu hỏi nào về PDF',
      copy: 'Sao chép',
      copySucc: 'Sao chép thành công',
      historyTitle: 'Lịch sử',
      expand: 'Sụp đổ',
      noDataDesc: 'Không có lịch sử~',
      noMore: 'Không còn nữa.',
      Search: 'Tìm kiếm',
      delete: 'Xóa bỏ',
      loading: 'đang tải...',
      deleteTitle: 'Mẹo',
      deleteContent: 'Sau khi xóa, tệp PDF này không thể phục hồi được, bạn có muốn xóa nó không?',
      deleteOk: 'Xóa bỏ',
      deleteCancel: 'Hủy bỏ',
      deleteSuccess: 'Xóa thành công',
      listError:
        'Danh sách đọc không tải được. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ khách hàng',
    },
    shareModal: {
      shareSetting: 'Chia sẻ cài đặt nội dung',
      shareText: 'Chia sẻ',
      text1: 'Cài đặt chia sẻ',
      text2: 'Tạo liên kết chia sẻ',
      range: 'Phạm vi:',
      custom: 'Tùy chỉnh:',
      page: 'trang',
      sourceLang: 'Nguồn:',
      target: 'Mục tiêu:',
      errMsg:
        'Nội dung phạm vi chia sẻ hiện tại chưa được dịch đầy đủ nên vui lòng dịch trước khi chia sẻ.',
      pageNuErr: 'Vui lòng nhập đúng giá trị số trang',
      trans: 'Dịch',
      cancel: 'Hủy bỏ',
      update: 'Đã cập nhật link chia sẻ',
      gen: 'Chia sẻ',
      shareTips: 'Chia sẻ liên kết PDF đã dịch với bạn bè của bạn để cùng đọc:',
      copySucc: 'Sao chép liên kết thành công',
      copy: 'Sao chép',
      settings: 'Chia sẻ cài đặt:',
      modify: 'biến đổi',
      translateTips:
        'Mẹo: Nếu quá trình dịch mất nhiều thời gian, bạn có thể đóng cửa sổ bật lên và chia sẻ sau khi dịch xong.',
      translating: 'Bản dịch PDF...',
      remain: 'còn lại',
      remain1: 'Tổng số yêu cầu dự kiến',
      totalPage: 'Tổng cộng 1 trang',
      search: 'Tìm kiếm',
      notSupport: 'Ngôn ngữ này hiện không được hỗ trợ',
    },
    toolbar: { Adjust: 'Điều chỉnh', autoFit: 'Tự động điều chỉnh' },
    memoTotalCount: 'Tổng cộng 1 nội dung',
    dragTipModal: {
      press: 'Nhấn',
      black: 'không gian',
      leftKey: 'đáy trái',
      tips: 'để kéo và xem.',
      ok: 'Tôi hiểu rồi',
    },
  },
  locale: {
    zh: 'người Trung Quốc',
    en: 'Tiếng Anh',
    es: 'người Tây Ban Nha',
    ru: 'tiếng Nga',
    pt: 'Tiếng Bồ Đào Nha (Brazil)',
    id: 'tiếng Indonesia',
    cs: 'tiếng Séc',
    de: 'tiếng Đức',
    fr: 'người Pháp',
    it: 'người Ý',
    nl: 'tiếng Hà Lan',
    pl: 'Đánh bóng',
    vi: 'Tiếng Việt',
    tr: 'tiếng Thổ Nhĩ Kỳ',
    uk: 'tiếng Ukraina',
    zhHant: 'Truyền thống Trung Hoa',
    ja: 'tiếng Nhật',
    ko: 'Hàn Quốc',
    ar: 'tiếng Ả Rập',
    fa: 'tiếng Ba Tư',
    th: 'Thái',
    hi: 'Tiếng Hindi',
    noSearchLang: 'Ngôn ngữ này không được hỗ trợ',
    placeholder: 'Tìm kiếm',
    af: 'tiếng Afrikaans',
    am: 'tiếng Amharic',
    as: 'Tiếng Assam',
    az: 'Tiếng Azerbaijan',
    ba: 'Bashkir',
    bg: 'tiếng Bungari',
    bn: 'Bangla',
    bo: 'tiếng Tây Tạng',
    bs: 'tiếng Bosnia',
    ca: 'tiếng Catalan',
    cy: 'người xứ Wales',
    da: 'người Đan Mạch',
    dsb: 'Tiếng Hạ Sorbia',
    dv: 'lặn',
    el: 'người Hy Lạp',
    et: 'tiếng Estonia',
    eu: 'Tiếng Basque',
    fi: 'tiếng Phần Lan',
    fil: 'Tiếng Philippin',
    fj: 'tiếng Fiji',
    frCA: 'Tiếng Pháp (Canada)',
    ga: 'người Ireland',
    gl: 'tiếng Galicia',
    gom: 'Konkani',
    gu: 'tiếng Gujarati',
    ha: 'Tiếng Hausa',
    he: 'tiếng Do Thái',
    hr: 'tiếng Croatia',
    hsb: 'Tiếng Thượng Sorbia',
    ht: 'Tiếng Creole Haiti',
    hu: 'người Hungary',
    hy: 'tiếng Armenia',
    ig: 'người Igbo',
    ikt: 'Inuinnaqtun',
    is: 'tiếng Iceland',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (tiếng Latin)',
    ka: 'tiếng Gruzia',
    kk: 'Kazakhstan',
    km: 'tiếng Khmer',
    kmr: 'Người Kurd (Miền Bắc)',
    kn: 'tiếng Kannada',
    ku: 'Người Kurd (Miền Trung)',
    ky: 'Tiếng Kyrgyzstan',
    ln: 'lingala',
    lo: 'tiếng Lào',
    lt: 'tiếng Litva',
    lug: 'Ganda',
    lv: 'tiếng Latvia',
    lzh: 'Tiếng Trung (Văn học)',
    mai: 'Maithili',
    mg: 'Malagasy',
    mi: 'người Maori',
    mk: 'Tiếng Macedonia',
    ml: 'Mã Lai',
    mnCyrl: 'Tiếng Mông Cổ (chữ Cyrillic)',
    mnMong: 'Tiếng Mông Cổ (truyền thống)',
    mr: 'Tiếng Marathi',
    ms: 'Mã Lai',
    mt: 'cây nho',
    mww: 'Hmong Daw',
    my: 'Myanmar (Miến Điện)',
    nb: 'người Na Uy',
    ne: 'tiếng Nepal',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Tiếng Punjab',
    prs: 'Dari',
    ps: 'Tiếng Pa-tô',
    ptPT: 'Tiếng Bồ Đào Nha (Bồ Đào Nha)',
    ro: 'người Rumani',
    run: 'Rundi',
    rw: 'Tiếng Kinyarwanda',
    sd: 'tiếng Sindhi',
    si: 'Sinhala',
    sk: 'Tiếng Slovak',
    sl: 'tiếng Slovenia',
    sm: 'Tiếng Samoa',
    sn: 'Shona',
    so: 'tiếng Somali',
    sq: 'tiếng Albania',
    srCyrl: 'Tiếng Serbia (Cyrillic)',
    srLatn: 'Tiếng Serbia (tiếng Latinh)',
    st: 'Sesotho',
    sv: 'tiếng Thụy Điển',
    sw: 'tiếng Swahili',
    ta: 'Tiếng Tamil',
    te: 'tiếng Telugu',
    ti: 'Tigrinya',
    tk: 'người Thổ Nhĩ Kỳ',
    tlhLatn: 'Tiếng Klingon (tiếng Latinh)',
    tlhPiqd: 'Tiếng Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'tiếng Tonga',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'người Duy Ngô Nhĩ',
    ur: 'tiếng Urdu',
    uz: 'Tiếng Uzbek (tiếng Latinh)',
    xh: 'Xhosa',
    yo: 'Tiếng Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Tiếng Quảng Đông (truyền thống)',
    zhHans: 'Tiếng Trung giản thể',
    zu: 'Tiếng Zulu',
    auto: 'Tự động phát hiện',
  },
  stripe: {
    contact: 'Hãy liên hệ với chúng tôi theo địa chỉ contact@noam.tools nếu có bất kỳ câu hỏi nào!',
    fail: {
      title: 'Thanh toán không thành công',
      text1: 'Rất tiếc, chúng tôi không thể xử lý yêu cầu thanh toán của bạn.',
      text2: 'Vui lòng kiểm tra kết nối internet của bạn và thử lại.',
      btn: 'Thử lại',
    },
    succ: {
      title: 'Chúc mừng',
      text1: 'chào mừng đến với Noam,',
      text2: 'chúng ta hãy bắt đầu cùng nhau!',
      btn: 'Hãy làm một số phép thuật',
    },
  },
  welfare: {
    newUser: {
      label: 'Lợi ích của người dùng mới:',
      joinBtn: 'Tham gia ngay',
      text: 'Chỉ có 100 điểm có sẵn! Hãy sở hữu ngay!',
      remain: 'Chỉ còn 30',
    },
  },
  help: {
    Fixedplugin: 'Đã sửa lỗi plugin',
    Howtouse: 'Cách sử dụng',
    try: 'Hãy thử một lần',
    FreeTrialBenefits: 'Lợi ích dùng thử miễn phí',
    pin: 'Pin Noam, để truy cập dễ dàng hơn～～',
    Video: 'Băng hình',
    ParallelTranslation: 'Dịch song song',
    WebSummary: 'Tóm tắt trang web',
    ChatPDF: 'Trò chuyện PDF',
    AquickOverviewOfNoam: 'Tổng quan nhanh về Noam',
    WordSelectionTranslation: 'Dịch chọn từ',
    HoverTranslation: 'Dịch di chuột',
    ImageTranslation: 'Dịch hình ảnh',
    InstantTranslation: 'Dịch nhanh',
    VideoTranslation: 'Dịch video',
    benefits: 'Chúng tôi đã chuẩn bị các lợi ích cho bạn!',
    Free: 'Miễn phí',
    Membership: 'Tư cách thành viên',
    Translation: 'Dịch',
    PDFparallelTranslation: 'Dịch song song PDF',
    ChatPDF2: 'Trò chuyệnPDF',
    Summary: 'Bản tóm tắt',
    VideoSummary: 'Tóm tắt video',
    FavoritesManagement: 'Quản lý yêu thích',
    Focusing: 'Lấy nét',
    dayFree: 'Dùng thử miễn phí 3 ngày',
    planIsNotExist: 'kế hoạch không tồn tại',
    TryItForFree: 'Dùng thử miễn phí',
    startYoutube: 'Hãy bắt đầu với YouTube~',
    startWiki: 'Hãy bắt đầu với Wikimedia~',
    tryNow: 'Hãy thử ngay bây giờ~',
    GoWithYouTube: 'Đi cùng YouTube',
    GoWithWikipedia: 'Đi với Wikipedia',
    NoamHelp: 'Noam có thể giúp bạn dịch và tóm tắt',
    SkipAllSteps: 'Bỏ qua tất cả các bước',
    next: 'Kế tiếp',
    done: 'Xong',
  },
  trialModal: {
    title: 'Quà chào mừng',
    trialText: 'Dùng thử 7 ngày',
    desc1: 'Sau khi thời gian dùng thử kết thúc, bạn sẽ phải trả phí hàng năm là 180 USD',
    desc2: 'Hủy bất cứ lúc nào',
    btn: 'Bắt đầu ngay bây giờ（Chỉ $0,99）',
    installBtn: 'Cài đặt ngay để nhận quà',
    web: 'Dịch song song',
    select: 'Dịch chọn từ',
    hover: 'Dịch di chuột',
    image: 'Dịch hình ảnh',
    realtime: 'Dịch nhanh',
    video: 'Dịch video',
    pdf: 'Dịch song song PDF',
    chat: 'Trò chuyệnPDF',
    webSummary: 'Tóm tắt web',
    videoSummary: 'Tóm tắt video',
    memo: 'Quản lý yêu thích',
    focus: 'Lấy nét',
  },
  privacyCollectionPanel: {
    title: 'Trang web này sử dụng cookie',
    text: 'Để cải thiện trải nghiệm của bạn, chúng tôi muốn sử dụng cookie để cá nhân hóa nội dung và dịch vụ.',
    acceptBtn: 'Chấp nhận tất cả',
  },
  installTips: {
    text: 'Hãy cài đặt “Noam Browser Plugin” để mở khóa nhiều tính năng hơn! Tham khảo chéo trên web,\n    tóm tắt video trên web và hơn thế nữa!',
    btn: 'Thử ngay bây giờ',
  },
}

export default TRANSLATION
