import { storageHelper } from '@/helpers/storageHelper'
import i18n, {
  $FirstNamespace,
  $IsResourcesDefined,
  AppendKeyPrefix,
  DefaultNamespace,
  InterpolationMap,
  Namespace,
  ParseKeys,
  TFunctionReturn,
  TFunctionReturnOptionalDetails,
  TOptions,
} from 'i18next'
import { $Dictionary } from 'i18next/typescript/helpers'
import { initReactI18next, useTranslation } from 'react-i18next'
import { TranslationKey, _i18n } from './i18nService'
import resources from './resource'

const fallbackLng = 'en'
let lng = navigator.language
if (Object.keys(resources).indexOf(lng) === -1) {
  lng = fallbackLng
}
i18n.use(initReactI18next).init({
  resources,
  lng, // 默认语言
  fallbackLng, // 如果当前语言资源不存在，则使用默认语言
  interpolation: {
    escapeValue: false, // 如果你在翻译文本中使用 HTML 标签，设置为 false
  },
})
_i18n.uiLang = lng
const cachedLang = storageHelper.get(['uiLang']).uiLang
if (cachedLang) {
  i18n.changeLanguage(cachedLang)
  _i18n.uiLang = cachedLang
}
_i18n.setTranslationFunction(i18n.t)

export const useTranslationWithType = () => {
  const { t, i18n } = useTranslation()
  return { t: t as TFunction, i18n }
}

/** 重新定义一下 i18n t 函数的类型，copy from i18n type ，仅修改了 [key] 的 type */
interface TFunction<Ns extends Namespace = DefaultNamespace, KPrefix = undefined> {
  $TFunctionBrand: $IsResourcesDefined extends true ? `${$FirstNamespace<Ns>}` : never
  <
    const Key extends ParseKeys<Ns, TOpt, KPrefix> | TemplateStringsArray,
    const TOpt extends TOptions,
    Ret extends TFunctionReturn<Ns, AppendKeyPrefix<Key, KPrefix>, TOpt>,
    const ActualOptions extends TOpt & InterpolationMap<Ret> = TOpt & InterpolationMap<Ret>
  >(
    ...args:
      | [key: TranslationKey | TranslationKey[], options?: ActualOptions]
      | [key: string | string[], options: TOpt & $Dictionary & { defaultValue: string }]
      | [key: string | string[], defaultValue: string, options?: TOpt & $Dictionary]
  ): TFunctionReturnOptionalDetails<Ret, TOpt>
}
