import React, { ElementType, Suspense, lazy, useEffect, useState } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import AmeSpin from './components/AmeSpin/AmeSpin'
import { PAGE_LINKS } from './helpers/commonConsts'
import { userService } from './services/userService'
import { observer } from 'mobx-react'
import { routerService } from './services/routerService'

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<AmeSpin fullscreen={true} />}>
      <Component {...props} />
    </Suspense>
  )

const AuthRoute = observer(({ children }: any) => {
  const [canRender, setRenderFlag] = useState(false)

  useEffect(() => {
    async function checkLogin() {
      if (!userService.isLogin) {
        await userService.updateUserInfo()
        if (!userService.userData) {
          routerService.navigateTo('login', {
            redirectUrl: encodeURIComponent(window.location.href),
          })
          return
        }
      }

      setRenderFlag(true)
    }

    checkLogin()
  }, [userService.isLogin])
  if (!canRender) return null
  return children
})

const HomePage = Loadable(lazy(() => import('./pages/HomePage/HomePage')))
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage/LoginPage')))
const PricingPage = Loadable(lazy(() => import('./pages/PricingPage/PricingPage')))
const ProvacyPage = Loadable(lazy(() => import('./pages/PrivacyPage/PrivacyPage')))
const TermsPage = Loadable(lazy(() => import('./pages/TermsPage/TermsPage')))
const PdfPage = Loadable(lazy(() => import('./pages/PdfPage/PdfPage')))
const ContactPage = Loadable(lazy(() => import('./pages/ContactPage/ContactPage')))
const FastSpringPage = Loadable(lazy(() => import('./pages/FastSpringPage/FastSpringPage')))
const StriptFailPage = Loadable(lazy(() => import('./pages/StripeFailPage/StripeFailPage')))
const StriptSuccPage = Loadable(lazy(() => import('./pages/StripeSuccessPage/StripeSuccessPage')))
const HelpPage = Loadable(lazy(() => import('./pages/HelpPage/HelpPage')))

const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.home,
    element: <HomePage />,
  },
  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.pricing,
    element: <PricingPage></PricingPage>,
  },
  { path: PAGE_LINKS.policy, element: <ProvacyPage /> },
  { path: PAGE_LINKS.terms, element: <TermsPage /> },
  {
    path: PAGE_LINKS.pdf,
    element: <PdfPage />,
  },
  {
    path: PAGE_LINKS.contact,
    element: <ContactPage />,
  },
  {
    path: PAGE_LINKS.fastSpring,
    element: <FastSpringPage />,
  },
  {
    path: PAGE_LINKS.stripeFail,
    element: <StriptFailPage />,
  },
  {
    path: PAGE_LINKS.stripeSucc,
    element: <StriptSuccPage />,
  },
  {
    path: PAGE_LINKS.help,
    element: (
      <AuthRoute>
        <HelpPage />
      </AuthRoute>
    ),
  },
]

export const Router = () => {
  return useRoutes(routes)
}
