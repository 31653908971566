import { api } from '@/helpers/api'
import { pricingModal } from '@/helpers/api/pricing'
import { observable } from 'mobx'
import { userService } from '../userService'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
declare let fastspring: any

interface CheckoutData {
  planId: string
  successUrl?: string
  cancelUrl?: string
  isTrial?: boolean
  target?: '_self' | 'blank'
  source: string
}
class VipService {
  @observable planList: pricingModal.VipPlan[] = []
  timer: NodeJS.Timeout | undefined = undefined
  checkUserInfoTimer: NodeJS.Timeout | null = null
  @observable trialPlan: pricingModal.VipPlan | null = null

  public async updatePlanList(force: boolean = false): Promise<pricingModal.VipPlan[]> {
    if (this.planList.length && !force) {
      return this.planList
    }
    try {
      const { planList } = await api.pricing.list()
      this.planList = planList
    } catch (error) {}
    return this.planList
  }

  public async getTrialPlan() {
    const data = await api.pricing.getUserTrialType()
    this.trialPlan = data.data
    if (data.type === 'new_user_gift') {
      userService.enableNewUserDiscount = true
    }
    return data
  }

  public async checkout(
    data: CheckoutData,
    {
      dataPopupClosed,
      dataErrorCallback,
      dataPopupWebhookReceived,
    }: {
      dataPopupClosed: () => void
      dataErrorCallback: () => void
      dataPopupWebhookReceived: () => void
    },
    code?: string
  ) {
    // this.stripeCheckout({
    //   ...data,
    //   cb: { dataPopupClosed, dataErrorCallback, dataPopupWebhookReceived },
    // })
    this.fsCheckout(
      data,
      {
        dataPopupClosed,
        dataErrorCallback,
        dataPopupWebhookReceived,
      },
      code
    )
  }

  private async stripeCheckout({
    planId,
    successUrl,
    cancelUrl,
    isTrial,
    target = '_self',
    cb,
  }: {
    planId: string
    isTrial?: boolean
    successUrl?: string
    cancelUrl?: string
    target?: '_self' | 'blank'
    cb: {
      dataPopupClosed: () => void
      dataErrorCallback: () => void
      dataPopupWebhookReceived: () => void
    }
  }) {
    if (!successUrl) {
      successUrl = `${envHelper.webHost}/s_succ`
    }
    if (!cancelUrl) {
      cancelUrl = `${envHelper.webHost}/s_fail`
    }
    try {
      const { payUrl } = await api.pricing.stripeCheckout({
        planId,
        successUrl,
        cancelUrl,
        isTrial,
      })
      cb.dataPopupClosed()
      window.open(payUrl, target)
    } catch (error) {
      cb.dataErrorCallback()
    }
  }

  private checkUserInfo() {
    const version = userService.vipVersion
    if (this.checkUserInfoTimer) {
      clearTimeout(this.checkUserInfoTimer)
      this.checkUserInfoTimer = null
    }
    const checkFn = () => {
      this.checkUserInfoTimer = setTimeout(async () => {
        await userService.updateUserInfo()
        if (userService.vipVersion === version) {
          checkFn()
        }
      }, 1000)
    }
    checkFn()
  }

  private async fsCheckout(
    { planId, isTrial, source }: CheckoutData,
    {
      dataPopupClosed,
      dataErrorCallback,
      dataPopupWebhookReceived,
    }: {
      dataPopupClosed: () => void
      dataErrorCallback: () => void
      dataPopupWebhookReceived: () => void
    },
    code?: string
  ) {
    this.initEvent({ dataErrorCallback, dataPopupClosed, dataPopupWebhookReceived })
    try {
      const {
        data: { payload, key },
      } = await api.pricing.checkout({ planId, code, isTrial, source })
      fastspring.builder.secure(payload, key)
      fastspring.builder.checkout()
    } catch (error) {
      dataErrorCallback()
    }
  }

  public initEvent({
    dataPopupClosed,
    dataErrorCallback,
    dataPopupWebhookReceived,
  }: {
    dataPopupClosed: () => void
    dataErrorCallback: () => void
    dataPopupWebhookReceived: () => void
  }) {
    // 定义回调函数
    ;(window as any).dataPopupWebhookReceived = (data: any) => {
      // 在这里处理 dataPopupWebhookReceived 事件
      console.log('noam fs markPaid', data)
      this.checkUserInfo()
      this.emitExtensionMessage('markPaid')
      dataPopupWebhookReceived()
    }
    ;(window as any).dataPopupClosed = () => {
      // 在这里处理 dataPopupClosed 事件
      console.log('data popup closed')
      dataPopupClosed()
    }
    ;(window as any).dataErrorCallback = (error: any) => {
      // 在这里处理 dataErrorCallback 事件
      console.error(error, 'data error callback')
      dataErrorCallback()
    }
  }

  private clearTimer() {
    if (!this.timer) {
      return
    }
    clearTimeout(this.timer)
    this.timer = undefined
  }
  private emitExtensionMessage(event: 'markPaidFail' | 'markPaid') {
    this.clearTimer()
    let maxCount = 10
    const sendMsg = () => {
      if (maxCount < 0) return
      maxCount--
      this.timer = setTimeout(() => {
        const hasInstallExtension = commonUtils.isInstalledExtension()
        if (hasInstallExtension) {
          commonUtils.sendExtensionMessage(event, {})
          return
        }
        sendMsg()
      }, 1000)
    }
    sendMsg()
  }
}

export const vipService = new VipService()
