import { get } from './common'
import { pricing } from './pricing'
import { user } from './user'
import { pdf } from './pdf'

export function getHuiyiUrlConfig() {
  return get<ServerDataTypes.UrlConfig>('/url_config')
}
export const api = {
  user,
  pdf,
  pricing: pricing,
  getHuiyiUrlConfig,
}
