import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './PlanItem.module.less'
import { pricingModal } from '@/helpers/api/pricing'
import { commonUtils } from '@/helpers/commonUtils'
import AmeIcon from '../AmeIcon/AmeIcon'
import { useTranslationWithType } from '@/i18n/i18n'

/** PlanItem 组件的 Props */
export type PlanItemProps = {
  plan: pricingModal.VipPlan
  active?: boolean
  onClick?: () => void
  isUpgrade?: boolean
}

const PRO_BENEFITS = [
  'pricing.pro.benefit2',
  'pricing.pro.benefit3',
  'pricing.pro.benefit4',
  'pricing.pro.benefit5',
  'pricing.pro.benefit6',
]
const PROV_BENEFITS = [
  'pricing.proPlus.benefit2',
  'pricing.proPlus.benefit3',
  'pricing.proPlus.benefit4',
  'pricing.proPlus.benefit5',
  'pricing.proPlus.benefit6',
]
const PlanItem: FC<PlanItemProps> = observer(
  ({ plan, active, onClick, isUpgrade }: PlanItemProps) => {
    const { t } = useTranslationWithType()
    const [benefits, setBenefits] = useState<string[]>([])
    useEffect(() => {
      setBenefits(plan.version === 'pro' ? PRO_BENEFITS : PROV_BENEFITS)
    }, [plan.version])
    const getVersionInfo = () => {
      if (plan.version === 'pro') {
        return (
          <div className={classNames(style.versionInfo)}>
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('pricing/icon__pro.png')}
            />
            <span>Pro</span>
          </div>
        )
      }
      return (
        <div className={classNames(style.versionInfo)}>
          <img
            className={classNames(style.icon)}
            src={commonUtils.getStaticFile('pricing/icon__prov.png')}
          />
          <span>Pro+</span>
        </div>
      )
    }

    return (
      <div
        className={classNames(style.planItem, {
          [style.pro]: plan.version === 'pro',
          [style.proV]: plan.version === 'pro+',
          [style.active]: active,
        })}
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
      >
        <div className={classNames(style.itemBox, { [style.active]: active })}>
          {/* 版本号 */}
          {getVersionInfo()}
          {/* 选中iconf */}
          {active && !isUpgrade && (
            <div className={classNames(style.activeBox)}>
              <AmeIcon
                type='icon-line-global_check'
                className={classNames(style.ameIcon)}
              ></AmeIcon>
            </div>
          )}

          <div className={classNames(style.bList)}>
            {/* 权益列表 */}
            {benefits.map((item) => {
              return (
                <div key={item} className={classNames(style.bItem)}>
                  <AmeIcon
                    className={style.ameIcon}
                    type={
                      plan.version === 'pro' ? 'icon-fill-global_check2' : 'icon-fill-global_check'
                    }
                  ></AmeIcon>
                  <span>{t(item as any)}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
)

export default PlanItem
