const TRANSLATION = {
  slogen: '最簡單的AI助手',
  pageHeader: {
    help: '幫助中心',
    contact: '聯絡我們',
    pricing: '價錢',
    signUp: '登入',
    settings: '設定',
    logout: '登出',
    logoutSucc: '註銷成功',
  },
  pageFooter: {
    product: {
      title: '產品',
      web: '網路翻譯與摘要',
      pdf: 'PDF翻譯',
      video: '影片翻譯及總結',
      image: '影像翻譯',
    },
    resources: { title: '資源' },
    follow: { title: '跟隨', twitter: '推特', facebook: 'Facebook' },
    legal: { title: '合法的', policy: '隱私權政策', terms: '服務條款' },
  },
  home: {
    getStart: '開始使用',
    getStartedForFree: '免費開始使用',
    top: {
      desc: 'Noam 是一款免費的 AI 瀏覽器擴展，可協助您翻譯摘要網頁、影片和 PDF 內容。\n      加入諾姆，享受魔法吧！',
      title2: '一個魔法',
      title1: '一鍵點擊',
    },
    howItWork: {
      title: '那麼，它是如何運作的',
      desc: 'Noam整合AI模型，提供使用者跨語言網頁翻譯與摘要、PDF與圖片翻譯、影片翻譯與摘要的智慧體驗',
    },
    PDFTranslate: {
      title: 'PDF翻譯',
      desc: '雙語翻譯+聊天PDF',
      sub1: { title: '雙語閱讀', desc: '雙語閱讀，閱讀時可以比較原文和譯文，提升閱讀效率' },
      sub2: { title: '聊天PDF', desc: 'AI分析總結，根據PDF內容解答您的疑問' },
      sub3: { title: '準確性和效率', desc: 'AI 支援的 PDF 實現更準確、更有效率的翻譯' },
    },
    aiAssistant: {
      title: '人工智慧助手',
      desc: '讓網路翻譯更聰明、更便捷',
      panel1: {
        title: '雙語翻譯',
        desc: '雙語交叉引用翻譯可幫助您將原文與譯文進行比較，以便您更好地理解並提高閱讀效率。',
      },
      panel2: {
        left: {
          title: '懸停翻譯',
          desc: '瀏覽網頁時，將滑鼠直接停留在需要翻譯的內容上即可取得翻譯結果。',
        },
        right: {
          title: '文字選擇翻譯',
          desc: '瀏覽網頁時，直接選擇要加底線的文字即可得到翻譯結果。',
        },
      },
      panel3: {
        one: { title: '點選', desc: '隨時隨地為您翻譯、總結網頁和影片內容；帶給您更流暢的體驗！' },
        two: {
          title: '更全面、更便利',
          desc: '多語言全覆蓋，雙語無障礙閱讀，一鍵翻譯；帶給您無與倫比的體驗！',
        },
        three: {
          title: '人工智慧驅動',
          desc: 'AI驅動大模型，確保翻譯準確性與一致性，提升閱讀體驗',
        },
      },
      panel4: {
        tab1: {
          key: '影片',
          title: '影片翻譯及總結',
          desc: '您的效率專家，結合chatgpt翻譯總結YouTube影片內容，節省您的閱讀時間！',
        },
        tab2: { key: '影像', title: '影像翻譯', desc: '瀏覽網頁時點擊翻譯圖像' },
        tab3: {
          key: '網路',
          title: '網路翻譯與摘要',
          desc: '快速擷取網頁關鍵資訊進行摘要，提高閱讀效率',
        },
        tag1: '簡單的',
        tag2: '效率',
        tag3: '容易理解',
      },
    },
    userReviews: { title: '用戶評論', desc: 'chromewebstore 上的五星級評論' },
    lastBlock: { desc: '更好的AI翻譯，更流暢的閱讀體驗' },
  },
  login: {
    title: '歡迎來到諾姆',
    btn: '使用谷歌登入',
    tips: '註冊即表示我同意',
    terms: '使用條款',
    and: '和',
    policy: '隱私權政策',
    btn1: '現在登入',
    desc: '登入以解鎖更多體驗',
  },
  pricing: {
    pageTitle: '價錢',
    desc: '比較並選擇最適合您的',
    yearly: '每年',
    monthly: '每月',
    discount: '20折優惠',
    preMonth: '每月',
    billedYearly: '按年計費',
    billedMonthly: '按月計費',
    detailedCompare: '詳細比較',
    FAQ: '常問問題',
    day: '天',
    month: '月',
    around: '大約',
    free: {
      benefit1: '即時翻譯100次/天',
      benefit2: '網路翻譯與摘要',
      benefit3: '30張圖像翻譯',
      benefit4: '影片翻譯及總結',
      benefit5: '10 PDF 翻譯',
      benefit6: '5,000 代幣',
      recommendText: '免費試用',
      btn: '玩得開心',
    },
    pro: {
      benefit1: '客戶服務快速支援',
      benefit2: '1,000,000 個代幣/月',
      benefit3: '網路翻譯與摘要',
      benefit4: '200張圖片翻譯',
      benefit5: '影片翻譯及總結',
      benefit6: '200 個 PDF 翻譯',
      btn: '開始使用',
      recommendText: '大多數人都會選擇',
    },
    proPlus: {
      benefit1: '客戶服務快速支援',
      benefit2: '3,000,000 個代幣/月',
      benefit3: '網路翻譯與摘要',
      benefit4: '500張圖片翻譯',
      benefit5: '影片翻譯及總結',
      benefit6: '500 個 PDF 翻譯',
      recommendText: '最適合的選擇',
    },
    enterprise: {
      benefit1: '客戶服務快速支援',
      benefit2: '多用戶',
      benefit3: '客製化解決方案',
      benefit5: '專屬客戶經理',
      recommendText: '客製化方案（≥3人）',
      talkToUs: '與我們交談',
      btn: '接觸',
    },
    web: {
      contrastiveTranslation: '雙語翻譯',
      instantTranslation: '即時翻譯',
      hoverTranslation: '懸停翻譯',
      dictionaryTranslation: '文字選擇翻譯',
      summary: '網頁摘要',
    },
    imageTranslation: '圖片翻譯',
    video: { translation: '影片翻譯', summary: '影片摘要' },
    pdf: { translation: 'PDF翻譯', maxPage: 'PDF 的最大頁數', chat: 'PDF查詢數量' },
    FQAObj: {
      q1: '我可以免費使用它嗎？',
      a1: 'Noam有免費版本，可以直接使用。',
      q2: '是否有不同的會員方案和費用選項？',
      a2: '除了免費版本外，還有三種會員方案：Pro、Pro+ 和 Enterprise；可選擇連續包月及連續包年兩種繳費方式，連續包年綜合費用較優惠。',
      q3: '連續包月訂閱和連續包年訂閱有什麼區別？',
      a3: '功能上沒有差別。連續包年的綜合成本低於連續包月的綜合成本；包月訂閱每月計費一次，包年訂閱則每年計費一次。',
      q4: '如何取消我的自動續訂方案？',
      a4: '您可以進入背景設定頁面選擇訂閱取消續訂方案。',
    },
  },
  upgradeModal: {
    title: '提醒事項',
    limitAlertStr: '您的免費套餐額度已用完，建議您升級套餐以繼續體驗~！',
    upgradeAlertStr: '您的免費套餐額度已用完，建議您升級套餐以繼續體驗~！',
    btn: '現在升級',
    more: '更多計劃',
    contactStr: '請等待下次重置或透過 contact@noam.tools 與我們聯繫。',
    upload: { free: '您已達到 pdf 翻譯的極限', pro: '您已達到本月 pdf 翻譯的限制' },
    chat: { free: '您已達到 Chatpdf 超出限制', pro: '您已達到本月 Chatpdf 的限制' },
    pageLimit: { free: '您已達到 PDF 頁面限制' },
  },
  buyModal: {
    title: '訂閱計劃',
    freeTrial: '3 天免費試用',
    selectLabel: '選擇一個計劃',
    btn: '免費試用',
    yearLabel: '年',
    monthLabel: '月',
    afterFree: '庭審結束後',
  },
  privacy: {
    title: '隱私權政策',
    updateTime: '更新日期：2024 年 3 月 15 日',
    block1:
      '我們，Noam（簡稱「Noam」、「我們」、「我們的」或「我們的」）尊重您的隱私，並致力於保護我們從您那裡獲得的或關於您的任何資訊。本隱私權政策描述了我們在您使用我們的網站、應用程式和服務（統稱為「服務」）時收集或使用個人資訊的做法。本隱私權政策不適用於我們代表我們的業務產品客戶處理的內容，例如我們的 API。這些數據的使用須遵守我們的客戶協議，其中涵蓋了這些產品的存取和使用。',
    subTitle1: '我們收集的個人資訊',
    block2: '我們收集以下與您相關的個人資訊（「個人資訊」）：',
    block3: '您提供的資訊：如果您建立帳戶來使用我們的服務或與我們溝通，我們將收集以下個人資訊：',
    subBlock1:
      '帳戶資訊：當您在我們的平台上建立帳戶時，我們會收集與您的帳戶相關的信息，包括您的姓名、聯絡資訊、帳戶憑證、支付卡資訊和交易記錄（簡稱「帳戶資訊」） 。',
    subBlock2:
      '使用者內容：當您使用我們的服務時，我們會從您的輸入、文件上傳或提供給我們的回饋中收集個人資訊（稱為「內容」）。',
    subBlock3:
      '通訊資訊：如果您向我們發送訊息，我們將收集您的姓名、聯絡資訊以及您發送的訊息內容（簡稱「通訊資訊」）。',
    subBlock4:
      '社群媒體資訊：我們在 Instagram、Facebook、Medium、Twitter、YouTube 和 LinkedIn 等社群媒體網站上設有頁面。當您與我們的社交媒體頁面互動時，我們會收集您選擇提供給我們的個人訊息，例如您的聯絡資訊（稱為「社交資訊」）。此外，託管我們社交媒體頁面的公司可能會向我們提供有關我們社交媒體活動的總結資訊和分析。',
    block4:
      '在您使用服務時自動接收的個人資訊：當您存取、使用服務或與服務互動時，我們會收到以下有關您的存取、使用或互動的資訊（「技術資訊」）：',
    subBlock5:
      '日誌資料：您的瀏覽器在使用我們的服務時自動發送的訊息。日誌資料包括您的網際網路通訊協定 (IP) 位址、瀏覽器類型和設定、您要求的日期和時間以及您與我們網站互動的方式。',
    subBlock6:
      '使用資料：我們可能會自動收集有關您使用服務的信息，例如您查看或互動的內容類型、您使用的功能和您採取的操作，以及您的時區、國家/地區、日期和時間訪問權限、用戶代理程式和版本、電腦或行動裝置類型以及您的電腦連線。',
    subBlock7:
      '設備資訊：包括設備名稱、作業系統、設備識別碼以及您正在使用的瀏覽器。收集的資訊可能取決於您使用的設備類型及其設定。',
    subBlock8:
      'Cookie：我們使用 Cookie 來營運和管理我們的服務並改善您的體驗。 「cookie」是您造訪的網站傳送到您的瀏覽器的資訊。您可以將瀏覽器設定為接受所有cookie、拒絕所有cookie，或在提供cookie時通知您，以便您每次決定是否接受。但是，拒絕 Cookie 可能會阻止您使用網站某些區域或功能的顯示或功能，或對網站的某些區域或功能的顯示或功能產生負面影響。有關 Cookie 的更多信息，請訪問關於 Cookie 的一切。',
    subBlock9:
      '分析：我們可能會使用各種使用cookie的線上分析產品來幫助我們分析使用者如何使用我們的服務並改善您在使用服務時的體驗。',
    subTitle2: '我們如何使用個人資訊',
    block5: '我們可能將個人資訊用於以下目的：',
    subBlock10: '提供、管理、維護和/或分析服務；',
    subBlock26: '改善我們的服務並進行研究；',
    subBlock11: '與您溝通；',
    subBlock12: '開發新項目和服務；',
    subBlock13: '防止詐欺、犯罪活動或濫用我們的服務，並保護我們的 IT 系統、架構和網路的安全；',
    subBlock14: '進行業務轉讓；',
    subBlock15:
      '遵守法律義務和法律程序，保護我們以及我們的附屬公司、您或其他第三方的權利、隱私、安全或財產。',
    block6:
      '摘要或去識別化信息：我們可能會匯總或去識別化個人信息，以防止將其重新用於識別目的，並使用此類信息來分析我們服務的有效性、改進和添加我們服務的功能、進行研究以及用於類似目的。此外，我們可能會分析我們服務使用者的一般行為和特徵，並與第三方共享匯總資訊、發布此類匯總資訊或將其公開。我們可能會透過服務、cookie 以及本隱私權政策中所述的其他方式收集匯總資訊。我們將以匿名或去識別化的形式維護和使用去識別化訊息，除非法律要求，我們不會嘗試重新識別此類資訊。',
    subTitle3: '個人資訊的揭露',
    block7: '在某些情況下，我們可能會向第三方提供您的個人信息，恕不另行通知，除非法律要求：',
    subBlock16:
      '供應商和服務提供者：為了協助我們滿足業務營運需求並執行某些服務和功能，我們可能會向供應商和服務提供者提供個人資訊，包括託管服務提供者、雲端服務提供者和其他資訊技術服務提供者、電子郵件通訊軟體以及網路分析服務提供者等。',
    subBlock17:
      '業務轉讓：如果我們進行策略性交易、重組、破產、破產接管或向其他提供者轉移服務（統稱為「交易」），您的個人資訊和其他資訊可能會與交易對方和協助進行業務的其他方共享。',
    subBlock18:
      '法律要求：我們可能會與政府機構、行業同行或其他第三方共享您的個人信息，包括您與我們的服務的交互信息，(1) 如果法律要求或如果我們認為此類行動對於遵守法律義務是必要的； (2) 保護和捍衛我們的權利或財產； (3) 如果我們單方面確定存在違反我們的條款、政策或法律的行為； (4) 發現或防止詐欺或其他非法活動； (5) 保護我們的產品、員工或用戶，或公共安全、保全、完整性； (vi) 抗辯法律責任。',
    subTitle4: '您的權利',
    block8:
      '根據您的地理位置，歐洲經濟區、英國和全球範圍內的個人可能對其個人資訊擁有某些法定權利。例如，您可能有權：',
    subBlock19: '存取您的個人資訊以及有關如何處理該資訊的資訊。',
    subBlock20: '從我們的記錄中刪除您的個人資訊。',
    subBlock21: '更正或更新您的個人資訊。',
    subBlock22: '將您的個人資訊轉移給第三方（資料可攜性）。',
    subBlock23: '限制我們處理您的個人資訊的方式。',
    subBlock24: '撤回您的同意－如果我們依賴同意作為處理的法律依據，您可以隨時撤回您的同意。',
    subBlock25: '反對我們處理您的個人資訊的方式。',
    subTitle5: '其他網站的連結',
    block9:
      '本服務可能包含非 Noam 運營或控制的其他網站的鏈接，包括社交媒體服務（「第三方網站」）。您與第三方網站分享的資訊將受第三方網站的特定隱私權政策和服務條款的約束，而不是受本隱私權政策的約束。我們提供這些連結是為了方便，並不代表我們認可或審查這些網站。請直接聯絡第三方網站以取得有關其隱私權政策和條款的資訊。',
    subTitle6: '資訊的安全和保留',
    block10:
      '我們實施商業上合理的技術、管理和組織措施，以保護線上和線下個人資訊免遭遺失、濫用以及未經授權的存取、揭露、變更或敵對行為。然而，任何網路或電子郵件傳輸都不能保證完全的安全性或準確性。特別是，發送給我們或我們發送的電子郵件可能不安全。因此，您在決定透過服務或電子郵件向我們發送哪些訊息時應特別小心。此外，我們對服務中的隱私設定或安全措施或第三方網站的安全設定或安全措施的任何阻礙不承擔任何責任。我們將在為您提供服務或其他合法商業目的（例如解決爭議、安全原因或遵守我們的法律義務）所需的時間內保留您的個人資訊。我們保留個人資訊的時間長短取決於許多因素，例如資訊的數量、性質、敏感性、未經授權使用或揭露的潛在風險、我們處理資訊的目的以及其他具有法律約束力的考慮因素。',
    subTitle7: '隱私權政策的修改',
    block11:
      '我們可能會不時更新本隱私權政策。當我們這樣做時，我們將在此頁面上發布更新版本，除非適用法律另有要求。',
    subTitle8: '如何聯絡我們',
    block12: '如果您對本隱私權政策有任何未解答的問題或疑慮，請聯絡我們的客戶服務。電子郵件：',
  },
  terms: {
    title: '服務條款',
    updateTime: '更新時間：2024 年 3 月 15 日',
    block1: '感謝您使用諾姆！',
    block2:
      '這些使用條款適用於您對 Noam 服務的使用，包括我們的應用程式介面、軟體、工具、開發人員服務、資料、文件和網站（統稱為「服務」）。使用我們的服務即表示您同意遵守這些條款。我們的隱私權政策解釋了我們如何收集和使用個人資訊。',
    subTitle1: '註冊和訪問',
    block3:
      '如果您未滿18歲，您必須獲得父母或法定監護人的許可才能使用本服務。如果您代表其他人或實體使用本服務，您必須被授權代表他們接受這些條款。您必須提供準確、完整的資訊才能註冊帳戶。您不得向組織外部的任何人提供您的存取憑證或帳戶，並且您對使用您的憑證進行的所有活動負責。',
    subTitle2: '使用要求',
    block4:
      '(a) 服務的使用。您可以根據這些條款存取服務，並且我們授予您使用服務的非專有權利。使用本服務時，您將遵守本條款和所有適用的法律。我們和我們的附屬公司擁有服務的所有權利、所有權和利益。',
    block5:
      '(b) 回饋。我們歡迎回饋、意見、想法、建議和改進。如果您提供任何此類內容，我們可以不受任何限制地使用它，並且無需向您提供補償。',
    block6:
      '(c) 限制。您不得：(1) 以侵害、盜用或侵害他人任何權利的方式使用本服務； (2) 進行逆向工程、反編譯、反組譯、翻譯或以其他方式嘗試發現服務模型、演算法和系統的原始碼或底層元件（除非適用法律禁止此類限制）； (3) 使用服務的產出來發展與Noam競爭的模型； (4) 使用任何自動化或程式方法從服務中提取資料或輸出，除非透過 API 允許，包括抓取、網路收集或網路資料提取； (5) 將服務的輸出描述為人工生成，而實際上並非人工生成，或以其他方式違反我們的使用政策； (6) 未經我們事先同意購買、出售或轉讓 API 金鑰； (7) 提供我們 13 歲或適用的數字同意年齡以下兒童的任何個人資訊。您將遵守我們文件中的任何速率限制和其他要求。',
    block7:
      '(d) 第三方服務。與服務相關的任何第三方軟體、服務或其他產品均受其自身條款的約束，我們不對第三方產品負責。',
    subTitle3: '內容',
    block8:
      '(a) 您的內容。您可以向服務提供輸入（“輸入”）並接收基於輸入產生和返回的輸出（“輸出”）（統稱為“內容”）。在雙方之間，在適用法律允許的範圍內，您擁有所有意見。 Noam 可以使用內容來提供和維護服務、遵守適用法律並執行我們的政策。您對內容負責，包括確保其不違反任何適用法律或這些條款。',
    block9:
      '(b) 準確性。人工智慧和機器學習是快速發展的研究領域。我們不斷努力改善我們的服務，使其更加準確、可靠、安全和有益。鑑於機器學習的機率性質，在某些情況下使用我們的服務可能會導致錯誤的輸出，這可能無法準確反映真實的個人、地點或事實。您應該根據您的用例適當評估任何輸出的準確性，包括手動檢查輸出。',
    subTitle4: '費用和付款',
    block10:
      '(a) 費用和帳單。您將根據適用定價頁面上的定價和條款或我們之間書面同意的價格和條款支付所有費用（「費用」）。即使我們已開立發票或收到付款，我們也保留糾正定價錯誤或錯誤的權利。您將提供完整且準確的帳單信息，包括有效且授權的付款方式。我們將按照約定定期向您的付款方式收取費用，但我們可能會合理更改計費日期。您授權 Noam、其附屬公司和第三方支付處理商透過您的付款方式收費。如果您的付款失敗，我們將向您發出書面通知，並可能暫停您對服務的訪問，直到收到付款。除非本協議另有規定，否則付款不可退還。',
    block11:
      '(b) 稅收。除非另有說明，費用不包括任何聯邦、州、地方和外國稅費、關稅和類似評估（「稅費」）。您有責任繳納與您的購買相關的所有稅費，不包括我們將向您開立發票的基於我們淨收入的稅費。您同意及時繳納此類稅款並向我們提供具有法律效力的隱私權聲明並獲得處理此類資料所需的同意，並且您向我們保證您按照適用法律處理此類資料。',
    block12:
      '(c) 價格變動。我們可能會透過您的帳戶和/或我們的網站通知您來更改我們的價格。價格上漲將在發布後立即生效。任何價格變更將在變更生效日期後適用於您帳戶的費用。',
    block13:
      '(d) 爭議和逾期付款。如果您對任何費用或稅金有異議，請在發票日期後三十 (30) 天內聯絡 contact@noam.tools。',
    block14:
      '(e) 免費使用。您不得建立多個帳戶來獲得免費積分。如果我們確定您沒有善意地使用免費積分，我們可能會向您收取標準費用或停止提供對服務的存取權。',
    subTitle5: '保密、安全和資料保護',
    block15:
      '(a) 保密。您可能有權存取諾姆的機密資訊。您只能在本條款允許的範圍內使用機密資訊來使用本服務。您不得向任何第三方披露機密信息，並且您保護機密信息的方式不得低於保護您自己的類似機密信息的程度，至少要採取合理的謹慎態度。機密資訊是指 Noam 指定為機密的非公開信息，或在具體情況下應合理視為機密的非公開信息，包括軟體、規格和其他非公開商業資訊。機密資訊不包括以下資訊：(1) 在您沒有過失的情況下公開為公眾所知的資訊； (2) 您在根據本條款收到該資訊之前沒有任何保密義務； (3) 由第三方合法向您揭露且不承擔保密義務的； (4) 由您自行開發，未使用保密資訊。如果法律、法院或其他政府命令要求，您可以披露機密信息，但您必須以合理的方式向 Noam 提供事先書面通知，並儘可能做出合理的努力來限制披露範圍，包括協助我們反對披露請求。',
    block16:
      '(b) 安全。您必須採取合理且適當的措施來幫助保護您對服務的存取和使用。如果您發現與使用服務相關的任何漏洞或違規行為，您必須立即聯絡 Noam 並提供漏洞或違規行為的詳細資訊。',
    block17:
      '((c) 個人資料的處理。如果您使用本服務處理個人數據，您必須提供合法充分的隱私權聲明並獲得處理此類資料所需的同意，並且您向我們保證您按照適用法律處理此類數據。',
    subTitle6: '期限和終止',
    block18:
      '(a) 終止；暫停。這些條款將從您首次使用本服務時生效，並將一直有效直至終止。您可以隨時以任何理由透過停止使用服務和內容來終止這些條款。我們可以出於任何原因在事先通知的情況下終止這些條款。如果您嚴重違反第 2 條（使用要求）、第 5 條（保密、安全和資料保護）、第 8 條（爭議解決）或第 9 條（一般條款），或如果我們的與我們無法控制的任何第三方技術提供者的關係發生變化，或為了遵守法律或政府要求。如果您未能遵守這些條款，或您的使用對我們或任何第三方構成安全風險，或者我們懷疑您的使用具有欺詐性或可能使我們或任何第三方承擔責任，我們可能會暫停您對服務的訪問。',
    block19:
      '(b) 終止的影響。終止後，您將停止使用服務並按照我們的指示立即歸還或銷毀任何機密資訊。本條款中依其性質應在終止或期滿後繼續有效的條款，包括但不限於第 3 條和第 5-9 條，應繼續有效。',
    subTitle7: '保障;免責聲明；責任限制',
    block20:
      '(a) 賠償。對於因您使用本服務（包括您的內容、您的資訊）而產生或與之相關的任何索賠、損失和費用（包括律師費），您將為我們、我們的關聯公司和我們的人員進行辯護、賠償，並使我們免受損害。',
    block21:
      '(b) 免責聲明。服務以「現況」提供。在法律允許的範圍內，我們以及我們的關聯公司和授權人不對服務做出任何形式的陳述或保證，也不作任何保證，包括但不限於適銷性、特定用途的適用性、令人滿意的品質、不侵權的保證、安靜的享受，以及交易或貿易使用過程中產生的任何保證。我們不保證服務不會中斷、準確或無錯誤，也不保證任何內容安全、不遺失或不更改。',
    block22:
      '(c) 責任限制。我們、我們的關聯公司或我們的授權人均不對任何間接、偶然、特殊、後果性或懲罰性損害負責，包括利潤損失、聲譽損失、使用損失、資料遺失或其他無形損失，即使我們已被告知發生此類損害的可能性。根據本條款，我們的總責任不會超過您在提出索賠之前十二 (12) 個月內向服務支付的費用或一百美元 ($100)，以較高者為準。本節中的限制在適用法律允許的最大範圍內適用。',
    subTitle8: '治理法律和管轄區',
    block23:
      '除美國法律衝突規則外，本條款以及您對服務的使用均受美國法律管轄。您對應用程式的使用也可能受到其他地方、州、國家或國際法律的約束。與這些條款相關的任何法律訴訟或程序將僅在美國法院提起，並且您同意接受此類法院的屬人管轄權。',
    subTitle9: '一般條款',
    block24:
      '(a) 雙方的關係。諾姆和您是獨立承包商，未經另一方事先書面同意，任何一方均無權代表另一方或約束另一方或為另一方承擔義務。',
    block25:
      '(b) 品牌使用。未經我們事先書面同意，您不得使用 Noam 或其任何附屬公司的名稱、標誌或商標。',
    subTitle10: '可分割性',
    block26: '如果這些條款的任何規定被確定為無效、非法或不可執行，其餘規定將仍然完全有效。',
  },
  contact: {
    title: '聯絡我們',
    block1: '如需定製或有任何問題或建議，請透過 contact@noam.tools 聯絡我們，我們將立即回覆。',
    block2: '請在 Google Apps Extension 商店中為我們評分 ★★★★★ 來支持我們。',
    block3: '透過分享此擴充功能來幫助其他人：',
  },
  pdf: {
    toast1: 'PDF正在加載，請稍後重試！',
    toast2: 'PDF內容過多，暫時不支援對話框',
    toast3: '正在下載，請下載完成後重試。',
    shareMsg1: '分享連結生成成功',
    shareMsg2: '分享內容更新成功',
    shareMsg3: '分享連結生成失敗，請稍後重試或聯絡我們的客服。',
    shareMsg4: '分享內容更新失敗，請稍後再試或聯絡客服',
    shareBtnText1: '產生分享連結',
    shareBtnText2: '更新分享內容',
    uploading: '上傳中',
    uploadingMsg: '請耐心等待，上傳需要一些時間。',
    cancelUpload: '取消',
    uploadErr: '文件上傳失敗。請稍後重試或聯絡客服',
    uploadMsg1: '請將文件拖放到此處',
    uploadMsg2: '支援的文件類型：PDF |  最大檔案大小：100MB',
    uploadMsg3: '點擊選擇或拖放檔案上傳至此處',
    searchPlaceholder: '搜尋',
    collectionEmpty: '收藏中暫無內容~',
    kw: '主食',
    video: '影片',
    link: '關聯',
    dataLoading: '載入資料中...',
    freeBannerTips: '免費 PDF 翻譯結束',
    vipBannerTips: '您已達到本月 pdf 翻譯的限制。',
    freeBannerBtn: '升級享受更多',
    vipBannerBtn: '升級享受更多',
    dialogTitle: '注意力',
    dialogContent: '你確定要刪除嗎？',
    okBtn: '刪除',
    cancelBtn: '取消',
    unLoginToast: '請登入使用此功能',
    parseErrTips: '無法解析文件',
    new: '新文件',
    parseErrDialogContent: '目前PDF連結不支援直接翻譯，請翻譯後下載原連結PDF',
    okBtn2: '認出',
    notPdf: '僅 PDF 文件',
    uploadingMsg1: '正在進行文件上傳。請稍後再試。',
    uploadingMsg2: '您目前正在轉換一個文件，請稍後再試。',
    uploadingMsg3: '轉換進度查詢失敗，請稍後再試或聯絡客服',
    uploadingMsg4: 'Noam 無法直接存取您的本機文件，請手動上傳 PDF 進行翻譯。',
    uploadingMsg5: '取得文件url失敗，請稍後重試或聯絡客服。',
    fileMsg: '目前檔案大小：',
    fileMsg2: '請上傳小於100M的文件',
    fileErrMsg: '文件讀取失敗，請稍後重試或聯絡客服。',
    parseFileToast: '檔案解析失敗，請稍後重試或聯絡客服。',
    noId: '文件ID不存在',
    autoDetect: '自動偵測',
    hasTransTask: '有一個任務正在翻譯。請稍等。',
    transSucc: '翻譯成功了！',
    entry: 'PDF列表',
    listError: 'PDF清單載入失敗，請稍後重試或聯絡客服。',
    collectionTab: '備忘錄',
    pdfLoading: '正在解析...',
    installTips: '安裝小插件，您可以翻譯更多PDF文件，還可以體驗流暢的網頁翻譯：',
    webTrans: '完整的交叉引用',
    hoverTrans: '懸停段落翻譯',
    selectTrans: '在單字下方劃線並翻譯',
    installText: '一站式滿足您所有日常翻譯需求',
    start: '開始您的免費體驗',
    shareTips: 'PDF翻譯完成，快來分享給你的朋友吧！',
    download: {
      loading: '已儲存...',
      btn: '現在保存',
      trans: '僅翻譯',
      both: '雙語',
      download: '下載',
      msg1: '目前文件部分譯本比原文長，下載後可能出現文字重疊的情況，建議在網頁上閱讀。',
      msg2: '1、內容尚未完全翻譯，請完全翻譯後下載。',
      msg3: '2.文件部分翻譯過長，可以在網頁中閱讀，避免文字重疊。',
      msg4: '目前有任務正在翻譯，請翻譯完成後重試。',
      fileName: '檔案名稱：',
      fileType: '下載檔案類型：',
      transContinue: '繼續翻譯',
      save: '儲存到本地',
      downloadMsg1: 'PDF 翻譯進度：',
      downloadMsg2: '預計需求',
      minute: '分鐘...',
      downloadSucc: 'PDF下載完成，點選儲存！',
    },
    guide: {
      title1: '雙語翻譯',
      title2: '聊天PDF',
      text1: '雙語閱讀',
      text2: '雙語閱讀，閱讀時可以比較原文和譯文，提升閱讀效率',
      text3: '聊天PDF',
      text4: 'AI分析總結，根據PDF內容解答您的疑問',
      text5: '準確性和效率',
      text6: 'AI 支援的 PDF 實現更準確、更有效率的翻譯',
      placeholder: '請填寫PDF連結',
      start: '開始翻譯',
      toast: '請輸入以 .pdf 結尾的有效在線 pdf 鏈接',
    },
    original: '原來的',
    parallel: '平行線',
    trans: '翻譯',
    initial: '翻譯 PDF',
    thumbnail: '目錄',
    downloadBtn: '下載',
    shareBtn: '分享',
    uploadBtn: '上傳',
    installText2: '請先安裝',
    chatPdf: {
      clearSucc: '通關成功',
      clear: '清除',
      clearContent: '清除後，將創建一個新的對話，並且該對話的內容不會被保存，您確定要清除嗎？',
      clearDialogTitle: '注意力',
      cancel: '取消',
      chatTitle: '歡迎來到 Noam，您的 PDF 摘要已準備就緒：',
      summaryTitle: '您可能感興趣的問題：',
      placeholder: '輸入有關 PDF 的任何問題',
      copy: '複製',
      copySucc: '複製成功',
      historyTitle: '歷史',
      expand: '坍塌',
      noDataDesc: '沒有歷史~',
      noMore: '不再。',
      Search: '搜尋',
      delete: '刪除',
      loading: '載入中...',
      deleteTitle: '提示',
      deleteContent: '刪除後，此 PDF 不可恢復，是否要刪除它？',
      deleteOk: '刪除',
      deleteCancel: '取消',
      deleteSuccess: '刪除成功',
      listError: '閱讀列表載入失敗。請稍後再試或聯絡客戶支持',
    },
    shareModal: {
      shareSetting: '共享內容設定',
      shareText: '分享',
      text1: '共享設定',
      text2: '產生分享連結',
      range: '範圍：',
      custom: '客製化：',
      page: '頁面',
      sourceLang: '來源：',
      target: '目標：',
      errMsg: '目前分享範圍的內容尚未完全翻譯，請在翻譯後分享。',
      pageNuErr: '請輸入正確的頁碼值',
      trans: '翻譯',
      cancel: '取消',
      update: '更新了分享鏈接',
      gen: '分享',
      shareTips: '與您的朋友分享翻譯後的 PDF 連結以供閱讀：',
      copySucc: '複製連結成功',
      copy: '複製',
      settings: '共享設定：',
      modify: '調整',
      translateTips: '提示：如果翻譯時間較長，您可以關閉彈跳窗，等翻譯完成後再分享。',
      translating: 'PDF翻譯...',
      remain: '其餘的',
      remain1: '預計需求總計',
      totalPage: '共 1 頁',
      search: '搜尋',
      notSupport: '目前不支援該語言',
    },
    toolbar: { Adjust: '調整', autoFit: '自動適配' },
    memoTotalCount: '共 1 項內容',
    dragTipModal: {
      press: '按',
      black: '空間',
      leftKey: '左邊按鈕',
      tips: '拖曳並查看。',
      ok: '我得到了它',
    },
  },
  locale: {
    zh: '中國人',
    en: '英語',
    es: '西班牙語',
    ru: '俄文',
    pt: '葡萄牙語（巴西）',
    id: '印尼',
    cs: '捷克語',
    de: '德文',
    fr: '法語',
    it: '義大利語',
    nl: '荷蘭語',
    pl: '拋光',
    vi: '越南語',
    tr: '土耳其',
    uk: '烏克蘭',
    zhHant: '中國傳統的',
    ja: '日本人',
    ko: '韓國人',
    ar: '阿拉伯',
    fa: '波斯語',
    th: '泰國',
    hi: '印地語',
    noSearchLang: '不支援該語言',
    placeholder: '搜尋',
    af: '南非荷蘭語',
    am: '阿姆哈拉語',
    as: '阿薩姆語',
    az: '亞塞拜然語',
    ba: '巴什基爾語',
    bg: '保加利亞語',
    bn: '孟加拉語',
    bo: '藏',
    bs: '波士尼亞語',
    ca: '加泰隆尼亞語',
    cy: '威爾斯語',
    da: '丹麥語',
    dsb: '下索布語',
    dv: '迪維希語',
    el: '希臘文',
    et: '愛沙尼亞語',
    eu: '巴斯克',
    fi: '芬蘭',
    fil: '菲律賓人',
    fj: '斐濟語',
    frCA: '法語（加拿大）',
    ga: '愛爾蘭語',
    gl: '加利西亞語',
    gom: '孔卡尼',
    gu: '古吉拉特語',
    ha: '豪薩語',
    he: '希伯來文',
    hr: '克羅埃西亞語',
    hsb: '上索布語',
    ht: '海地克里奧爾語',
    hu: '匈牙利',
    hy: '亞美尼亞語',
    ig: '伊博語',
    ikt: '伊努因納克頓',
    is: '冰島的',
    iu: '因紐特人',
    iuLatn: '因紐特語（拉丁語）',
    ka: '喬治亞語',
    kk: '哈薩克語',
    km: '高棉語',
    kmr: '庫德語（北部）',
    kn: '卡納達語',
    ku: '庫德語（中）',
    ky: '吉爾吉斯斯坦',
    ln: '林加拉語',
    lo: '寮國',
    lt: '立陶宛語',
    lug: '幹達',
    lv: '拉脫維亞語',
    lzh: '中文（文學）',
    mai: '邁蒂利',
    mg: '馬爾加什',
    mi: '毛利人',
    mk: '馬其頓語',
    ml: '馬拉雅拉姆語',
    mnCyrl: '蒙古語（西里爾字母）',
    mnMong: '蒙古語（繁體）',
    mr: '馬拉地語',
    ms: '馬來語',
    mt: '馬爾他語',
    mww: '苗族',
    my: '緬甸（緬語）',
    nb: '挪威',
    ne: '尼泊爾語',
    nso: '塞索托薩萊博阿',
    nya: '尼亞尼亞',
    or: '奧迪亞',
    otq: '克雷塔羅奧托米',
    pa: '旁遮普語',
    prs: '達裡語',
    ps: '普什圖語',
    ptPT: '葡萄牙語（葡萄牙）',
    ro: '羅馬尼亞語',
    run: '倫迪',
    rw: '基尼亞盧安達語',
    sd: '信德語',
    si: '僧伽羅語',
    sk: '斯洛伐克語',
    sl: '斯洛維尼亞語',
    sm: '薩摩亞人',
    sn: '紹納語',
    so: '索馬利亞',
    sq: '阿爾巴尼亞語',
    srCyrl: '塞爾維亞語（西里爾字母）',
    srLatn: '塞爾維亞語（拉丁語）',
    st: '塞索托語',
    sv: '瑞典',
    sw: '斯瓦希里語',
    ta: '泰米爾語',
    te: '泰盧固語',
    ti: '提格里尼亞',
    tk: '土庫曼語',
    tlhLatn: '克林貢語（拉丁文）',
    tlhPiqd: '克林貢語 (pIqaD)',
    tn: '茨瓦納語',
    to: '東加',
    tt: '韃靼人',
    ty: '大溪地',
    ug: '維吾爾族',
    ur: '烏爾都語',
    uz: '烏茲別克語（拉丁語）',
    xh: '科薩語',
    yo: '約魯巴語',
    yua: '尤卡坦瑪雅',
    yue: '粵語（繁體）',
    zhHans: '簡體中文',
    zu: '祖魯語',
    auto: '自動偵測',
  },
  stripe: {
    contact: '如有任何疑問，請透過 contact@noam.tools 與我們聯繫！',
    fail: {
      title: '支付失敗',
      text1: '不幸的是，我們無法處理您的付款請求。',
      text2: '請檢查您的網路連接，然後重試。',
      btn: '重試',
    },
    succ: {
      title: '恭喜你',
      text1: '歡迎來到諾姆，',
      text2: '讓我們一起開始吧！',
      btn: '讓我們施展一些魔法',
    },
  },
  welfare: {
    newUser: {
      label: '新用戶福利：',
      joinBtn: '立即加入',
      text: '僅剩 100 個名額！立即購買！',
      remain: '僅剩 30 名',
    },
  },
  help: {
    Fixedplugin: '固定插件',
    Howtouse: '如何使用',
    try: '試一試',
    FreeTrialBenefits: '免費試用福利',
    pin: 'Pin Noam，方便訪問～～',
    Video: '影片',
    ParallelTranslation: '平行翻譯',
    WebSummary: '網路摘要',
    ChatPDF: '聊天PDF',
    AquickOverviewOfNoam: '諾姆的快速概述',
    WordSelectionTranslation: '選詞翻譯',
    HoverTranslation: '懸停翻譯',
    ImageTranslation: '圖片翻譯',
    InstantTranslation: '即時翻譯',
    VideoTranslation: '影片翻譯',
    benefits: '我們為您準備了福利！',
    Free: '自由的',
    Membership: '會員資格',
    Translation: '翻譯',
    PDFparallelTranslation: 'PDF 平行翻譯',
    ChatPDF2: '聊天PDF',
    Summary: '概括',
    VideoSummary: '影片摘要',
    FavoritesManagement: '收藏管理',
    Focusing: '聚焦',
    dayFree: '3 天免費試用',
    planIsNotExist: '計劃不存在',
    TryItForFree: '免費試用',
    startYoutube: '先從YouTube開始吧~',
    startWiki: '我們先從維基百科開始吧~',
    tryNow: '趕快來嘗試吧~',
    GoWithYouTube: '與 YouTube 一起使用',
    GoWithWikipedia: '使用維基百科',
    NoamHelp: 'Noam可以幫你翻譯和總結',
    SkipAllSteps: '跳過所有步驟',
    next: '下一個',
    done: '完畢',
  },
  trialModal: {
    title: '歡迎禮物',
    trialText: '7 天試用',
    desc1: '試用結束後，您將被收取180美元的年費',
    desc2: '隨時取消',
    btn: '立即開始（僅需$0.99）',
    installBtn: '立即安裝即可領取您的禮物',
    web: '平行翻譯',
    select: '選詞翻譯',
    hover: '懸停翻譯',
    image: '圖片翻譯',
    realtime: '即時翻譯',
    video: '影片翻譯',
    pdf: 'PDF 平行翻譯',
    chat: '聊天PDF',
    webSummary: '網路摘要',
    videoSummary: '影片摘要',
    memo: '收藏管理',
    focus: '聚焦',
  },
  privacyCollectionPanel: {
    title: '本網站使用cookies',
    text: '為了改善您的體驗，我們希望使用 cookie 來個人化內容和服務。',
    acceptBtn: '全都接受',
  },
  installTips: {
    text: '安裝“Noam瀏覽器插件”以解鎖更多功能！網路交叉引用，\n    網路影片摘要等等！',
    btn: '現在就試試',
  },
}

export default TRANSLATION
