const TRANSLATION = {
  slogen: 'El asistente de IA más sencillo',
  pageHeader: {
    help: 'Centro de ayuda',
    contact: 'Contáctenos',
    pricing: 'Precios',
    signUp: 'Acceso',
    settings: 'Ajustes',
    logout: 'Cerrar sesión',
    logoutSucc: 'Cerrar sesión exitosamente',
  },
  pageFooter: {
    product: {
      title: 'Productos',
      web: 'Traducción web y resumen',
      pdf: 'Traducción de PDF',
      video: 'Traducción y resumen de vídeo',
      image: 'Traducción de imágenes',
    },
    resources: { title: 'Recursos' },
    follow: { title: 'Seguir', twitter: 'Gorjeo', facebook: 'Facebook' },
    legal: { title: 'Legal', policy: 'política de privacidad', terms: 'Términos de servicio' },
  },
  home: {
    getStart: 'Empezar',
    getStartedForFree: 'Comience gratis',
    top: {
      desc: 'Noam es una extensión de navegador de IA gratuita que le ayuda a traducir páginas web resumidas, vídeos y contenido PDF.\n      ¡Únete a Noam y disfruta de la magia!',
      title2: 'Una magia',
      title1: 'Un click',
    },
    howItWork: {
      title: 'Entonces, ¿cómo funciona?',
      desc: 'Noam integra modelos de IA para brindar a los usuarios una experiencia inteligente para traducción y resúmenes de páginas web en varios idiomas, traducción de PDF e imágenes, traducción y resúmenes de videos.',
    },
    PDFTranslate: {
      title: 'Traducir PDF',
      desc: 'Traducción Bilingüe + Chat PDF',
      sub1: {
        title: 'Lectura bilingüe',
        desc: 'Lectura bilingüe, para que pueda comparar el texto original y la traducción mientras lee para mejorar la eficiencia de la lectura.',
      },
      sub2: {
        title: 'Chat PDF',
        desc: 'La IA analiza y resume, y puede responder a sus preguntas basándose en el contenido del PDF.',
      },
      sub3: {
        title: 'Precisión y eficiencia',
        desc: 'PDF con tecnología de IA para traducciones más precisas y eficientes',
      },
    },
    aiAssistant: {
      title: 'Asistente de IA',
      desc: 'Cómo hacer que la traducción web sea más inteligente y cómoda',
      panel1: {
        title: 'Traducción bilingüe',
        desc: 'Las traducciones bilingües de referencias cruzadas lo ayudan a comparar el texto original con la traducción para que pueda comprender mejor y mejorar su eficiencia de lectura.',
      },
      panel2: {
        left: {
          title: 'Traductor de hover',
          desc: 'Cuando navegue por una página web, coloque el mouse directamente sobre el contenido que debe traducirse para obtener el resultado de la traducción.',
        },
        right: {
          title: 'Traducción de selección de texto',
          desc: 'Al navegar por una página web, seleccione directamente el texto que desea subrayar para obtener el resultado de la traducción.',
        },
      },
      panel3: {
        one: {
          title: 'Apuntar y hacer clic',
          desc: 'Traduzca y resuma páginas web y contenido de vídeo en cualquier momento, en cualquier lugar y en cualquier escenario; ¡brindándote una experiencia más fluida!',
        },
        two: {
          title: 'Más completo y conveniente',
          desc: 'Cobertura total en varios idiomas, lectura bilingüe sin barreras, traducción con una sola tecla; ¡Traerte una experiencia incomparable!',
        },
        three: {
          title: 'Impulsado por IA',
          desc: 'Gran modelo impulsado por IA para garantizar la precisión y coherencia de la traducción y mejorar la experiencia de lectura',
        },
      },
      panel4: {
        tab1: {
          key: 'Video',
          title: 'Traducción y resumen de vídeo',
          desc: '¡Su experto en eficiencia, combinado con chatgpt, traduce y resume el contenido de video de YouTube para ahorrarle tiempo de lectura!',
        },
        tab2: {
          key: 'Imagen',
          title: 'Traducir imagen',
          desc: 'Haga clic para traducir imágenes mientras navega por la web',
        },
        tab3: {
          key: 'Web',
          title: 'Traducción y resumen web',
          desc: 'Extraer rápidamente información clave de páginas web para resumirla, mejorando su eficiencia de lectura',
        },
        tag1: 'Simple',
        tag2: 'Eficiencia',
        tag3: 'Facilmente entendido',
      },
    },
    userReviews: {
      title: 'Opiniones de los usuarios',
      desc: 'Revisión de cinco estrellas en chromewebstore',
    },
    lastBlock: { desc: 'Mejor traducción con IA y experiencia de lectura más fluida' },
  },
  login: {
    title: 'Bienvenido a Noam',
    btn: 'Iniciar sesión con Google',
    tips: 'Al registrarme, acepto',
    terms: 'Condiciones de uso',
    and: 'y',
    policy: 'política de privacidad',
    btn1: 'Inicia sesión ahora',
    desc: 'Inicia sesión para desbloquear más experiencias',
  },
  pricing: {
    pageTitle: 'Precios',
    desc: 'Compara y elige lo mejor para ti',
    yearly: 'Anualmente',
    monthly: 'Mensual',
    discount: '20% de descuento',
    preMonth: 'Por mes',
    billedYearly: 'Facturado anualmente',
    billedMonthly: 'Facturado mensualmente',
    detailedCompare: 'Comparación detallada',
    FAQ: 'Preguntas más frecuentes',
    day: 'día',
    month: 'mes',
    around: 'Alrededor',
    free: {
      benefit1: 'Traducción instantánea 100 veces/día',
      benefit2: 'Traducción web y resumen',
      benefit3: '30 hojas Traducción de imágenes',
      benefit4: 'Traducción de vídeo y resumen',
      benefit5: '10 Traducción de PDF',
      benefit6: '5000 fichas',
      recommendText: 'Prueba gratis',
      btn: 'Divertirse',
    },
    pro: {
      benefit1: 'Servicio al cliente Soporte rápido',
      benefit2: '1.000.000 tokens/mes',
      benefit3: 'Traducción web y resumen',
      benefit4: '200 hojas Traducción de imágenes',
      benefit5: 'Traducción de vídeo y resumen',
      benefit6: 'Traducción de 200 PDF',
      btn: 'Empezar',
      recommendText: 'La mayoría de la gente elige',
    },
    proPlus: {
      benefit1: 'Servicio al cliente Soporte rápido',
      benefit2: '3.000.000 tokens/mes',
      benefit3: 'Traducción web y resumen',
      benefit4: '500 hojas Traducción de imágenes',
      benefit5: 'Traducción de vídeo y resumen',
      benefit6: 'Traducción de 500 PDF',
      recommendText: 'La elección más adecuada',
    },
    enterprise: {
      benefit1: 'Servicio al cliente Soporte rápido',
      benefit2: 'Multi usuario',
      benefit3: 'Soluciones personalizadas',
      benefit5: 'Gerente de cuenta exclusiva',
      recommendText: 'Planes personalizados (≥3人)',
      talkToUs: 'Háblanos',
      btn: 'Contacto',
    },
    web: {
      contrastiveTranslation: 'Traducción bilingüe',
      instantTranslation: 'Traduccion instantanea',
      hoverTranslation: 'Traductor de hover',
      dictionaryTranslation: 'Traducción de selección de texto',
      summary: 'resumen de la pagina web',
    },
    imageTranslation: 'Traducción de imágenes',
    video: { translation: 'Traducción de vídeos', summary: 'Vídeo resumen' },
    pdf: {
      translation: 'traducción de PDF',
      maxPage: 'Número máximo de páginas en PDF',
      chat: 'Número de consultas en PDF',
    },
    FQAObj: {
      q1: '¿Puedo usarlo gratis?',
      a1: 'Existe una versión gratuita de Noam que se puede utilizar directamente.',
      q2: '¿Existen diferentes planes de membresía y opciones de tarifas?',
      a2: 'Además de la versión gratuita, existen tres planes de membresía, Pro, Pro+ y Enterprise; Puedes elegir entre dos métodos de pago, mensual continuo y anual continuo, y el costo integral del anual continuo es más favorable.',
      q3: '¿Cuál es la diferencia entre una suscripción mensual continua y una suscripción anual continua?',
      a3: 'No hay diferencia en la funcionalidad. El costo integral de la suscripción anual continua es menor que el de la suscripción mensual continua; la suscripción mensual se factura una vez al mes, mientras que la suscripción anual se factura una vez al año.',
      q4: '¿Cómo cancelo mi plan de renovación automática?',
      a4: 'Puede ir a la página de configuración del backend y elegir suscribirse para cancelar el plan de renovación.',
    },
  },
  upgradeModal: {
    title: 'Recordatorios',
    limitAlertStr:
      '¡La cuota de tu plan gratuito se ha agotado, te recomendamos actualizar tu plan para continuar con la experiencia~!',
    upgradeAlertStr:
      '¡La cuota de tu plan gratuito se ha agotado, te recomendamos actualizar tu plan para continuar con la experiencia~!',
    btn: 'Actualizar ahora',
    more: 'Más planes',
    contactStr: 'Espere el próximo reinicio o contáctenos en contact@noam.tools.',
    upload: {
      free: 'Has alcanzado tu límite de traducción de PDF.',
      pro: 'Has alcanzado tu límite de traducción de PDF en este mes.',
    },
    chat: {
      free: 'Has alcanzado tu límite de límite de Chatpdf excedido',
      pro: 'Has alcanzado tu límite de Chatpdf en este mes.',
    },
    pageLimit: { free: 'Has alcanzado tu límite de páginas PDF excedido' },
  },
  buyModal: {
    title: 'Plan de suscripción',
    freeTrial: 'Prueba gratuita de 3 días',
    selectLabel: 'Selecciona un plan',
    btn: 'Pruébalo gratis',
    yearLabel: 'año',
    monthLabel: 'mes',
    afterFree: 'después del juicio',
  },
  privacy: {
    title: 'política de privacidad',
    updateTime: 'Actualizado: 15 de marzo de 2024',
    block1:
      'Nosotros, Noam (denominados "Noam", "nosotros", "nuestro" o "nos"), respetamos su privacidad y nos comprometemos a proteger cualquier información que obtengamos de usted o sobre usted. Esta Política de Privacidad describe nuestras prácticas con respecto a la recopilación o el uso de información personal derivada de su uso de nuestro sitio web, aplicaciones y servicios (denominados colectivamente los "Servicios"). Esta Política de Privacidad no se aplica al contenido que manejamos en nombre de nuestros clientes de productos comerciales, como nuestra API. El uso de estos datos está sujeto a nuestro acuerdo con el cliente, que cubre el acceso y el uso de estos productos.',
    subTitle1: 'Información personal que recopilamos',
    block2:
      'Recopilamos la siguiente información personal ("Información personal") relacionada con usted:',
    block3:
      'Información proporcionada por usted: si crea una cuenta para utilizar nuestros servicios o se comunica con nosotros, recopilaremos información personal de la siguiente manera:',
    subBlock1:
      'Información de la cuenta: cuando crea una cuenta en nuestra plataforma, recopilamos información relacionada con su cuenta, incluido su nombre, información de contacto, credenciales de la cuenta, información de la tarjeta de pago e historial de transacciones (denominado "Información de la cuenta").',
    subBlock2:
      'Contenido del usuario: cuando utiliza nuestros servicios, recopilamos información personal de sus entradas, cargas de archivos o comentarios que nos proporciona (denominado "Contenido").',
    subBlock3:
      'Información de comunicación: si nos envía mensajes, recopilaremos su nombre, información de contacto y el contenido de los mensajes que envíe (denominado "Información de comunicación").',
    subBlock4:
      'Información de redes sociales: tenemos páginas en sitios web de redes sociales como Instagram, Facebook, Medium, Twitter, YouTube y LinkedIn. Cuando interactúa con nuestras páginas de redes sociales, recopilamos la información personal que usted elige proporcionarnos, como sus datos de contacto (denominados "Información social"). Además, las empresas que alojan nuestras páginas de redes sociales pueden proporcionarnos información y análisis agregados sobre nuestras actividades en las redes sociales.',
    block4:
      'Información personal recibida automáticamente a partir de su uso de los servicios: cuando accede, utiliza o interactúa con los servicios, recibimos la siguiente información sobre su acceso, uso o interacción ("Información técnica"):',
    subBlock5:
      'Datos de registro: Información enviada automáticamente por su navegador al utilizar nuestros servicios. Los datos de registro incluyen su dirección de Protocolo de Internet (IP), tipo y configuración del navegador, la fecha y hora de sus solicitudes y cómo interactúa con nuestro sitio web.',
    subBlock6:
      'Datos de uso: podemos recopilar automáticamente información sobre su uso del servicio, como los tipos de contenido que ve o con el que interactúa, las funciones que utiliza y las acciones que realiza, así como su zona horaria, país, fecha y hora de acceso, agente de usuario y versión, tipo de computadora o dispositivo móvil y su conexión de computadora.',
    subBlock7:
      'Información del dispositivo: incluye el nombre del dispositivo, el sistema operativo, el identificador del dispositivo y el navegador que está utilizando. La información recopilada puede depender del tipo de dispositivo que esté utilizando y su configuración.',
    subBlock8:
      'Cookies: utilizamos cookies para operar y administrar nuestros servicios y mejorar su experiencia. Una "cookie" es información enviada por un sitio web que usted visita a su navegador. Puede configurar su navegador para que acepte todas las cookies, las rechace todas o le notifique cuando se proporciona una cookie para que pueda decidir si la acepta cada vez. Sin embargo, rechazar las cookies puede impedirle utilizar o afectar negativamente la visualización o funcionalidad de ciertas áreas o características del sitio web. Para obtener más información sobre las cookies, visite Todo sobre las cookies.',
    subBlock9:
      'Análisis: podemos utilizar varios productos de análisis en línea que utilizan cookies para ayudarnos a analizar cómo los usuarios utilizan nuestros servicios y mejorar su experiencia al utilizar los servicios.',
    subTitle2: 'Cómo utilizamos la información personal',
    block5: 'Podemos utilizar información personal para los siguientes fines:',
    subBlock10: 'Proporcionar, gestionar, mantener y/o analizar servicios;',
    subBlock26: 'Mejorar nuestros servicios y realizar investigaciones;',
    subBlock11: 'Comunicándose con usted;',
    subBlock12: 'Desarrollar nuevos proyectos y servicios;',
    subBlock13:
      'Prevenir fraudes, actividades delictivas o uso indebido de nuestros servicios y proteger la seguridad de nuestros sistemas, arquitectura y redes de TI;',
    subBlock14: 'Realización de transferencias comerciales;',
    subBlock15:
      'Cumplir con obligaciones legales y procedimientos legales, protegiendo nuestros derechos, privacidad, seguridad o propiedad, así como los de nuestros afiliados, usted u otros terceros.',
    block6:
      'Resumen o información no identificada: podemos agregar o no identificar información personal para evitar su reutilización con fines de identificación y usar dicha información para analizar la efectividad de nuestros servicios, mejorar y agregar funciones a nuestros servicios, realizar investigaciones y para fines similares. . Además, podemos analizar el comportamiento general y las características de los usuarios de nuestros servicios y compartir información agregada con terceros, publicar dicha información agregada o ponerla a disposición de manera general. Podemos recopilar información agregada a través de los servicios, cookies y otros medios descritos en esta Política de Privacidad. Mantendremos y utilizaremos información no identificada de forma anónima o no identificada, y no intentaremos volver a identificar dicha información a menos que lo exija la ley.',
    subTitle3: 'Divulgación de información personal',
    block7:
      'En ciertos casos, podemos proporcionar su información personal a terceros sin previo aviso, a menos que lo exija la ley:',
    subBlock16:
      'Proveedores y proveedores de servicios: para ayudarnos a satisfacer las necesidades operativas comerciales y realizar ciertos servicios y funciones, podemos proporcionar información personal a proveedores y proveedores de servicios, incluidos proveedores de servicios de alojamiento, proveedores de servicios en la nube y otros proveedores de servicios de tecnología de la información, software de comunicación por correo electrónico. y proveedores de servicios de análisis web, etc. Estos socios accederán, procesarán o almacenarán información personal solo bajo nuestra dirección mientras desempeñan sus funciones para nosotros.',
    subBlock17:
      'Transferencias comerciales: si participamos en transacciones estratégicas, reorganizaciones, quiebras, adquisiciones por quiebra o transición de servicios a otro proveedor (denominadas colectivamente "Transacciones"), su información personal y otra información se puede compartir con contrapartes de transacciones y otras partes que ayudan en las Transacciones durante la debida diligencia y transferidas como parte de las Transacciones junto con otros activos a una empresa sucesora o afiliada.',
    subBlock18:
      'Requisitos legales: podemos compartir su información personal con autoridades gubernamentales, pares de la industria u otros terceros, incluida su información de interacción con nuestros servicios, (1) si lo exige la ley o si creemos que dicha acción es necesaria para cumplir con las obligaciones legales; (2) para proteger y defender nuestros derechos o propiedad; (3) si determinamos unilateralmente que existe una violación de nuestros términos, políticas o leyes; (4) para detectar o prevenir fraudes u otras actividades ilegales; (5) para proteger nuestros productos, empleados o usuarios, o la seguridad pública o la integridad; o (vi) para defenderse de responsabilidad legal.',
    subTitle4: 'Tus derechos',
    block8:
      'Dependiendo de su ubicación geográfica, las personas en el Espacio Económico Europeo, el Reino Unido y en todo el mundo pueden tener ciertos derechos legales con respecto a su información personal. Por ejemplo, es posible que tenga derecho a:',
    subBlock19: 'Acceder a su información personal e información sobre cómo se procesa.',
    subBlock20: 'Eliminar su información personal de nuestros registros.',
    subBlock21: 'Corregir o actualizar su información personal.',
    subBlock22: 'Transferir su información personal a un tercero (portabilidad de datos).',
    subBlock23: 'Limite la forma en que procesamos su información personal.',
    subBlock24:
      'Retire su consentimiento: cuando dependamos del consentimiento como base legal para el procesamiento, puede retirar su consentimiento en cualquier momento.',
    subBlock25: 'Oponerse a cómo procesamos su información personal.',
    subTitle5: 'Enlaces a otros sitios web',
    block9:
      'El servicio puede contener enlaces a otros sitios web que Noam no opera ni controla, incluidos los servicios de redes sociales ("Sitios web de terceros"). La información que comparta con sitios web de terceros se regirá por las políticas de privacidad y los términos de servicio específicos de los sitios web de terceros, no por esta Política de privacidad. Proporcionamos estos enlaces para su comodidad y no implican nuestro respaldo o revisión de estos sitios web. Comuníquese directamente con los sitios web de terceros para obtener información sobre sus políticas y términos de privacidad.',
    subTitle6: 'Seguridad y Retención de Información',
    block10:
      'Implementamos medidas técnicas, administrativas y organizativas comercialmente razonables para proteger la información personal en línea y fuera de línea contra pérdidas, uso indebido y acceso no autorizado, divulgación, alteración o actos hostiles. Sin embargo, ninguna transmisión por Internet o por correo electrónico puede garantizar una seguridad o precisión total. En particular, los correos electrónicos que nos envíen o nos envíen pueden no ser seguros. Por lo tanto, debe tener especial cuidado al decidir qué información enviarnos a través del servicio o del correo electrónico. Además, no somos responsables de ninguna obstrucción de la configuración de privacidad o las medidas de seguridad en el servicio o las configuraciones o medidas de seguridad de los sitios web de terceros. Conservaremos su información personal durante el tiempo que sea necesario para brindarle servicios o para otros fines comerciales legítimos, como resolver disputas, razones de seguridad o cumplir con nuestras obligaciones legales. El período de tiempo que conservemos la información personal dependerá de muchos factores, como la cantidad, la naturaleza, la sensibilidad de la información, los riesgos potenciales de uso o divulgación no autorizados, los fines para los cuales procesamos la información y otras consideraciones legalmente vinculantes.',
    subTitle7: 'Modificación de la Política de Privacidad',
    block11:
      'Podemos actualizar esta Política de Privacidad de vez en cuando. Cuando lo hagamos, publicaremos una versión actualizada en esta página, a menos que la ley aplicable exija lo contrario.',
    subTitle8: 'Cómo contactarnos',
    block12:
      'Si tiene alguna pregunta o inquietud sin respuesta sobre esta Política de Privacidad, comuníquese con nuestro servicio de atención al cliente. Correo electrónico:',
  },
  terms: {
    title: 'Términos de servicio',
    updateTime: 'Actualizado: 15 de marzo de 2024',
    block1: '¡Gracias por usar Noam!',
    block2:
      'Estos Términos de uso se aplican a su uso de los servicios de Noam, incluidas nuestras interfaces de programación de aplicaciones, software, herramientas, servicios de desarrollador, datos, documentación y sitio web (denominados colectivamente los "Servicios"). Al utilizar nuestros Servicios, usted acepta cumplir con estos términos. Nuestra Política de Privacidad explica cómo recopilamos y utilizamos información personal.',
    subTitle1: 'Registro y Acceso',
    block3:
      'Si eres menor de 18 años, debes obtener permiso de tus padres o tutores legales para utilizar el Servicio. Si utiliza el Servicio en nombre de otra persona o entidad, debe estar autorizado para aceptar estos términos en su nombre. Debe proporcionar información precisa y completa para registrarse para obtener una cuenta. No puede proporcionar sus credenciales de acceso ni su cuenta a nadie fuera de su organización, y es responsable de todas las actividades realizadas utilizando sus credenciales.',
    subTitle2: 'Requisitos de uso',
    block4:
      '(a) Uso del Servicio. Puede acceder al Servicio según estos términos y le otorgamos un derecho no exclusivo para utilizar el Servicio. Al utilizar el Servicio, cumplirá con estos términos y todas las leyes aplicables. Nosotros y nuestros afiliados poseemos todos los derechos, títulos e intereses en el Servicio.',
    block5:
      '(b) Retroalimentación. Agradecemos comentarios, ideas, sugerencias y mejoras. Si proporciona dicho contenido, podremos usarlo sin restricciones y sin compensación para usted.',
    block6:
      '(c) Restricciones. No puede: (1) utilizar el Servicio de una manera que infrinja, se apropie indebidamente o viole cualquier derecho de otros; (2) realizar ingeniería inversa, descompilar, desensamblar, traducir o intentar descubrir de otro modo el código fuente o los componentes subyacentes de los modelos, algoritmos y sistemas del Servicio (a menos que dichas restricciones estén prohibidas por la ley aplicable); (3) utilizar los resultados del Servicio para desarrollar modelos que compitan con Noam; (4) extraer datos o resultados del Servicio utilizando cualquier método automatizado o de programación a menos que lo permita la API, incluido el raspado, la recolección web o la extracción de datos web; (5) representar el resultado del Servicio como generado artificialmente cuando no lo es, o violar de otro modo nuestras políticas de uso; (6) comprar, vender o transferir claves API sin nuestro consentimiento previo; o (7) proporcionarnos cualquier información personal de niños menores de 13 años o la edad aplicable de consentimiento digital. Cumplirá con los límites de tarifas y otros requisitos de nuestra documentación.',
    block7:
      '(d) Servicios de terceros. Cualquier software, servicio u otro producto de terceros asociado con el Servicio se rige por sus propios términos y no somos responsables de los productos de terceros.',
    subTitle3: 'Contenido',
    block8:
      '(a) Su contenido. Usted puede proporcionar información ("Entrada") al Servicio y recibir resultados ("Salida") generados y devueltos en función de la Entrada (denominados colectivamente "Contenido"). Entre las partes, y en la medida permitida por la ley aplicable, usted es propietario de todos los Aportes. Noam puede usar Contenido para proporcionar y mantener el Servicio, cumplir con las leyes aplicables y hacer cumplir nuestras políticas. Usted es responsable del Contenido, incluida la garantía de que no viole ninguna ley aplicable o estos términos.',
    block9:
      '(b) Precisión. La inteligencia artificial y el aprendizaje automático son campos de investigación en rápida evolución. Nos esforzamos continuamente por mejorar nuestro servicio para hacerlo más preciso, confiable, seguro y beneficioso. Dada la naturaleza probabilística del aprendizaje automático, el uso de nuestro servicio puede generar resultados incorrectos en ciertos casos, que pueden no reflejar con precisión personas, lugares o hechos reales. Debe evaluar adecuadamente la precisión de cualquier resultado según su caso de uso, incluso revisando manualmente los resultados.',
    subTitle4: 'Tarifas y pago',
    block10:
      '(a) Tarifas y Facturación. Pagará todas las tarifas ("Tarifas") de acuerdo con los precios y los términos de la página de precios correspondiente o según lo acordado por escrito entre nosotros. Nos reservamos el derecho de corregir errores o equivocaciones en los precios incluso si hemos emitido facturas o recibido pagos. Proporcionará información de facturación completa y precisa, incluido un método de pago válido y autorizado. Cobraremos su método de pago periódicamente según lo acordado, pero podemos cambiar razonablemente la fecha de facturación. Usted autoriza a Noam, sus afiliados y procesadores de pagos externos a cargar su método de pago. Si su pago falla, le enviaremos un aviso por escrito y podremos suspender el acceso al Servicio hasta que se reciba el pago. Salvo que se especifique lo contrario en este acuerdo, los pagos no son reembolsables.',
    block11:
      '(b) Impuestos. A menos que se indique lo contrario, las Tarifas no incluyen impuestos, derechos ni evaluaciones similares federales, estatales, locales y extranjeros ("Impuestos"). Usted es responsable de todos los impuestos asociados con sus compras, excluidos los impuestos basados ​​en nuestros ingresos netos que le facturaremos. Usted acepta pagar puntualmente dichos Impuestos y proporcionarnos declaraciones de privacidad legalmente válidas y obtener los consentimientos necesarios para manejar dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable.',
    block12:
      '(c) Cambios de precios. Podemos cambiar nuestros precios notificándole a través de su cuenta y/o nuestro sitio web. Los aumentos de precios entrarán en vigor inmediatamente después de su publicación. Cualquier cambio de precio se aplicará a las tarifas de su cuenta después de la fecha de vigencia del cambio.',
    block13:
      '(d) Disputas y Pagos Atrasados. Si desea disputar alguna tarifa o impuesto, comuníquese con contact@noam.tools dentro de los treinta (30) días posteriores a la fecha de la factura.',
    block14:
      '(e) Uso gratuito. No puedes crear varias cuentas para obtener créditos gratis. Si determinamos que no está utilizando los créditos gratuitos de buena fe, podremos cobrarle tarifas estándar o dejar de brindarle acceso al Servicio.',
    subTitle5: 'Confidencialidad, Seguridad y Protección de Datos',
    block15:
      '(a) Confidencialidad. Es posible que tenga acceso a información confidencial de Noam. Solo puede utilizar información confidencial según lo permitido en estos términos con el fin de utilizar el Servicio. No puede revelar información confidencial a ningún tercero y protegerá la información confidencial de una manera no menos protectora que la que protege su propia información confidencial similar, al menos con un cuidado razonable. La información confidencial es información no pública designada como confidencial por Noam o que razonablemente debería tratarse como confidencial según las circunstancias, incluido el software, las especificaciones y otra información comercial no pública. La información confidencial no incluye información que: (1) esté generalmente disponible para el público sin culpa de su parte; (2) usted tenía en su poder sin ninguna obligación de confidencialidad antes de recibirlo bajo estos términos; (3) le sea revelada legalmente por un tercero sin ninguna obligación de confidencialidad; o (4) usted lo desarrolla de forma independiente sin utilizar información confidencial. Puede divulgar información confidencial si así lo exige la ley, un tribunal u otra orden gubernamental, pero debe notificar previamente por escrito a Noam de manera razonable y, en la medida de lo posible, hacer esfuerzos razonables para limitar el alcance de la divulgación, lo que incluye ayudarnos. en oponerse a las solicitudes de divulgación.',
    block16:
      '(b) Seguridad. Debe implementar medidas razonables y apropiadas para ayudar a salvaguardar su acceso y uso del Servicio. Si descubre alguna vulnerabilidad o violación relacionada con su uso del Servicio, debe comunicarse de inmediato con Noam y proporcionar detalles de la vulnerabilidad o violación.',
    block17:
      '((c) Procesamiento de datos personales. Si utiliza el Servicio para procesar datos personales, debe proporcionar declaraciones de privacidad legalmente suficientes y obtener los consentimientos necesarios para procesar dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable. .',
    subTitle6: 'Duración y Terminación',
    block18:
      '(a) Terminación; Suspensión. Estos términos entrarán en vigencia desde el primer uso que haga del Servicio y permanecerán vigentes hasta su terminación. Puede rescindir estos términos en cualquier momento y por cualquier motivo interrumpiendo el uso del Servicio y el Contenido. Podemos rescindir estos términos con previo aviso por cualquier motivo. Podemos rescindir inmediatamente estos términos notificándole si incumple sustancialmente la Sección 2 (Requisitos de uso), la Sección 5 (Confidencialidad, seguridad y protección de datos), la Sección 8 (Resolución de disputas) o la Sección 9 (Términos generales), o si nuestras relación con cualquier proveedor de tecnología externo fuera de nuestro control, cambios o para el cumplimiento de leyes o solicitudes gubernamentales. Podemos suspender su acceso al Servicio si no cumple con estos términos, o si su uso representa un riesgo de seguridad para nosotros o cualquier tercero, o si sospechamos que su uso es fraudulento o puede exponernos a nosotros o a cualquier tercero a responsabilidad. .',
    block19:
      '(b) Efectos de la Terminación. Tras la rescisión, dejará de utilizar el Servicio y devolverá o destruirá de inmediato cualquier información confidencial según nuestras instrucciones. Las disposiciones de estos términos que por su naturaleza deberían sobrevivir a la terminación o vencimiento, incluidas, entre otras, las Secciones 3 y 5-9, seguirán vigentes.',
    subTitle7: 'Indemnización; Descargos de responsabilidad; Limitación de responsabilidad',
    block20:
      '(a) Indemnización. Usted defenderá, indemnizará y nos eximirá a nosotros, a nuestros afiliados y a nuestro personal de cualquier reclamo, pérdida y gasto (incluidos los honorarios de abogados) que surjan de o estén relacionados con su uso del Servicio, incluido su Contenido, su uso de productos o servicios relacionados con el Servicio, y su violación de estos términos o la ley aplicable.',
    block21:
      '(b) Descargos de responsabilidad. El Servicio se proporciona "tal cual". En la medida permitida por la ley, nosotros y nuestros afiliados y licenciantes no hacemos representaciones ni garantías de ningún tipo con respecto al Servicio y no ofrecemos garantías, incluidas, entre otras, garantías de comerciabilidad, idoneidad para un propósito particular, calidad satisfactoria, no infracción. y disfrute tranquilo, y cualquier garantía que surja del curso del trato o uso del comercio. No garantizamos que el Servicio será ininterrumpido, preciso o libre de errores, o que cualquier Contenido será seguro, no se perderá ni se alterará.',
    block22:
      '(c) Limitación de responsabilidad. Ni nosotros, nuestras afiliadas ni nuestros licenciantes seremos responsables de ningún daño indirecto, incidental, especial, consecuente o punitivo, incluida la pérdida de ganancias, pérdida de reputación, pérdida de uso, pérdida de datos u otras pérdidas intangibles, incluso si Se nos ha informado de la posibilidad de que se produzcan tales daños. Según estos términos, nuestra responsabilidad total no excederá las Tarifas que pagó por el Servicio en los doce (12) meses anteriores al reclamo o cien dólares estadounidenses ($100), lo que sea mayor. Las limitaciones de esta sección se aplican en la medida máxima permitida por la ley aplicable.',
    subTitle8: 'Ley aplicable y jurisdicción',
    block23:
      'Los Términos y su uso de los Servicios se rigen por las leyes de los Estados Unidos, excepto por las normas de conflicto de leyes de los EE. UU. Su uso de la Aplicación también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales. Cualquier acción o procedimiento legal relacionado con estos Términos se llevará a cabo exclusivamente en los tribunales de los Estados Unidos, y usted acepta someterse a la jurisdicción personal de dichos tribunales.',
    subTitle9: 'Términos generales',
    block24:
      '(a) Relación de las Partes. Noam y usted son contratistas independientes y ninguna de las partes tiene el poder de representar o vincular a la otra parte o asumir obligaciones para la otra parte sin el consentimiento previo por escrito de la otra parte.',
    block25:
      '(b) Uso de la marca. No puede utilizar el nombre, los logotipos ni las marcas comerciales de Noam ni de ninguna de sus filiales sin nuestro consentimiento previo por escrito.',
    subTitle10: 'Divisibilidad',
    block26:
      'Si se determina que alguna disposición de estos términos es inválida, ilegal o inaplicable, las disposiciones restantes permanecerán en pleno vigor y efecto.',
  },
  contact: {
    title: 'Contáctenos',
    block1:
      'Para personalización o si tiene alguna pregunta o sugerencia, contáctenos en contact@noam.tools y le responderemos de inmediato.',
    block2: 'Apóyenos calificándonos en la tienda de extensiones de Google Apps en ★★★★★.',
    block3: 'Ayude a otros compartiendo esta extensión:',
  },
  pdf: {
    toast1: 'El PDF se está cargando, ¡inténtalo de nuevo más tarde!',
    toast2: 'El contenido del PDF es demasiado, no admite el diálogo por el momento',
    toast3: 'Descargando, inténtelo nuevamente después de que se complete la descarga.',
    shareMsg1: 'Compartir generación de enlaces exitosa',
    shareMsg2: 'Comparta actualizaciones de contenido con éxito',
    shareMsg3:
      'Error al generar el enlace para compartir. Vuelva a intentarlo más tarde o comuníquese con nuestro servicio de atención al cliente.',
    shareMsg4:
      'No se pudo compartir la actualización de contenido. Inténtelo de nuevo más tarde o comuníquese con el servicio de atención al cliente.',
    shareBtnText1: 'Generar enlaces para compartir',
    shareBtnText2: 'Contenido compartido actualizado',
    uploading: 'Subiendo',
    uploadingMsg: 'Tenga paciencia ya que la carga lleva algún tiempo.',
    cancelUpload: 'Cancelar',
    uploadErr:
      'Error al cargar el archivo. Inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente.',
    uploadMsg1: 'Por favor arrastre y suelte el archivo aquí.',
    uploadMsg2: 'Tipos de archivos admitidos: PDF |  Tamaño máximo de archivo: 100 MB',
    uploadMsg3: 'Haga clic para seleccionar o arrastre y suelte archivos para cargarlos aquí',
    searchPlaceholder: 'Buscar',
    collectionEmpty: 'No hay contenidos en la colección ~',
    kw: 'grapa',
    video: 'video',
    link: 'enlace',
    dataLoading: 'Cargando datos...',
    freeBannerTips: 'Fin de la traducción gratuita de PDF',
    vipBannerTips: 'Has alcanzado tu límite de traducción de PDF en este mes.',
    freeBannerBtn: 'Actualiza para disfrutar más',
    vipBannerBtn: 'Actualiza para disfrutar más',
    dialogTitle: 'Atención',
    dialogContent: '¿Estás seguro de que quieres eliminarlo?',
    okBtn: 'Borrar',
    cancelBtn: 'Cancelar',
    unLoginToast: 'Por favor inicia sesión para usar esta función',
    parseErrTips: 'No se puede analizar el archivo',
    new: 'archivo nuevo',
    parseErrDialogContent:
      'El enlace PDF actual no admite traducción directa; descargue el enlace PDF original después de la traducción.',
    okBtn2: 'reconocer',
    notPdf: 'Sólo archivos PDF',
    uploadingMsg1: 'Carga de archivos en progreso. Por favor, inténtelo de nuevo más tarde.',
    uploadingMsg2:
      'Actualmente tienes un archivo en proceso de conversión. Vuelve a intentarlo más tarde.',
    uploadingMsg3:
      'Error en la consulta del progreso de la conversión. Vuelva a intentarlo más tarde o comuníquese con el servicio de atención al cliente.',
    uploadingMsg4:
      'Noam no puede acceder a sus archivos locales directamente; cargue manualmente un PDF para traducirlo.',
    uploadingMsg5:
      'No se pudo obtener la URL del archivo. Vuelva a intentarlo más tarde o comuníquese con el servicio de atención al cliente.',
    fileMsg: 'Volumen de archivo actual:',
    fileMsg2: 'Sube un archivo de menos de 100M',
    fileErrMsg:
      'Error al leer el archivo, inténtelo de nuevo más tarde o comuníquese con el servicio de atención al cliente.',
    parseFileToast:
      'Error al analizar el archivo. Vuelva a intentarlo más tarde o comuníquese con nuestro servicio de atención al cliente.',
    noId: 'El ID del archivo no existe',
    autoDetect: 'Detección automática',
    hasTransTask: 'Hay una tarea en proceso de traducción. Espere por favor.',
    transSucc: '¡La traducción fue exitosa!',
    entry: 'Lista de PDF',
    listError:
      'La lista de PDF no se pudo cargar. Vuelva a intentarlo más tarde o comuníquese con el servicio de atención al cliente.',
    collectionTab: 'Memorándum',
    pdfLoading: 'Analizando...',
    installTips:
      'Instale el pequeño complemento, podrá traducir más archivos PDF y también podrá experimentar una traducción fluida de páginas web:',
    webTrans: 'referencia cruzada completa',
    hoverTrans: 'Traducción de párrafos al pasar el cursor',
    selectTrans: 'subraya las palabras y traduce',
    installText: 'Ventanilla única para todas sus necesidades de traducción diarias',
    start: 'Comienza tu experiencia gratuita',
    shareTips: 'La traducción del PDF está terminada, ¡adelante y compártela con tus amigos!',
    download: {
      loading: 'Salvado...',
      btn: 'Guardar ahora',
      trans: 'Sólo traducción',
      both: 'bilingüismo',
      download: 'Descargar',
      msg1: 'Algunas de las traducciones del documento actual son más largas que el texto original y el texto puede superponerse después de la descarga, por lo que se recomienda leerlo en la página web.',
      msg2: '1. El contenido no se ha traducido completamente. Descárguelo después de traducirlo por completo.',
      msg3: '2. La parte del documento traducida es demasiado larga, puede leerla en la página web para evitar la superposición de texto.',
      msg4: 'Actualmente hay tareas en proceso de traducción. Vuelva a intentarlo una vez completada la traducción.',
      fileName: 'Nombre del archivo:',
      fileType: 'Descargar tipo de archivo:',
      transContinue: 'Continuar traduciendo',
      save: 'Guardar en local',
      downloadMsg1: 'PDF Progreso en la traducción:',
      downloadMsg2: 'Requisitos proyectados',
      minute: 'Minutos...',
      downloadSucc: 'La descarga del PDF está completa, ¡haga clic en guardar ahora!',
    },
    guide: {
      title1: 'Traducción bilingüe',
      title2: 'Chat PDF',
      text1: 'Lectura bilingüe',
      text2:
        'Lectura bilingüe, para que pueda comparar el texto original y la traducción mientras lee para mejorar la eficiencia de la lectura.',
      text3: 'Chat PDF',
      text4:
        'La IA analiza y resume, y puede responder a sus preguntas basándose en el contenido del PDF.',
      text5: 'Precisión y eficiencia',
      text6: 'PDF con tecnología de IA para traducciones más precisas y eficientes',
      placeholder: 'Por favor complete el enlace PDF',
      start: 'Iniciar traducción',
      toast: 'Ingrese un enlace pdf en línea válido que termine en .pdf',
    },
    original: 'Original',
    parallel: 'Paralelo',
    trans: 'Traducción',
    initial: 'Traducir PDF',
    thumbnail: 'catálogos',
    downloadBtn: 'Descargar',
    shareBtn: 'Compartir',
    uploadBtn: 'Subir',
    installText2: 'Por favor instale primero',
    chatPdf: {
      clearSucc: 'Borrado exitosamente',
      clear: 'Claro',
      clearContent:
        'Después de borrarla, se creará una nueva conversación y el contenido de esta conversación no se guardará. ¿Está seguro de que desea borrarla?',
      clearDialogTitle: 'Atención',
      cancel: 'Cancelar',
      chatTitle: 'Bienvenido a Noam, tu resumen en PDF está listo:',
      summaryTitle: 'Preguntas que te pueden interesar:',
      placeholder: 'Ingrese cualquier pregunta sobre el PDF',
      copy: 'Copiar',
      copySucc: 'Copia exitosa',
      historyTitle: 'Historia',
      expand: 'Colapsar',
      noDataDesc: 'Sin historia ~',
      noMore: 'No más.',
      Search: 'Buscar',
      delete: 'Borrar',
      loading: 'cargando...',
      deleteTitle: 'Consejo',
      deleteContent: 'Después de eliminarlo, este PDF no se puede recuperar. ¿Quieres eliminarlo?',
      deleteOk: 'Borrar',
      deleteCancel: 'Cancelar',
      deleteSuccess: 'Eliminar exitosamente',
      listError:
        'La lista de lectura no se pudo cargar. Inténtalo de nuevo más tarde o ponte en contacto con el servicio de atención al cliente.',
    },
    shareModal: {
      shareSetting: 'Compartir configuración de contenido',
      shareText: 'Compartir',
      text1: 'Compartiendo ajustes',
      text2: 'Generar enlaces para compartir',
      range: 'Alcance:',
      custom: 'Personalización:',
      page: 'paginas',
      sourceLang: 'Fuente:',
      target: 'Objetivo:',
      errMsg:
        'El contenido del rango para compartir actual no se ha traducido completamente, así que tradúzcalo antes de compartirlo.',
      pageNuErr: 'Por favor ingrese el valor del número de página correcto',
      trans: 'Traducir',
      cancel: 'Cancelar',
      update: 'Enlaces para compartir actualizados',
      gen: 'Compartir',
      shareTips: 'Comparte el enlace del PDF traducido con tus amigos para leerlo:',
      copySucc: 'Copiar enlace correctamente',
      copy: 'Copiar',
      settings: 'Compartir configuraciones:',
      modify: 'modificar',
      translateTips:
        'Consejo: si la traducción lleva mucho tiempo, puedes cerrar la ventana emergente y compartirla una vez finalizada la traducción.',
      translating: 'Traducción de PDF...',
      remain: 'restante',
      remain1: 'Necesidades totales proyectadas',
      totalPage: '1 páginas en total',
      search: 'Buscar',
      notSupport: 'Este idioma no es compatible actualmente',
    },
    toolbar: { Adjust: 'Ajustar', autoFit: 'Ajuste automático' },
    memoTotalCount: 'Total de 1 contenido',
    dragTipModal: {
      press: 'Prensa',
      black: 'espacio',
      leftKey: 'botón izquierdo',
      tips: 'para arrastrar y ver.',
      ok: 'Lo tengo',
    },
  },
  locale: {
    zh: 'Chino',
    en: 'Inglés',
    es: 'Español',
    ru: 'ruso',
    pt: 'Portugués (Brasil)',
    id: 'indonesio',
    cs: 'checo',
    de: 'Alemán',
    fr: 'Francés',
    it: 'italiano',
    nl: 'Holandés',
    pl: 'Polaco',
    vi: 'vietnamita',
    tr: 'turco',
    uk: 'ucranio',
    zhHant: 'Chino tradicional',
    ja: 'japonés',
    ko: 'coreano',
    ar: 'Arábica',
    fa: 'persa',
    th: 'tailandés',
    hi: 'hindi',
    noSearchLang: 'Este idioma no es compatible',
    placeholder: 'Buscar',
    af: 'africaans',
    am: 'amárico',
    as: 'Assamese',
    az: 'azerbaiyano',
    ba: 'bashkir',
    bg: 'búlgaro',
    bn: 'bengalí',
    bo: 'tibetano',
    bs: 'bosnio',
    ca: 'catalán',
    cy: 'galés',
    da: 'danés',
    dsb: 'bajo sorabo',
    dv: 'Divehi',
    el: 'Griego',
    et: 'estonio',
    eu: 'vasco',
    fi: 'finlandés',
    fil: 'filipino',
    fj: 'fiyiano',
    frCA: 'Francés (Canadá)',
    ga: 'irlandesa',
    gl: 'gallego',
    gom: 'Konkani',
    gu: 'gujarati',
    ha: 'Hausa',
    he: 'hebreo',
    hr: 'croata',
    hsb: 'alto sorabo',
    ht: 'criollo haitiano',
    hu: 'húngaro',
    hy: 'armenio',
    ig: 'igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandés',
    iu: 'inuktitut',
    iuLatn: 'Inuktitut (latín)',
    ka: 'georgiano',
    kk: 'kazajo',
    km: 'jemer',
    kmr: 'Kurdo (norte)',
    kn: 'canarés',
    ku: 'Kurdo (Central)',
    ky: 'Kirguistán',
    ln: 'Lingala',
    lo: 'laosiano',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'letón',
    lzh: 'Chino (literario)',
    mai: 'Maithili',
    mg: 'madagascarí',
    mi: 'Maorí',
    mk: 'macedónio',
    ml: 'malayalam',
    mnCyrl: 'mongol (cirílico)',
    mnMong: 'Mongol (tradicional)',
    mr: 'marathi',
    ms: 'malayo',
    mt: 'maltés',
    mww: 'Hmong Daw',
    my: 'Myanmar (birmano)',
    nb: 'noruego',
    ne: 'nepalí',
    nso: 'Sesotho en Leboa',
    nya: 'nyanja',
    or: 'Odia',
    otq: 'Otomí de Querétaro',
    pa: 'punjabi',
    prs: 'Darí',
    ps: 'pastún',
    ptPT: 'Portugués (Portugal)',
    ro: 'rumano',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'sindhi',
    si: 'cingalés',
    sk: 'eslovaco',
    sl: 'esloveno',
    sm: 'samoano',
    sn: 'Shona',
    so: 'somalí',
    sq: 'albanés',
    srCyrl: 'Serbio (cirílico)',
    srLatn: 'serbio (latín)',
    st: 'sesoto',
    sv: 'sueco',
    sw: 'swahili',
    ta: 'Tamil',
    te: 'telugu',
    ti: 'Tigrinya',
    tk: 'turcomano',
    tlhLatn: 'Klingon (latín)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'setswana',
    to: 'tongano',
    tt: 'tártaro',
    ty: 'tahitiano',
    ug: 'uigur',
    ur: 'urdu',
    uz: 'Uzbeko (latín)',
    xh: 'xhosa',
    yo: 'Yoruba',
    yua: 'Maya yucateco',
    yue: 'Cantonés (tradicional)',
    zhHans: 'Chino simplificado',
    zu: 'zulú',
    auto: 'Detección automática',
  },
  stripe: {
    contact: '¡Contáctenos en contact@noam.tools si tiene alguna pregunta!',
    fail: {
      title: 'Pago fallido',
      text1: 'Lamentablemente, no podemos procesar su solicitud de pago.',
      text2: 'Por favor verifique su conexión a Internet e inténtelo nuevamente.',
      btn: 'Rever',
    },
    succ: {
      title: 'Felicidades',
      text1: 'bienvenido a noam,',
      text2: '¡Empecemos juntos!',
      btn: 'Hagamos algo de magia',
    },
  },
  welfare: {
    newUser: {
      label: 'Beneficios para nuevos usuarios:',
      joinBtn: 'Únete ahora',
      text: '¡Solo 100 lugares disponibles! ¡Consigue el tuyo ahora!',
      remain: 'Sólo quedan 30',
    },
  },
  help: {
    Fixedplugin: 'Complemento fijo',
    Howtouse: 'Cómo utilizar',
    try: 'Darle una oportunidad',
    FreeTrialBenefits: 'Beneficios de la prueba gratuita',
    pin: 'Pin Noam, para un acceso más fácil～～',
    Video: 'Video',
    ParallelTranslation: 'Traducción paralela',
    WebSummary: 'Resumen Web',
    ChatPDF: 'Chat PDF',
    AquickOverviewOfNoam: 'Una descripción general rápida de Noam',
    WordSelectionTranslation: 'Traducción de selección de palabras',
    HoverTranslation: 'Traducción al pasar el cursor',
    ImageTranslation: 'Traducción de imágenes',
    InstantTranslation: 'Traduccion instantanea',
    VideoTranslation: 'Traducción de vídeos',
    benefits: '¡Hemos preparado beneficios para ti!',
    Free: 'Gratis',
    Membership: 'Afiliación',
    Translation: 'Traducción',
    PDFparallelTranslation: 'Traducción paralela de PDF',
    ChatPDF2: 'ChatPDF',
    Summary: 'Resumen',
    VideoSummary: 'Vídeo resumen',
    FavoritesManagement: 'Gestión de favoritos',
    Focusing: 'Enfoque',
    dayFree: 'Prueba gratuita de 3 días',
    planIsNotExist: 'el plan no existe',
    TryItForFree: 'Pruébalo gratis',
    startYoutube: 'Empecemos con YouTube ~',
    startWiki: 'Empecemos con Wikimedia ~',
    tryNow: 'Pruébalo ahora ~',
    GoWithYouTube: 'Ve con YouTube',
    GoWithWikipedia: 'Vaya con Wikipedia',
    NoamHelp: 'Noam puede ayudarte a traducir y resumir',
    SkipAllSteps: 'Saltar todos los pasos',
    next: 'Próximo',
    done: 'Hecho',
  },
  trialModal: {
    title: 'Regalo de bienvenida',
    trialText: 'Prueba de 7 días',
    desc1: 'Una vez finalizada la prueba, se le cobrará una tarifa anual de $180',
    desc2: 'Cancelar en cualquier momento',
    btn: 'Comience ahora (solo $0,99)',
    installBtn: 'Instala ahora para recibir tu regalo',
    web: 'Traducción paralela',
    select: 'Traducción de selección de palabras',
    hover: 'Traducción al pasar el cursor',
    image: 'Traducción de imágenes',
    realtime: 'Traduccion instantanea',
    video: 'Traducción de vídeos',
    pdf: 'Traducción paralela de PDF',
    chat: 'ChatPDF',
    webSummary: 'Resumen web',
    videoSummary: 'Vídeo resumen',
    memo: 'Gestión de favoritos',
    focus: 'Enfoque',
  },
  privacyCollectionPanel: {
    title: 'Este sitio web utiliza cookies',
    text: 'Para mejorar su experiencia, nos gustaría utilizar cookies para personalizar el contenido y los servicios.',
    acceptBtn: 'Aceptar todo',
  },
  installTips: {
    text: '¡Instale el “Complemento del navegador Noam” para desbloquear más funciones! Referencias cruzadas web,\n    resumen de vídeo web y mucho más.',
    btn: 'Pruebalo ahora',
  },
}

export default TRANSLATION
