const TRANSLATION = {
  slogen: 'أسهل مساعد الذكاء الاصطناعي',
  pageHeader: {
    help: 'مركز المساعدة',
    contact: 'اتصل بنا',
    pricing: 'التسعير',
    signUp: 'تسجيل الدخول',
    settings: 'إعدادات',
    logout: 'تسجيل خروج',
    logoutSucc: 'تم تسجيل الخروج بنجاح',
  },
  pageFooter: {
    product: {
      title: 'منتجات',
      web: 'ترجمة الويب والملخص',
      pdf: 'ترجمة قوات الدفاع الشعبي',
      video: 'ترجمة وملخص الفيديو',
      image: 'ترجمة الصور',
    },
    resources: { title: 'موارد' },
    follow: { title: 'يتبع', twitter: 'تويتر', facebook: 'فيسبوك' },
    legal: { title: 'قانوني', policy: 'سياسة الخصوصية', terms: 'شروط الخدمة' },
  },
  home: {
    getStart: 'البدء',
    getStartedForFree: 'ابدأ مجانًا',
    top: {
      desc: 'Noam هو امتداد مجاني لمتصفح الذكاء الاصطناعي يساعدك على ترجمة صفحات الويب ومقاطع الفيديو ومحتوى PDF الملخصة.\n      انضم إلى نعوم واستمتع بالسحر!',
      title2: 'سحر واحد',
      title1: 'نقره واحدة',
    },
    howItWork: {
      title: 'إذًا، كيف يعمل',
      desc: 'يدمج Noam نماذج الذكاء الاصطناعي لتزويد المستخدمين بتجربة ذكية لترجمة صفحات الويب وتلخيصها عبر اللغات، وترجمة ملفات PDF والصور، وترجمة الفيديو وتلخيصه.',
    },
    PDFTranslate: {
      title: 'ترجمة قوات الدفاع الشعبي',
      desc: 'ترجمة ثنائية اللغة + دردشة PDF',
      sub1: {
        title: 'القراءة ثنائية اللغة',
        desc: 'قراءة ثنائية اللغة، بحيث يمكنك مقارنة النص الأصلي والترجمة أثناء القراءة لتحسين كفاءة القراءة',
      },
      sub2: {
        title: 'الدردشة بصيغة PDF',
        desc: 'يقوم الذكاء الاصطناعي بالتحليل والتلخيص، ويمكنه الإجابة على أسئلتك بناءً على محتوى PDF',
      },
      sub3: {
        title: 'الدقة والكفاءة',
        desc: 'ملف PDF مدعوم بالذكاء الاصطناعي للحصول على ترجمات أكثر دقة وكفاءة',
      },
    },
    aiAssistant: {
      title: 'مساعد الذكاء الاصطناعي',
      desc: 'جعل ترجمة الويب أكثر ذكاءً وملاءمة',
      panel1: {
        title: 'الترجمة ثنائية اللغة',
        desc: 'تساعدك الترجمات المرجعية ثنائية اللغة على مقارنة النص الأصلي بالترجمة حتى تتمكن من الفهم بشكل أفضل وتحسين كفاءة القراءة لديك.',
      },
      panel2: {
        left: {
          title: 'تحوم الترجمة',
          desc: 'عند تصفح صفحة ويب، قم بتمرير مؤشر الماوس مباشرة فوق المحتوى المطلوب ترجمته للحصول على نتيجة الترجمة.',
        },
        right: {
          title: 'ترجمة اختيار النص',
          desc: 'عند تصفح صفحة ويب، حدد النص الذي سيتم تسطيره مباشرة للحصول على نتيجة الترجمة.',
        },
      },
      panel3: {
        one: {
          title: 'أشر وقم بالنقر',
          desc: 'ترجمة وتلخيص صفحات الويب ومحتوى الفيديو لك في أي وقت وفي أي مكان وفي أي سيناريو؛ تجلب لك تجربة أكثر سلاسة!',
        },
        two: {
          title: 'أكثر شمولاً وملاءمة',
          desc: 'تغطية كاملة متعددة اللغات، قراءة ثنائية اللغة خالية من العوائق، ترجمة بمفتاح واحد؛ تجلب لك تجربة لا مثيل لها!',
        },
        three: {
          title: 'يحركها الذكاء الاصطناعي',
          desc: 'نموذج كبير يعتمد على الذكاء الاصطناعي لضمان دقة الترجمة واتساقها وتعزيز تجربة القراءة',
        },
      },
      panel4: {
        tab1: {
          key: 'فيديو',
          title: 'ترجمة وملخص الفيديو',
          desc: 'يقوم خبير الكفاءة الخاص بك، جنبًا إلى جنب مع chatgpt، بترجمة وتلخيص محتوى فيديو YouTube لتوفير وقت القراءة!',
        },
        tab2: { key: 'صورة', title: 'ترجمة الصور', desc: 'انقر لترجمة الصور أثناء تصفح الويب' },
        tab3: {
          key: 'ويب',
          title: 'ترجمة الويب والملخص',
          desc: 'استخراج المعلومات الأساسية بسرعة من صفحات الويب لتلخيصها، مما يعزز كفاءة القراءة لديك',
        },
        tag1: 'بسيط',
        tag2: 'كفاءة',
        tag3: 'يسهل فهمها',
      },
    },
    userReviews: { title: 'مراجعات المستخدم', desc: 'مراجعة خمس نجوم على متجر chromewebstore' },
    lastBlock: { desc: 'ترجمة أفضل بالذكاء الاصطناعي وتجربة قراءة أكثر سلاسة' },
  },
  login: {
    title: 'مرحبا بكم في نعوم',
    btn: 'تسجيل الدخول عبر جوجل',
    tips: 'بالتسجيل، أنا أوافق على',
    terms: 'شروط الاستخدام',
    and: 'و',
    policy: 'سياسة الخصوصية',
    btn1: 'تسجيل الدخول الآن',
    desc: 'قم بتسجيل الدخول لفتح المزيد من التجارب',
  },
  pricing: {
    pageTitle: 'التسعير',
    desc: 'قارن واختر الأفضل لك',
    yearly: 'سنويا',
    monthly: 'شهريا',
    discount: '20٪ خصم',
    preMonth: 'كل شهر',
    billedYearly: 'تتم الفاتورة سنويًا',
    billedMonthly: 'الفاتورة الشهرية',
    detailedCompare: 'مقارنة تفصيلية',
    FAQ: 'التعليمات',
    day: 'يوم',
    month: 'شهر',
    around: 'حول',
    free: {
      benefit1: 'ترجمة فورية 100 مرة في اليوم',
      benefit2: 'ترجمة الويب والملخص',
      benefit3: '30 ورقة ترجمة الصور',
      benefit4: 'ترجمة وملخص الفيديو',
      benefit5: '10 ترجمة PDF',
      benefit6: '5000 رمز',
      recommendText: 'تجربة مجانية',
      btn: 'استمتع',
    },
    pro: {
      benefit1: 'الدعم السريع لخدمة العملاء',
      benefit2: '1,000,000 توكن / شهر',
      benefit3: 'ترجمة الويب والملخص',
      benefit4: '200 ورقة ترجمة الصور',
      benefit5: 'ترجمة وملخص الفيديو',
      benefit6: '200 ترجمة PDF',
      btn: 'البدء',
      recommendText: 'معظم الناس يختارون',
    },
    proPlus: {
      benefit1: 'الدعم السريع لخدمة العملاء',
      benefit2: '3,000,000 توكن / شهر',
      benefit3: 'ترجمة الويب والملخص',
      benefit4: '500 ورقة ترجمة الصور',
      benefit5: 'ترجمة وملخص الفيديو',
      benefit6: '500 ترجمة PDF',
      recommendText: 'الاختيار الأنسب',
    },
    enterprise: {
      benefit1: 'الدعم السريع لخدمة العملاء',
      benefit2: 'متعدد المستخدمين',
      benefit3: 'حلول مخصصة',
      benefit5: 'مدير الحساب الحصري',
      recommendText: 'خطط مخصصة (≥3人)',
      talkToUs: 'تكلم معنا',
      btn: 'اتصال',
    },
    web: {
      contrastiveTranslation: 'الترجمة ثنائية اللغة',
      instantTranslation: 'ترجمة فورية',
      hoverTranslation: 'تحوم الترجمة',
      dictionaryTranslation: 'ترجمة اختيار النص',
      summary: 'ملخص صفحة الويب',
    },
    imageTranslation: 'ترجمة الصور',
    video: { translation: 'ترجمة الفيديو', summary: 'ملخص الفيديو' },
    pdf: {
      translation: 'ترجمة قوات الدفاع الشعبي',
      maxPage: 'الحد الأقصى لعدد الصفحات في PDF',
      chat: 'عدد الاستفسارات بصيغة PDF',
    },
    FQAObj: {
      q1: 'هل يمكنني استخدامه مجانا؟',
      a1: 'هناك نسخة مجانية من Noam يمكن استخدامها مباشرة.',
      q2: 'هل هناك خطط عضوية مختلفة وخيارات الرسوم؟',
      a2: 'بالإضافة إلى الإصدار المجاني، هناك ثلاث خطط عضوية، Pro وPro+ وEnterprise؛ يمكنك الاختيار بين طريقتين للدفع، شهري مستمر وسنوي مستمر، والتكلفة الشاملة للسنوي المستمر هي الأفضل.',
      q3: 'ما الفرق بين الاشتراك الشهري المستمر والاشتراك السنوي المستمر؟',
      a3: 'لا يوجد فرق في الوظيفة. التكلفة الشاملة للاشتراك السنوي المستمر أقل من تكلفة الاشتراك الشهري المستمر؛ تتم فوترة الاشتراك الشهري مرة واحدة شهريًا، بينما تتم فوترة الاشتراك السنوي مرة واحدة سنويًا.',
      q4: 'كيف يمكنني إلغاء خطة التجديد التلقائي الخاصة بي؟',
      a4: 'يمكنك الذهاب إلى صفحة إعدادات الواجهة الخلفية واختيار الاشتراك لإلغاء خطة التجديد.',
    },
  },
  upgradeModal: {
    title: 'تذكيرات',
    limitAlertStr: 'لقد تم استنفاد حصة خطتك المجانية، ننصحك بترقية خطتك لمواصلة التجربة ~!',
    upgradeAlertStr: 'لقد تم استنفاد حصة خطتك المجانية، ننصحك بترقية خطتك لمواصلة التجربة ~!',
    btn: 'تحديث الآن',
    more: 'المزيد من الخطط',
    contactStr: 'يرجى انتظار إعادة التعيين التالية أو الاتصال بنا على contact@noam.tools.',
    upload: {
      free: 'لقد وصلت إلى الحد الأقصى لترجمة PDF',
      pro: 'لقد وصلت إلى الحد الأقصى لترجمة PDF في هذا الشهر',
    },
    chat: {
      free: 'لقد وصلت إلى الحد الأقصى المسموح به لـ Chatpdf',
      pro: 'لقد وصلت إلى الحد الأقصى المسموح به لملف Chatpdf في هذا الشهر',
    },
    pageLimit: { free: 'لقد وصلت إلى الحد الأقصى المسموح به لصفحات PDF' },
  },
  buyModal: {
    title: 'خطة الاشتراك',
    freeTrial: 'تجربة مجانية لمدة 3 أيام',
    selectLabel: 'حدد خطة',
    btn: 'قم بتجربته مجانا',
    yearLabel: 'سنة',
    monthLabel: 'شهر',
    afterFree: 'بعد المحاكمة',
  },
  privacy: {
    title: 'سياسة الخصوصية',
    updateTime: 'تم التحديث: 15 مارس 2024',
    block1:
      'نحن، نعوم (يشار إلينا باسم "نعوم" أو "نحن" أو "خاصتنا")، نحترم خصوصيتك ونلتزم بحماية أي معلومات نحصل عليها منك أو عنك. توضح سياسة الخصوصية هذه ممارساتنا فيما يتعلق بجمع أو استخدام المعلومات الشخصية من استخدامك لموقعنا الإلكتروني وتطبيقاتنا وخدماتنا (يشار إليها مجتمعة باسم "الخدمات"). لا تنطبق سياسة الخصوصية هذه على المحتوى الذي نتعامل معه نيابة عن عملاء منتجات الأعمال لدينا، مثل واجهة برمجة التطبيقات (API) الخاصة بنا. يخضع استخدام هذه البيانات لاتفاقية العملاء الخاصة بنا، والتي تغطي الوصول إلى هذه المنتجات واستخدامها.',
    subTitle1: 'المعلومات الشخصية التي نجمعها',
    block2: 'نقوم بجمع المعلومات الشخصية التالية ("المعلومات الشخصية") المتعلقة بك:',
    block3:
      'المعلومات التي قدمتها: إذا قمت بإنشاء حساب لاستخدام خدماتنا أو التواصل معنا، فسنقوم بجمع المعلومات الشخصية على النحو التالي:',
    subBlock1:
      'معلومات الحساب: عندما تقوم بإنشاء حساب على منصتنا، نقوم بجمع المعلومات المتعلقة بحسابك، بما في ذلك اسمك ومعلومات الاتصال وبيانات اعتماد الحساب ومعلومات بطاقة الدفع وسجل المعاملات (يشار إليها باسم "معلومات الحساب").',
    subBlock2:
      'محتوى المستخدم: عندما تستخدم خدماتنا، نقوم بجمع معلومات شخصية من مدخلاتك أو تحميلات الملفات أو التعليقات المقدمة إلينا (يشار إليها باسم "المحتوى").',
    subBlock3:
      'معلومات الاتصال: إذا قمت بإرسال رسائل إلينا، فسنقوم بجمع اسمك ومعلومات الاتصال بك ومحتوى الرسائل التي ترسلها (يشار إليها باسم "معلومات الاتصال").',
    subBlock4:
      'معلومات وسائل التواصل الاجتماعي: لدينا صفحات على مواقع التواصل الاجتماعي مثل Instagram وFacebook وMedium وTwitter وYouTube وLinkedIn. عندما تتفاعل مع صفحات وسائل التواصل الاجتماعي الخاصة بنا، نقوم بجمع المعلومات الشخصية التي تختار تقديمها لنا، مثل تفاصيل الاتصال الخاصة بك (يشار إليها باسم "المعلومات الاجتماعية"). بالإضافة إلى ذلك، قد تزودنا الشركات التي تستضيف صفحات وسائل التواصل الاجتماعي الخاصة بنا بمعلومات وتحليلات مجمعة حول أنشطتنا على وسائل التواصل الاجتماعي.',
    block4:
      'المعلومات الشخصية التي يتم تلقيها تلقائيًا من استخدامك للخدمات: عند وصولك إلى الخدمات أو استخدامها أو التفاعل معها، نتلقى المعلومات التالية حول وصولك أو استخدامك أو تفاعلك ("المعلومات الفنية"):',
    subBlock5:
      'بيانات السجل: المعلومات التي يرسلها متصفحك تلقائيًا عند استخدام خدماتنا. تتضمن بيانات السجل عنوان بروتوكول الإنترنت (IP) الخاص بك، ونوع المتصفح والإعدادات، وتاريخ ووقت طلباتك، وكيفية تفاعلك مع موقعنا.',
    subBlock6:
      'بيانات الاستخدام: قد نقوم تلقائيًا بجمع معلومات حول استخدامك للخدمة، مثل أنواع المحتوى الذي تشاهده أو تتفاعل معه، والميزات التي تستخدمها، والإجراءات التي تتخذها، بالإضافة إلى المنطقة الزمنية والبلد والتاريخ والوقت الخاص بك. الوصول ووكيل المستخدم والإصدار ونوع الكمبيوتر أو الجهاز المحمول واتصال الكمبيوتر الخاص بك.',
    subBlock7:
      'معلومات الجهاز: تتضمن اسم الجهاز ونظام التشغيل ومعرف الجهاز والمتصفح الذي تستخدمه. قد تعتمد المعلومات التي تم جمعها على نوع الجهاز الذي تستخدمه وإعداداته.',
    subBlock8:
      'ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط لتشغيل خدماتنا وإدارتها وتحسين تجربتك. "ملف تعريف الارتباط" هو المعلومات التي يرسلها موقع الويب الذي تزوره إلى متصفحك. يمكنك ضبط المتصفح الخاص بك لقبول جميع ملفات تعريف الارتباط، أو رفض جميع ملفات تعريف الارتباط، أو إعلامك عند توفير ملف تعريف الارتباط حتى تتمكن من تحديد ما إذا كنت ستقبله في كل مرة. ومع ذلك، فإن رفض ملفات تعريف الارتباط قد يمنعك من استخدام أو التأثير سلبًا على عرض أو وظائف مناطق أو ميزات معينة في موقع الويب. لمزيد من المعلومات حول ملفات تعريف الارتباط، يرجى زيارة كل شيء عن ملفات تعريف الارتباط.',
    subBlock9:
      'التحليلات: قد نستخدم العديد من منتجات التحليلات عبر الإنترنت التي تستخدم ملفات تعريف الارتباط لمساعدتنا في تحليل كيفية استخدام المستخدمين لخدماتنا وتحسين تجربتك عند استخدام الخدمات.',
    subTitle2: 'كيف نستخدم المعلومات الشخصية',
    block5: 'قد نستخدم المعلومات الشخصية للأغراض التالية:',
    subBlock10: 'توفير الخدمات وإدارتها وصيانتها و/أو تحليلها؛',
    subBlock26: 'تحسين خدماتنا وإجراء البحوث؛',
    subBlock11: 'التواصل معك؛',
    subBlock12: 'تطوير مشاريع وخدمات جديدة؛',
    subBlock13:
      'منع الاحتيال أو النشاط الإجرامي أو إساءة استخدام خدماتنا، وحماية أمن أنظمة تكنولوجيا المعلومات والهندسة المعمارية والشبكات لدينا؛',
    subBlock14: 'إجراء التحويلات التجارية؛',
    subBlock15:
      'الامتثال للالتزامات القانونية والإجراءات القانونية، وحماية حقوقنا أو خصوصيتنا أو أمننا أو ممتلكاتنا، بالإضافة إلى حقوق الشركات التابعة لنا أو أنت أو أطراف ثالثة أخرى.',
    block6:
      'المعلومات الموجزة أو غير المحددة للهوية: يجوز لنا تجميع المعلومات الشخصية أو إلغاء تحديد هويتها لمنع إعادة استخدامها لأغراض تحديد الهوية واستخدام هذه المعلومات لتحليل فعالية خدماتنا وتحسين وإضافة ميزات إلى خدماتنا وإجراء الأبحاث ولأغراض مماثلة. . بالإضافة إلى ذلك، قد نقوم بتحليل السلوك والخصائص العامة لمستخدمي خدمتنا ومشاركة المعلومات المجمعة مع أطراف ثالثة، أو نشر هذه المعلومات المجمعة، أو إتاحتها بشكل عام. يجوز لنا جمع معلومات مجمعة من خلال الخدمات، ومن خلال ملفات تعريف الارتباط، ومن خلال وسائل أخرى موضحة في سياسة الخصوصية هذه. سنحتفظ بالمعلومات غير محددة الهوية ونستخدمها في شكل مجهول أو غير محدد الهوية، ولن نحاول إعادة تحديد هذه المعلومات ما لم يقتضي القانون ذلك.',
    subTitle3: 'الكشف عن المعلومات الشخصية',
    block7:
      'في بعض الحالات، قد نقدم معلوماتك الشخصية إلى أطراف ثالثة دون إشعار آخر لك، ما لم يقتضي القانون ذلك:',
    subBlock16:
      'الموردون ومقدمو الخدمات: لمساعدتنا في تلبية الاحتياجات التشغيلية للأعمال وأداء خدمات ووظائف معينة، قد نقدم معلومات شخصية للموردين ومقدمي الخدمات، بما في ذلك مقدمو خدمات الاستضافة ومقدمو الخدمات السحابية ومقدمو خدمات تكنولوجيا المعلومات الآخرون وبرامج الاتصال عبر البريد الإلكتروني. ومقدمي خدمات تحليلات الويب، وما إلى ذلك. لن يتمكن هؤلاء الشركاء من الوصول إلى المعلومات الشخصية أو معالجتها أو تخزينها إلا بناءً على توجيهاتنا أثناء أداء واجباتهم لصالحنا.',
    subBlock17:
      'تحويلات الأعمال: إذا انخرطنا في معاملات استراتيجية، أو عمليات إعادة تنظيم، أو حالات إفلاس، أو عمليات استحواذ بسبب الإفلاس، أو نقل الخدمات إلى مزود آخر (يشار إليها مجتمعة باسم "المعاملات")، فقد تتم مشاركة معلوماتك الشخصية والمعلومات الأخرى مع نظراء المعاملات والأطراف الأخرى التي تساعد في المعاملات أثناء العناية الواجبة ونقلها كجزء من المعاملات إلى جانب الأصول الأخرى إلى شركة خلف أو شركة تابعة.',
    subBlock18:
      'المتطلبات القانونية: يجوز لنا مشاركة معلوماتك الشخصية مع السلطات الحكومية أو نظراء الصناعة أو أطراف ثالثة أخرى، بما في ذلك معلومات تفاعلك مع خدماتنا، (1) إذا كان ذلك مطلوبًا بموجب القانون أو إذا كنا نعتقد أن هذا الإجراء ضروري للامتثال للالتزامات القانونية؛ (2) لحماية حقوقنا أو ممتلكاتنا والدفاع عنها؛ (3) إذا قررنا من جانب واحد أن هناك انتهاكًا لشروطنا أو سياساتنا أو قوانيننا؛ (4) لاكتشاف أو منع الاحتيال أو الأنشطة غير القانونية الأخرى؛ (5) لحماية منتجاتنا أو موظفينا أو مستخدمينا أو السلامة العامة والأمن والنزاهة؛ أو (6) للدفاع ضد المسؤولية القانونية.',
    subTitle4: 'حقوقك',
    block8:
      'اعتمادًا على موقعك الجغرافي، قد يتمتع الأفراد في المنطقة الاقتصادية الأوروبية والمملكة المتحدة والعالم بحقوق قانونية معينة فيما يتعلق بمعلوماتهم الشخصية. على سبيل المثال، قد يكون لك الحق في:',
    subBlock19: 'الوصول إلى معلوماتك الشخصية والمعلومات المتعلقة بكيفية معالجتها.',
    subBlock20: 'حذف معلوماتك الشخصية من سجلاتنا.',
    subBlock21: 'تصحيح أو تحديث معلوماتك الشخصية.',
    subBlock22: 'نقل معلوماتك الشخصية إلى طرف ثالث (إمكانية نقل البيانات).',
    subBlock23: 'الحد من كيفية معالجتنا لمعلوماتك الشخصية.',
    subBlock24:
      'سحب موافقتك - عندما نعتمد على الموافقة كأساس قانوني للمعالجة، يمكنك سحب موافقتك في أي وقت.',
    subBlock25: 'الاعتراض على كيفية معالجتنا لمعلوماتك الشخصية.',
    subTitle5: 'روابط لمواقع أخرى',
    block9:
      'قد تحتوي الخدمة على روابط لمواقع إلكترونية أخرى لا تديرها أو تسيطر عليها شركة Noam، بما في ذلك خدمات الوسائط الاجتماعية ("مواقع الطرف الثالث"). ستخضع المعلومات التي تشاركها مع مواقع الطرف الثالث لسياسات الخصوصية المحددة وشروط الخدمة الخاصة بمواقع الطرف الثالث، وليس لسياسة الخصوصية هذه. نحن نقدم هذه الروابط من أجل التسهيل ولا تعني تأييدنا أو مراجعتنا لهذه المواقع. يرجى الاتصال بمواقع الطرف الثالث مباشرة للحصول على معلومات حول سياسات وشروط الخصوصية الخاصة بها.',
    subTitle6: 'الأمن والاحتفاظ بالمعلومات',
    block10:
      'نحن ننفذ إجراءات فنية وإدارية وتنظيمية معقولة تجاريًا لحماية المعلومات الشخصية عبر الإنترنت وغير المتصلة بالإنترنت من الفقدان أو سوء الاستخدام أو الوصول غير المصرح به أو الكشف أو التغيير أو الأعمال العدائية. ومع ذلك، لا يمكن لأي نقل عبر الإنترنت أو البريد الإلكتروني أن يضمن الأمان أو الدقة الكاملة. وعلى وجه الخصوص، قد لا تكون رسائل البريد الإلكتروني المرسلة إلينا أو منا آمنة. لذا، يجب أن تكون حذرًا بشكل خاص عند تحديد المعلومات التي تريد إرسالها إلينا عبر الخدمة أو البريد الإلكتروني. بالإضافة إلى ذلك، نحن لسنا مسؤولين عن أي عرقلة لإعدادات الخصوصية أو تدابير الأمان في الخدمة أو إعدادات الأمان أو التدابير الأمنية لمواقع الطرف الثالث. سنحتفظ بمعلوماتك الشخصية طالما كان ذلك ضروريًا لتزويدك بالخدمات أو لأغراض تجارية مشروعة أخرى، مثل حل النزاعات أو لأسباب الأمن والسلامة أو الامتثال لالتزاماتنا القانونية. يعتمد طول المدة التي نحتفظ فيها بالمعلومات الشخصية على العديد من العوامل، مثل مقدار المعلومات وطبيعتها وحساسيتها والمخاطر المحتملة للاستخدام أو الكشف غير المصرح به، والأغراض التي نعالج المعلومات من أجلها، وغيرها من الاعتبارات الملزمة قانونًا.',
    subTitle7: 'تعديل سياسة الخصوصية',
    block11:
      'قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. عندما نقوم بذلك، سنقوم بنشر نسخة محدثة على هذه الصفحة، ما لم ينص القانون المعمول به على خلاف ذلك.',
    subTitle8: 'كيف تتواصل معنا',
    block12:
      'إذا كانت لديك أية أسئلة أو مخاوف لم تتم الإجابة عليها بشأن سياسة الخصوصية هذه، فيرجى الاتصال بخدمة العملاء لدينا. بريد إلكتروني:',
  },
  terms: {
    title: 'شروط الخدمة',
    updateTime: 'تم التحديث: 15 مارس 2024',
    block1: 'شكرا لاستخدامك نعوم!',
    block2:
      'تنطبق شروط الاستخدام هذه على استخدامك لخدمات Noam، بما في ذلك واجهات برمجة التطبيقات والبرامج والأدوات وخدمات المطورين والبيانات والوثائق والموقع الإلكتروني (يشار إليها إجمالاً باسم "الخدمات"). باستخدام خدماتنا، فإنك توافق على الالتزام بهذه الشروط. تشرح سياسة الخصوصية الخاصة بنا كيف نقوم بجمع واستخدام المعلومات الشخصية.',
    subTitle1: 'التسجيل والوصول',
    block3:
      'إذا كان عمرك أقل من 18 عامًا، فيجب عليك الحصول على إذن من والديك أو الأوصياء القانونيين لاستخدام الخدمة. إذا كنت تستخدم الخدمة نيابة عن شخص أو كيان آخر، فيجب أن تكون مرخصًا لقبول هذه الشروط نيابةً عنهم. يجب عليك تقديم معلومات دقيقة وكاملة للتسجيل للحصول على حساب. لا يجوز لك تقديم بيانات اعتماد الوصول أو الحساب الخاص بك إلى أي شخص خارج مؤسستك، وأنت مسؤول عن جميع الأنشطة التي يتم إجراؤها باستخدام بيانات الاعتماد الخاصة بك.',
    subTitle2: 'متطلبات الاستخدام',
    block4:
      '(أ) استخدام الخدمة. يمكنك الوصول إلى الخدمة بموجب هذه الشروط، ونمنحك حقًا غير حصري لاستخدام الخدمة. عند استخدام الخدمة، فإنك ستلتزم بهذه الشروط وجميع القوانين المعمول بها. نحن والشركات التابعة لنا نملك جميع الحقوق والملكية والمصالح في الخدمة.',
    block5:
      '(ب) ردود الفعل. نحن نرحب بالتعليقات والتعليقات والأفكار والاقتراحات والتحسينات. إذا قمت بتقديم أي محتوى من هذا القبيل، فقد نستخدمه دون أي قيود ودون تعويض لك.',
    block6:
      '(ج) القيود. لا يجوز لك: (1) استخدام الخدمة بطريقة تنتهك أو تختلس أو تنتهك أي حقوق للآخرين؛ (2) إجراء هندسة عكسية أو تفكيك أو تفكيك أو ترجمة أو محاولة اكتشاف كود المصدر أو المكونات الأساسية لنماذج الخدمة والخوارزميات والأنظمة (ما لم تكن هذه القيود محظورة بموجب القانون المعمول به)؛ (3) استخدام مخرجات الخدمة لتطوير نماذج تنافس نعوم؛ (4) استخراج البيانات أو المخرجات من الخدمة باستخدام أي طرق آلية أو برمجية ما لم يسمح بذلك من خلال واجهة برمجة التطبيقات، بما في ذلك الاستخراج أو تجميع الويب أو استخراج بيانات الويب؛ (5) تمثيل مخرجات الخدمة على أنها تم إنشاؤها بشكل مصطنع عندما لا تكون كذلك، أو تنتهك سياسات الاستخدام الخاصة بنا بطريقة أخرى؛ (6) شراء أو بيع أو نقل مفاتيح API دون موافقتنا المسبقة؛ أو (7) تزويدنا بأي معلومات شخصية عن الأطفال الذين تقل أعمارهم عن 13 عامًا أو السن المعمول به للموافقة الرقمية. سوف تلتزم بأي حدود للأسعار ومتطلبات أخرى في وثائقنا.',
    block7:
      '(د) خدمات الطرف الثالث. تخضع أي برامج أو خدمات أو منتجات أخرى تابعة لجهة خارجية مرتبطة بالخدمة لشروطها الخاصة، ونحن لسنا مسؤولين عن منتجات الجهات الخارجية.',
    subTitle3: 'محتوى',
    block8:
      '(أ) المحتوى الخاص بك. يجوز لك تقديم مدخلات ("المدخلات") إلى الخدمة وتلقي المخرجات ("المخرجات") التي يتم إنشاؤها وإرجاعها بناءً على المدخلات (يشار إليها إجمالاً باسم "المحتوى"). بين الطرفين، وإلى الحد الذي يسمح به القانون المعمول به، فإنك تمتلك جميع المدخلات. يجوز لـ Noam استخدام المحتوى لتوفير الخدمة والحفاظ عليها، والامتثال للقوانين المعمول بها، وإنفاذ سياساتنا. أنت مسؤول عن المحتوى، بما في ذلك التأكد من أنه لا ينتهك أي قوانين معمول بها أو هذه الشروط.',
    block9:
      '(ب) الدقة. الذكاء الاصطناعي والتعلم الآلي من مجالات البحث سريعة التطور. نحن نسعى باستمرار لتحسين خدماتنا لجعلها أكثر دقة وموثوقية وأمانًا وإفادة. نظرًا للطبيعة الاحتمالية للتعلم الآلي، فإن استخدام خدمتنا قد يؤدي إلى مخرجات غير صحيحة في بعض الحالات، والتي قد لا تعكس بدقة الأفراد أو الأماكن أو الحقائق الحقيقية. يجب عليك تقييم دقة أي مخرجات بشكل مناسب بناءً على حالة الاستخدام الخاصة بك، بما في ذلك عن طريق مراجعة المخرجات يدويًا.',
    subTitle4: 'الرسوم والدفع',
    block10:
      '(أ) الرسوم والفواتير. ستدفع جميع الرسوم ("الرسوم") وفقًا للتسعير والشروط الواردة في صفحة التسعير المعمول بها أو كما هو متفق عليه كتابيًا بيننا. نحن نحتفظ بالحق في تصحيح أخطاء أو أخطاء التسعير حتى لو قمنا بإصدار فواتير أو تلقينا الدفع. ستقدم معلومات كاملة ودقيقة عن الفواتير، بما في ذلك طريقة دفع صالحة ومصرح بها. سنقوم بتحصيل الرسوم من طريقة الدفع الخاصة بك على أساس دوري كما هو متفق عليه، ولكن يجوز لنا تغيير تاريخ الفاتورة بشكل معقول. أنت تفوض شركة Noam والشركات التابعة لها ومعالجي الدفع التابعين لجهات خارجية لتحصيل الرسوم من طريقة الدفع الخاصة بك. إذا فشلت عملية الدفع، فسنقدم لك إشعارًا كتابيًا وقد نقوم بتعليق الوصول إلى الخدمة حتى يتم استلام الدفع. باستثناء ما هو منصوص عليه خلاف ذلك في هذه الاتفاقية، فإن المدفوعات غير قابلة للاسترداد.',
    block11:
      '(ب) الضرائب. ما لم يُنص على خلاف ذلك، لا تشمل الرسوم أي ضرائب ورسوم وتقييمات اتحادية وحكومية ومحلية وأجنبية وتقييمات مماثلة ("الضرائب"). أنت مسؤول عن جميع الضرائب المرتبطة بمشترياتك، باستثناء الضرائب المستندة إلى صافي دخلنا والتي سنقوم بإصدار فاتورة لك بها. أنت توافق على دفع هذه الضرائب على الفور وتزويدنا ببيانات خصوصية صالحة قانونًا والحصول على الموافقات اللازمة للتعامل مع هذه البيانات، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به.',
    block12:
      '(ج) تغيرات الأسعار. قد نقوم بتغيير أسعارنا عن طريق إخطارك عبر حسابك و/أو موقعنا الإلكتروني. ستكون الزيادات في الأسعار سارية فور نشرها. سيتم تطبيق أي تغييرات في الأسعار على الرسوم الخاصة بحسابك بعد تاريخ سريان التغيير.',
    block13:
      '(د) المنازعات والمدفوعات المتأخرة. إذا كنت ترغب في الاعتراض على أي رسوم أو ضرائب، فيرجى الاتصال بـ contact@noam.tools خلال ثلاثين (30) يومًا من تاريخ الفاتورة.',
    block14:
      '(هـ) الاستخدام المجاني. لا يجوز لك إنشاء حسابات متعددة للحصول على أرصدة مجانية. إذا قررنا أنك لا تستخدم الأرصدة المجانية بحسن نية، فقد نفرض عليك رسومًا قياسية أو نتوقف عن توفير الوصول إلى الخدمة.',
    subTitle5: 'السرية والأمن وحماية البيانات',
    block15:
      '(أ) السرية. قد يكون لديك حق الوصول إلى المعلومات السرية الخاصة بـ Noam. لا يجوز لك استخدام المعلومات السرية إلا على النحو المسموح به بموجب هذه الشروط لغرض استخدام الخدمة. لا يجوز لك الكشف عن معلومات سرية لأي طرف ثالث، وسوف تقوم بحماية المعلومات السرية بطريقة لا تقل حماية عن حماية معلوماتك السرية المماثلة، على الأقل مع العناية المعقولة. المعلومات السرية هي معلومات غير عامة تم تصنيفها على أنها سرية بواسطة Noam أو يجب التعامل معها بشكل معقول على أنها سرية في ظل الظروف، بما في ذلك البرامج والمواصفات وغيرها من المعلومات التجارية غير العامة. لا تتضمن المعلومات السرية المعلومات التي: (1) تصبح متاحة للجمهور بشكل عام دون أي خطأ من جانبك؛ (2) كانت بحوزتك دون أي التزام بالسرية قبل استلامها بموجب هذه الشروط؛ (3) تم الكشف عنها لك بشكل قانوني من قبل طرف ثالث دون أي التزامات بالسرية؛ أو (4) تم تطويره بشكل مستقل بواسطتك دون استخدام المعلومات السرية. يجوز لك الكشف عن المعلومات السرية إذا كان ذلك مطلوبًا بموجب القانون أو المحكمة أو أي أمر حكومي آخر، ولكن يجب عليك تقديم إشعار كتابي مسبق إلى Noam بطريقة معقولة، وإلى أقصى حد ممكن، بذل جهود معقولة للحد من نطاق الكشف، بما في ذلك مساعدتنا في معارضة طلبات الإفصاح.',
    block16:
      '(ب) الأمن. يجب عليك تنفيذ التدابير المعقولة والمناسبة للمساعدة في حماية وصولك إلى الخدمة واستخدامها. إذا اكتشفت أي نقاط ضعف أو انتهاكات تتعلق باستخدامك للخدمة، فيجب عليك الاتصال بـ Noam على الفور وتقديم تفاصيل حول الثغرة الأمنية أو الانتهاك.',
    block17:
      '((ج) معالجة البيانات الشخصية. إذا كنت تستخدم الخدمة لمعالجة البيانات الشخصية، فيجب عليك تقديم بيانات خصوصية كافية قانونًا والحصول على الموافقات اللازمة لمعالجة هذه البيانات، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به .',
    subTitle6: 'المدة والإنهاء',
    block18:
      '(أ) الإنهاء؛ تعليق. ستكون هذه الشروط سارية منذ أول استخدام لك للخدمة وستظل سارية حتى إنهائها. يجوز لك إنهاء هذه الشروط في أي وقت ولأي سبب عن طريق التوقف عن استخدام الخدمة والمحتوى. يجوز لنا إنهاء هذه الشروط بإشعار مسبق لأي سبب من الأسباب. يجوز لنا إنهاء هذه الشروط على الفور عن طريق إخطارك إذا قمت بانتهاك مادي للقسم 2 (متطلبات الاستخدام)، أو القسم 5 (السرية والأمن وحماية البيانات)، أو القسم 8 (حل النزاعات)، أو القسم 9 (الشروط العامة)، أو إذا كان لدينا العلاقة مع أي مزود تكنولوجيا تابع لجهة خارجية خارج نطاق سيطرتنا، أو للامتثال للقانون أو الطلبات الحكومية. يجوز لنا تعليق وصولك إلى الخدمة إذا فشلت في الالتزام بهذه الشروط، أو إذا كان استخدامك يشكل خطرًا أمنيًا علينا أو على أي طرف ثالث، أو إذا اشتبهنا في أن استخدامك احتيالي أو قد يعرضنا أو يعرض أي طرف ثالث للمسؤولية. .',
    block19:
      '(ب) آثار الإنهاء. عند الإنهاء، ستتوقف عن استخدام الخدمة وستقوم على الفور بإرجاع أو تدمير أي معلومات سرية وفقًا لتوجيهاتنا. تظل أحكام هذه الشروط التي بطبيعتها سارية بعد الإنهاء أو انتهاء الصلاحية، بما في ذلك على سبيل المثال لا الحصر الأقسام 3 و5-9، سارية المفعول.',
    subTitle7: 'التعويض؛ إخلاء المسؤولية؛ تحديد المسؤولية',
    block20:
      '(أ) التعويض. سوف تدافع عنا وتعوضنا وتحمينا نحن والشركات التابعة لنا وموظفينا من وضد أي مطالبات وخسائر ونفقات (بما في ذلك أتعاب المحاماة) تنشأ عن أو تتعلق باستخدامك للخدمة، بما في ذلك المحتوى الخاص بك، استخدام المنتجات أو الخدمات المتعلقة بالخدمة، وانتهاكك لهذه الشروط أو القانون المعمول به.',
    block21:
      '(ب) إخلاء المسؤولية. يتم توفير الخدمة "كما هي". إلى الحد الذي يسمح به القانون، لا نقدم نحن والشركات التابعة لنا والمرخصون لنا أي تعهدات أو ضمانات من أي نوع فيما يتعلق بالخدمة ولا نقدم أي ضمانات، بما في ذلك على سبيل المثال لا الحصر ضمانات قابلية التسويق والملاءمة لغرض معين والجودة المرضية وعدم الانتهاك. والتمتع الهادئ وأي ضمانات تنشأ عن التعامل أو استخدام التجارة. نحن لا نضمن أن الخدمة ستكون متواصلة أو دقيقة أو خالية من الأخطاء، أو أن أي محتوى سيكون آمنًا أو غير مفقود أو متغير.',
    block22:
      '(ج) حدود المسؤولية. لن نتحمل نحن أو الشركات التابعة لنا أو الجهات المرخصة لدينا المسؤولية عن أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو تأديبية، بما في ذلك خسارة الأرباح أو فقدان السمعة أو فقدان الاستخدام أو فقدان البيانات أو غيرها من الخسائر غير الملموسة، حتى لو لقد تم إعلامنا بإمكانية حدوث مثل هذه الأضرار. بموجب هذه الشروط، لن تتجاوز مسؤوليتنا الإجمالية الرسوم التي دفعتها للخدمة خلال الاثني عشر (12) شهرًا السابقة للمطالبة أو مائة دولار أمريكي (100 دولار)، أيهما أكبر. تنطبق القيود الواردة في هذا القسم إلى الحد الأقصى الذي يسمح به القانون المعمول به.',
    subTitle8: 'القنون والشريعة السائدة',
    block23:
      'تخضع الشروط واستخدامك للخدمات لقوانين الولايات المتحدة، باستثناء قواعد تنازع القوانين الأمريكية. قد يخضع استخدامك للتطبيق أيضًا لقوانين محلية أو حكومية أو وطنية أو دولية أخرى. سيتم تقديم أي إجراء قانوني أو إجراء يتعلق بهذه الشروط حصريًا إلى محاكم الولايات المتحدة، وأنت توافق على الخضوع للسلطة القضائية الشخصية لهذه المحاكم.',
    subTitle9: 'شروط عامة',
    block24:
      '(أ) العلاقة بين الأطراف. أنت ونعوم متعاقدان مستقلان، ولا يتمتع أي من الطرفين بسلطة تمثيل الطرف الآخر أو إلزامه أو تحمل التزامات الطرف الآخر دون موافقة كتابية مسبقة من الطرف الآخر.',
    block25:
      '(ب) استخدام العلامة التجارية. لا يجوز لك استخدام الاسم أو الشعارات أو العلامات التجارية الخاصة بشركة Noam أو أي من الشركات التابعة لها دون الحصول على موافقة كتابية مسبقة منا.',
    subTitle10: 'الاستقلالية',
    block26:
      'إذا تبين أن أي بند من هذه الشروط غير صالح أو غير قانوني أو غير قابل للتنفيذ، فستظل الأحكام المتبقية سارية المفعول والتأثير الكامل.',
  },
  contact: {
    title: 'اتصل بنا',
    block1:
      'للتخصيص أو إذا كان لديك أي أسئلة أو اقتراحات، يرجى الاتصال بنا على contact@noam.tools وسوف نقوم بالرد على الفور.',
    block2: 'ادعمنا من خلال تقييمنا على متجر Google Apps Extension على ★★★★★.',
    block3: 'ساعد الآخرين من خلال مشاركة هذا الامتداد:',
  },
  pdf: {
    toast1: 'يتم تحميل ملف PDF، يرجى المحاولة مرة أخرى لاحقًا!',
    toast2: 'محتوى PDF كبير جدًا، ولا يدعم مربع الحوار في الوقت الحالي',
    toast3: 'جاري التحميل، يرجى المحاولة مرة أخرى بعد انتهاء التحميل.',
    shareMsg1: 'تمت مشاركة إنشاء الرابط بنجاح',
    shareMsg2: 'مشاركة تحديثات المحتوى بنجاح',
    shareMsg3:
      'فشل إنشاء رابط المشاركة، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء لدينا.',
    shareMsg4: 'فشل تحديث محتوى المشاركة، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء',
    shareBtnText1: 'إنشاء روابط المشاركة',
    shareBtnText2: 'تحديث محتوى المشاركة',
    uploading: 'جارٍ التحميل',
    uploadingMsg: 'يرجى التحلي بالصبر لأن التحميل يستغرق بعض الوقت.',
    cancelUpload: 'يلغي',
    uploadErr: 'فشل تحميل الملف. يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء',
    uploadMsg1: 'يرجى سحب وإسقاط الملف هنا',
    uploadMsg2: 'أنواع الملفات المدعومة: PDF |  الحد الأقصى لحجم الملف: 100 ميجابايت',
    uploadMsg3: 'انقر لتحديد الملفات أو سحبها وإفلاتها لتحميلها هنا',
    searchPlaceholder: 'يبحث',
    collectionEmpty: 'لا يوجد محتويات في المجموعة~',
    kw: 'التيلة',
    video: 'فيديو',
    link: 'وصلة',
    dataLoading: 'تحميل البيانات...',
    freeBannerTips: 'نهاية ترجمة PDF المجانية',
    vipBannerTips: 'لقد وصلت إلى الحد الأقصى لترجمة PDF في هذا الشهر.',
    freeBannerBtn: 'قم بالترقية للاستمتاع بالمزيد',
    vipBannerBtn: 'قم بالترقية للاستمتاع بالمزيد',
    dialogTitle: 'انتباه',
    dialogContent: 'هل أنت متأكد أنك تريد حذف ذلك؟',
    okBtn: 'يمسح',
    cancelBtn: 'يلغي',
    unLoginToast: 'الرجاء تسجيل الدخول لاستخدام هذه الوظيفة',
    parseErrTips: 'غير قادر على تحليل الملف',
    new: 'ملف جديد',
    parseErrDialogContent:
      'رابط PDF الحالي لا يدعم الترجمة المباشرة، يرجى تنزيل الرابط الأصلي PDF بعد الترجمة',
    okBtn2: 'يتعرف على',
    notPdf: 'ملفات PDF فقط',
    uploadingMsg1: 'جاري تحميل الملف. الرجاء معاودة المحاولة في وقت لاحق.',
    uploadingMsg2: 'لديك حاليًا ملف قيد التحويل، يرجى المحاولة مرة أخرى لاحقًا.',
    uploadingMsg3:
      'فشل الاستعلام عن تقدم التحويل، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء',
    uploadingMsg4:
      'لا يستطيع نعوم الوصول إلى ملفاتك المحلية مباشرة، يرجى تحميل ملف PDF يدويًا لترجمته.',
    uploadingMsg5:
      'فشل الحصول على عنوان URL للملف، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.',
    fileMsg: 'حجم الملف الحالي:',
    fileMsg2: 'يرجى تحميل ملف أصغر من 100M',
    fileErrMsg: 'فشلت قراءة الملف، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.',
    parseFileToast:
      'فشل تحليل الملف، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء لدينا.',
    noId: 'معرف الملف غير موجود',
    autoDetect: 'اكتشاف تلقائي',
    hasTransTask: 'هناك مهمة يجري ترجمتها. انتظر من فضلك.',
    transSucc: 'تمت الترجمة بنجاح!',
    entry: 'قائمة قوات الدفاع الشعبي',
    listError: 'فشل تحميل قائمة PDF، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بخدمة العملاء.',
    collectionTab: 'مذكرة',
    pdfLoading: 'تفسير...',
    installTips:
      'قم بتثبيت المكون الإضافي الصغير، ويمكنك ترجمة المزيد من ملفات PDF، ويمكنك أيضًا تجربة الترجمة السلسة لصفحات الويب:',
    webTrans: 'الإسناد الترافقي الكامل',
    hoverTrans: 'تحوم ترجمة الفقرة',
    selectTrans: 'ضع خطًا تحت الكلمات ثم ترجمتها',
    installText: 'متجر شامل لجميع احتياجات الترجمة اليومية الخاصة بك',
    start: 'ابدأ تجربتك المجانية',
    shareTips: 'انتهت ترجمة PDF، تابعها وشاركها مع أصدقائك!',
    download: {
      loading: 'أنقذ...',
      btn: 'احفظ الان',
      trans: 'الترجمة فقط',
      both: 'ثنائية اللغة',
      download: 'تحميل',
      msg1: 'بعض ترجمات المستند الحالي أطول من النص الأصلي، وقد يتداخل النص بعد التنزيل، لذا يوصى بقراءته على صفحة الويب.',
      msg2: '1、لم يتم ترجمة المحتوى بالكامل، يرجى تنزيله بعد ترجمته بالكامل.',
      msg3: '2、جزء الترجمة من المستند طويل جدًا، يمكنك قراءته في صفحة الويب لتجنب تداخل النص.',
      msg4: 'توجد حاليًا مهام قيد الترجمة، يرجى المحاولة مرة أخرى بعد اكتمال الترجمة.',
      fileName: 'اسم الملف:',
      fileType: 'تحميل نوع الملف:',
      transContinue: 'مواصلة الترجمة',
      save: 'حفظ إلى المحلية',
      downloadMsg1: 'تقدم PDF في الترجمة:',
      downloadMsg2: 'المتطلبات المتوقعة',
      minute: 'دقائق...',
      downloadSucc: 'اكتمل التنزيل بصيغة PDF، انقر فوق "حفظ" الآن!',
    },
    guide: {
      title1: 'الترجمة ثنائية اللغة',
      title2: 'الدردشة بصيغة PDF',
      text1: 'القراءة ثنائية اللغة',
      text2:
        'قراءة ثنائية اللغة، بحيث يمكنك مقارنة النص الأصلي والترجمة أثناء القراءة لتحسين كفاءة القراءة',
      text3: 'الدردشة بصيغة PDF',
      text4:
        'يقوم الذكاء الاصطناعي بالتحليل والتلخيص، ويمكنه الإجابة على أسئلتك بناءً على محتوى PDF',
      text5: 'الدقة والكفاءة',
      text6: 'ملف PDF مدعوم بالذكاء الاصطناعي للحصول على ترجمات أكثر دقة وكفاءة',
      placeholder: 'يرجى ملء رابط PDF',
      start: 'ابدأ الترجمة',
      toast: 'الرجاء إدخال رابط pdf صالح على الإنترنت وينتهي بـ .pdf',
    },
    original: 'إبداعي',
    parallel: 'موازي',
    trans: 'ترجمة',
    initial: 'ترجمة قوات الدفاع الشعبي',
    thumbnail: 'كتالوجات',
    downloadBtn: 'تحميل',
    shareBtn: 'يشارك',
    uploadBtn: 'رفع',
    installText2: 'الرجاء التثبيت أولا',
    chatPdf: {
      clearSucc: 'تم التطهير بنجاح',
      clear: 'واضح',
      clearContent:
        'بعد الحذف، سيتم إنشاء محادثة جديدة ولن يتم حفظ محتويات هذه المحادثة، هل أنت متأكد من رغبتك في مسحها؟',
      clearDialogTitle: 'انتباه',
      cancel: 'يلغي',
      chatTitle: 'مرحبًا بك في Noam، ملخص PDF الخاص بك جاهز:',
      summaryTitle: 'أسئلة قد تهمك:',
      placeholder: 'أدخل أي سؤال حول ملف PDF',
      copy: 'ينسخ',
      copySucc: 'نسخ النجاح',
      historyTitle: 'تاريخ',
      expand: 'ينهار',
      noDataDesc: 'لا يوجد تاريخ~',
      noMore: 'لا أكثر.',
      Search: 'يبحث',
      delete: 'يمسح',
      loading: 'تحميل...',
      deleteTitle: 'نصيحة',
      deleteContent: 'بعد الحذف، لا يمكن استرداد ملف PDF هذا، هل تريد حذفه؟',
      deleteOk: 'يمسح',
      deleteCancel: 'يلغي',
      deleteSuccess: 'تم الحذف بنجاح',
      listError: 'فشل تحميل قائمة القراءة. يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بدعم العملاء',
    },
    shareModal: {
      shareSetting: 'مشاركة إعدادات المحتوى',
      shareText: 'يشارك',
      text1: 'إعدادات المشاركة',
      text2: 'إنشاء روابط المشاركة',
      range: 'نِطَاق:',
      custom: 'التخصيص:',
      page: 'الصفحات',
      sourceLang: 'مصدر:',
      target: 'الهدف:',
      errMsg: 'لم يتم ترجمة محتوى نطاق المشاركة الحالي بشكل كامل، لذا يرجى ترجمته قبل المشاركة.',
      pageNuErr: 'الرجاء إدخال قيمة رقم الصفحة الصحيحة',
      trans: 'يترجم',
      cancel: 'يلغي',
      update: 'تم تحديث روابط المشاركة',
      gen: 'يشارك',
      shareTips: 'شارك رابط PDF المترجم مع أصدقائك لقراءته:',
      copySucc: 'نجاح نسخ الرابط',
      copy: 'ينسخ',
      settings: 'إعدادات المشاركة:',
      modify: 'يُعدِّل',
      translateTips:
        'نصيحة: إذا استغرقت الترجمة وقتًا طويلاً، فيمكنك إغلاق النافذة المنبثقة ومشاركتها بعد الانتهاء من الترجمة.',
      translating: 'ترجمة PDF...',
      remain: 'متبقي',
      remain1: 'إجمالي الاحتياجات المتوقعة',
      totalPage: '1 صفحات في المجموع',
      search: 'يبحث',
      notSupport: 'هذه اللغة غير مدعومة حاليًا',
    },
    toolbar: { Adjust: 'يُعدِّل', autoFit: 'ملاءمة تلقائية' },
    memoTotalCount: 'إجمالي محتوى واحد',
    dragTipModal: {
      press: 'يضعط',
      black: 'فضاء',
      leftKey: 'الزر الأيسر',
      tips: 'للسحب والعرض.',
      ok: 'حصلت عليه',
    },
  },
  locale: {
    zh: 'صينى',
    en: 'إنجليزي',
    es: 'الأسبانية',
    ru: 'الروسية',
    pt: 'البرتغالية (البرازيل).',
    id: 'الاندونيسية',
    cs: 'التشيكية',
    de: 'ألمانية',
    fr: 'فرنسي',
    it: 'ايطالي',
    nl: 'هولندي',
    pl: 'تلميع',
    vi: 'الفيتنامية',
    tr: 'اللغة التركية',
    uk: 'الأوكرانية',
    zhHant: 'تقاليد صينية',
    ja: 'اليابانية',
    ko: 'الكورية',
    ar: 'عربي',
    fa: 'اللغة الفارسية',
    th: 'التايلاندية',
    hi: 'الهندية',
    noSearchLang: 'هذه اللغة غير مدعومة',
    placeholder: 'يبحث',
    af: 'الأفريكانية',
    am: 'الأمهرية',
    as: 'الأسامية',
    az: 'أذربيجاني',
    ba: 'بشكير',
    bg: 'البلغارية',
    bn: 'البنغالية',
    bo: 'التبتية',
    bs: 'البوسنية',
    ca: 'الكاتالونية',
    cy: 'تهرب من دفع الرهان',
    da: 'دانماركي',
    dsb: 'الصوربية السفلى',
    dv: 'ديفيهي',
    el: 'اليونانية',
    et: 'الإستونية',
    eu: 'الباسك',
    fi: 'الفنلندية',
    fil: 'الفلبينية',
    fj: 'فيجي',
    frCA: 'الفرنسية (كندا)',
    ga: 'الأيرلندية',
    gl: 'الجاليكية',
    gom: 'الكونكانية',
    gu: 'الغوجاراتية',
    ha: 'الهوسا',
    he: 'اللغة العبرية',
    hr: 'الكرواتية',
    hsb: 'الصوربية العليا',
    ht: 'الكريولية الهايتية',
    hu: 'المجرية',
    hy: 'الأرمينية',
    ig: 'الإيغبو',
    ikt: 'إينويناكتون',
    is: 'الأيسلندية',
    iu: 'إنكتيتوت',
    iuLatn: 'إنكتيتوت (لاتينية)',
    ka: 'الجورجية',
    kk: 'الكازاخستانية',
    km: 'الخمير',
    kmr: 'الكردية (الشمالية)',
    kn: 'الكانادا',
    ku: 'الكردية (الوسطى)',
    ky: 'قيرغيزستان',
    ln: 'اللينجالا',
    lo: 'لاو',
    lt: 'الليتوانية',
    lug: 'غاندا',
    lv: 'لاتفيا',
    lzh: 'الصينية (الأدبية)',
    mai: 'مايثيلي',
    mg: 'مدغشقر',
    mi: 'الماوري',
    mk: 'المقدونية',
    ml: 'المالايالامية',
    mnCyrl: 'المنغولية (السيريلية)',
    mnMong: 'المنغولية (التقليدية)',
    mr: 'المهاراتية',
    ms: 'لغة الملايو',
    mt: 'المالطية',
    mww: 'همونغ داو',
    my: 'ميانمار (البورمية)',
    nb: 'النرويجية',
    ne: 'النيبالية',
    nso: 'سيسوتو سا ليبوا',
    nya: 'نيانجا',
    or: 'أوديا',
    otq: 'كويريتارو أوتومي',
    pa: 'البنجابية',
    prs: 'داري',
    ps: 'الباشتو',
    ptPT: 'البرتغالية (البرتغال)',
    ro: 'روماني',
    run: 'روندي',
    rw: 'الكينيارواندية',
    sd: 'السندية',
    si: 'السنهالية',
    sk: 'السلوفاكية',
    sl: 'السلوفينية',
    sm: 'ساموا',
    sn: 'شونا',
    so: 'الصومالية',
    sq: 'الألبانية',
    srCyrl: 'الصربية (السيريلية)',
    srLatn: 'الصربية (اللاتينية)',
    st: 'سيسوتو',
    sv: 'السويدية',
    sw: 'السواحلية',
    ta: 'التاميل',
    te: 'التيلجو',
    ti: 'التغرينية',
    tk: 'التركمان',
    tlhLatn: 'كلينجون (لاتينية)',
    tlhPiqd: 'كلينجون (pIqaD)',
    tn: 'سيتسوانا',
    to: 'تونجا',
    tt: 'التتار',
    ty: 'تاهيتي',
    ug: 'الأويغور',
    ur: 'الأردية',
    uz: 'الأوزبكية (اللاتينية)',
    xh: 'خوسا',
    yo: 'اليوروبا',
    yua: 'يوكاتيك مايا',
    yue: 'الكانتونية (التقليدية)',
    zhHans: 'الصينية المبسطة',
    zu: 'الزولو',
    auto: 'اكتشاف تلقائي',
  },
  stripe: {
    contact: 'اتصل بنا على contact@noam.tools إذا كانت لديك أية أسئلة!',
    fail: {
      title: 'عملية الدفع فشلت',
      text1: 'للأسف، لا يمكننا معالجة طلب الدفع الخاص بك.',
      text2: 'الرجاء التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى.',
      btn: 'أعد المحاولة',
    },
    succ: {
      title: 'تهانينا',
      text1: 'مرحبا بكم في نعوم،',
      text2: 'دعونا نبدأ معا!',
      btn: 'دعونا نفعل بعض السحر',
    },
  },
  welfare: {
    newUser: {
      label: 'فوائد المستخدم الجديد:',
      joinBtn: 'نضم الان',
      text: '100 مكان متاح فقط! احصل على منتجك الآن!',
      remain: 'بقي 30 فقط',
    },
  },
  help: {
    Fixedplugin: 'البرنامج المساعد الثابت',
    Howtouse: 'كيف تستعمل',
    try: 'جربها',
    FreeTrialBenefits: 'فوائد التجربة المجانية',
    pin: 'دبوس نعوم، لتسهيل الوصول إليه～～',
    Video: 'فيديو',
    ParallelTranslation: 'الترجمة الموازية',
    WebSummary: 'ملخص الويب',
    ChatPDF: 'الدردشة بصيغة PDF',
    AquickOverviewOfNoam: 'لمحة سريعة عن نعوم',
    WordSelectionTranslation: 'ترجمة اختيار الكلمات',
    HoverTranslation: 'تحوم الترجمة',
    ImageTranslation: 'ترجمة الصور',
    InstantTranslation: 'ترجمة فورية',
    VideoTranslation: 'ترجمة الفيديو',
    benefits: 'لقد أعددنا الفوائد لك!',
    Free: 'حر',
    Membership: 'عضوية',
    Translation: 'ترجمة',
    PDFparallelTranslation: 'ترجمة موازية لقوات الدفاع الشعبي',
    ChatPDF2: 'ChatPDF',
    Summary: 'ملخص',
    VideoSummary: 'ملخص الفيديو',
    FavoritesManagement: 'إدارة المفضلة',
    Focusing: 'التركيز',
    dayFree: 'تجربة مجانية لمدة 3 أيام',
    planIsNotExist: 'الخطة غير موجودة',
    TryItForFree: 'قم بتجربته مجانا',
    startYoutube: 'لنبدأ مع يوتيوب ~',
    startWiki: 'لنبدأ مع ويكيميديا~',
    tryNow: 'جربها الآن~',
    GoWithYouTube: 'اذهب مع يوتيوب',
    GoWithWikipedia: 'اذهب مع ويكيبيديا',
    NoamHelp: 'يستطيع نعوم مساعدتك في الترجمة والتلخيص',
    SkipAllSteps: 'تخطي كافة الخطوات',
    next: 'التالي',
    done: 'منتهي',
  },
  trialModal: {
    title: 'هدية الترحيب',
    trialText: 'تجربة لمدة 7 أيام',
    desc1: 'بعد انتهاء النسخة التجريبية، سيتم محاسبتك برسوم سنوية قدرها 180 دولارًا',
    desc2: 'إلغاء في أي وقت',
    btn: 'ابدأ الآن (فقط 0.99 دولارًا)',
    installBtn: 'قم بالتثبيت الآن لتحصل على هديتك',
    web: 'الترجمة الموازية',
    select: 'ترجمة اختيار الكلمات',
    hover: 'تحوم الترجمة',
    image: 'ترجمة الصور',
    realtime: 'ترجمة فورية',
    video: 'ترجمة الفيديو',
    pdf: 'ترجمة موازية لقوات الدفاع الشعبي',
    chat: 'ChatPDF',
    webSummary: 'ملخص الويب',
    videoSummary: 'ملخص الفيديو',
    memo: 'إدارة المفضلة',
    focus: 'التركيز',
  },
  privacyCollectionPanel: {
    title: 'هذا الموقع يستخدم الكوكيز',
    text: 'لتحسين تجربتك، نود استخدام ملفات تعريف الارتباط لتخصيص المحتوى والخدمات.',
    acceptBtn: 'قبول الكل',
  },
  installTips: {
    text: 'قم بتثبيت "Noam Browser Plugin" لفتح المزيد من الميزات! الإسناد الترافقي على الويب,\n    تلخيص فيديو الويب، والمزيد!',
    btn: 'جربه الآن',
  },
}

export default TRANSLATION
