import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { throttle } from 'lodash'
import { userService } from '../userService'
import { TranslateServiceConsts as Consts } from './consts'
import { TranslateServiceType as Type } from './serviceTypes'

type Locale = Type.Locale
type Paragraph = Type.Paragraph
type TranslateResult = Type.TranslateResult
type LocaleWithAutoDetect = Type.LocaleWithAutoDetect
type TriggerType = PostDataTypes.UseType

interface ParagraphGroup {
  merged: boolean
  mergedText: string
  from: LocaleWithAutoDetect
  to: Locale
  paragraphs: Array<Paragraph>
  textType: 'html' | 'plain'
}

class TranslateService {
  protected storagePrefix = ''
  private localeList: Type.LocaleList = []
  private db!: IDBDatabase

  private translatedTextCacheMap: {
    [key: string]: string
  } = {}

  private updateUsageInfoThrottleCal = throttle(
    () => {
      userService.updateUserInfo()
    },
    5000,
    { leading: true }
  )

  // 网页对照翻译、PDF 翻译、悬浮翻译、字幕翻译
  public async translate(
    {
      paragraphs,
      taskUid,
      originTitle,
      originUrl,
      triggerType,
      detectLang,
      shareHashId,
      fileId,
      context,
    }: {
      paragraphs: Array<Paragraph>
      taskUid: string
      originUrl: string
      originTitle: string
      triggerType: TriggerType
      detectLang?: Type.Locale
      shareHashId?: string
      fileId: number
      context?: string
    },
    callback: (results: Array<TranslateResult>) => void
  ): Promise<void> {
    if (!paragraphs || paragraphs.length <= 0) {
      callback([])
      return
    }
    // deepl 翻译引擎需要标点符号后面加空格
    paragraphs.forEach((p) => {
      p.text = this.addWhiteSpace(p.text)
    })
    const paragraphGroups = this.mergeParagraph(paragraphs)
    await this.translateParagraphGroups({
      originTitle,
      originUrl,
      paragraphGroups,
      taskUid,
      triggerType,
      detectLang,
      shareHashId,
      fileId,
      context,
      callback,
    })
  }

  public async getLocaleList(): Promise<Type.LocaleList> {
    return this.sortLocale(this.localeList)
  }

  public async clearTranslateCache() {
    this.translatedTextCacheMap = {}
    storageHelper.set({ translatedTextCacheMap: {} })
  }

  public init() {
    this.initIndexedDB()
    this.initLocaleList()
  }

  private initLocaleList() {
    const allLocales = Consts.ALL_LOCALES
    this.localeList = []
    Object.keys(allLocales).forEach((key) => {
      const item = allLocales[key]
      this.localeList.push({
        locale: key,
        name: item['name'],
        localName: `${item['nativeName']}`,
        chineseName: item.chineseName || item.nativeName,
      })
    })
  }

  private sortLocale(_languages: Type.LocaleList): Type.LocaleList {
    const defaultLocaleSortList = ['en', 'ja', 'ko', 'es', 'de', 'fr', 'fr-CA', 'pt', 'pt-PT', 'ru']
    const languages = [..._languages]
    const sortLanguages: Type.LocaleList = []
    defaultLocaleSortList.forEach((locale) => {
      const targetLocale = languages.find((l) => l.locale === locale)
      const targetLocaleIndex = languages.findIndex((l) => l.locale === locale)
      if (targetLocale) {
        sortLanguages.push(targetLocale)
        languages.splice(targetLocaleIndex, 1)
      }
    })
    return sortLanguages.concat(languages)
  }

  private async translateByXunFeiTranslate({
    options,
    triggerType,
    callback,
  }: {
    options: PostDataTypes.TranslateOptions
    triggerType: TriggerType
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    const normalizedOptions = this.normalizeOptionsLocale(options)
    try {
      const { respContentList } = await api.pdf.translate(
        {
          ...normalizedOptions,
          useType: triggerType,
        },
        userService.isLogin
      )
      callback(
        null,
        respContentList.map((data, index) => {
          return {
            data,
            index,
          }
        })
      )
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // api.recordError(`xunfei_${commonUtils.formatErrorMsg(error)}`, {
      //   ...normalizedOptions,
      //   useType: triggerType,
      // })
      callback(commonUtils.formatErrorMsg(error), [])
    }
  }

  private async translateByAiTranslate({
    options,
    triggerType,
    callback,
  }: {
    options: PostDataTypes.TranslateOptions
    triggerType: TriggerType
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    const normalizedOptions = this.normalizeOptionsLocale(options)
    try {
      const { taskId } = await api.pdf.aiTranslate({
        ...normalizedOptions,
        useType: triggerType,
      })
      console.time('ai_translate ')
      await this.fetchAiTranslateResult({
        taskId,
        options: normalizedOptions,
        callback,
      })
      console.timeEnd('ai_translate')
    } catch (error) {
      // api.recordError(`ai_${commonUtils.formatErrorMsg(error)}`, {
      //   ...normalizedOptions,
      //   useType: triggerType,
      //   translateType: 'ai',
      // })
      callback(commonUtils.formatErrorMsg(error), [])
    }
  }

  private async fetchAiTranslateResult({
    taskId,
    options,
    callback,
  }: {
    taskId: number
    options: PostDataTypes.TranslateOptions
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    try {
      const { status, data } = await api.pdf.getAiTranslateResult(taskId)
      callback(null, data)
      // NOTE: 增加最大循环次数判断
      if (status === 'running') {
        await commonUtils.asyncDelay(1 * 1000)
        await this.fetchAiTranslateResult({ taskId, options, callback })
      }
    } catch (error) {
      // api.recordError(`ai_result_${commonUtils.formatErrorMsg(error)}`, {
      //   ...options,
      //   taskId,
      // })
      callback(commonUtils.formatErrorMsg(error), [])
    }
  }

  private mergeParagraph(paragraphs: Array<Paragraph>): Array<ParagraphGroup> {
    const paragraphGroups: Array<ParagraphGroup> = []
    // 限制详见 https://learn.microsoft.com/zh-cn/azure/cognitive-services/Translator/service-limits#character-and-array-limits-per-request
    // 一段 paragraphGroup 最多包含 40 * 1000 个字符
    const maxMergedTextLength = 40 * 1000
    // 一段 paragraphGroup 最多包含 100 个 payloads
    const maxParagraphCount = 1000
    const pushNewParagraphGroup = (paragraph: Paragraph) => {
      paragraphGroups.push({
        merged: false,
        mergedText: paragraph.text,
        from: paragraph.from,
        to: paragraph.to,
        paragraphs: [paragraph],
        textType: paragraph.htmlStr ? 'html' : 'plain',
      })
    }
    paragraphs.forEach((paragraph) => {
      const matchedGroup = this.findMatchedParagraphGroup(paragraph, paragraphGroups)
      if (matchedGroup) {
        if (
          matchedGroup.paragraphs.length < maxParagraphCount &&
          matchedGroup.mergedText.length + paragraph.text.length < maxMergedTextLength
        ) {
          matchedGroup.paragraphs.push(paragraph)
          matchedGroup.mergedText += paragraph.text
        } else {
          matchedGroup.merged = true
          pushNewParagraphGroup(paragraph)
        }
      } else {
        pushNewParagraphGroup(paragraph)
      }
    })
    return paragraphGroups
  }

  private findMatchedParagraphGroup(
    paragraph: Paragraph,
    paragraphGroups: Array<ParagraphGroup>
  ): ParagraphGroup | undefined {
    return paragraphGroups.find(({ merged, from, to, textType }) => {
      const paragraphTextType = paragraph.htmlStr ? 'html' : 'plain'
      const textTypeIsMatched = paragraphTextType === textType
      return !merged && from === paragraph.from && to === paragraph.to && textTypeIsMatched
    })
  }

  private getParagraphCacheKey(p: Paragraph): string {
    const { to, from, text } = p
    const md5 = commonUtils.md5(`$ai-${to}-${from}-${text}`)
    return md5.substring(8, 24)
  }

  private async translateParagraphGroup({
    pageTitle,
    pageUrl,
    paragraphGroup,
    taskUid,
    triggerType,
    detectLang,
    shareHashId,
    fileId,
    context,
    callback,
  }: {
    paragraphGroup: ParagraphGroup
    taskUid: string
    pageTitle: string
    pageUrl: string
    triggerType: TriggerType
    detectLang?: Type.Locale
    shareHashId?: string
    fileId: number
    context?: string
    callback: (results: Array<TranslateResult>) => void
  }): Promise<void> {
    const { from, textType, to, paragraphs } = paragraphGroup
    const options: PostDataTypes.TranslateOptions = {
      fromLang: from,
      taskUid,
      textList: paragraphs.map((p) => {
        return p.htmlStr || p.text
      }),
      toLang: to,
      textType,
      pageUrl,
      pageTitle,
      translateType: 'ai',
      detectLang,
      shareHashId,
      fileId,
      context,
    }
    await this.tryTranslateByServer(options, paragraphs, triggerType, callback)
  }

  private tryGetCachedParagraphs(paragraphs: Array<Paragraph>): {
    needServiceTranslateParagraphs: Array<Paragraph>
    cachedResults: Array<TranslateResult>
  } {
    const needServiceTranslateParagraphs: Array<Paragraph> = []
    const cachedResults: Array<TranslateResult> = []
    paragraphs.forEach((p) => {
      const cachedValue = this.getCachedTranslate(p)
      if (cachedValue) {
        cachedResults.push({
          ...p,
          result: cachedValue,
          detectedLang: p.from,
          error: null,
        })
      } else {
        needServiceTranslateParagraphs.push(p)
      }
    })
    return {
      needServiceTranslateParagraphs,
      cachedResults,
    }
  }

  private async translateParagraphGroups({
    paragraphGroups,
    originTitle,
    originUrl,
    taskUid,
    triggerType,
    detectLang,
    shareHashId,
    callback,
    fileId,
    context,
  }: {
    paragraphGroups: Array<ParagraphGroup>
    taskUid: string
    originUrl: string
    originTitle: string
    triggerType: TriggerType
    detectLang?: Type.Locale
    shareHashId?: string
    fileId: number
    context?: string
    callback: (results: Array<TranslateResult>) => void
  }): Promise<void> {
    await Promise.all(
      paragraphGroups.map(async (s) => {
        return this.translateParagraphGroup({
          pageTitle: originTitle,
          pageUrl: originUrl,
          paragraphGroup: s,
          taskUid,
          triggerType,
          detectLang,
          shareHashId,
          fileId,
          context,
          callback,
        })
      })
    )
  }

  private async tryTranslateByServer(
    options: PostDataTypes.TranslateOptions,
    paragraphs: Paragraph[],
    triggerType: TriggerType,
    callback: (results: Array<TranslateResult>) => void
  ): Promise<void> {
    const { fromLang } = options
    const results: Array<TranslateResult> = []
    const { cachedResults, needServiceTranslateParagraphs } =
      this.tryGetCachedParagraphs(paragraphs)
    // 获取缓存数据
    callback(cachedResults)
    if (needServiceTranslateParagraphs.length === 0) {
      callback([])
      return
    }
    const translatedParagraphs: Array<TranslateResult> =
      needServiceTranslateParagraphs as Array<TranslateResult>

    await this.translateByServer({
      options: {
        ...options,
        textList: needServiceTranslateParagraphs.map((p) => {
          return p.htmlStr || p.text
        }),
      },
      triggerType,
      callback: (err, data) => {
        if (err) {
          const errMsg = commonUtils.formatErrorMsg(err) || '翻译失败，请稍后重试或联系客服'
          results.push(
            ...translatedParagraphs
              .filter((p) => !p.result)
              .map((p) => {
                return {
                  ...p,
                  result: '',
                  detectedLang: fromLang,
                  error: errMsg,
                }
              })
          )
          callback(results)
        } else {
          const cacheList: Array<{ p: Paragraph; cacheData: string }> = []
          results.push(
            ...data.map(({ data, index }) => {
              const p = translatedParagraphs[index]
              cacheList.push({
                p,
                cacheData: data,
              })
              return {
                ...p,
                result: data,
                detectedLang: fromLang,
                error: null,
              }
            })
          )
          // 更新缓存数据
          this.setTranslateToCache(cacheList)
          callback(results)
        }
      },
    })
  }

  private async translateByServer({
    options,
    triggerType,
    callback,
  }: {
    options: PostDataTypes.TranslateOptions
    triggerType: TriggerType
    callback: (err: string | null, results: Array<ServerDataTypes.AiTranslateResult>) => void
  }) {
    // NOTE：默认调用机器翻译接口
    const translateType: 'xunfei' | 'ai' = 'xunfei' as unknown as 'xunfei' | 'ai'
    if (translateType === 'xunfei') {
      await this.translateByXunFeiTranslate({
        options,
        triggerType,
        callback,
      })
    }
    if (translateType === 'ai') {
      await this.translateByAiTranslate({
        options,
        triggerType,
        callback,
      })
    }
  }

  private normalizeOptionsLocale(
    options: PostDataTypes.TranslateOptions
  ): PostDataTypes.TranslateOptions {
    const normalizedOptions = { ...options }
    normalizedOptions.fromLang = commonUtils.standardizeLocale(normalizedOptions.fromLang)
    if (normalizedOptions.fromLang === Consts.AUTO_DETECT_LANG) {
      normalizedOptions.fromLang = 'auto'
    }
    normalizedOptions.fromLang = commonUtils.standardizeLocale(normalizedOptions.fromLang as Locale)
    normalizedOptions.toLang = commonUtils.standardizeLocale(normalizedOptions.toLang as Locale)
    return {
      ...normalizedOptions,
    }
  }

  private async loadStorageData() {
    console.log('load stroage data')
    // Open our object store and then get a cursor list of all the different data items in the IDB to iterate through
    const objectStore = this.db
      .transaction('translatedTextCacheMap')
      .objectStore('translatedTextCacheMap')
    objectStore.openCursor().onsuccess = (event: any) => {
      const cursor = event.target.result
      // Check if there are no (more) cursor items to iterate through
      if (!cursor) {
        // No more items to iterate through, we quit.
        console.log('Entries all displayed.')
        return
      }

      // Check which suffix the deadline day of the month needs
      const { data } = cursor.value
      if (data) {
        this.translatedTextCacheMap = data
      }
      // continue on to the next item in the cursor
      cursor.continue()
    }
  }

  private getCachedTranslate(p: Paragraph) {
    return this.translatedTextCacheMap[this.getParagraphCacheKey(p)]
  }

  // private async setTranslateToCache(
  //   list: Array<{ p: Paragraph; cacheData: string }>
  // ) {
  //   list.forEach(({ p, cacheData }) => {
  //     this.translatedTextCacheMap[this.getParagraphCacheKey(p)] = cacheData
  //   })
  //   const cachedSize = await chrome.storage.local.getBytesInUse()
  //   // 最大缓存 30 MB
  //   if (cachedSize > 30 * 1024 * 1024) {
  //     const translatedTextCacheMap: { [key: string]: string } = {}
  //     // 移除前 5w 条缓存数据
  //     Array.from(Object.keys(this.translatedTextCacheMap))
  //       .slice(50 * 1000)
  //       .forEach((key) => {
  //         translatedTextCacheMap[key] = this.translatedTextCacheMap[key]
  //       })
  //     this.translatedTextCacheMap = {
  //       ...translatedTextCacheMap,
  //     }
  //   }
  //   storageHelper.set({ translatedTextCacheMap: this.translatedTextCacheMap })
  // }

  private initIndexedDB() {
    // Let us open our database
    const DBOpenRequest = window.indexedDB.open('translatedTextCacheMap', 4)

    // Register two event handlers to act on the database being opened successfully, or not
    DBOpenRequest.onerror = (event) => {
      console.log('Error loading database.')
    }

    DBOpenRequest.onsuccess = (event) => {
      // Store the result of opening the database in the db variable. This is used a lot below
      this.db = DBOpenRequest.result

      this.loadStorageData()
    }
    DBOpenRequest.onupgradeneeded = (event: any) => {
      this.db = event.target.result

      this.db.onerror = (event) => {
        console.log('Error loading database.')
      }

      // Create an objectStore for this database
      const objectStore = this.db.createObjectStore('translatedTextCacheMap', {
        keyPath: 'key',
      })

      objectStore.createIndex('data', 'data', { unique: false })
    }
  }

  private setTranslateToCache(list: Array<{ p: Paragraph; cacheData: string }>) {
    if (!this.db) {
      return
    }
    list.forEach(({ p, cacheData }) => {
      this.translatedTextCacheMap[this.getParagraphCacheKey(p)] = cacheData
    })

    //     const cachedSize = this.db.estimatedSize
    // // 最大缓存 30 MB
    // if (cachedSize > 30 * 1024 * 1024) {
    //   const translatedTextCacheMap: { [key: string]: string } = {}
    //   // 移除前 5w 条缓存数据
    //   Array.from(Object.keys(this.translatedTextCacheMap))
    //     .slice(50 * 1000)
    //     .forEach((key) => {
    //       translatedTextCacheMap[key] = this.translatedTextCacheMap[key]
    //     })
    //   this.translatedTextCacheMap = {
    //     ...translatedTextCacheMap,
    //   }
    // }

    // Open a read/write DB transaction, ready for adding the data
    const transaction = this.db.transaction(['translatedTextCacheMap'], 'readwrite')

    // Report on the success of the transaction completing, when everything is done
    transaction.oncomplete = () => {
      console.log('Transaction completed: database modification finished.')
    }

    // Handler for any unexpected error
    transaction.onerror = () => {
      console.log(`Transaction not opened due to error: ${transaction.error}`)
    }

    // Call an object store that's already been added to the database
    const objectStore = transaction.objectStore('translatedTextCacheMap')
    // Make a request to add our newItem object to the object store
    const objectStoreRequest = objectStore.put({
      key: 'translateText',
      data: this.translatedTextCacheMap,
    })
    objectStoreRequest.onsuccess = (event) => {
      console.log('request sucess')
    }
  }

  private addWhiteSpace(text: string): string {
    const reg = /([.,])/g
    text = text.replace(reg, '$1 ')
    return text
  }
}

export const translateService = new TranslateService()
