import { uiController } from '@/controllers/uiController'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import { FC } from 'react'
import style from './CommonDialogLayout.module.less'
import BuyModal from '../BuyModal/BuyModal'
import PdfUpgradeModal from '../PdfUpgradeModal/PdfUpgradeModal'
import { userService } from '@/services/userService'
import TrialModal from '../TrialModal/TrialModal'
import { vipService } from '@/services/vipService'
import LoginModal from '../LoginModal/LoginModal'

interface CommonDialogLayoutProps {}

const CommonDialogLayout: FC<CommonDialogLayoutProps> = observer(
  (props: CommonDialogLayoutProps) => {
    return (
      <div>
        {/* 通用提示弹窗 */}
        <Modal className={style.commonModal} centered {...uiController.commonModalProps}>
          {uiController.commonModalProps.content}
        </Modal>

        {/* 购买弹窗 */}
        <BuyModal
          open={uiController.showBuyModal}
          onCancel={() => {
            uiController.showBuyModal = false
          }}
          onConfirm={() => {
            userService.updateUserInfo()
            uiController.showBuyModal = false
          }}
        ></BuyModal>

        <TrialModal open={uiController.showTrialModal} plan={vipService.trialPlan!} />

        {/* ChatPdf 提問次數已用完彈窗 */}
        <PdfUpgradeModal
          type='chat'
          open={uiController.pdfChatTimeLimited}
          onClose={() => {
            uiController.pdfChatTimeLimited = false
          }}
        ></PdfUpgradeModal>
        {/* PDF 上传次数已用完彈窗 */}
        <PdfUpgradeModal
          type='upload'
          open={uiController.pdfUploadTimeLimited}
          onClose={() => {
            uiController.pdfUploadTimeLimited = false
          }}
        ></PdfUpgradeModal>
        {/* PDF 上传页数超出限制彈窗 */}
        <PdfUpgradeModal
          type='pageLimit'
          open={uiController.pdfUploadPageLimited}
          onClose={() => {
            uiController.pdfUploadPageLimited = false
          }}
        ></PdfUpgradeModal>

        {/* 登录弹窗 */}
        <LoginModal {...uiController.loginModalProps}></LoginModal>
      </div>
    )
  }
)

export default CommonDialogLayout
