import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import style from './ContactText.module.less'
import { useTranslationWithType } from '@/i18n/i18n'

/** ContactText 组件的 Props */
export type ContactTextProps = {
  style?: React.CSSProperties
}

const ContactText: FC<ContactTextProps> = observer((props: ContactTextProps) => {
  const { t } = useTranslationWithType()
  return (
    <div
      style={props.style}
      className={classNames(style.contactText)}
      dangerouslySetInnerHTML={{
        __html: t('stripe.contact').replace(
          'contact@noam.tools',
          ` <a class="${style.email}" href='mailto:contact@noam.tools' >contact@noam.tools</a> `
        ),
      }}
    ></div>
  )
})

export default ContactText
