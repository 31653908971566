import { get, post } from './common'

export namespace pricingModal {
  type VipTimeType = 'month_subscription' | 'year_subscription'
  export interface VipPlan {
    id: string
    originPrice: number
    price: number
    priceUnit: 'cents'
    timeType: VipTimeType
    tokenCount: number
    count: number
    newUserDiscount?: boolean
    version: 'pro' | 'pro+'
    activity: boolean
    activityType?: 'new_user'
    trialDays: number
    trialPrice: number
    activeTime: number
    priceType?: 'year' | 'month'
  }
}
export namespace pricing {
  export function list() {
    return get<{
      planList: pricingModal.VipPlan[]
    }>('/vip/plan_list', {}, true)
  }

  export function checkout(data: {
    planId: string
    code?: string
    isTrial?: boolean
    source: string
  }) {
    return post<{ data: { payload: string; key: string } }>('/fastspring/checkout', {}, data, true)
  }

  export function stripeCheckout(data: {
    planId: string
    successUrl: string
    cancelUrl: string
    isTrial?: boolean
  }) {
    return post<{ payUrl: string }>('/stripe/checkout', {}, data, true)
  }

  export function getTrialPlan() {
    // trial:是否符合试用条件
    return get<{ data: pricingModal.VipPlan; trial: boolean }>('/user/trail_plan', {}, true)
  }

  export function getUserTrialType() {
    return get<{ data: pricingModal.VipPlan; type: 'new_user_gift' | 'trail' }>(
      '/user/trail_type',
      {},
      true
    )
  }
}
